import Accordion from 'react-bootstrap/Accordion';

import MailTo from './MailTo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';

export default function FAQsCommonItems(props) {
    return (<>
        <Accordion.Item eventKey="0">
            <Accordion.Header>I have received an invitation to register, but accidentally clicked ‘decline’ against the terms and conditions rather than ‘accept’. What do I do now?</Accordion.Header>
            <Accordion.Body>
                Log in again using your username and password and click ‘accept’.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
            <Accordion.Header>What is my username?</Accordion.Header>
            <Accordion.Body>
                Your username is your email address.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
            <Accordion.Header>What can I do if I have forgotten my password for the Learning Portal?</Accordion.Header>
            <Accordion.Body>
                On the log in page of the Learning Portal, there is a ‘forgot your password?’ link. You simply click on this link and then enter your email address. An email will be sent to you with instructions on how to reset your password. If you do not receive an email, please contact the {props.serviceLabel} Team at <MailTo email={`${props.serviceEmail}?subject=LMS Support Query`}>{props.serviceEmail}</MailTo> or via the support page (the question mark <FontAwesomeIcon icon={faCircleQuestion} className='m-0' /> in the left hand navigation bar).
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
            <Accordion.Header>How can I change my existing password?</Accordion.Header>
            <Accordion.Body>
                When you are logged into the Learning Portal, open your profile page and click ‘change password’.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
            <Accordion.Header>When and how can I access the Learning Portal?</Accordion.Header>
            <Accordion.Body>
                You can access the Learning Portal anytime from anywhere such as your workplace or your home, using a PC, laptop or tablet.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
            <Accordion.Header>What are the minimum computer requirements to use the Learning Portal?</Accordion.Header>
            <Accordion.Body>
                The only requirement is that your browser is up to date, we recommend using the latest version of Google Chrome or Microsoft Edge. For admin users a minimum screen width of 600px is required.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
            <Accordion.Header>How secure is the Learning Portal?</Accordion.Header>
            <Accordion.Body>
                The Learning Portal has been designed to comprise of multiple layers of security to ensure that your activities are not accessible to others.
            </Accordion.Body>
        </Accordion.Item>
    </>
    );
}
