import BaseContainer from "../components/BaseContainer";
import { BeginButton } from '../components/CustomButtons';
import { useEffect,  useState } from 'react';
import { Row } from 'react-bootstrap';
import { faPeopleLine, faFileChartColumn, faPersonChalkboard } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

import useLmsStore from '../hooks/useLmsStore'
import shallow from 'zustand/shallow';

export default function TeamDashboard(props) {
    const [showReportsGroup2, setShowReportsGroup2] = useState(false);
    const { personSettings } = useLmsStore(state =>
        ({
            personSettings: state.personSettings.data,
        }), shallow)
        useEffect(() => {
            setShowReportsGroup2(window.location.hostname.includes('localhost') || window.location.hostname.includes('cerespoweracademy') || window.location.hostname.includes('lms'));
        }, []);
    return (<BaseContainer title='Team Dashboard' >
        <div style={{marginTop:'10px', maxHeight: '650px', overflow: 'auto', overflowX: 'hidden'}}>
            <Row className='gx-4 gy-4 mt-0'>
                {
                    personSettings.isManager === true ?
                    <BeginButton title='Team Profiles' description='View all users learning plans, learning pathways and review their progress.' icon={faPeopleLine} to='/lms/myteamprofiles' />
                    :
                    <></>
                }
                {
                    personSettings.isApplicationAdministrator === true || personSettings.isAdministrator === true || personSettings.isManager === true ?
                    <BeginButton title='Team Reports' description='Run reports.' icon={faFileChartColumn} to='/lms/reports' />
                    :
                    <></>
                }
        
            </Row>
        </div>
    </BaseContainer>);
}
