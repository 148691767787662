import { useState, useMemo, useCallback } from 'react';
import { Row, Button, OverlayTrigger, Tooltip, Modal  } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus, faEye } from '@fortawesome/pro-regular-svg-icons';
import { TableContainer } from '../helpers/tableHelper';
import MaterialReactTable from 'material-react-table';
import { getCorrencySign } from '../helpers/formatHelper';

import ModalAddress from './ModalAddress';
import ModalRoom from './ModalRoom';

const TabCompanyAddress = ({ submitFn = (obj) => null, hasChangedFn = () => null, closeFn = (obj) => null, addresses, addressTypes, allCountries, allCounties, allTowns }) => {
    const [showRooms, setShowRooms] = useState(false);
    const [showModalAdr, setShowModalAdr] = useState(false);
    const [showModalRm, setShowModalRm] = useState(false);
    const [room, setRoom] = useState([]);
    const [address, setAddress] = useState([]);
    const [addressId, setAddressId] = useState(0);
    const [addressesVar, setAddresses] = useState(addresses);
    const [adrRooms, setRooms] = useState([]);
    const [inProgress, setInProgress] = useState(false);
    const [apply, setApply] = useState(false);
    
    const [addressToDelete, setAddressToDelete] = useState(null);
    const [roomToDelete, setRoomToDelete] = useState(null);
    
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmDeleteModalRoom, setShowConfirmDeleteModalRoom] = useState(false);
       
    const handleSubmit = async event => {
        setInProgress(true);
        await submitFn(addressesVar);
        setInProgress(false);
        setApply(false);
    };

    const toggleModalAddress = () => {
        setShowModalAdr(!showModalAdr);
    }

    const toggleModalRoom = () => {
        setShowModalRm(!showModalRm);
    }

    const handleSelectRoom = (rowData) => {
        setShowRooms(false);
        setRooms([...rowData.rooms]);
        setShowRooms(true);
        setAddressId(rowData.addressId);
    }
    
    const handleRmAdd2 = (rowData) => {
        setRoom({addressId: rowData.addressId, 
            code: "",
            dailyCost: null, 
            dailyCostCurrencyTypeId: null,
            delegateCost :  null,
            delegateCostCurrencyTypeId : null,
            description : null,
            inHouseLunch : false,
            inHouseRefreshement : false,
            layoutTypes : null,
            maxStudents : null,
            minStudents : null,
            name : null,
            roomId: -1,
            squareFeet : null
        });
        setShowModalRm(true);
        setRooms([...rowData.rooms]);
        setShowRooms(true);
        setAddressId(rowData.addressId);
    }
    
    const handleRmAdd = () => {
        setRoom({addressId: addressId, 
            code: "",
            dailyCost: null, 
            dailyCostCurrencyTypeId: null,
            delegateCost :  null,
            delegateCostCurrencyTypeId : null,
            description : null,
            inHouseLunch : false,
            inHouseRefreshement : false,
            layoutTypes : null,
            maxStudents : null,
            minStudents : null,
            name : null,
            roomId: -1,
            squareFeet : null
        });
        setShowModalRm(true);       
    }

    const handleAdrAdd = () => {
        setApply(true);  
        hasChangedFn();      
        setAddress({"addressId":-1,"priority":null,"rooms":[],"addressType":null,"addressLine1":"","addressOthers":null,"townId":null,"county":null,"country":null,"postcode":""});
        setShowModalAdr(true);
    }
   
    const handleAdrDelete = useCallback(        
        (row) => {
            setAddressToDelete(row);
            setShowConfirmDeleteModal(true);
        },
        [],
    );

    const handleRmDelete = useCallback(
        (row) => {
            setRoomToDelete(row);
            setShowConfirmDeleteModalRoom(true);
        },
        [],
    );

    const handleAdrEdit = (rowData) => {
        setAddress(rowData ?? []);
        setShowModalAdr(true);
    }

    const editAdrRowFn = (obj) => {
        setApply(true);
        hasChangedFn();
        const newList = addressesVar.filter((item) => item.addressId !== obj.addressId);
        newList.push(obj);
        setAddresses([...newList]);
    }

    const editRmRowFn = (obj) => {
        setApply(true);
        hasChangedFn();
        const newList = adrRooms.filter((item) => item.roomId !== obj.roomId);
        newList.push(obj);
        setRooms([...newList]);
        const adrs = addressesVar.filter((item) => item.addressId !== addressId);
        const newItem = addressesVar.filter((item) => item.addressId === addressId);
        newItem[0].rooms = newList;
        adrs.push(newItem[0]);
        setAddresses([...adrs]);
    }  
   
    const confirmDelete = () => {
        if (addressToDelete) {
            setApply(true);
            hasChangedFn();
            const index = addressesVar.findIndex((item) => item.addressId === addressToDelete.addressId);
            if (index !== -1) {
                addressesVar.splice(index, 1);
                setAddresses([...addressesVar]);
            }
            setShowRooms(false);
            setShowConfirmDeleteModal(false);
        }
    };

    const cancelDelete = () => {
        setShowConfirmDeleteModal(false);
    };
     
     const confirmDeleteRoom = () => {        
        if (roomToDelete) {
            setApply(true);
            hasChangedFn();
            const editedItem = addressesVar.filter((item) => item.addressId === roomToDelete.addressId);
            const newList = editedItem[0].rooms.filter((item) => item.roomId !== roomToDelete.roomId);
            setRooms([...newList]);
            const adrs = addressesVar.filter((item) => item.addressId !== roomToDelete.addressId);            
            editedItem[0].rooms = newList;
            adrs.push(editedItem[0]);
            setAddresses([...adrs]);
            setShowRooms(false);
            setShowConfirmDeleteModalRoom(false);
        }        
    };

    const cancelDeleteRoom = () => {
        setShowConfirmDeleteModalRoom(false);
    };

    const handleRmEdit = (rowData) => {
        setRoom(rowData || []);
        setShowModalRm(true);
    }
  
    const columnsAdr = useMemo(
        () => [
            {
                accessorKey: "fullAddress",
                size: 300,
                header: "Address",
                sx: {
                    width: "80%"
                }               
            }, 
            {
                accessorKey: 'addressId',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'left',
                    sx: {
                        textOverflow: 'unset'
                    }
                },                
                size: 300,
                Cell: ({ cell, row }) => {
                    return (<>                      
                        <OverlayTrigger placement='left' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Edit address</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAdrEdit(row.original)}>
                                <FontAwesomeIcon size='xl' icon={faPencil} className='text-primary' />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Delete address</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAdrDelete(row.original)}>
                                <FontAwesomeIcon size='xl' icon={faTrash} className='text-primary' />
                            </Button>
                        </OverlayTrigger>
                    </>)
                }
            },
            {
                accessorKey: "rooms",
                header: "Rooms",                
                Cell: ({ cell, row }) => {
                    return (
                        <>                        
                            {cell.getValue().length > 0 ? cell.getValue().length : '0'}                       
                            <OverlayTrigger
                                placement="right"   
                                overlay={<Tooltip className='cls-theme-tooltip'>
                                    {cell.getValue().length > 0 ? 'View rooms' : 'Add room'}
                                    </Tooltip>}
                                >
                                <span style={{ marginLeft: cell.getValue().length > 0 ? '60px' : '70px', display: 'inline-block', alignItems: 'center' }}>
                                    <Button
                                        variant='contained'
                                        onClick={() =>
                                            cell.getValue().length > 0
                                            ? handleSelectRoom(row.original)
                                            : handleRmAdd2(row.original)
                                        }
                                        >
                                        <FontAwesomeIcon
                                            size='lg'
                                            icon={cell.getValue().length > 0 ? faEye : faPlus}
                                            className='text-primary'
                                        />
                                    </Button>
                                </span>                    
                        </OverlayTrigger> 
                        </>
                    )
                }
            }
        ],
        [],
    );

    const columnsRms = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Room",
            },
            {
                accessorKey: "code",
                header: "Room Code",
            },
            {
                accessorKey: "dailyCost",
                header: "Daily Rate",
                size: 200,
                // Cell: ({ cell, row }) => {
                //     return (<>{getCorrencySign(row.original.dailyCostCurrencyTypeId)}{cell.getValue()}</>)
                // }

                Cell: ({ cell, row }) => {
                    const cellValue = cell.getValue();
                    const currencySign = getCorrencySign(row.original.dailyCostCurrencyTypeId);
                  
                    if (!isNaN(cellValue)) {
                      return (
                        <>
                          {currencySign}{cellValue}
                        </>
                      );
                    } else {
                      return (
                        <>
                          N/A
                        </>
                      );
                    }
                  }
            },
            {
                accessorKey: 'roomId',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 50,
                Cell: ({ cell, row }) => {
                    return (<>                        
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Edit room</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleRmEdit(row.original)}>
                                <FontAwesomeIcon size='xl' icon={faPencil} className='text-primary' />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Delete room</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleRmDelete(row.original)}>
                                <FontAwesomeIcon size='xl' icon={faTrash} className='text-primary' />
                            </Button>
                        </OverlayTrigger>
                    </>)
                }
            }
        ],
        [],
    );      
    return (
        <>
        <Row>
            <TableContainer>
                <h5>Address</h5>
                <MaterialReactTable
                    columns={columnsAdr}
                    data={addressesVar || []}
                    enableSorting={false}                 
                    muiTableBodyRowProps={{ hover: false }}
                    enableBottomToolbar={true}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableGrouping={false}
                    enableHiding={false}
                    enableTopToolbar={true}
                    editingMode="cell"                    
                    enableColumnActions={true}
                    enableColumnFilters={true}                      
                    enablePagination={true}
                    muiTableProps={{
                        sx: {
                          tableLayout: 'fixed',
                        },
                      }}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add address</Tooltip>}>
                                <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAdrAdd()}>
                                    <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                </Button>
                            </OverlayTrigger>
                        </>
                    )}
                    initialState={{
                        pagination: { pageIndex: 0, pageSize: 5 },
                        showGlobalFilter: true
                    }}
                    state={{
                        isLoading: false
                    }}
                />
            </TableContainer>
        </Row>
        {showRooms?   
        <Row>
            <TableContainer>
                <h5>Rooms</h5>
                <MaterialReactTable
                    columns={columnsRms}
                    data={adrRooms || []} 
                    enableSorting={false}                 
                    muiTableBodyRowProps={{ hover: false }}
                    enableBottomToolbar={true}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableGrouping={false}
                    enableHiding={false}
                    enableTopToolbar={true}
                    editingMode="cell"                    
                    enableColumnActions={true}
                    enableColumnFilters={false}                      
                    enablePagination={true}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add room</Tooltip>}>
                                <Button variant='clear' className='pt-0 pb-0' onClick={() => handleRmAdd()}>
                                    <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                </Button>
                            </OverlayTrigger>
                        </>
                    )}
                    initialState={{
                        pagination: { pageIndex: 0, pageSize: 5 },
                        showGlobalFilter: true
                    }}
                    state={{
                        isLoading: false
                    }}
                />
            </TableContainer>
        </Row>
        :
        <></>
        }
        {/* <Button variant='primary' className="mt-2 float-right" onClick={closeFn}>Close</Button> */}
        {
            inProgress ?
            <Button variant="primary" className="mx-2 mt-2 float-right" disabled>
                <span className="spinner-border spinner-border-sm mx-2 mt-2" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                Please wait...
            </Button>
            : apply ?
            <Button variant="primary" className="mx-2 mt-2 float-right" onClick={handleSubmit}>
                Save
            </Button>
            :
            <Button variant="primary" className="mx-2 mt-2 float-right" disabled>
                Save
            </Button>
        }
        <ModalAddress closeFn={toggleModalAddress} open={showModalAdr} submitFn={(obj) => editAdrRowFn(obj)} address={address} addressTypes={addressTypes} allCountries={allCountries} allCounties={allCounties} allTowns={allTowns}/> 
        <ModalRoom closeFn={toggleModalRoom} open={showModalRm} submitFn={(obj) => editRmRowFn(obj)} room={room} /> 
                
        <Modal show={showConfirmDeleteModal} onHide={cancelDelete}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this address?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelDelete}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={confirmDelete}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showConfirmDeleteModalRoom} onHide={cancelDeleteRoom}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete this room?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelDeleteRoom}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={confirmDeleteRoom}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default TabCompanyAddress;