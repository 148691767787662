import React from 'react';
import {faUser, faLaptop, faList, faSearch, faTag} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {Button} from "react-bootstrap";

const learningItemsDeliveryMethodsEnum = {
    WDReLearning: "WDR eLearning",
    Instructor: "Instructor",
    Undefined: "Undefined",
}
const LearningItemSingleSearchResult = ({learningItem}) => {

    const deliveryMethodIconMapper = {
        [learningItemsDeliveryMethodsEnum.WDReLearning]: <FontAwesomeIcon icon={faLaptop}/>,
        [learningItemsDeliveryMethodsEnum.Instructor]: <FontAwesomeIcon icon={faUser}/>,
        [learningItemsDeliveryMethodsEnum.Undefined]: <FontAwesomeIcon icon={faList}/>,
    }

    const renderDeliveryMethodIcon = () => {
        return deliveryMethodIconMapper[learningItem.DeliveryMethod]
    }

    const renderButtons = () => {
        return <>
            <Button size={"sm"} onClick={() => {

            }} className={"w-100 d-flex justify-content-center align-items-center"}>
               <FontAwesomeIcon size={"sm"} icon={faSearch}/>
            </Button>
            <Button size={"sm"} onClick={() => {

            }} className={"w-100 d-flex justify-content-center align-items-center mt-1"}>
               <FontAwesomeIcon size={"sm"} icon={faTag}/>
            </Button>
        </>
    }

    return (
        <div className={"row mb-3 w-100 p-1"}>
            <div className={"col-2 d-flex flex-column align-items-center justify-content-center"}>
                <div className={""}>{renderDeliveryMethodIcon()}</div>
                <div className={"text-small"}> {learningItem.Code}</div>

            </div>
            <div className={"col-8"}>
                <div className={"text-medium"}>{learningItem.Title}</div>
            </div>
            <div className={"col-2"}>
                {renderButtons()}
            </div>
        </div>
    );
};

export default LearningItemSingleSearchResult;
