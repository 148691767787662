import { useEffect } from 'react';

import BaseContainer from "../components/BaseContainer";
import { BeginButton } from '../components/CustomButtons'

import { Row } from 'react-bootstrap';

import useLmsStore, { hrefToAdminServer,hasPermission } from '../hooks/useLmsStore';
import shallow from 'zustand/shallow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faUser, faUsers, faBooks, faListOl, faFileChartColumn, faRoad, faGear, faBuildings, faUserCheck, faPersonChalkboard,faLaptopFile } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

function ExternalTitle(props) {
    return (<>{props.title} <FontAwesomeIcon size='xs' icon={faArrowUpRightFromSquare} className='text-mutted' /></>);
};

export default function Administration(props) {
    const { personBasic, fetchPersonBasic } = useLmsStore(state =>
    ({
        personBasic: state.personBasic.data,
        fetchPersonBasic: state.fetchPersonBasic
    }), shallow)

    useEffect(() => {
        fetchPersonBasic();
    }, []);

    return (<BaseContainer title='Admin Dashboard'>
        <div style={{marginTop:'10px', maxHeight: '650px', overflow: 'auto',  overflowX: 'hidden'}}>
        <Row className='gx-4 gy-4 mt-0'>
            <BeginButton title={<ExternalTitle title='Learning Items' />} description='View, create and update learning items.' icon={faLaptopFile} href={hrefToAdminServer(personBasic, 'portal.learningItems')} target='admin-tab' />
            <BeginButton title='Users' description='View, create & update user records.' icon={faUser} to='/lms/users' />
            <BeginButton title='Create Users (Starters)' description='Upload starters feed file' icon={faUser} to='/lms/createusers' />
            <BeginButton title='Catalogues' description='View, create, update or mark catalogues as inactive and assign to users/groups.' icon={faBooks} to='/lms/catalogues' />
            <BeginButton title='Learning Plan Admin' description='View, create, update learning plans and assign to users/groups.' icon={faListOl} to='/lms/learningplan' />
            <BeginButton title={<ExternalTitle title='Groups' />} description='View, create and update groups, assign users and learning pathways to the group.' icon={faUsers} href={hrefToAdminServer(personBasic, 'portal.admin.groups')} target='admin-tab' />
            <BeginButton title='Reports' description='Run reports.' icon={faFileChartColumn} to='/lms/reports' />
            <BeginButton title={<ExternalTitle title='PathWays Admin' />} description='View, create, update and add learning items to learning pathways.' icon={faRoad} href={hrefToAdminServer(personBasic, 'portal.admin.trainingCards')} target='admin-tab' />
            <BeginButton title='Application Settings' description='Modify application settings.' icon={faGear} to='/lms/applicationsettings' />
            <BeginButton title='Companies' description='View, create, update or delete companies.' icon={faBuildings} to='/lms/companies' />
            <BeginButton title={<ExternalTitle title='Assessments' />} description='View assessments.' icon={faUserCheck} href={hrefToAdminServer(personBasic, 'portal.admin.assessments')} target='admin-tab' />
            { hasPermission(useLmsStore, 'ea.view')===true? <>
            <BeginButton title={<ExternalTitle title='Event Attendance' />} description='View & update event attendance for instructor-led events and upload copies of registers to the event.' icon={faUsers} href={hrefToAdminServer(personBasic, 'portal.admin.eventAttendance')} target='admin-tab' />
            </>: <></>}
            {process.env.REACT_APP_RNEXT === 'true' ? <BeginButton title='Experiment' description='Experiment.' icon={faBuildings} to='/lms/experiment' /> : null}
        </Row></div>
    </BaseContainer>);
}
