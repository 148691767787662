import { useEffect, useMemo, useState } from 'react';

import MaterialReactTable from 'material-react-table';

import BaseContainer from '../components/BaseContainer';

import { TableContainer } from '../helpers/tableHelper';

import { Button, OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';

import ProtectedAccess from '../components/ProtectedAccess';

import useLmsStore, { postDeleteAlertItem } from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faMessageExclamation } from '@fortawesome/pro-solid-svg-icons';

import { cellToLocaleDateTimeString } from '../helpers/formatHelper';

export default function Alerts(props) {
    const [tabId, setTabId] = useState('0');
    const [reloading, setReloading] = useState(false);

    const { administratorAlerts, applicationAdminAlerts, myAlerts, peopleAlertsToAction, fetchAdministratorAlerts, fetchApplicationAdminAlerts, fetchMyAlerts, fetchPeopleAlertsToAction } = useLmsStore(state =>
    ({
        myAlerts: state.myAlerts.data,
        peopleAlertsToAction: state.peopleAlertsToAction.data,
        administratorAlerts: state.administratorAlerts.data,
        applicationAdminAlerts: state.applicationAdminAlerts.data,
        fetchMyAlerts: state.fetchMyAlerts,
        fetchPeopleAlertsToAction: state.fetchPeopleAlertsToAction,
        fetchAdministratorAlerts: state.fetchAdministratorAlerts,
        fetchApplicationAdminAlerts: state.fetchApplicationAdminAlerts
    }), shallow)

    // on tabId change
    useEffect(() => {
        if (tabId === '0') {
            fetchMyAlerts();
        } else if (tabId === '1') {
            fetchPeopleAlertsToAction();
        } else if (tabId === '2') {
            fetchAdministratorAlerts();
        } else if (tabId === '3') {
            fetchApplicationAdminAlerts();
        }
    }, [tabId]);

    // on load
    useEffect(() => {
        fetchMyAlerts(true);
    }, []);

    const handleDeleteRow = async (alertId) => {
        setReloading(true);
        await postDeleteAlertItem(useLmsStore, alertId);
        if (tabId === '0') {
            fetchMyAlerts(true);
        } else if (tabId === '1') {
            fetchPeopleAlertsToAction(true);
        } else if (tabId === '2') {
            fetchAdministratorAlerts(true);
        } else if (tabId === '3') {
            fetchApplicationAdminAlerts(true);
        }
        setReloading(false);
    };


    const handleEventInfoRow = async (alertId) => {
       return true;
    };

    const columns = useMemo(
        () => [
            {
                accessorFn: (row) => row.alertId,
                id: 'icon',
                header: 'Icon',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 75,
                Cell: ({ cell, row }) => {
                    return (<FontAwesomeIcon icon={faMessageExclamation} className='text-info' size='lg' />)
                }
            },
            {
                accessorKey: 'createdDate',
                header: 'Created Date',
                size: 150,
                Cell: cellToLocaleDateTimeString
            },
            {
                accessorKey: 'title',
                header: 'Title',
                muiTableBodyCellProps: {
                    classes: { root: 'fw-unset' }   // Flexible width
                },
                Cell: ({ cell, row }) => <span dangerouslySetInnerHTML={{ __html: cell.getValue() }} />
            },
            {
                accessorKey: 'alertId',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 75,
                Cell: ({ cell, row }) => {
                    return (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Acknowledge and delete</Tooltip>}>
                        <Button variant='clear' className='pt-0 pb-0' onClick={() => handleDeleteRow(cell.getValue())}>
                            <FontAwesomeIcon size='xl' icon={faTrash} />

                        </Button>

                    </OverlayTrigger>
                    )
                }
            }
        ],
        [],
    );

    return (
        <BaseContainer title='Alerts'>
            <TableContainer>
                <MaterialReactTable
                    columns={columns}
                    data={((tabId === '2' && administratorAlerts) || (tabId === '3' && applicationAdminAlerts) || (tabId === '0' && myAlerts) || (tabId === '1' && peopleAlertsToAction)) || []}
                    enableColumnActions={false}
                    enablePagination={false}
                    enableSorting={false}
                    muiTableBodyRowProps={{ hover: false }}
                    muiTableHeadProps={{
                        sx: {
                            display: 'none'
                        }
                    }}
                    muiTableProps={{
                        sx: {
                            tableLayout: 'fixed',
                        },
                    }}
                    positionActionsColumn="last"
                    renderTopToolbar={({ table }) => (
                        <>
                            <ProtectedAccess
                                adminComponent={<Tabs activeKey={tabId} onSelect={(k) => setTabId(k)} className="pt-3 mb-2" style={{ backgroundColor: 'var(--app-bg)' }}>
                                    <Tab title='My Alerts' eventKey='0' />
                                    <Tab title='My Team Alerts' eventKey='1' />
                                    <Tab title='My Admin Alerts' eventKey='3' />
                                    <Tab title='My WDR Admin Alerts' eventKey='2' />
                                </Tabs>}
                                appAdminComponent={<Tabs activeKey={tabId} onSelect={(k) => setTabId(k)} className="pt-3 mb-2" style={{ backgroundColor: 'var(--app-bg)' }}>
                                    <Tab title='My Alerts' eventKey='0' />
                                    <Tab title='My Team Alerts' eventKey='1' />
                                    <Tab title='My Admin Alerts' eventKey='3' />
                                </Tabs>}
                                managerComponent={<Tabs activeKey={tabId} onSelect={(k) => setTabId(k)} className="pt-3 mb-2" style={{ backgroundColor: 'var(--app-bg)' }}>
                                    <Tab title='My Alerts' eventKey='0' />
                                    <Tab title='My Team Alerts' eventKey='1' />
                                </Tabs>}
                                defaultComponent={<Tabs activeKey={tabId} onSelect={(k) => setTabId(k)} className="pt-3 mb-2" style={{ backgroundColor: 'var(--app-bg)' }}>
                                    <Tab title='My Alerts' eventKey='0' />
                                </Tabs>}
                            />
                        </>
                    )}
                    state={{
                        isLoading: (tabId === '2' && !administratorAlerts) || (tabId === '3' && !applicationAdminAlerts) || (tabId === '0' && !myAlerts) || (tabId === '1' && !peopleAlertsToAction) || reloading
                    }}
                />
            </TableContainer>
        </BaseContainer>);

}
