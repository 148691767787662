import React, {useCallback} from 'react';
import {useDropzone} from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleUp} from "@fortawesome/pro-regular-svg-icons";

function UploadUsersDropZone({onSelectFile = () => null}) {
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length) {
            onSelectFile(acceptedFiles[0])
        }
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.csv'],
        },
        maxFiles:1
    })

    const getDropzoneClassNames = () => {
        return isDragActive ? "upload_users_drop_zone upload_users_drop_zone--active" : "upload_users_drop_zone"
    }

    return (
        <div className={getDropzoneClassNames()} {...getRootProps()} >
            <input {...getInputProps()} />

            {
                isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag 'n' drop the csv with users here, or click to select files</p>
            }
            <FontAwesomeIcon size='6x' icon={faArrowCircleUp} className='text-primary'/>
        </div>
    );
}

export default UploadUsersDropZone;
