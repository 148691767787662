import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, InputGroup, Modal, Button } from "react-bootstrap";
import Multiselect from 'multiselect-react-dropdown';
import RedAsterisk from '../helpers/RedAsterisk';

const ModalRoom = ({ closeFn = (obj) => null, open = false, submitFn = (obj) => null, room}) => {   
    const [validated, setValidated] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const options =[{id: 2, value:"Any"},{id: 3, value:"Conference"},{ id: 4, value:"U Shape"}, {id: 6, value:"Boardroom"}, {id: 5, value:"Theatre"} ];
    const [ltList, setLtList] = useState([]);
    const [newRoom, setRoom] = useState([]);
    const [minStudents, setMinStudents] = useState(room.minStudents ? room.minStudents : '');

    const handleMinStudentsChange = (event) => {
      setMinStudents(event.target.value);
    };

    const handleSwitchChange = event =>{
        let data = newRoom;
        data[event.target.id] = event.target.checked;
        setRoom(data);
    }
      
    const handleSubmit = async event => {
        const form = event.currentTarget;
        const minStudents = form["minStudents"].value;
        const maxStudents = form["maxStudents"].value;
        
        const delegateCost = form["delegateCost"].value;
        const dailyCost = form["dailyCost"].value;
        const delegateCostCurrencyTypeId = form["delegateCostCurrencyTypeId"].value;
        const dailyCostCurrencyTypeId = form["dailyCostCurrencyTypeId"].value;

        // console.log ("minStudents : " +  minStudents)
        // console.log ("maxStudents : " +  maxStudents)

        if (delegateCost !== "" && delegateCostCurrencyTypeId === "currency") {
            // Display an error message for the currency dropdown
            const currencyDropdownDelegate  = form["delegateCostCurrencyTypeId"];
            currencyDropdownDelegate.setCustomValidity("Please select a currency when Delegate Rate is entered.");
            setValidated(false);
        } else {
            // Reset any previous validation errors for the currency dropdown
            const currencyDropdownDelegate = form["delegateCostCurrencyTypeId"];
            currencyDropdownDelegate.setCustomValidity("");
        }
    
        if (dailyCost !== "" && dailyCostCurrencyTypeId === "currency") {
            // Display an error message for the currency dropdown
            const currencyDropdown = form["dailyCostCurrencyTypeId"];
            currencyDropdown.setCustomValidity("Please select a currency when Daily rate is entered.");
            setValidated(false);
        } else {
            // Reset any previous validation errors for the currency dropdown
            const currencyDropdown = form["dailyCostCurrencyTypeId"];
            currencyDropdown.setCustomValidity("");
        }

        if (minStudents > maxStudents) {
            // Display an error message for "Minimum Delegates" field
            const minStudentsField = form["minStudents"];
            minStudentsField.setCustomValidity("Minimum Delegates must be less than Maximum Delegates");
            setValidated(false);
        } else {
            // Reset any previous validation errors
            const minStudentsField = form["minStudents"];
            minStudentsField.setCustomValidity("");
            setValidated(true);
        }

        if (maxStudents < minStudents) {
            // Display an error message for "Minimum Delegates" field
            const minStudentsField = form["maxStudents"];
            minStudentsField.setCustomValidity("Maximum Delegates must be less than Minimum Delegates");
            setValidated(false);
        } else {
            // Reset any previous validation errors
            const minStudentsField = form["maxStudents"];
            minStudentsField.setCustomValidity("");
            setValidated(true);
        }

        if (!inProgress && form.checkValidity() === true) {  
            let data = newRoom;
            var elements = Array.from(form.elements)
            elements.map(el => {
                if(el.localName ==='input')
                {
                    if(el.type === "number"){
                        data[el.id]= parseInt(el.value,0);
                                            }
                    else if(el.type ==='checkbox')
                    {
                        //do nothing
                    }
                    else if(el.type ==='switch')
                    {
                        //do nothing
                    }
                    else if(el.type ==='date')
                    {
                        data[el.id]= el.value;
                    }
                    else if(el.readOnly!==true){
                        
                        data[el.id]=  el.value;
                    }
                }
                if(el.localName ==='select')
                {
                    data[el.id]= parseInt(el.value,0);
                }
            })
            data.layoutTypes = ltList;
            setInProgress(true);
            submitFn(data);
            setInProgress(false);
            closeFn();
        }    
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
    };

    const onSelect = (selectedList, selectedItem) => {
        setLtList(selectedList);
    };

    const onRemove = (selectedList, removedItem) => {
        setLtList(selectedList);
    };

    useEffect(() => {
        setRoom(room);
        setLtList(room.layoutTypes?? room.layoutTypes);
    }, [room]);

    return (
        <Modal size="lg" aria-labelledby="example-modal-sizes-title-lg" show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{room.roomId <= 0 ? 'Add Room' : 'Edit Room'}  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container fluid>
                <Row>
                    <h3>Room</h3>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} id="room-form">
                        <Row>
                            <Multiselect
                                options={options}
                                displayValue="value"
                                selectedValues={ltList}
                                onSelect={onSelect} // Function will trigger on select event
                                onRemove={onRemove} // Function will trigger on remove event
                                placeholder="Select/Add"
                                showCheckbox={true}
                                />
                            <Col>
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Room name <RedAsterisk/></Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control type="input" placeholder="name" defaultValue={room.name? room.name : ""} className="no-border" required />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a Room name.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control type="input" placeholder="description" defaultValue={room.description? room.description : ""} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="minStudents">
                                    <Form.Label>Minimum Delegates</Form.Label>
                                    <Form.Control type="number" min="0" placeholder="minimum students" 
                                     onChange={handleMinStudentsChange}
                                     defaultValue={room.minStudents? room.minStudents : ""} name="minStudents" />
                                    <Form.Control.Feedback type="invalid">
                                        Minimum Delegates must be less than Maximum Delegates.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="delegateCost">
                                    <Form.Label>Delegate rate</Form.Label>
                                    <Form.Control type="number" min="1" placeholder="delegate rate" defaultValue={room.delegateCost? room.delegateCost : ""} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="delegateCostCurrencyTypeId">
                                    <Form.Label>Currency</Form.Label>
                                    <Form.Select aria-label="Default select example" defaultValue={room.delegateCostCurrencyTypeId}>
                                    <option>currency</option>
                                    <option value={0}>EUR</option>
                                    <option value={1}>USD</option>
                                    <option value={2}>GBP</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a currency when Daily Cost is entered.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Check 
                                inline
                                type="switch"
                                id="inHouseRefreshement"
                                label="in house refreshment"
                                defaultChecked= {room.inHouseRefreshement}
                                onChange={handleSwitchChange} 
                                />
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="code">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control type="input" placeholder="code" defaultValue={room.code? room.code : ""} disabled />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="squareFeet">
                                    <Form.Label>Square Feet</Form.Label>
                                    <Form.Control type="input" placeholder="square feet" defaultValue={room.squareFeet? room.squareFeet : ""} />
                                </Form.Group>
                                 <Form.Group className="mb-3" controlId="maxStudents">
                                    <Form.Label>Maximum Delegates</Form.Label>
                                    <Form.Control type="number" min="0" placeholder="maximum students" 
                                    defaultValue={room.maxStudents? room.maxStudents : ""} name="maxStudents" />
                                    <Form.Control.Feedback type="invalid">
                                        Maximum Delegates must be greater than Minimum Delegates.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                   
                                <Form.Group className="mb-3" controlId="dailyCost">
                                    <Form.Label>Daily rate</Form.Label>
                                    <Form.Control type="number"  min="1" placeholder="daily rate" defaultValue={room.dailyCost? room.dailyCost: ""} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="dailyCostCurrencyTypeId">
                                    <Form.Label>Currency</Form.Label>
                                    <Form.Select aria-label="Default select example" defaultValue={room.dailyCostCurrencyTypeId}>
                                    <option>currency</option>
                                    <option value={0}>EUR</option>
                                    <option value={1}>USD</option>
                                    <option value={2}>GBP</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a currency when Daily Rate is entered.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Check 
                                inline
                                type="switch"
                                id="inHouseLunch"
                                label="in house lunch"
                                defaultChecked= {room.inouseLunch}
                                onChange={handleSwitchChange} 
                                />
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
                <Button variant="primary" type="submit" form="room-form">
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalRoom;