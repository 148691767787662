import axios from 'axios';
import create from "zustand";
import { devtools } from 'zustand/middleware'
import produce from 'immer';
import { toast } from 'react-toastify';
import {pca} from "../../index";

import MessageQ from '../helpers/messageQ'
import {createUsersResponseApiToFront} from "../helpers/converters/UsersConverter";
import {learningItemsTabEnum, searchGroupsInitialPayload} from "../helpers/constantsAndEnums";
import { responsiveProperty } from '@mui/material/styles/cssUtils';

export const HEADERS_JSON = { headers: { 'Content-Type': 'application/json' } };
export const HEADERS_MULTIPART = { headers: { 'Content-Type': 'multipart/form-data' } };
export const FORMCONFIRM ="Unsaved Changes  You have made changes that have yet to be saved. Are you sure you want to cancel and discard your changes?";

/**
 * Refresh the local data if more that REFRESH_AFTER milliseconds has elapsed.
 */
export const REFRESH_AFTER = 600000; // 5 minutes

export const WDR_HUB_URL = '/wdrlmshub';

export const apiAxios = axios.create({
    withCredentials: true,
    headers: { 'authorization': `Bearer ${window.localStorage.getItem('token') === null ? "" : window.localStorage.getItem('token').replace(/"/g, '')}` }
});

apiAxios.interceptors.request.use((config) => {
    // Abort if no token present (user not logged in)
    if (!window.localStorage.getItem('token')) {
        throw new Error('No Authorization Token');
    }
    return config;
}, (error) => {
    return Promise.reject(error); // Delegate error to calling side
});

apiAxios.interceptors.response.use((response) => {
    return response;
}, (error) => { // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    if (!error.response || status === 401) {
        forceLogin();
        // Clear token
    } else if (status > 499) {
        if (error.response && error.response.data && error.response.data.toLowerCase().includes('session expired')) {
            forceLogin();
        } else if (status === 504) {
            window.location = `${window.location.protocol}//${window.location.host}/lms/servererror?error=${status}`
        } else if (process.env.NODE_ENV !== 'development') {
            window.location = `${window.location.protocol}//${window.location.host}/lms/servererror?error=${status}`
        }
    } else {
        return Promise.reject(error); // Delegate error to calling side
    }
});

export function fetchByIdNoGlobalLockFn(set, get, propName, url, transformedDataFn = (response) => responseData(response)) {
    return (id, forceFetch = false) => {
        return new Promise((resolve, reject) => {
            // Ajax calls are async promises so ensure that each call is queued.
            // Identify each call with a unique call. When the lock is aquired,
            // it is time for that call to determine if the store data needs
            // refreshing before being returned by the promise.
            let lockId = getLockId();
            // Wait for the lock to be acquired.
            until(() => {
                //console.log('test RM=> ',get()[propName][id]);
                if (get()[propName][id] === undefined) {
                    // Acquiring the lock
                    set(produce((state) => { state[propName] = { ...state[propName], [id]: {data: undefined, loading: 0 }, loading: 0 } }));
                    //console.log(`test RM=> ${propName}${id} loading:  `, lockId);
                }else if (!(get()[propName][id].loading > 0)) {
                    // Acquiring the lock
                    set(produce((state) => { state[propName][id].loading = lockId; }));
                    //console.log(`test RM=> ${propName}${id} loading:  `, lockId);
                }
                return get()[propName][id].loading === lockId;
            }, 300000, `'${propName} ${lockId} ${url}' fetch failed to complete in the allocated time. This may be due to a slow server response time.`, ()=>{
                //do something to stop loading
                set(produce((state) => { state[propName] = { ...state[propName], [id]: {data: undefined, loading: 0 }, loading: 0 } }));
            }).then(() => {
                // Does the store data need refreshing?
                if (forceFetch || timeElapsed(get()[propName][id])) {
                    // Get the data from the server
                    apiAxios(`${url}/${id.toString().replace(/-/g, '\\')}`).then((response) => {
                        try {
                            if (!response || !response.status) {
                                throw new Error('Reponse format invalid.');
                            }
                            // Apply the tranformation function to the response
                            const data = transformedDataFn(response);
                            // Update the store and return the promised data
                            set(produce((state) => { state[propName] = response.status === 200 ? { ...state[propName], [id]: { data: data, time: new Date() }, loading: 0 } : { ...state[propName], [id]: { data: undefined, loading: 0 } } }));
                            resolve(data);
                        } catch (e1) {
                            // Report error and update the store. Store data not updated.
                            toastError(e1);
                            set(produce((state) => { 
                                state[propName][id].loading = 0;
                            }));
                            reject(e1);
                        }
                    }).catch((e2) => {
                        // Report error and update the store. Store data not updated.
                        toastError(e2);
                        set(produce((state) => { state[propName][id].loading = 0; }));
                        reject(e2);
                    });
                } else {
                    // No need to refresh the data so serve exisiting promised data
                    set(produce((state) => { state[propName][id].loading = 0; }));
                    resolve(get()[propName][id].data);
                }
                // });
            }).catch((e3) => {
                // Report error and update the store. Store data not updated.
                toastError(e3);
                set(produce((state) => { state[propName][id] = {  data: undefined, loading: 0 } }));
                reject(e3);
            });
        });
    };
};

export function fetchByIdFn(set, get, propName, url, transformedDataFn = (response) => responseData(response)) {
    return (id, forceFetch = false) => {
        return new Promise((resolve, reject) => {
            // Ajax calls are async promises so ensure that each call is queued.
            // Identify each call with a unique call. When the lock is aquired,
            // it is time for that call to determine if the store data needs
            // refreshing before being returned by the promise.
            let lockId = getLockId();
            // Wait for the lock to be acquired.
            until(() => {
                if (!(get()[propName].loading > 0)) {
                    // Acquiring the lock
                    set(produce((state) => { state[propName] = { ...state[propName], loading: lockId } }));
                }
                return get()[propName].loading === lockId;
            }, 120000, `'${propName} ${lockId}' fetch failed to complete in the allocated time. This may be due to a slow server response time.`, ()=>{
                //do something to stop loading
                set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
            }).then(() => {
                // Does the store data need refreshing?
                if (forceFetch || timeElapsed(get()[propName][id])) {
                    // Get the data from the server
                    apiAxios(`${url}/${id.toString().replace(/-/g, '\\')}`).then((response) => {
                        try {
                            if (!response || !response.status) {
                                throw new Error('Reponse format invalid.');
                            }
                            // Apply the tranformation function to the response
                            const data = transformedDataFn(response);
                            // Update the store and return the promised data
                            set(produce((state) => { state[propName] = response.status === 200 ? { ...state[propName], [id]: { data: data, time: new Date() }, loading: 0 } : { ...state[propName], [id]: { data: undefined } } }));
                            resolve(data);
                        } catch (e1) {
                            // Report error and update the store. Store data not updated.
                            toastError(e1);
                            set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
                            reject(e1);
                        }
                    }).catch((e2) => {
                        // Report error and update the store. Store data not updated.
                        toastError(e2);
                        set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
                        reject(e2);
                    });
                } else {
                    // No need to refresh the data so serve exisiting promised data
                    set(produce((state) => { state[propName] = { ...state[propName], loading: false } }));
                    resolve(get()[propName][id].data);
                }
                // });
            }).catch((e3) => {
                // Report error and update the store. Store data not updated.
                toastError(e3);
                set(produce((state) => { state[propName] = { ...state[propName], loading: false } }));
                reject(e3);
            });
        });
    };
};

export function fetchAsynTransformFn(set, get, propName, url, asyncTransformedDataFn) {
    if (!asyncTransformedDataFn) {
        throw new Error('asyncTransformedDataFn must be defined.');
    }
    return (forceFetch = false) => {
        return new Promise((resolve, reject) => {
            // Ajax calls are async promises so ensure that each call is queued.
            // Identify each call with a unique call. When the lock is aquired,
            // it is time for that call to determine if the store data needs
            // refreshing before being returned by the promise.
            let lockId = getLockId();
            // Wait for the lock to be acquired.
            until(() => {
                if (!(get()[propName].loading > 0)) {
                    // Acquiring the lock
                    set(produce((state) => { state[propName] = { ...state[propName], loading: lockId } }));
                }
                return get()[propName].loading === lockId;
            }, 300000, `'${propName} ${lockId}' fetch failed to complete in the allocated time. This may be due to a slow server response time.`, ()=>{
                //do something to stop loading
                set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
            }).then(() => {
                // Does the store data need refreshing?
                if (forceFetch || timeElapsed(get()[propName])) {
                    // Get the data from the server
                    apiAxios(url).then(async (response) => {
                        try {
                             if (!response || !response.status) {
                                throw new Error('Reponse format invalid.');
                            }
                            // Apply the tranformation function to the response
                            const data = await asyncTransformedDataFn(response);
                            // Update the store and return the promised data
                            set(produce((state) => { state[propName] = response.status === 200 ? { data: data, time: new Date(), loading: 0 } : { data: undefined } }));
                            resolve(data);
                        } catch (e1) {
                            // Report error and update the store. Store data not updated.
                            toastError(e1);
                            set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
                            reject(e1);
                        }
                    }).catch((e2) => {

                        // Report error and update the store. Store data not updated.
                        toastError(e2);
                        set(produce((state) => { state[propName] = { ...state[propName], loading: 0 } }));
                        reject(e2);
                    });
                } else {
                    // No need to refresh the data so serve exisiting promised data
                    set(produce((state) => { state[propName] = { ...state[propName], loading: 0} }));
                    resolve(get()[propName].data);
                }
                // });
            }).catch((e3) => {
                // Report error and update the store. Store data not updated.
                toastError(e3);
                set(produce((state) => { state[propName] = { ...state[propName], loading: false } }));
                reject(e3);
            });
        });
    };
};

export function fetchFn(set, get, propName, url, transformedDataFn = (response) => responseData(response)) {
    return fetchAsynTransformFn(set, get, propName, url, (response) => new Promise((resolve) => resolve(transformedDataFn(response))));
};

export function getLockId() { return Math.floor(Math.random() * 999999999999); };

export function postBooleanPromiseFn(url, noPostData = false, doToastSuccess = true) {
    return function (store, postData) {
        !noPostData && throwIfDataNotObject(postData);
        return new Promise(async (resolve) => {
            return apiAxios.post(url, postData, HEADERS_JSON).then((response) => {

                throwIfStatusNot200(response);

                if (!noPostData && doToastSuccess) {
                    toastSuccess();
                }
                resolve(true);
            }).catch((e) => {
                toastError(e);
                resolve(false);
            });
        });
    };
};

export function postDataPromiseFn(url, successDataFn = (store, postData, response) => responseData(response), failureDataFn = (store, postData) => null) {
    return function (store, postData) {
        //Zc - need to check with Xavier for postmethod that receive integers and not objects  throwIfDataNotObject(postData);
        return new Promise(async (resolve) => {
            return apiAxios.post(url, postData, HEADERS_JSON).then((response) => {
                throwIfStatusNot200(response);
                resolve(successDataFn(store, postData, response));
            }).catch((e) => {
                toastError(e);
                resolve(failureDataFn(store, postData));
            });
        });
    };
};

export function postFilePromiseFn(url, successDataFn = (store, postData, response) => responseData(response), failureDataFn = (store, postData) => null) {
    return function (store, postData) {
        //Zc - need to check with Xavier for postmethod that receive integers and not objects  throwIfDataNotObject(postData);
        return new Promise(async (resolve) => {
            return apiAxios.post(url, postData, HEADERS_MULTIPART).then((response) => {
                throwIfStatusNot200(response);
                resolve(successDataFn(store, postData, response));
            }).catch((e) => {
                toastError(e);
                resolve(failureDataFn(store, postData));
            });
        });
    };
};

/**
 * delete alert data.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postDeleteAlertItem = postBooleanPromiseFn('/api/alert/inactiveAlert', true);

function forceLogin() {
    // Clear token
    window.localStorage.setItem('token', null);
    if(pca.getActiveAccount()){
        pca.logoutRedirect()
    }
    window.location = `${window.location.protocol}//${window.location.host}/login`
};

export function throwIfDataIndexNotFound(methodName, idx, id) {
    if (idx === -1) {
        throw new Error(`${methodName}: Data item ${id} not found.`);
    }
};

export function throwIfDataNotObject(data) {
    if (typeof data !== 'object') {
        throw new Error(`Expected an object. Got a ${typeof data})`);
    }
};

export function throwIfDataNotValid(data) {
    if (!data.isValid) {
        throw new Error(`Data error (data.isValid: ${data.isValid})`);
    }
};

export function throwIfDataStatusNot200(data) {
    if (data.statusCode !== 200) {
        throw new Error(`Data error (data.statusCode: ${data.statusCode})`);
    }
};

export function throwIfStatusNot200(response) {
    if (response.status !== 200) {
        throw new Error(`Data error (status ${response.status})`);
    }
};

/**
 * Return true if data not set or the time elapsed since
 * the last property update is greater than REFRESH_AFTER.
 * 
 * @param {object} obj 
 * @returns 
 */
export const timeElapsed = (obj) => !obj || !obj.data || (obj.time instanceof Date && (new Date() - obj.time > REFRESH_AFTER));

export const toastError = (error = { message: 'Error' }, altMessage = null) => {
    console.error("Toast error : "+error);
    try {
        const msg = altMessage || error.message;
        // If the same messages are received within 5 sec of the first one then do not display.
        MessageQ.execute(msg, 5000, () => toast.error(msg, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light"
        }))
    } catch (e) {
        console.error(e);
    }
};

export const toastSuccess = (message = 'Data saved') => {
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
    });
};

export function until(conditionFn, timeout = 60000, errorMsg = 'Wait time exceeded.', onErrorFn = null) {
    let attempts = 0;
    const poll = async (resolve, reject) => {
        let waitFor = Math.floor(Math.random() * (2000 - 100)) + 100; // 10 to 200
        attempts += waitFor;
        if (await conditionFn()) {
            resolve();
        } else {
            if (attempts > timeout) {
                if(onErrorFn){
                    onErrorFn();
                }
                reject(new Error(errorMsg));
            } else {
                setTimeout(_ => poll(resolve, reject), waitFor);
            }
        }
    }
    return new Promise(poll);
};

async function allApplicationsTransform(response) {
    return new Promise(async (res) => {
        let appsList = responseData(response),
            promises = [],
            appDetails = {},
            data = [];
        if (appsList && appsList.length) {
            appsList.forEach((app) => {
                // Get the details and store in details object
                promises.push(apiAxios(`/api/application/GetAllApplicationSettingGroupDetail/${app.id}`).then((response) => {
                    appDetails[app.id] = responseData(response, []);
                }));
            });
            await Promise.all(promises);
            appsList.forEach((app) => appDetails[app.id].forEach((setting) => data.push({ applicationId: app.id, applicationName: app.name, name: setting.name, id: setting.id, settings: setting.settings })));
        }
        res(data);
    });
};

const store = (set, get) => ({
    activeTrainingPlan: { data: undefined },
    activeSortedTrainingPlan: { data: undefined },
    activeSortedTrainingPlanNew: { data: undefined },
    addressTypes: { data: undefined },
    administratorAlerts: { data: undefined },
    adminNews: { data: undefined },
    adminTrainingPlanGroupSettingInfo: { data: undefined },
    adminTrainingPlanItems: { data: undefined },
    adminTrainingPlanItemsByGroup: { data: undefined },
    allApplications: { data: undefined },
    allBookingCancellationReasons: { data: undefined },
    allCounties: { data: undefined },
    allCountries: { data: undefined },
    allLearningItems: { data: undefined },
    allNews: { data: undefined },
    allPersonCatalogueItems: { data: undefined },
    allTopPersonCatalogueItems: { data: undefined },
    allTowns: { data: undefined },
    applicationAdminAlerts: { data: undefined },
    applicationGroups: { data: undefined },
    applicationPeople: { data: undefined },
    applicationTrainingPlanSummaryS: { data: undefined },
    applicationTrainingPlans: { data: undefined },
    byAuthority: { data: undefined },
    bookingEvents: { data: undefined },
    teamBookingEvents: { data: undefined },
    catalogues: { data: undefined },
    companies: { data: undefined },
    companiesByApplication: { data: undefined },
    dashboardSummary: { data: undefined },
    employeeTypes: { data: undefined },
    myActiveSortedTrainingPlan: { data: undefined },
    myActiveSortedTrainingPlanNew: { data: undefined },
    createUsers: {
        data: [],
        loading: 0
    },
    learningItemsState: {
        learningItems:[],
        activeTab:learningItemsTabEnum.Detail,
        learningItemsCount:0,
        triggerSearch:0,
        searchLearningItemsPayload:{...searchGroupsInitialPayload},
        activeLearningItem:null
    },
    myActiveTrainingPlan: { data: undefined },
    myAlerts: { data: undefined },
    myLatestAlerts: { data: undefined },
    myLatestPersonNews: { data: undefined },
    myMandatoryTrainingPlan: { data: undefined },
    myOverdueTrainingPlanItems: { data: undefined },
    myPathWays: { data: undefined },
    myPathWayDetail: { data: undefined },
    myTopAlerts: { data: undefined },
    negotiate: { data: undefined },
    peopleAlertsToAction: { data: undefined },
    personAlerts: { data: undefined },
    personBasic: { data: undefined },
    personCatalogues: { data: undefined },
    personFullInfo: { data: undefined },
    personPathWays: { data: undefined },
    personSettings: { data: undefined },
    personSummary: { data: undefined },
    personTrainingPlanSummary: { data: undefined },
    registrations: { data: undefined },
    teamElearningTrainingPlans: { data: undefined },
    teamRegistrations: { data: undefined },
    teamTrainingPlanSummary: { data: undefined },
    trainingPlanSummary: { data: undefined },
    users: { data: undefined },
    userStatus: { data: undefined },
    userRoles: { data: undefined },
    userCompetencies : { data: undefined },
    userTrainingPlans : { data: undefined },
    accreditationGroupNameForUser: { data: undefined },
    fetchActiveTrainingPlan: fetchByIdFn(set, get, 'activeTrainingPlan', '/api/TrainingPlan/GetActiveTrainingPlan'),
    fetchActiveSortedTrainingPlan: fetchByIdFn(set, get, 'activeSortedTrainingPlan', '/api/TrainingPlan/GetActiveSortedTrainingPlan'),   
    fetchActiveSortedTrainingPlanNew: fetchByIdFn(set, get, 'activeSortedTrainingPlanNew', '/api/TrainingPlan/GetActiveSortedTrainingPlanNew'),  
    fetchAddressTypes: fetchFn(set, get, 'addressTypes', '/api/companylookup/getAddressTypes'),
    fetchAdministratorAlerts: fetchFn(set, get, 'administratorAlerts', '/api/alert/GetAdministratorAlerts'),
    fetchAdminNews: fetchFn(set, get, 'adminNews', '/api/news/GetAllAdminNews'),
    fetchAdminTrainingPlanGroupSettingInfo: fetchByIdFn(set, get, 'adminTrainingPlanGroupSettingInfo', process.env.REACT_APP_NEW_API_URLS !== 'true' ? '/api/TrainingPlan/GetAdminTrainingPlanGroupSettingInfo' : '/api/TrainingPlan/GetAdminTrainingPlanGroupSettingInfoLms'),
    fetchAdminTrainingPlanItems: fetchByIdFn(set, get, 'adminTrainingPlanItems', process.env.REACT_APP_NEW_API_URLS !== 'true' ? '/api/TrainingPlan/GetAdminTrainingPlanItems'   : '/api/TrainingPlan/GetAdminTrainingPlanItemsLms'),
    fetchAdminTrainingPlanItemsByGroup: fetchByIdFn(set, get, 'adminTrainingPlanItemsByGroup', '/api/TrainingPlan/GetAdminTrainingPlanItemsByGroup'),
    fetchAllApplications: fetchAsynTransformFn(set, get, 'allApplications', '/api/application/GetAllApplications', allApplicationsTransform),
    fetchAllBookingCancellationReasons: fetchFn(set, get, 'allBookingCancellationReasons', '/api/booking/getAllBookingCancellationReasons'),
    fetchAllCounties: fetchFn(set, get, 'allCounties', '/api/companylookup/GetAllCounties/10'),
    fetchAllCountries: fetchFn(set, get, 'allCountries', '/api/companylookup/GetAllCountries'),
    fetchAllLearningItems: fetchFn(set, get, 'allLearningItems', '/api/learningitem/getAllLearningItems'),
    fetchAllNews: fetchFn(set, get, 'allNews', '/api/news/GetAllNews/100'),
    fetchAllPersonCatalogueItems: fetchFn(set, get, 'allPersonCatalogueItems', '/api/PersonCatalogue/GetAllPersonCatalogueItems'),
    fetchAllTopPersonCatalogueItems: fetchFn(set, get, 'allTopPersonCatalogueItems', '/api/PersonCatalogue/GetAllTopPersonCatalogueItems/10'),
    fetchAllTowns: fetchFn(set, get, 'allTowns', '/api/companylookup/GetAllTowns'),
    fetchApplicationAdminAlerts: fetchFn(set, get, 'applicationAdminAlerts', '/api/alert/GetApplicationAdminAlerts'),
    fetchApplicationGroups: fetchFn(set, get, 'applicationGroups', '/api/group/GetApplicationGroups'),
    fetchApplicationPeople: fetchFn(set, get, 'applicationPeople', '/api/people/GetApplicationPeople'),
    fetchApplicationTrainingPlanSummaryS: fetchFn(set, get, 'applicationTrainingPlanSummaryS', '/api/trainingPlan/GetApplicationTrainingPlanSummaryS'),
    fetchApplicationTrainingPlans: fetchFn(set, get, 'applicationTrainingPlans', '/api/trainingplan/report/GetApplicationTrainingPlans'),
    fetchByAuthority: fetchFn(set, get, 'byAuthority', '/api/application/findByAuthority'),
    fetchCatalogues: fetchFn(set, get, 'catalogues', '/api/Catalogue/GetAllCatalogues'),
    fetchCompanies: fetchFn(set, get, 'companies', '  /api/company/GetAdminAllCompanies'),
    fetchAllCompanyPeople: fetchByIdFn(set, get, 'allCompanyPeople', '  /api/company/GetAllCompanyPeople'),    
    fetchDashboardSummary: fetchFn(set, get, 'dashboardSummary', '/api/TrainingPlan/GetDashboardSummary/1000'),
    fetchMyActiveSortedTrainingPlan: fetchFn(set, get, 'myActiveSortedTrainingPlan', '/api/TrainingPlan/GetMyActiveSortedTrainingPlan'),
    fetchMyActiveSortedTrainingPlanNew: fetchFn(set, get, 'myActiveSortedTrainingPlanNew', '/api/TrainingPlan/GetMyActiveSortedTrainingPlanNew'),
    fetchMyActiveTrainingPlan: fetchFn(set, get, 'myActiveTrainingPlan', '/api/TrainingPlan/GetMyActiveTrainingPlan'),
    fetchMyAlerts: fetchFn(set, get, 'myAlerts', '/api/alert/GetMyAlerts'),
    fetchMyLatestAlerts: fetchFn(set, get, 'myLatestAlerts', '/api/alert/GetMyLatestAlerts'),
    fetchMyLatestPersonNews: fetchFn(set, get, 'myLatestPersonNews', '/api/news/GetLatestPersonNews'),
    fetchMyMandatoryTrainingPlan: fetchFn(set, get, 'myMandatoryTrainingPlan', '/api/TrainingPlan/GetMyMandatoryTrainingPlan'),
    fetchMyOverdueTrainingPlanItems: fetchFn(set, get, 'myOverdueTrainingPlanItems', '/api/TrainingPlan/GetMyOverdueTrainingPlanItems'),
    fetchMyPathWays: fetchFn(set, get, 'myPathWays', '/api/LearningPathWay/GetMyPathWays'),
    fetchMyPathWayDetail: fetchByIdNoGlobalLockFn(set, get, 'myPathWayDetail', '/api/LearningPathWay/GetMyPathWayDetail', myPathWayDetailTransform),
    fetchMyTopAlerts: fetchFn(set, get, 'myTopAlerts', '/api/alert/GetMyTopAlerts'),
    fetchPeopleAlertsToAction: fetchFn(set, get, 'peopleAlertsToAction', '/api/alert/GetPeopleAlertsToAction'),
    fetchPersonAlerts: fetchByIdFn(set, get, 'personAlerts', '/api/alert/GetPersonAlerts'),
    fetchPersonBasic: fetchFn(set, get, 'personBasic', '/api/account/getPersonBasic'),
    fetchPersonCatalogues: fetchFn(set, get, 'personCatalogues', '/api/PersonCatalogue/GetPersonCatalogues'),
    fetchPersonFullInfo: fetchByIdFn(set, get, 'personFullInfo', '/api/people/GetPersonFullInfo'),
    fetchPersonPathWays: fetchByIdFn(set, get, 'personPathWays', '/api/LearningPathWay/GetPersonPathWays'),
    fetchPersonPathWayDetail: fetchByIdFn(set, get, 'myPathWayDetail', '/api/LearningPathWay/GetPersonPathWayDetail', myPathWayDetailTransform),
    fetchPersonSettings: fetchFn(set, get, 'personSettings', '/api/account/getPersonSettings'),
    fetchPersonSummary: fetchFn(set, get, 'personSummary', '/api/account/getPersonSummary'),
    fetchPersonTrainingPlanSummary: fetchByIdFn(set, get, 'personTrainingPlanSummary', '/api/TrainingPlan/GetPersonTrainingPlanSummary'),
    fetchRegistrations: fetchFn(set, get, 'registrations', '/api/WaitingDates/GetAllPeopleWaitingDates'),
    fetchBookingEvents: fetchFn(set, get, 'bookingEvents', '/api/booking/GetAllBookingReport'),
    fetchTeamElearningTrainingPlans: fetchFn(set, get, 'teamElearningTrainingPlans', '/api/trainingplan/report/GetTeamElearningTrainingPlans'),
    fetchTeamRegistrations: fetchFn(set, get, 'teamRegistrations', '/api/WaitingDates/GetTeamWaitingDates'),
    fetchTeamBookingEvents: fetchFn(set, get, 'teamBookingEvents', '/api/booking/GetTeamBookingReport'), 
    fetchTeamTrainingPlanSummary: fetchFn(set, get, 'teamTrainingPlanSummary', '/api/TrainingPlan/GetTeamTrainingPlanSummary'),
    fetchTrainingPlanSummary: fetchFn(set, get, 'trainingPlanSummary', '/api/TrainingPlan/GetTrainingPlanSummary'),
    fetchUseStatus: fetchFn(set, get, 'userStatus', '/api/people/getPersonStatus'),
    fetchUserCompaniesByApplication: fetchFn(set, get, 'companiesByApplication', '/api/company/getAllCompaniesByApplication'),
    fetchUserEmpType: fetchFn(set, get, 'employeeTypes', '/api/people/getEmployementTypes'),
    fetchUsers: fetchFn(set, get, 'users', '/api/people/getApplicationPeopleResult'),
    fetchUserRoles: fetchFn(set, get, 'userRoles', '/api/people/getAllRoles'),
    fetchCompetencies: fetchFn(set, get, 'userCompetencies', '/api/UKPN/getCompetencies'),
    fetchTrainingPlans: fetchFn(set, get, 'userTrainingPlans', '/api/UKPN/getTrainingPlans'),
    fetchAccreditationGroupNameForUser: fetchFn(set, get, 'accreditationGroupNameForUser', '/api/UKPN/getAccreditationGroupNameForUser'),
   });

const useLmsStore = process.env.NODE_ENV === 'development' ? create(devtools(store)) : create(store);

if (process.env.NODE_ENV === 'development') {
    Object.defineProperty(window, 'appStore', {
        get() {
            return useLmsStore.getState();
        },
    });
}

export default useLmsStore;

export const clearStore = () => useLmsStore.setState({}, true);

export const getAdminNewsItem = (newsId) => apiAxios(`/api/news/GetNewsDetail/${newsId}`);


export const getAdminPortalUrl = () => {
    let location = window.location.hostname.split('.')[0],
        urlFragment = null;//las titme i have to set as 'test' here for it to wrok in test environment. I do nkow why
      //  console.log('get admin portal 0');
    if (process.env.REACT_APP_LEGACY_SERVER !== 'live') {
    //    console.log('get admin portal 1');
        if (process.env.NODE_ENV === 'development') {
            urlFragment = 'test';
        }
        // Other configs used again in future?
        else if (process.env.REACT_APP_SERVER === 'test') {
         //   console.log('get admin portal 2');
            urlFragment = 'test';
        }

        else if (process.env.REACT_APP_SERVER === 'dev') {
            //   console.log('get admin portal 2');
               urlFragment = 'dev';
           }
    }
    if (urlFragment) {
           switch (location) {
            case 'principality':
                return `https://principality-portal.${urlFragment}.wdr.co.uk/`;
            case 'cerespoweracademy':
                return `https://cerespoweracademy-admin.${urlFragment}.wdr.co.uk/`;
            case 'gwct':
                return `https://gwct-admin.${urlFragment}.wdr.co.uk/`;
            case 'ukpn-lms':
                    return `https://ukpn-portal.${urlFragment}.wdr.co.uk/`;
          //  case 'localhost':
            case 'lms-react':
            return `https://ukpn-portal.${urlFragment}.wdr.co.uk/`;
            default:
             //   return `http://ukpn-portal.${urlFragment}.wdr.co.uk/`;
              return `https://learningportal.${urlFragment}.wdr.co.uk/`;
        }
    } else {
        switch (location) {
            case 'principality':
                return "https://principality-admin.wdr.co.uk/";
            case 'cerespoweracademy':
                return "https://cerespoweracademy-admin.wdr.co.uk/";
            case 'gwct':
                return "https://gwct-admin.wdr.co.uk/";
            case 'ukpn-lms':
                    return `https://ukpn-portal.wdr.co.uk/`;
            default:
                return "https://lms-admin.wdr.co.uk/";
        }
    }
};

export const getAllActiveEventsByPersonGroups = (learningItemId) => apiAxios(`/api/event/GetAllActiveEventsByPersonGroups/${learningItemId}`);

export const getAllCompanyAddress = (companyId) => apiAxios(`/api/company/address/getAllCompanyAddress/${companyId}`);

export const getAllDivisions = (companyId) => apiAxios(`/api/company/GetAllDivisions/${companyId}`);

export const getAllManagersByCompany = (companyId) => apiAxios(`/api/people/getAllManagersByCompany/${companyId}`);

export const getCanCancelBooking = (bookingId) => apiAxios(`/api/event/GetCanCancelBooking/${bookingId}`);

export const getCatalogueInfo = (catalogueId) => apiAxios(`/api/Catalogue/GetCatalogueAdmin/${catalogueId}`);

export const getCompanyDetail = (companyId) => apiAxios(`/api/company/GetCompanyDetail/${companyId}`);

export const getAllCompanyPeople = (companyId) => apiAxios(`/api/company/GetAllCompanyPeople/${companyId}`);

export const getTrainerGroupMembers = (companyId) => apiAxios(`/api/group/GetTrainerGroupMembers/${companyId}`);

export const getElearningLinks = (learningItemId, trainingPlanItemId, regPGroupId, regPItemId, icompProgId) => apiAxios(`/api/elearning/Getelearninglinks/${learningItemId}/${trainingPlanItemId}/${regPGroupId}/${regPItemId}/${icompProgId}`);

export const getEventFullInfo = (eventId) => apiAxios(`/api/event/GetEventFullInfo/${eventId}`);

export const getIsTrainingPlanUpdatedByItem = (learningItemId) => apiAxios(`/api/TrainingPlan/IsTrainingPlanUpdatedByItem/${learningItemId}`);

export const getReportData = (reportName) => apiAxios.post(`/api/TrainingPlan/report/${reportName}`, {});

export const getRegistrations = () => apiAxios('/api/WaitingDates/GetAllPeopleWaitingDates');

export const getSynopsis = (learningItemId) => apiAxios(`/api/learningitem/synopsis/${learningItemId}`);

export const getUserInfo = (userId) => apiAxios(`/api/people/getAdminPersonFullInfo/${userId}`);

export const getSendInvitation = (userInvId) => apiAxios(`/api/people/resendInvitation/${userInvId}`);

//export const getAllRoles = () => apiAxios('/api/people/getAllRoles');
export const getCompetencies = (userId) => apiAxios(`/api/UKPN/getCompetencies/${userId}`);

export const getTrainingPlans = (userId) => apiAxios(`/api/UKPN/getTrainingPlans`);
export const getAccreditationGroupNameForUser = (userId) => apiAxios(`/api/UKPN/getAccreditationGroupNameForUser`);

export const hrefToAdminServer = (personBasic, url) => {
    if (personBasic && personBasic.personIdentifier) {
        let portalUrl = getAdminPortalUrl();
        return `${portalUrl}#/signonFromNewLms?lmsid=${personBasic.personIdentifier}&returnUrl=https:%2F%2Flms.wdr.co.uk%2FadminDashboard&goToUrl=${url}`;
    } else {
        return null;
    }
};

export const hasPermission = (store, name) => {
    let settings =  store.getState().personSettings;

    if (name!== '' && settings){
      if (settings.loading>-1){

        let item =   settings.data.lmsPermissions.filter(x => x === name );
        if (item.length>0){
          // console.log('item', item);
        return true;
        }

     }
    }

    return false;
};

export const isReadyOnly = (store, permissionName) =>{
    let settings =  store.getState().personSettings;

    if (settings.loading>-1){
       
        if (settings.data.isSuperApplicationAdministrator || settings.data.isAdministrator){
            return false;
        }

        let item =   settings.data.lmsPermissions.filter(x => x === permissionName);

        if (item.length>0){
            return true;
        }
    }

    return false;
}

function myPathWayDetailTransform(response) {
    // Flatten the data
    let data = responseData(response),
        newData = [];
    if (!data.pathwayGroups) {
        data.pathwayGroups = [];
    }
    let myregId = response.config.url.replace('\\','/').split('/').pop();

    data.pathwayGroups.forEach(x => {
        x.pathwayItems.forEach(y => {
            y.pwgrpId = x.id;
            y.myregid =  parseInt(myregId);
            y.pwgrpName = x.name;
            newData.push(y);
        })
    })
    return newData;
};

/**
 * Add course to plan.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postAddToPlan = postDataPromiseFn('/api/TrainingPlan/addTrainingPlan',
    (store, postData, response) => {
        if (!(store.getState().allPersonCatalogueItems.loading > 0)) {
            store.setState({ allPersonCatalogueItems: { data: store.getState().allPersonCatalogueItems.data, loading: getLockId() } });
            try {
                let id = responseData(response);
                if (id < 1) {
                    throw new Error(`postAddToPlan: Data error (id: ${id})`);
                }
                // Update the store
                let data = structuredClone(store.getState().allPersonCatalogueItems.data),
                // eslint-disable-next-line
                idx = data.findIndex(x => x.learningItemId === postData.LearningItemId);
                if (idx>0){
                    throwIfDataIndexNotFound('postAddToPlan', idx, postData.LearningItemId);
                    data[idx].trainingPlanId = id;
                    // Refresh the store data
                    store.setState({ allPersonCatalogueItems: { data: data, time: new Date(), loading: 0 } });// refresh data!
                    store.setState({ myActiveSortedTrainingPlan: { data: null, time: null } }); // refresh linked data!
                }
                return true;
            } catch (err) {
                toastError(err);
                store.setState({ allPersonCatalogueItems: { loading: 0 } });
                return false;
            }
        } else {
            return true;
        }
    },
    (store, postData) => false
);

export const postRemoveToPlan = postDataPromiseFn('/api/TrainingPlan/RemoveTrainingPlan',
    (store, postData, response) => {
        if (store.getState().myActiveSortedTrainingPlan) {
            if (!(store.getState().myActiveSortedTrainingPlan.loading > 0)) {
                store.setState({ myActiveSortedTrainingPlan: { data: store.getState().myActiveSortedTrainingPlan.data, loading: getLockId() } });
                try {       
                    let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data),
                    // eslint-disable-next-line
                    idx = transformedData.findIndex(x => x.id === postData );
                    if (idx > -1) {
                        transformedData = transformedData.filter(x => x.id !== postData );
                        store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date() } });// refresh data!
                        store.setState({ allPersonCatalogueItems: { loading: 0 } });// refresh linked data!
                        store.setState({ dashboardSummary: { data: null, time: null } }); // Refresh dashboard
                    }
                    return true;
                } catch (err) {
                    toastError(err);
                    store.setState({ myActiveSortedTrainingPlan: { loading: 0 } });
                    return false;
                }
            } else {
                return true;
            }
        }
    },
    (store, postData) => false
);

/**
 * Add course to plan.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postPersonfilestore = postFilePromiseFn('/api/personfilestore',
    (store, postData, response) => {
        try {
            return true;
        } catch (err) {
            toastError(err);
            return false;
        }
    },
    (store, postData) => false
);

/**
 * Post admin news data.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postAdminNewsItem = postBooleanPromiseFn('/api/news/saveNews');

export const postAdminTrainingPlan = (store, { userData, dateUpdates, otherUpdates }) => {
    return new Promise((resolve) => {
        let jobs = [];
        if (otherUpdates) {
            otherUpdates.forEach(dateUpdate => {
                dateUpdate.joinId = dateUpdate.joinId || -1; // New entries must default to -1
            });
            jobs.push(postAdminTrainingPlanJob1(store, { personId: userData.id, traininigPlanItems: otherUpdates }));
        }
        Promise.all([...jobs]).then((values) => {
            jobs = [];
            if (dateUpdates) {
                dateUpdates.forEach(dateUpdate => {
                    jobs.push(postAdminTrainingPlanJob2(store, dateUpdate));
                });
            }
            Promise.all([...jobs]).then((values) => {
                toastSuccess();
                // Because we have multiple posts it is easier / safer to request the data again from the server
                // There is an opportunity here to change the server api to support all updates in one post.
                store.getState().fetchAdminTrainingPlanItems(userData.id, true).then(data => resolve(data));
            }).catch(function (err) {
                toastError(err);
                resolve(false);
            });
        }).catch(function (err) {
            toastError(err);
            resolve(false);
        });

    });
};

export const postAdminTrainingPlanJob1 = postBooleanPromiseFn('/api/TrainingPlan/saveAdminTrainingPlan', false, false);

export const postAdminTrainingPlanJob2 = postBooleanPromiseFn(process.env.REACT_APP_NEW_API_URLS !== 'true' ? '/api/TrainingPlan/SaveAdminTrainingPlanItemDetail' : '/api/TrainingPlan/SaveAdminTrainingPlanItemDetailLms', false, false);

export const postAdminTrainingPlanGroupInfo = postDataPromiseFn('/api/TrainingPlan/saveAdminTrainingPlanGroupInfoLms',
    (store, postData, response) => {
        if (!(store.getState().adminTrainingPlanItemsByGroup.loading > 0)) {
            store.setState({ adminTrainingPlanItemsByGroup: { ...store.getState().adminTrainingPlanItemsByGroup, loading: getLockId() } });
            try {
                let data = responseData(response);
                let transformedData = structuredClone(store.getState().adminTrainingPlanItemsByGroup);
                // Store the data in format {id:{data:..., time:...}}
                transformedData[postData.groupId] = {
                    data: data,
                    time: new Date()
                };
                transformedData.loading = 0;
                // Refresh the store data
                store.setState({ adminTrainingPlanItemsByGroup: transformedData });
                toastSuccess();
                return data;
            } catch (err) {
                toastError(err);
                store.setState({ adminTrainingPlanItemsByGroup: { loading: 0 } });
                return postData.learningItems; // On post fail, return original data
            }
        } else {
            return postData.learningItems; // On post fail, return original data
        }
    },
    (store, postData) => postData.learningItems // On post fail, return original data
);

export const postAdminTrainingPlanGroupSettingInfo = postDataPromiseFn('/api/TrainingPlan/saveAdminTrainingPlanGroupSettingInfo',
    (store, postData, response) => {
        if (!(store.getState().adminTrainingPlanGroupSettingInfo.loading > 0)) {
            store.setState({ adminTrainingPlanGroupSettingInfo: { ...store.getState().adminTrainingPlanGroupSettingInfo, loading: getLockId() } });
            try {
                let data = responseData(response);
                let transformedData = structuredClone(store.getState().adminTrainingPlanGroupSettingInfo);
                // Store the data in format {id:{data:..., time:...}}
                transformedData[`${data.groupId}-${data.learningItemId}`] = {
                    data: data,
                    time: new Date()
                };
                // Refresh the store data
                store.setState({ adminTrainingPlanGroupSettingInfo: { data: transformedData, loading: 0 } });
                toastSuccess();
                return data;
            } catch (err) {
                toastError(err);
                store.setState({ adminTrainingPlanGroupSettingInfo: { loading: 0 } });
                return postData.dateUpdates; // On post fail, return original data
            }
        } else {
            return postData.dateUpdates; // On post fail, return original data
        }
    },
    (store, postData) => postData.dateUpdates // On post fail, return original data
);

/**
 * Post application settings data.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postApplicationSettings = postDataPromiseFn('/api/application/SaveApplicationSettingGroupDetail',
    (store, postData, response) => {
        if (!(store.getState().allApplications.loading > 0)) {
            store.setState({ allApplications: { data: store.getState().allApplications.data, loading: getLockId() } });
            try {
                let data = responseData(response),
                    applicationId = data && data.length ? postData[0].applicationId : 0,
                    applicationName = data && data.length ? postData[0].applicationName : 0;
                if (data[0].applicationId !== applicationId) {
                    throw new Error(`postCatalogue: Data error (expected ${applicationId}, got ${data[0].applicationId})`);
                }
                data.forEach(x => x.applicationName = applicationName);
                // Update the store
                let transformedData = structuredClone(store.getState().allApplications.data);
                // Remove old and add new
                transformedData = transformedData.filter(x => x.applicationId !== applicationId).concat(data);
                // Refresh the store data
                store.setState({ allApplications: { data: transformedData, time: new Date(), loading: 0 } });
                toastSuccess();
                return true;
            } catch (err) {
                toastError(err);
                store.setState({ allApplications: { loading: 0 } });
                return false;
            }
        } else {
            return true;
        }
    },
    (store, postData) => false
);

/**
 * Post user data.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postCompany = postBooleanPromiseFn('/api/company/saveCompany');

/**
 * Logout.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postLogout = postBooleanPromiseFn('/api/account/logout', true);

export const postLogoutSaml = postBooleanPromiseFn('/api/samlAuth/logout', true);

/**
 * Password change.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postPasswordChange = postBooleanPromiseFn('/api/account/updatepassword');

/**
 * Post user data.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
//export const postUser = postBooleanPromiseFn('/api/people/savePersonFullInfo');
export const postUser = postDataPromiseFn('/api/people/savePersonFullInfo',
    (store, postData, response) => {
        if (!(store.getState().users.loading > 0)) {
            store.setState({ users: { data: store.getState().users.data, loading: getLockId() } });
            try {
                let data = responseData(response);
                let transformedData = structuredClone(store.getState().users);
                //manipulate data
                let company = store.getState().companiesByApplication.data.filter((item) => item.companyId === data.companyId);
                let usrList = transformedData.data.filter((item) => item.id === data.id);
                let usr;
                if (usrList.length > 0) {
                    usr = usrList[0];
                    usr.emailAddress = data.emailAddress;
                    usr.firstName = data.firstName;
                    usr.lastName = data.lastName;
                    usr.photograph = data.photograph === "" ? "" : data.photograph;
                    usr.isDeleted = data.isDeleted;
                }
                if (usrList.length === 0) {
                    usr = {
                        canSendInvitation: data.canSendInvitation,
                        companyName: "",
                        emailAddress: data.emailAddress,
                        employeeId: data.employeeId,
                        firstName: data.firstName,
                        id: data.id,
                        invitationId: data.invitationId,
                        isDeleted: false,
                        jobTitle: data.jobTitle,
                        lastName: data.lastName,
                        photograph: data.photograph === "" ? "" : data.photograph,
                        roleName: null,
                        searchTerms: ""
                    }
                }

                if (company.length === 1) {
                    usr.companyName = company[0].companyName;
                    usr.searchTerms = data.roleName + "," + data.firstName + " " + data.lastName + "," + company[0].companyName + "," + data.emailAddress;
                }
                else {
                    usr.companyName = "Error";
                    usr.searchTerms = data.roleName + "," + data.firstName + " " + data.lastName + ", error," + data.emailAddress;
                }

                //reset data
                const newList = transformedData.data.filter((item) => item.id !== data.id);
                newList.push(usr);

                // Store the data
                transformedData = {
                    data: newList,
                    loading: 0,
                    time: new Date()
                };

                // Refresh the store data
                store.setState({ users: transformedData });
                toastSuccess();
                return data;
            } catch (err) {
                toastError(err);
                store.setState({ users: { data: store.getState().users.data, loading: 0, time: new Date() } });
                return postData.dateUpdates; // On post fail, return original data
            }
        } else {
            return postData.dateUpdates; // On post fail, return original data
        }
    },
    (store, postData) => postData.dateUpdates // On post fail, return original data
);

/**
 * Post catalogue data.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postCatalogue = postDataPromiseFn('/api/catalogue/saveCatalogueAdmin',
    (store, postData, response) => {
        if (!(store.getState().catalogues.loading > 0)) {
            store.setState({ catalogues: { data: store.getState().catalogues.data, loading: getLockId() }  });
            try {
                let data = responseData(response);
                throwIfDataStatusNot200(response.data);
                if (data < 1) {
                    throw new Error(`postCatalogue: Data error (data.value: ${data})`);
                }
                // //Update store for Learning Plan
                let transformedData = structuredClone(store.getState().catalogues.data),
                // eslint-disable-next-line
                idx = transformedData.findIndex(x => x.id === data.catalogueApplicationId);
                if (idx > -1) {
                    throwIfDataIndexNotFound('postCatalogue', idx, data);
                    transformedData[idx].title = data.title;
                    transformedData[idx].isActive= data.isActive;
                }else{
                    data['id']=data.catalogueApplicationId;
                    transformedData.push(data);
                }
                // Refresh the store data
                store.setState({ catalogues: { data: transformedData, time: null, loading: 0 } });
                toastSuccess();
                return true;
            } catch (err) {
                toastError(err);
                store.setState({ catalogues: { loading: 0 } });
                return false;
            }
        } else {
            return true;
        }
    },
    (store, postData) => false
);

const doManipulatePathWayDetailsUpdate = (store, data, returnObj)=>{
    let transformedData = structuredClone(store.getState().myPathWayDetail);

    for (const property in transformedData) {
        try {
            if( property!== 'data' && property!== 'time' && property!== 'loading'){
                if(transformedData[property].data){
                    let indexes = transformedData[property].data.findIndex(x => x.learningItemId === data.learningItemId);
                    let status = (data.elearningInfo != undefined? (data.elearningInfo.completionStatusName!= undefined? data.elearningInfo.completionStatusName.toLowerCase():undefined):undefined);
    
                    if(indexes && indexes !== -1 ){
                        if(Array.isArray(indexes)){
                            indexes.forEach((idx) => {
                                //console.log(`${idx}: ${property}`);       
                                transformedData[property].data[idx].statusName = (status === 'complete' ? 'Completed' : 'In progress');
                                if (transformedData[property].data[idx].elearningInfo && transformedData[property].data[idx].elearningInfo !== data.elearningInfo) {
                                    transformedData[property].data[idx].elearningInfo = data.elearningInfo;
                                    returnObj.pathwayIds.push(property.split('-')[0]);
                                } else if(!transformedData[property].data[idx].elearningInfo){
                                    transformedData[property].data[idx].elearningInfo = data.elearningInfo;
                                    returnObj.pathwayIds.push(property.split('-')[0]);
                                }
                                if(returnObj.status === 'Complete'){
                                    transformedData[property].time = null;
                                }
                            })
                        }else {
                            //console.log(`${indexes}: ${property}`);       
                            transformedData[property].data[indexes].statusName = (status === 'complete' ? 'Completed' : 'In progress');
                            if (transformedData[property].data[indexes].elearningInfo && transformedData[property].data[indexes].elearningInfo !== data.elearningInfo) {
                                transformedData[property].data[indexes].elearningInfo = data.elearningInfo;
                                returnObj.pathwayIds.push(property.split('-')[0]);
                            } else if(!transformedData[property].data[indexes].elearningInfo){
                                transformedData[property].data[indexes].elearningInfo = data.elearningInfo;
                                returnObj.pathwayIds.push(property.split('-')[0]);
                            }
                            if(returnObj.status === 'Complete'){
                                transformedData[property].time = null;
                            }
                        }
                    }
                }
            }
        } catch (err) {
            toastError(err);
            //store.setState({ myPathWayDetail: { loading: 0 } }); // Always unlock on error. data undefined will cause a complete reload from the server
        } 
    }
    store.setState({ myPathWayDetail: { ...transformedData, loading: 0 } }); 
};

/**
 * Manipulate training plan and pathway.
 * 
 * Return a status with true/false if successful.
 * 
 * @param {obj} changeData 
 * @returns 
 */
export const manipulatePathWayDetails = (store, data)=>{
    let returnObj ={ pathwayIds: [], status: undefined };
    //console.log(`manipulatePathWaiDetails=>   data:`, data);
    if (store.getState().myPathWayDetail) {
        if (!(store.getState().myPathWayDetail.loading > 0)) {
            //store.setState({ myPathWayDetail: { ...store.getState().myPathWayDetail, loading: getLockId() } }); // Always lock immediately
            let transformedData = structuredClone(store.getState().myPathWayDetail),
            idx= -1;
            //console.log(`manipulatePathWaiDetails1 RM=> loading:  `, store.getState().myPathWayDetail.loading);
            returnObj.status = (data.elearnInfo!=undefined? data.completionStatusName:undefined);
            for (const property in transformedData) {
                try {
                    if( property!== 'data' && property!== 'time' && property!== 'loading'){
                        if(transformedData[property].data){
                            idx = transformedData[property].data.findIndex(x => x.myregid === data.pathwayId && x.learningItemId === data.learningItemId);
                            if(idx !== -1 ){
                                if (isElearningChaged(transformedData[property].data[idx],data)){ //ZC- trying to do only if content changed
                                doManipulatePathWayDetailsUpdate(store, data, returnObj);
                                doMyActiveSortedTrainingPlanUpdate(store, data, false);
                                store.setState({   dashboardSummary: { data: null, time: null } }); // refresh linked data!
           
                                break; 
                                }
                            } 
                        }
                    }
                } catch (err) {
                    toastError(err);
                    //store.setState({ myPathWayDetail: { loading: 0 } }); // Always unlock on error. data undefined will cause a complete reload from the server
                } 
            }
        }
    }
    return returnObj;
};

const updatePathWaiDetails =(store, data)=>{
    //console.log(`updatePathWaiDetails=>   data:`, store.getState().myPathWayDetail);
    if (store.getState().myPathWayDetail) {
        if (!(store.getState().myPathWayDetail.loading > 0)) {
            //store.setState({ myPathWayDetail: { ...store.getState().myPathWayDetail, loading: getLockId() } }); // Always lock immediately
       
            let transformedData = structuredClone(store.getState().myPathWayDetail),
            idx= -1;
            for (const property in transformedData) {
                try {
                    if( property!== 'data' && property!== 'time' && property!== 'loading'){
                        idx = transformedData[property].data.findIndex(x => x.learningItemId === data.value);
                        if(idx !== -1 ){
                            //console.log(`${idx}: ${property}`);
                            transformedData[property].data[idx].statusName = 'Completed';
                            transformedData[property].data[idx].statusId = 2;
                        }
                        
                    }
                } catch (err) {
                    toastError(err);
                    store.setState({ myPathWayDetail: { loading: 0 } }); // Always unlock on error. data undefined will cause a complete reload from the server
                }
                
            }
            store.setState({ myPathWayDetail: { ...transformedData, loading: 0 } }); // Set the new data and unlock
        }
    }
};

const updatePathWayDetailsBooking =(store, data,isCancel)=>{
    console.log(`updatePathWayDetailsBooking=>   data:`, store.getState().myPathWayDetail);
    if (store.getState().myPathWayDetail) {
        if (!(store.getState().myPathWayDetail.loading > 0)) {
            //store.setState({ myPathWayDetail: { ...store.getState().myPathWayDetail, loading: getLockId() } }); // Always lock immediately
            console.log('updatePathWayDetailsBooking : loading >0 data.learningItemId:',  data.learningItemId);
            console.log('updatePathWayDetailsBooking : loading >0 data',  data);
            let transformedData = structuredClone(store.getState().myPathWayDetail),
            idx= -1;
            for (const property in transformedData) {
                try {
                    if( property!== 'data' && property!== 'time' && property!== 'loading'){
                        console.log('updatePathWayDetailsBooking : before transform transformed data', transformedData[property].data);
                        idx = transformedData[property].data.findIndex(x => x.learningItemId === data.learningItemId);
                        console.log('updatePathWayDetailsBooking : after transform idx', idx);
                        if(idx !== -1 ){

                            transformedData[property].data[idx].statusName = data.statusName;  
                            transformedData[property].data[idx].statusId = data.statusId;  
                            if (!isCancel){
                            console.log('Updating booking pw',data);                        
                            transformedData[property].data[idx].instructorInfo= data; //{bookingId: data.bookingId, statusId: data.bookingStatusId};
                            transformedData[property].data[idx].instructorInfo.statusId = data.bookingStatusId;
                            }
                            else{
                                console.log('Updating booking pw - cancel',data);
                                //transformedData[property].data[idx].statusName ='';
                                transformedData[property].data[idx].instructorInfo= {bookingId: 0, statusId: 6, statusName:'Cancelled'}
                            }                    
                        }
                        
                    }
                } catch (err) {
                    toastError(err);
                    store.setState({ myPathWayDetail: { loading: 0 } }); // Always unlock on error. data undefined will cause a complete reload from the server
                }
                
            }
            store.setState({ myPathWayDetail: { ...transformedData, loading: 0 } }); // Set the new data and unlock
        }
    }
};

export const updatePathWaiDetailsOpen =(store, data, isOpen)=>{
    if (store.getState().myPathWayDetail) {
        if (!(store.getState().myPathWayDetail.loading > 0)) {
            //store.setState({ myPathWayDetail: { ...store.getState().myPathWayDetail, loading: getLockId() } }); // Always lock immediately
       
            let transformedData = structuredClone(store.getState().myPathWayDetail),
            idx= -1;
            for (const property in transformedData) {
                try {
                    if( property!== 'data' && property!== 'time' && property!== 'loading'){
                        //console.log(' updatepwopen', data);
                        idx = transformedData[property].data.findIndex(x => x.learningItemId === data);
                        //console.log(' updatepwopen idx', idx);
                 
                        if(idx !== -1 ){
                            //console.log(`${idx}: ${property}`);
                            transformedData[property].data[idx].isOpen = isOpen;                            
                        }
                        
                    }
                } catch (err) {
                    toastError(err);
                    store.setState({ myPathWayDetail: { loading: 0 } }); // Always unlock on error. data undefined will cause a complete reload from the server
                }
                
            }
            store.setState({ myPathWayDetail: { ...transformedData, time: new Date(), loading: 0 } }); // Set the new data and unlock
        }
    }
};

export const updateLearningPlanOpen =(store, data, isOpen)=>{
    if (store.getState().myActiveSortedTrainingPlan) {
        if (!(store.getState().myActiveSortedTrainingPlan.loading > 0)) {
            store.setState({ myActiveSortedTrainingPlan: { data: store.getState().myActiveSortedTrainingPlan.data, loading: getLockId() } }); // Always lock immediately
            try {
                
                //Update store forr Learning Plan
                let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data),
                // eslint-disable-next-line
                idx = transformedData.findIndex(x => x.learningItemId === data);

                //console.log(' updateLearningPlan', data);

                if (idx > -1){
                    //console.log(' updateLearningPlan idx', idx);
                    store.setState({ myActiveSortedTrainingPlan: { loading: getLockId() } });
                    throwIfDataIndexNotFound('postCompleteTrainingPlanAndPathway', idx, data);
                    transformedData[idx].isOpen = isOpen;
                 
                    // Refresh the store data
                    store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });     
                  
                   
                }
                else{
                    // Refresh the store data
                    store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });   
                }
            } catch (err) {
                toastError(err);
                store.setState({ myActiveSortedTrainingPlan: { data: undefined, loading: 0 } }); // Always unlock on error. data undefined will cause a complete reload from the server
                return false;
            }
        } 
    }
return true;
};

export const updatePathwayOpen =(store, data, isOpen)=>{
    if (store.getState().myActiveSortedTrainingPlanNew) {
        if (!(store.getState().myActiveSortedTrainingPlanNew.loading > 0)) {
            store.setState({ myActiveSortedTrainingPlanNew: { data: store.getState().myActiveSortedTrainingPlanNew.data, loading: getLockId() } }); // Always lock immediately
            try {
                
                //Update store forr Learning Plan
                let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlanNew.data),
                // eslint-disable-next-line
                idx = transformedData.findIndex(x => x.id === data);

                if (idx > -1){
                 
                    store.setState({ myActiveSortedTrainingPlanNew: { loading: getLockId() } });
                    throwIfDataIndexNotFound('postCompleteTrainingPlanAndPathway', idx, data);
                    transformedData[idx].isOpen = isOpen;
                    transformedData[idx].isSelected = true;
                    // Refresh the store data
                    store.setState({ myActiveSortedTrainingPlanNew: { data: transformedData, time: new Date(), loading: 0  } });     
                  
                   
                }
                else{
                    // Refresh the store data
                    store.setState({ myActiveSortedTrainingPlanNew: { data: transformedData, time: new Date(), loading: 0  } });   
                }
            } catch (err) {
                toastError(err);
                store.setState({ myActiveSortedTrainingPlanNew: { data: undefined, loading: 0 } }); // Always unlock on error. data undefined will cause a complete reload from the server
                return false;
            }
        } 
    }
return true;
};

export const updatePathwayOpenTeam =(store, data, isOpen)=>{
    console.log('open 1');
    if (store.getState().activeSortedTrainingPlanNew) {
        console.log('open 1.1' ,store.getState().activeSortedTrainingPlanNew);
        if (!(store.getState().activeSortedTrainingPlanNew.loading > 0)) {
            console.log('open 3');
          //  store.setState({ activeSortedTrainingPlanNew: { data: store.getState().activeSortedTrainingPlanNew.data, loading: getLockId() } }); // Always lock immediately
            try {
                console.log('open 4');
                //Update store forr Learning Plan
                let transformedData = structuredClone(store.getState().activeSortedTrainingPlanNew);
                // eslint-disable-next-line
                console.log('open 4.2', transformedData);
                let idx = transformedData.findIndex(x => x.id === data);
 
                console.log('open 5');
                if (idx > -1){
                    console.log('open 6');
                    store.setState({ activeSortedTrainingPlanNew: { loading: getLockId() } });
                    throwIfDataIndexNotFound('postCompleteTrainingPlanAndPathway', idx, data);
                    transformedData[idx].isOpen = isOpen;
                    transformedData[idx].isSelected = true;
                    // Refresh the store data
                    store.setState({ activeSortedTrainingPlanNew: { data: transformedData, time: new Date(), loading: 0  } });     
                  
                    console.log('open 7');
                }
                else{
                    console.log('open 8');
                    // Refresh the store data
                    store.setState({ activeSortedTrainingPlanNew: { data: transformedData, time: new Date(), loading: 0  } });   
                }
            } catch (err) {
                console.log('open 9');
                toastError(err);
                store.setState({ activeSortedTrainingPlanNew: { data: undefined, loading: 0 } }); // Always unlock on error. data undefined will cause a complete reload from the server
                return false;
            }
        }
         
    }
return true;
};
   
export const createEventBookingNew = postDataPromiseFn('/api/booking/createBooking',
    (store, postData, response) => {
        let data = responseData(response);

        if (data!=null)
        updateLearningPlanStatus(store,response,postData.learningItemId,9, data.value.statusName,data.value);
        
        return true;
    
    },
    (store, postData) => false
);
export const createEventBooking = postDataPromiseFn('/api/booking/createBooking',
    (store, postData, response) => {
        if (store.getState().myActiveSortedTrainingPlan) {
            if (!(store.getState().myActiveSortedTrainingPlan.loading > 0)) {
                store.setState({ myActiveSortedTrainingPlan: { data: store.getState().myActiveSortedTrainingPlan.data, loading: getLockId() } }); // Always lock immediately
                try {
                    let data = responseData(response);
                    //console.log(`createEventBooking=> response:`, data);
                    throwIfDataNotValid(data);
                    //Update store forr Learning Plan
                    let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data),
                    // eslint-disable-next-line
                    idx = transformedData.findIndex(x => x.learningItemId === postData.learningItemId);
                    if (idx > -1){
                        throwIfDataIndexNotFound('createEventBooking', idx, postData.learningItemId);
                        transformedData[idx].statusName = data.value.statusName;
                        transformedData[idx].statusId = 9;
                        transformedData[idx].instructorInfo= data.value;// {bookingId: data.value.bookingInfo.bookingId, statusId: 1, venueName: data.value.eventInfo.venueName,venueCity:data.value.eventInfo.venueCity}
                        // Refresh the store data
                        store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });   
                        

                        data.value.bookingStatusId =1;

                       //Update pathway table
                        updatePathWayDetailsBooking(store,data.value,false);

                       //zc - testing update dashboard
                       store.setState({   dashboardSummary: { data: null, time: null } }); // refresh linked data!
                    }
                    else{
                        // Refresh the store data
                        store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });   
                    }
                    return true;
                } catch (err) {
                    toastError(err);
                    store.setState({ myActiveSortedTrainingPlan: { data: undefined, loading: 0 } });
                    return false;
                }
            } 
        }
        return true;
    },
    (store, postData) => false
);

export const cancelEventBooking = postDataPromiseFn('/api/booking/cancelBooking',
(store, postData, response) => {
    if (store.getState().myActiveSortedTrainingPlan) {
        if (!(store.getState().myActiveSortedTrainingPlan.loading > 0)) {
            store.setState({ myActiveSortedTrainingPlan: { data: store.getState().myActiveSortedTrainingPlan.data, loading: getLockId() } }); // Always lock immediately
            try {
                let data = responseData(response);
                //console.log(`cancelEventBooking=> response:`, data);
                throwIfDataNotValid(data);
                //Update store forr Learning Plan
                let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data),
                // eslint-disable-next-line
                idx = transformedData.findIndex(x => x.learningItemId === postData.learningItemId);
                if (idx > -1){
                    throwIfDataIndexNotFound('cancelEventBooking', idx, postData.learningItemId);
                    transformedData[idx].statusName = 'Not Started';
                    transformedData[idx].statusId = 1;
                    transformedData[idx].instructorInfo= {bookingId: 0, statusId: 6, statusName:'Cancelled'}
                    // Refresh the store data
                    store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } }); 
                    
                   
                    //Update pathway table
                    updatePathWayDetailsBooking(store, {bookingId: postData.bookingId,learningItemId:postData.learningItemId, statusName:'', bookingStatusId:6},true); 
                    
                      //zc - testing update dashboard
                      store.setState({   dashboardSummary: { data: null, time: null } }); // refresh linked data!
                }
                else{
                    // Refresh the store data
                    store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });   
                }
                return true;
            } catch (err) {
                toastError(err);
                store.setState({ myActiveSortedTrainingPlan: { data: undefined, loading: 0 } });
                return false;
            }
        } 
    }
    return true;
},
(store, postData) => false
);


export const cancelEventBookingNew = postDataPromiseFn('/api/booking/cancelBooking',
(store, postData, response) => {
    updateLearningPlanStatus(store,response,postData.learningItemId,1,'Not Started',{bookingId: 0, statusId: 6, statusName:'Cancelled'});

    return true;
   /* if (store.getState().myActiveSortedTrainingPlan) {
        if (!(store.getState().myActiveSortedTrainingPlan.loading > 0)) {
            store.setState({ myActiveSortedTrainingPlan: { data: store.getState().myActiveSortedTrainingPlan.data, loading: getLockId() } }); // Always lock immediately
            try {
                let data = responseData(response);
                //console.log(`cancelEventBooking=> response:`, data);
                throwIfDataNotValid(data);
                //Update store forr Learning Plan
                let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data),
                // eslint-disable-next-line
                idx = transformedData.findIndex(x => x.learningItemId === postData.learningItemId);
                if (idx > -1){
                    throwIfDataIndexNotFound('cancelEventBooking', idx, postData.learningItemId);
                    transformedData[idx].statusName = 'Not Started';
                    transformedData[idx].statusId = 1;
                    transformedData[idx].instructorInfo= {bookingId: 0, statusId: 6, statusName:'Cancelled'}
                    // Refresh the store data
                    store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } }); 
                    
                   
                    //Update pathway table
                    updatePathWayDetailsBooking(store, {bookingId: postData.bookingId,learningItemId:postData.learningItemId, statusName:'', bookingStatusId:6},true); 
                    
                      //zc - testing update dashboard
                      store.setState({   dashboardSummary: { data: null, time: null } }); // refresh linked data!
                }
                else{
                    // Refresh the store data
                    store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });   
                }
                return true;
            } catch (err) {
                toastError(err);
                store.setState({ myActiveSortedTrainingPlan: { data: undefined, loading: 0 } });
                return false;
            }
        } 
    }
    return true; */
},
(store, postData) => false 
);
export const createWaitingDatesNew = postDataPromiseFn('/api/waitingDates/CreateWaitingDatesByLearningItem',
    (store, postData, response) => {
        updateLearningPlanStatus(store,response,postData,8,'Awaiting Dates',null);
       return true;

    },
    (store, postData) => false
);

export const createWaitingDates = postDataPromiseFn('/api/waitingDates/CreateWaitingDatesByLearningItem',
    (store, postData, response) => {
        console.log('Wd 1');
        if (store.getState().myActiveSortedTrainingPlan) {
            if (!(store.getState().myActiveSortedTrainingPlan.loading > 0)) {
                store.setState({ myActiveSortedTrainingPlan: { data: store.getState().myActiveSortedTrainingPlan.data, loading: getLockId() } }); // Always lock immediately
                try {
                 
                    let data = responseData(response);
                    //console.log(`CreateWaitingDatesByLearningItem=> response:`, data);
                    throwIfDataNotValid(data);
                    //Update store forr Learning Plan
                    let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data),

                    // eslint-disable-next-line
                    idx = transformedData.findIndex(x => x.learningItemId === postData);
                   
                    if (idx > -1){
                        throwIfDataIndexNotFound('CreateWaitingDatesByLearningItem', idx, postData.learningItemId);
                        transformedData[idx].statusName = 'Awaiting Dates';
                        transformedData[idx].statusId = 8;
                         //  transformedData[idx].instructorInfo= {bookingId: data.value.bookingId, statusId: 1}
                        // Refresh the store data
                        store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });   
                        
                       //Update pathway table
                       console.log('up pw', postData);
                        updatePathWayDetailsBooking(store,{learningItemId:postData, statusName:'Waiting Dates',statusId:9,bookingId:-1,bookingStatusId:9},false);
                        toastSuccess('Your request has been processed sucessfully');
                        //zc - testing update dashboard
                        store.setState({   dashboardSummary: { data: null, time: null } }); // refresh linked data!
                        
                    }
                    else{
                        // Refresh the store data
                        store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });   
                    }
                         
                    return true;
                } catch (err) {
                    toastError(err);
                    store.setState({ myActiveSortedTrainingPlan: { data: undefined, loading: 0 } });
                    return false;
                }
            } 
        }
        return true;
    },
    (store, postData) => false
);


export const removeWaitingDates = postDataPromiseFn('/api/waitingDates/RemoveWaitingDates',
    (store, postData, response) => {
        console.log('Wd 1');
        if (store.getState().myActiveSortedTrainingPlan) {
            if (!(store.getState().myActiveSortedTrainingPlan.loading > 0)) {
                store.setState({ myActiveSortedTrainingPlan: { data: store.getState().myActiveSortedTrainingPlan.data, loading: getLockId() } }); // Always lock immediately
                try {

                    let data = responseData(response);
                    //console.log(`CreateWaitingDatesByLearningItem=> response:`, data);
                    throwIfDataNotValid(data);
                    //Update store forr Learning Plan
                    let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data),
                    // eslint-disable-next-line
                    idx = transformedData.findIndex(x => x.learningItemId === postData);

                    if (idx > -1){
                        throwIfDataIndexNotFound('RemoveWaitingDates', idx, postData.learningItemId);
                        transformedData[idx].statusName = 'No Started';
                        transformedData[idx].statusId = 1;
                         //  transformedData[idx].instructorInfo= {bookingId: data.value.bookingId, statusId: 1}
                        // Refresh the store data
                        store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });

                       //Update pathway table
                       console.log('up pw', postData);
                       updatePathWayDetailsBooking(store,{learningItemId:postData, statusName:'No Started',statusId:1,bookingId:-1,bookingStatusId:9},false);
                        toastSuccess('Your request has been processed sucessfully');
                        //zc - testing update dashboard
                        store.setState({   dashboardSummary: { data: null, time: null } }); // refresh linked data!

                    }
                    else{
                        // Refresh the store data
                        store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });
                    }

                    return true;
                } catch (err) {
                    toastError(err);
                    store.setState({ myActiveSortedTrainingPlan: { data: undefined, loading: 0 } });
                    return false;
                }
            }
        }
        return true;
    },
    (store, postData) => false
);

const updateLearningPlanStatus=(store,response, item, statusId,statusName, insInfo) =>{
    if (store.getState().myActiveSortedTrainingPlanNew) {
        if (!(store.getState().myActiveSortedTrainingPlanNew.loading > 0)) {
            store.setState({ myActiveSortedTrainingPlanNew: { data: store.getState().myActiveSortedTrainingPlanNew.data, loading: getLockId() } }); // Always lock immediately
            try {
             
                let data = responseData(response);
                //console.log(`CreateWaitingDatesByLearningItem=> response:`, data);
                throwIfDataNotValid(data);
                //Update store forr Learning Plan
                let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlanNew.data);
    console.log('status info', insInfo);
//console.log('item', item);
    let pwItems = transformedData.filter(x => x.learnProgId!==null || x.learnProgId>0);
    console.log('5, ', pwItems.length);
    //Check if pathway to update
    let idxp = 0;
    if(pwItems.length>0){
        console.log('6, ');
        pwItems.forEach((idx2) => {
            console.log('7, ');
            if (transformedData[idxp].children!=null){
                console.log('7.1, ');
            let indexI = transformedData[idxp].children.findIndex(x => x.learningItemId === item);
  
            if (indexI >0 ){
                console.log('7.2, ');
               transformedData[idxp].children[indexI].statusName = statusName;
               transformedData[idxp].children[indexI].statusId = statusId;   
               
               if (insInfo!=null){
                transformedData[idxp].children[indexI].instructorInfo=insInfo;
               }
                                         
            }
      }
 
        idxp++;
        console.log('8, ');
        });
    }
    let idx = transformedData.findIndex(x => x.learningItemId === item);
        if (idx > -1){
         throwIfDataIndexNotFound('updateLearningPlanStatus', idx, item);
         transformedData[idx].statusName = statusName;
         transformedData[idx].statusId = statusId;

         if (insInfo!=null){
            transformedData[idx].instructorInfo=insInfo;
           }
         // Refresh the store data                    
         store.setState({ myActiveSortedTrainingPlanNew: { data: transformedData, time: new Date(), loading: 0  } });
         store.setState({   dashboardSummary: { data: null, time: null } }); 

     }     

    } catch (err) {
        toastError(err);
        store.setState({ myActiveSortedTrainingPlanNew: { data: undefined, loading: 0 } });
        return false;
    }
    }
}
}
export const removeWaitingDatesNew = postDataPromiseFn('/api/waitingDates/RemoveWaitingDates',
    (store, postData, response) => {
        updateLearningPlanStatus(store,response,postData,1,'No Started',null);
        toastSuccess('Your request has been processed sucessfully');
        return true;  
       
    },
    (store, postData) => false
);


/**
 * Complete training plan and pathway.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {learningItemId} postData 
 * @returns 
 */
export const postCompleteTrainingPlanAndPathway = postDataPromiseFn('/api/TrainingPlan/CompleteTrainingPlanAndPathway',
    (store, postData, response) => {
        if (store.getState().myActiveSortedTrainingPlan) {
            if (!(store.getState().myActiveSortedTrainingPlan.loading > 0)) {
                try {
                    let data = responseData(response);
                    throwIfDataNotValid(data);
                    //Update store forr Learning Plan
                    let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data),
                    // eslint-disable-next-line
                    idx = transformedData.findIndex(x => x.learningItemId === data.value);
                    if (idx > -1){
                        store.setState({ myActiveSortedTrainingPlan: { data: store.getState().myActiveSortedTrainingPlan.data, loading: getLockId() } }); // Always lock immediately
                        throwIfDataIndexNotFound('postCompleteTrainingPlanAndPathway', idx, data.value);
                        transformedData[idx].statusName = 'Completed';
                        transformedData[idx].statusId = 2;
                        // Refresh the store data
                        store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date(), loading: 0  } });
                    }
                    store.setState({   dashboardSummary: { data: null, time: null } }); // reshesh dashboard information
                    updatePathWaiDetails(store, data);
                    return true;
                } catch (err) {
                    toastError(err);
                    store.setState({ myActiveSortedTrainingPlan: { data: undefined, loading: 0 } }); // Always unlock on error. data undefined will cause a complete reload from the server
                    return false;
                }
            } 
        }
    return true;
    },
    (store, postData) => false
);


/**
 * Complete training plan and pathway.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {learningItemId} postData 
 * @returns 
 */
export const postCompleteTrainingPlanAndPathwayNew = postDataPromiseFn('/api/TrainingPlan/CompleteTrainingPlanAndPathway',
    (store, postData, response) => {
        updateLearningPlanStatus(store,response,postData,2,'Completed', null);
     
    return true;
    },
    (store, postData) => false
);
export const postCreateUsers = postDataPromiseFn('/api/account/createusers',
    (store, postData, response) => {
        if (!(store.getState().createUsers.loading > 0)) {
            store.setState({ createUsers: { data: store.getState().createUsers.data, loading: getLockId() } });

        }
        let data = response.data

            store.setState({
            createUsers: {
                loading: 0,
                data: createUsersResponseApiToFront(data)
            }
        })

        return true;
    },
    (store, postData) => false
);

export const resetCreateUsersState = (store, data) => {
    if (store.getState().createUsers) {
        try {
            store.setState({
                createUsers:{
                    data:[],
                    loading:0
                }
            })

            return true;

        } catch (err) {
            return false;
        }


    }
};

/**
 * delete admin news data.
 * 
 * Return a Promise with status true/false if successful.
 * 
 * @param {object} postData 
 * @returns 
 */
export const postDeleteAdminNewsItem = postBooleanPromiseFn('/api/news/deleteNews', true);

export const inValidateMyActiveSortedTrainingPlan = () => {
    //zc now working 
    //store.setState({ myActiveSortedTrainingPlan: {Data:null,time: new Date()} });
};

const doMyActiveSortedTrainingPlanUpdate = (store, data, withLock) => {
    console.log(`doMyActiveSortedTrainingPlanUpdate data:`, data);
    let maxAttempts = 0,
        status = (data.elearningInfo != undefined? (data.elearningInfo.resultStatusName!= undefined? data.elearningInfo.resultStatusName.toLowerCase():undefined):undefined),
        transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data);
    
    if(withLock){
        store.setState({ myActiveSortedTrainingPlan: { ...store.getState().myActiveSortedTrainingPlan, loading: getLockId() } }); // Always lock immediately
    }
  
    let indexes = transformedData.findIndex(x => x.learningItemId === data.learningItemId);
    if(indexes && indexes !== -1 ){
        if(Array.isArray(indexes)){
            indexes.forEach((idx) => {
                throwIfDataIndexNotFound('doMyActiveSortedTrainingPlanUpdate', idx, data.learningItemId);
                transformedData[idx].statusName = (status === 'complete' ? 'Completed' : 'In progress');
            
                if (transformedData[idx].elearningInfo) {
                    //The data is not returing the max attempt so we need to keep it from the original value
                    maxAttempts = transformedData[idx].elearningInfo.maxAttempts;
                }
                transformedData[idx].elearningInfo = data.elearningInfo;
                if (maxAttempts > 0 && data.elearningInfo)
                    transformedData[idx].elearningInfo.maxAttempts = maxAttempts;
            })
        }
        else {
            throwIfDataIndexNotFound('doMyActiveSortedTrainingPlanUpdate', indexes, data.learningItemId);
            transformedData[indexes].statusName = (status === 'complete' ? 'Completed' : 'In progress');
            if (transformedData[indexes].elearningInfo) {
                //The data is not returing the max attempt so we need to keep it from the original value
                maxAttempts = transformedData[indexes].elearningInfo.maxAttempts;
            }
            transformedData[indexes].elearningInfo = data.elearningInfo;
            if (maxAttempts > 0 && data.elearningInfo)
                transformedData[indexes].elearningInfo.maxAttempts = maxAttempts;
        }
        
        // Refresh the store data
        if(status !== 'complete'){
            store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: new Date() } });
        }
        else{
            store.setState({ myActiveSortedTrainingPlan: { data: transformedData, time: null } });
        }  
    }
};

const doMyActiveSortedTrainingPlanUpdateNew = (store, data, withLock) => {
    let maxAttempts = 0,
        status = (data.elearningInfo != undefined? (data.elearningInfo.resultStatusName!= undefined? data.elearningInfo.resultStatusName.toLowerCase():undefined):undefined),
        transformedData = structuredClone(store.getState().myActiveSortedTrainingPlanNew.data);
    
    if(withLock){
        store.setState({ myActiveSortedTrainingPlanNew: { ...store.getState().myActiveSortedTrainingPlan, loading: getLockId() } }); // Always lock immediately
    }
  
    let indexes = transformedData.findIndex(x => x.learningItemId === data.learningItemId);
    if (indexes !== -1 ){
       
            transformedData[indexes].statusName = (status === 'complete' ? 'Completed' : 'In progress');
            if (transformedData[indexes].elearningInfo) {
                //The data is not returing the max attempt so we need to keep it from the original value
                maxAttempts = transformedData[indexes].elearningInfo.maxAttempts;
            }
            transformedData[indexes].elearningInfo = data.elearningInfo;
            if (maxAttempts > 0 && data.elearningInfo)
                transformedData[indexes].elearningInfo.maxAttempts = maxAttempts;
    }
    let pwItems = transformedData.filter(x => x.learnProgId!==null || x.learnProgId>0);
    //Check if pathway to update
    let idxp = 0;
    if(pwItems.length>0){
        pwItems.forEach((idx) => {
            if (transformedData[idxp].children!=null){
            let indexI = transformedData[idxp].children.findIndex(x => x.learningItemId === data.learningItemId);
  
            if (indexI >0 ){
               transformedData[idxp].children[indexI].statusName = (status === 'complete' ? 'Completed' : 'In progress');
            if (transformedData[idxp].children[indexI].elearningInfo) {
                //The data is not returing the max attempt so we need to keep it from the original value
                maxAttempts = transformedData[idxp].children[indexI].elearningInfo.maxAttempts;
            }
            transformedData[idxp].children[indexI].elearningInfo = data.elearningInfo;
            if (maxAttempts > 0 &&  transformedData[idxp].children[indexI].elearningInfo )
                transformedData[idxp].children[indexI].elearningInfo.maxAttempts = maxAttempts;
        
                //Update pw  status
            let completedCount = transformedData[idxp].children.filter(x => (x.statusName === 'Completed' || x.statusName==='Completed - Colleague' || (x.elearningInfo && x.elearningInfo.completionStatusName === 'Complete' && x.elearningInfo.sucessStatusName !== 'Failed')) || (x.statusName === 'Attended' || (x.instructorInfo && x.instructorInfo.statusName === 'Attended')));
            if (completedCount.length===transformedData[idxp].children[indexI].length ){
                transformedData[idxp].statusName="Completed";
            }
            else{
                transformedData[idxp].statusName="In Progress";
            }
        }   
            
        }
        idxp++;
        });
    }
        
        // Refresh the store data
        if(status !== 'complete'){
            store.setState({ myActiveSortedTrainingPlanNew: { data: transformedData, time: new Date() } });
        }
        else{
            store.setState({ myActiveSortedTrainingPlanNew: { data: transformedData, time: null } });
        }  
    
};

export const updateLearningPlanOpenNew = (store, data,isOpen) => {
    
    let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlanNew.data);
    let index = transformedData.findIndex(x => x.learningItemId === data);
    if (index !== -1 ){
    transformedData[index].isOpen = isOpen;
           
    let pwItems = transformedData.filter(x => (x.learnProgId!==null || x.learnProgId>0));
  
    //Check if pathway to update
    let idxp = 0;
    if(pwItems.length>0){
        pwItems.forEach((idx) => {
            if (transformedData[idxp].children!=null){
            let indexI = transformedData[idxp].children.findIndex(x => x.learningItemId === data);
  
            if (indexI >0 ){
               transformedData[idxp].children[indexI].isOpen = isOpen;           
        }   
            
        }
        idxp++;
        });
    }

    store.setState({ myActiveSortedTrainingPlanNew: { data: transformedData, time: new Date() } });
}
        
};

/**
 * Set the MyActiveSortedTrainingPlan with the information passed from the data
 * 
 * 
 * @param {messageFromSignalr} data 
 * @returns 
 */
export const setMyActiveSortedTrainingPlan = (store, data) => {
    //console.log(`setMyActiveSortedTrainingPlan=>   data:`, data);
    let returnObj ={ pathwayIds: [], status: undefined };
    if (store.getState().myActiveSortedTrainingPlan) {
        if (!(store.getState().myActiveSortedTrainingPlan.loading > 0)) {
            try {       
                let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlan.data),
                // eslint-disable-next-line
                idx = transformedData.findIndex(x => x.learningItemId === data.learningItemId && x.id === data.trainingPlanId);
                if (idx > -1) {
                    if (isElearningChaged(transformedData[idx],data)){ //ZC- trying to do only if content changed
                    console.log("updating table data.elearninginfo",data.elearningInfo);    
                    //console.log("updating table tsnsformdata.elearninginfo",transformedData[idx].elearningInfo);                       
                    doMyActiveSortedTrainingPlanUpdate(store, data, true);
                    doManipulatePathWayDetailsUpdate(store, data, returnObj);
                     //zc - testing update dashboard
                     store.setState({   dashboardSummary: { data: null, time: null } }); // refresh linked data!
                    return true;                  
                    }
                }  
              
            } catch (err) {
                toastError(err);
                store.setState({ myActiveSortedTrainingPlan: { loading: 0 } });
                return false;
            }
        } 
        
        return false;
        
    }
};

export const setMyActiveSortedTrainingPlanNew = (store, data) => {
      
    if (store.getState().myActiveSortedTrainingPlanNew) {
        if (!(store.getState().myActiveSortedTrainingPlanNew.loading > 0)) {
            try {     
                let transformedData = structuredClone(store.getState().myActiveSortedTrainingPlanNew.data),
                // eslint-disable-next-line
                idx = transformedData.findIndex(x => x.learningItemId === data.learningItemId);
                if (idx > -1) {
                    if (isElearningChaged(transformedData[idx],data)){ //ZC- trying to do only if content changed
                    //console.log("updating table tsnsformdata.elearninginfo",transformedData[idx].elearningInfo);                       
                    doMyActiveSortedTrainingPlanUpdateNew(store, data, true);
                  //  doManipulatePathWayDetailsUpdate(store, data, returnObj);
                     //zc - testing update dashboard
                     store.setState({   dashboardSummary: { data: null, time: null } }); // refresh linked data!
                    return true;                  
                    }
                }  
              
            } catch (err) {
                toastError(err);
                store.setState({ myActiveSortedTrainingPlanNew: { loading: 0 } });
                return false;
            }
        } 
        
        return false;
        
    }
};

export const isElearningChaged = (localData,newData) => {

 

    if ((localData.elearningInfo==null || localData.elearningInfo==undefined) && localData.elearningInfo!=newData.elearningInfo){
       
       return true;
    }
      if (localData.elearningInfo!==null && newData.elearningInfo){
        if (localData.elearningInfo.totalAttemptGroups !== newData.elearningInfo.totalAttemptGroups || 
            localData.elearningInfo.completionStatusId !== newData.elearningInfo.completionStatusId ||
            localData.elearningInfo.sucessStatusId !== newData.elearningInfo.sucessStatusId || 
            localData.elearningInfo.resultStatusId !== newData.elearningInfo.resultStatusId){
         
          /*  console.log('changed 1 localData',localData);
            console.log('changed 1 - newData', newData);
            console.log(' localData.elearningInfo.totalAttemptGroups: ' +localData.elearningInfo.totalAttemptGroups);
            console.log('newData.elearningInfo.totalAttemptGroups: ' + newData.elearningInfo.totalAttemptGroups);            
            console.log(' localData.elearningInfo.completionStatusId: ' +localData.elearningInfo.completionStatusId);
            console.log('newData.elearningInfocompletionStatusId: ' + newData.elearningInfo.completionStatusId);            
            console.log(' localData.elearningInfo.sucessStatusId: ' +localData.elearningInfo.sucessStatusId);
            console.log('newData.elearningInfo.sucessStatusId: ' + newData.elearningInfo.sucessStatusId);            
            console.log(' localData.elearningInfo.resultStatusId: ' +localData.elearningInfo.resultStatusId);
            console.log('newData.elearningInfo.resultStatusId: ' + newData.elearningInfo.resultStatusId);            
           */
            return true;
    }
}
 
    return false;
};

export const responseData = (response, defaultValue = null) => {
    let data = defaultValue;
    const contentType = response && response.headers && response.headers['content-type'];
    if (response.isValid === true || (contentType && contentType.indexOf("application/json") !== -1)) {
         data = (response && response.data && response.data.value ? response.data.value : response.data) || defaultValue;
    } else {

        let err = new Error(`Invalid content type returned (${contentType})`);
        toastError(err);
        console.log('error1',response);
        console.dir(response);
    }
    return data || defaultValue;
};
