import { useEffect } from 'react';
import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import { Carousel, Col, Row } from 'react-bootstrap';
import useTheme from '../hooks/useTheme';

const getExtension = (path) => /(?:\.([^.]+))?$/.exec(path)[1];

export default function NewsStand(props) {
    const { themeName } = useTheme();
    const { allNews, fetchAllNews } = useLmsStore(state =>
    ({
        allNews: state.allNews.data,
        fetchAllNews: state.fetchAllNews
    }), shallow)

    // on load
    useEffect(() => {
        fetchAllNews();
    }, [fetchAllNews]);

    function truncate(str, n) {
        return (str.length > n) ? str.slice(0, n - 1) + '&hellip;' : str;
    };

    return (
        <Col style={{ height: '5rem', overflowX: 'hidden' }} >
            {allNews && allNews.length > 0 ?
                <Carousel variant='dark' style={{ height: '100%' }} controls={false} indicators={false}>
                    {allNews.map((rowData, idx) => (
                        <Carousel.Item key={idx} interval={5000} >
                            <div className="w-100"><div className='h5 '>{rowData.title}</div><span style={{fontSize:"0.8rem"}} dangerouslySetInnerHTML={{ __html: rowData.description + (rowData.link ? '<br><a href="' + rowData.link + (getExtension(rowData.link) ? '" target="_blank' : '') + '">' + rowData.linkDescription + '</a>' : '') }} /></div>
                        </Carousel.Item>
                    ))}
                </Carousel> :
                <>
                    <h4 className="mt-2">{props.title}</h4>
                    <p className='text-muted'>Learning Management System</p>
                </>
            }
        </Col>
    );
}
