
const GOOD_DATE_AFTER = new Date(1900, 0, 1);

export const cellToLocaleDateString = ({ cell, row }) => toLocaleDateString(cell.getValue());

export const cellToLocaleDateTimeString = ({ cell, row }) => toLocaleDateTimeString(cell.getValue());

export const toDate = (x) => isSupportedDate(x) ? getDate(x) : '';

export const toDDsMMsYYYYString = (x) => isSupportedDate(x) ? new Intl.DateTimeFormat('en-GB', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(getDate(x)) : '';

export const toISODateString = (x) => isSupportedDate(x) ? (typeof x === 'string' && x.includes('T') ? x.split('T')[0] : getDate(x).toISOString().split('T')[0]) : '';

export const toISODateTimeString = (x) => isSupportedDate(x) ? (typeof x === 'string' && x.includes('T') ? x : getDate(x).toISOString()) : '';

export const toLocaleDateString = (x, formatter = new Intl.DateTimeFormat(navigator.language || navigator.userLanguage, { dateStyle: 'short' })) => !isSupportedDate(x) ? '' : formatter.format(getDate(x));

export const toLocaleDateTimeString = (x, formatter = new Intl.DateTimeFormat(navigator.language || navigator.userLanguage, { dateStyle: 'short', timeStyle: 'short' })) => !isSupportedDate(x) ? '' : formatter.format(getDate(x));

export const isSupportedDate = (x) => getDate(x, GOOD_DATE_AFTER) > GOOD_DATE_AFTER;

export const getDate = (x, defaultDate) => x instanceof Date ? x : typeof x === 'string' && x.includes('-') ? new Date(x.includes('T') ? x : `${x}T00:00:00.000Z`) : defaultDate;

export const getCorrencySign = (i) => {
    switch (i) {
        case 0:
            return "€"
        case 1:
            return "$"
        case 2:
            return "£"
        default:
            return "?"
    }
}