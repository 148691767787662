import {apiAxios} from "../helpers/axios";
import axios from "axios";
import {getAdminPortalRpCall2} from "../hooks/useLegacyStore";

const getApiUrl = (urlFragment) => {
    let urlSrv = '/rpcall-learningportal.test';
    return `${urlSrv}${urlFragment}`;
}

class ApiAxiosCalls {

    constructor() {
        this.searchPermissionsCancellationToken = null
        this.lastSearchPermissionsId = 0;
        this.searchGroupsCancellationToken = null
        this.searchGroupsId = 0;
        this.getCardsBelongingToGroupCancellationToken = null
        this.getCardsBelongingToGroupId = 0;
    }

    getApplicationLogInPageConfiguration() {
        return apiAxios.get('/api/Application/GetApplicationLogInPageConfiguration')
    }

    samlLogout() {
        return apiAxios.post("/api/samlAuth/logout2", {})
    }

    searchPermissions(payload) {

        const currentSearchId = ++this.lastSearchPermissionsId;

        if (this.searchPermissionsCancellationToken) {
            this.searchPermissionsCancellationToken.cancel('Request cancelled on purpose');
        }

        this.searchPermissionsCancellationToken = axios.CancelToken.source();

        return new Promise((resolve, reject) => {

            if (currentSearchId === this.lastSearchPermissionsId) {
                apiAxios.post(getAdminPortalRpCall2('/api/permissions/search'), payload, {
                    cancelToken: this.searchPermissionsCancellationToken.token
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                }).finally(() => {
                    if (currentSearchId === this.lastSearchPermissionsId) {
                        this.searchPermissionsCancellationToken = null;
                    }
                });
            }
        });
    }

    createGroupApiCall(payload) {
        return apiAxios.post(getAdminPortalRpCall2('/api/groupManagement'), payload)
    }


    getCardsBelongingToGroup(payload) {
        const currentSearchId = ++this.getCardsBelongingToGroupId;

        if (this.getCardsBelongingToGroupCancellationToken) {
            this.getCardsBelongingToGroupCancellationToken.cancel('Request cancelled on purpose');
        }

        this.getCardsBelongingToGroupCancellationToken = axios.CancelToken.source();

        return new Promise((resolve, reject) => {
            if (currentSearchId === this.getCardsBelongingToGroupId) {
                apiAxios.post(getAdminPortalRpCall2('/api/trainingCard/groupManagement/search'), payload, {
                    cancelToken: this.getCardsBelongingToGroupCancellationToken.token
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                }).finally(() => {
                    // Clear the token after the request completes or fails
                    if (currentSearchId === this.getCardsBelongingToGroupId) {
                        this.getCardsBelongingToGroupCancellationToken = null;
                    }
                });
            }

        });
    }

    searchGroupMembers(payload) {
        const currentSearchId = ++this.searchGroupsId;

        if (this.searchGroupsCancellationToken) {
            this.searchGroupsCancellationToken.cancel('Request cancelled on purpose');
        }

        this.searchGroupsCancellationToken = axios.CancelToken.source();

        return new Promise((resolve, reject) => {

            if (currentSearchId === this.searchGroupsId) {
                apiAxios.post(getAdminPortalRpCall2('/api/people/groupsearch'), payload, {
                    cancelToken: this.searchGroupsCancellationToken.token
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    if (!axios.isCancel(error)) {
                        reject(error);
                    }
                }).finally(() => {
                    if (currentSearchId === this.searchGroupsId) {
                        this.searchGroupsCancellationToken = null;
                    }
                });
            }

        });
    }

    getGroupSummaryRecords(payload) {
        return apiAxios.post(getAdminPortalRpCall2('/api/groupManagement/getSummaryRecords'), payload)
    }

    searchGroupsApiCall(payload) {
        return apiAxios.post(getAdminPortalRpCall2('/api/groupManagement/search'), payload)
    }

    //learning items calls
    searchLearningItems(payload) {
        return apiAxios.post(getAdminPortalRpCall2('/api/learningItems/search'), payload)
    }

    handleAdminLogin(personIdentifier = "0549957c-f3d3-40d4-be6d-0f8861586576") {
        return apiAxios.post(getApiUrl('/api/account/singleSignOnFromNewLms'), {ccid: personIdentifier})
            .then(res => {
                apiAxios.get(getApiUrl('/api/account/getApplicationRegistration')).then(res => {

                })
            })
    }
}

export const apiAxiosCalls = new ApiAxiosCalls()
