
const containsString = (value, searchTerm) => (typeof value === 'string' && value.toLowerCase().indexOf(searchTerm) > -1) || (typeof value === 'function' && value().toLowerCase().indexOf(searchTerm));
const hasString = (value, searchTerm) => (typeof value === 'string' && value.toLowerCase() === searchTerm) || (typeof value === 'function' && value().toLowerCase() === searchTerm);

/**
 * At present only support string properties
 * 
 * The properties to look into can be a function. Functions are passed
 * each object to search and must return a string value, e.g.
 *      (obj) => obj.learningItemTypeId === 8 ? 'Virtual/classroom' : obj.learningItemTypeId === 3 ? 'e-learning' : obj.learningItemTypeId === 2 ? 'Instructor led' : 'course'  );
 * 
 * @param {object[]} array - The array of objects to search
 * @param {string} searchCondition - The search condition
 * @param {string[] || function[]} propertyNames - The properties to look into. Each property can be a string or a function.
 * @returns 
 */
export const searchObjectArray = (array, searchCondition, propertyNames) => {
    if (typeof searchCondition !== 'string') {
        return array;
    }
    let searchTerms = searchCondition.toLowerCase().split(' ').filter(v => v.trim() !== ''),
        searchResults = [];
    if (searchTerms.length === 0) {
        return array;
    }
    array.forEach((obj) => {
        let searchResult = { obj: obj, searchCount: 0 };
        propertyNames.forEach(propertyName => {
            let propertyValue = typeof propertyName === 'function' ? propertyName(obj) : obj[propertyName];
            if (propertyValue && propertyValue.trim() !== '') {
                propertyValue = propertyValue.trim();
                searchTerms.forEach(searchTerm => {
                    if (hasString(propertyValue, searchCondition)) {
                        searchResult.searchCount += 1000;
                    } else if (hasString(propertyValue, searchTerm)) {
                        searchResult.searchCount += 100;
                    } else if (containsString(propertyValue, searchTerm)) {
                        searchResult.searchCount += 1;
                    }
                });
            }
        });
        if (searchResult.searchCount > 0) {
            searchResults.push(searchResult);
        }
    });
    return searchResults.sort((a, b) => b.searchCount - a.searchCount).map((searchResult) => searchResult.obj);
}
