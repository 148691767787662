import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandCircleDownOutlined from '@mui/icons-material/ExpandMore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClose } from '@fortawesome/pro-solid-svg-icons';

import moment from 'moment'
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import React, { useState, useEffect } from "react";

export default function CompetenciesAccordion(props) {

    const data = props.data;
    const header = props.header;

    const validateDate = (dateString) => {

        //console.log("validateDate RAW : " + dateString);
        const todayDateString = moment().format("DD/MM/YYYY");
        const isDateBeforeToday = moment(dateString, "DD/MM/YYYY").isBefore(moment(todayDateString, "DD/MM/YYYY"));

        // Check if the date is within 6 months in the future
        const isWithin6Months = moment(dateString, "DD/MM/YYYY").isBetween(moment(todayDateString, "DD/MM/YYYY"), moment(todayDateString, "DD/MM/YYYY").add(6, 'months'));

        //  const isWithin6Months = moment(dateString, "DD/MM/YYYY").isBetween(moment('03/10/2007', "DD/MM/YYYY"), moment('27/04/2011', "DD/MM/YYYY").add(6, 'months'));
        //  const isWithin6Months = moment(dateString, "DD/MM/YYYY").isBetween(moment('01/12/9999', "DD/MM/YYYY"), moment('31/12/9999', "DD/MM/YYYY").add(6, 'months'));

        // console.log('isWithin6Months : ' + isWithin6Months)
        // console.log("Is the date before today?:::::", isDateBeforeToday);

        let color;
        if (isWithin6Months) {
            color = "#E68B15";
            //console.log("The date is before today.");
        } else if (isDateBeforeToday) {
            color = "red"; // Change this to the desired color for within 6 months
            //console.log("The date is within 6 months in the future.");
        } else {
            color = "green";
            //console.log("The date is today or more than 6 months in the future.");
        }
        return color;
    };

    return (
       
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandCircleDownOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: '#281E84', color: 'white' }}
                >
                    <Typography>{header}</Typography>
                 
                </AccordionSummary>
                <AccordionDetails>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Operational</TableCell>
                                <TableCell>Qualifications Group</TableCell>
                                <TableCell>Qualification Name</TableCell>
                                <TableCell>Proficiency</TableCell>
                                <TableCell>Valid From</TableCell>
                                <TableCell>Valid To</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((competency) => (
                                <TableRow key={competency.id}>
                                    <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                        {competency.isOperational == 1
                                            ? <FontAwesomeIcon size='xl' icon={faCheck} className='text-success' />
                                            : <FontAwesomeIcon size='xl' icon={faClose} className='text-danger' />}
                                    </TableCell>
                                    <TableCell>{competency.qualificationGroup}</TableCell>
                                    <TableCell>{competency.qualificationName}</TableCell>
                                    <TableCell>{competency.proficiency}</TableCell>
                                    <TableCell>{competency.validFrom}</TableCell>
                                    <TableCell style={{ color: validateDate(competency.validTo) }}><b>{competency.validTo}</b></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
       
    );
}

