import { NavLink } from "react-router-dom";
import { Col } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboard, faChalkboardUser, faPersonChalkboard, faCircle, faPlus, faPhotoFilm, faRoad} from '@fortawesome/pro-solid-svg-icons';
import { ThemeProvider, createTheme } from '@mui/material';
// Add a new CSS class for dark blue text

function randomId() {
    return Math.floor(Math.random() * 999999999999).toString(16);
};

// INFO:
// learningItemTypeId:
//      1: course
//      2: instructor led training
//      3: e-learning
//      8: Virtual/classroom

/**
 * Display column if width is variant or greater
 * 
 * @param {string} variant - sm, md, lg or xl
 * @param {*} id  - An optional id. A random id is assigned if not provided.
 */
export const columnBlankIfWidth = (variant, id) => {
    return {
        accessorFn: (row) => '',
        id: id || randomId(),
        enableEditing: false,
        header: '',
        muiTableBodyCellProps: {
            classes: { root: `fw-unset-none-blank fw-cell-${variant}` },   // Flexible width, hidden if below variant size
            sx: { minSize: 0 }
        },
        muiTableHeadCellProps: {
            classes: { root: `fw-unset-none-blank fw-cell-${variant}` },   // Flexible width, hidden if below variant size
            sx: { minSize: 0 }
        }
    }
};

export const columnIconLearningItem = (addedByYouLabel = 'You', variant, navToFn) => {
    let columnDef = {
        accessorKey: 'code',
        enableEditing: false,
        header: 'Type',
        muiTableHeadCellProps: {
            align: 'center'
        },
        size: 90
    };
    if (navToFn) {
        columnDef.Cell = ({ cell, row }) => {
            return (<NavLink to={navToFn(cell, row)}><ColumnIconLearningItemCell  addedByYouLabel={addedByYouLabel} learningItemTypeId={row.original.learningItemTypeId} isMandatory={row.original.isMandatory} isAddedByAdmin={row.original.isAddedByAdmin} /></NavLink>)
        };
    } else {
        columnDef.Cell = ({ cell, row }) => {
            return (<ColumnIconLearningItemCell addedByYouLabel={addedByYouLabel} learningItemTypeId={row.original.learningItemTypeId} isMandatory={row.original.isMandatory} isAddedByAdmin={row.original.isAddedByAdmin} />)
        };
    }
    if (variant) {
        return {
            ...columnDef,
            ...{
                muiTableBodyCellProps: ({ cell, table }) => { return cell.id.startsWith('pwgrpName:') ? { sx: { display: 'none' } } : { align: 'center', classes: { root: `d-none fw-cell-${variant}` } } },
                muiTableHeadCellProps: { classes: { root: `d-none fw-cell-${variant}` } }
            }
        };
    } else {
        return {
            ...columnDef,
            ...{
                muiTableBodyCellProps: ({ cell, table }) => { return cell.id.startsWith('pwgrpName:') ? { sx: { display: 'none' } } : { align: 'center' } }
            }
        };
    }
};

const ColumnIconLearningItemCell = (props) => {
    return (<>
        <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{(props.isMandatory ? 'Mandatory ' : '') + learningItemName(props.learningItemTypeId)}</Tooltip>} >
            <FontAwesomeIcon icon={props.learningItemTypeId === 8 ? faPhotoFilm : props.learningItemTypeId === 3 ? faChalkboardUser : props.learningItemTypeId === 2 ? faPersonChalkboard : props.learningItemTypeId===-1?faRoad: faChalkboard} className={props.isMandatory ?  'app-mandatory' : 'app-non-mandatory'} size='2xl' /> 
        </OverlayTrigger>
        <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Added by {props.isAddedByAdmin ? 'Administrator' : props.addedByYouLabel}</Tooltip>} >
            <FontAwesomeIcon icon={props.isAddedByAdmin ? faCircle : faPlus} className='text-info' transform='shrink-6' />
        </OverlayTrigger>
    </>)
};

/**
 * Display column if width is variant or greater.
 * 
 * This function should not be used if columnDef contains
 * muiTableBodyCellProps and/or muiTableHeadCellProps 
 * defintitions
 * 
 * @param {string} variant - sm, md, lg or xl
 * @param {object} columnDef - column definition
 * @returns 
 */
export const columnIfWidth = (variant, columnDef) => {
    return {
        ...{
            muiTableBodyCellProps: { classes: { root: `d-none fw-cell-${variant}` } },
            muiTableHeadCellProps: { classes: { root: `d-none fw-cell-${variant}` } }
        },
        ...columnDef
    }
};

export const learningItemName = (id) => id === 8 ? 'Reference material' : id === 3 ? 'e-learning' : id === 2 ? 'Instructor led' : 'Course';

export const muiTableBodyCellEditFieldPropsDate = {
    inputProps: {
        sx: {
            fontSize: '0.875rem',
            padding: '2px 0 2px 0 !important'
        }
    },
    InputProps: {
        sx: {
            paddingLeft: '6px'
        }
    },
    required: false,
    sx: {
        width: '128px'
    },
    type: 'date'
}

export const muiTableBodyCellEditFieldPropsCheckboxFn = (props = {}) => {
    return function ({ cell, column, row, table }) {
        return {
            ...{
                inputProps: {
                    checked: cell.getValue(),
                    sx: {
                        fontSize: '0.675rem',
                        border: 'none !important',
                        padding: '2px 0 2px 0 !important'
                    }
                },
                InputProps: {
                    sx: {
                        paddingBottom: '4px',
                        paddingTop: '5px'
                    }
                },
                sx: {
                    border: 'none !important'
                },
                type: 'checkbox'
            },
            ...props
        };
    };
};

export const TableContainer = (props) => {
    const defaultMaterialTheme = createTheme({
        typography: {
            fontFamily: 'var(--bs-body-font-family)',
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontFamily: 'var(--bs-body-font-family)',
                        fontSize: 'var(--bs-body-font-size)'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        verticalAlign:'top',
                        fontFamily: 'var(--bs-body-font-family)',
                        fontSize: 'var(--bs-body-font-size)'
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        border: "none",
                        borderRadius: "unset",
                        boxShadow: "none",
                        margin: 0,
                        padding: 0
                    }
                }
            }
        }
    });

    return (<ThemeProvider theme={defaultMaterialTheme}>
        <Col className='shadow border rounded bg-white pb-1' {...props} />
    </ThemeProvider>);
}

