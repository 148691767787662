import { useEffect } from 'react';

import BaseContainer from '../components/BaseContainer';
import UserProfilesTable from '../components/UserProfilesTable';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

export default function MyTeamProfile(props) {

    const { teamTrainingPlanSummary, fetchTeamTrainingPlanSummary } = useLmsStore(state =>
    ({
        teamTrainingPlanSummary: state.teamTrainingPlanSummary.data,
        fetchTeamTrainingPlanSummary: state.fetchTeamTrainingPlanSummary
    }), shallow)

    // on load
    useEffect(() => {
        fetchTeamTrainingPlanSummary();
    }, []);


    return (
        <BaseContainer title='My Team Profiles' >
            <UserProfilesTable
                data={teamTrainingPlanSummary}
            />
        </BaseContainer>);
}
