import { useState } from 'react';

import UserCard from './UserCard';
import LearningPlanEditorUserTable from './LearningPlanEditorUserTable';
import DataLeftRight from './DataLeftRight';

export default function LearningPlanEditorUser(props) {

    return (<>
        <UserCard {...props.dataSelected} defaultMessage={<h5>Please select a User</h5>} />
        <DataLeftRight>
            <LearningPlanEditorUserTable {...props} />
        </DataLeftRight>
    </>);
}