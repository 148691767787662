import { useEffect } from 'react';

import { Row, Col } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

import { NavButton } from './CustomButtons';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

export default function DashboardAlerts(props) {
    const {myLatestAlerts, fetchMyLatestAlerts} = useLmsStore(state =>
    ({
        myLatestAlerts: state.myLatestAlerts.data,
        fetchMyLatestAlerts: state.fetchMyLatestAlerts
    }), shallow)

    useEffect(() => {
        fetchMyLatestAlerts();
    }, []);
    return (
        <Row className='mb-3'>
            <Col>
                <NavButton variant={!myLatestAlerts || myLatestAlerts.length === 0 ? 'info' : 'warning'} title='Alerts' to='/lms/alerts'>
                {!myLatestAlerts ? <Spinner animation="grow" variant="light" size="15" /> : myLatestAlerts.length}


                </NavButton>
            </Col>
        </Row>
    );
}