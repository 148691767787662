/**
 * Message Q singleton helper. Can be used to eliminate repeat messages within a time frame.
 */
class MessageQ {
    constructor() {
        if (!MessageQ.instance) {
            this.msgs = [];
            MessageQ.instance = this
        }
        return MessageQ.instance
    }
    /**
     *
     * @param {string} msg to add to the Q
     * @param {int} timeout in ms
     * @param {Function} fn to execute if msg not in the Q
     */
    execute(msg, timeout, fn) {
        if(!this.msgs.includes(msg)) {
            this.msgs = [...this.msgs, msg];
            setTimeout(() => this.msgs = this.msgs.map(m => m === msg ? undefined : m), timeout);
            fn();
        }
    };
}

const instance = new MessageQ()

export default instance