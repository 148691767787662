
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedLayout from './ProtectedLayout';

import Administration from '../pages/Administration';
import Alerts from '../pages/Alerts';
import ApplicationSettings from '../pages/ApplicationSettings';
import Catalogues from '../pages/Catalogues';
import ChangePassword from '../pages/ChangePassword';
import ClientTeamProfiles from '../pages/ClientTeamProfiles';
import Companies from '../pages/Companies';
import CourseLibrary from '../pages/CourseLibrary';
import Dashboard from '../pages/Dashboard';
import Experiment from '../pages/_Experiment';
import FAQs from '../pages/FAQs';
import Invitation from '../pages/Invitation';
import LearningPlan from '../pages/LearningPlan';
import Login from '../pages/Login';
import Saml from '../pages/Saml';
import SamlAuth from '../pages/SamlAuth';
import MySuperAdminDashboard from '../pages/MySuperAdminDashboard';
import MyAdminDashboard from '../pages/MyAdminDashboard';
import MyLearningPlan from '../pages/MyLearningPlan';
//import MyLearningPlanOld from '../pages/MyLearningPlanOld';
import MyTeamProfiles from '../pages/MyTeamProfiles';
import News from '../pages/News';
import NewsFeedItems from '../pages/NewsFeedItems';

import Qualifications from '../pages/Qualifications';
//import PendingEventsAccordian from '../pages/PendingEventsAccordian';
import CompetenciesAccordian from '../pages/CompetenciesAccordian';

import NotFound from '../pages/NotFound';
import Reports from '../pages/Reports';
import AccessCardDashboard from '../pages/AccessCardDashboard';
import ServerError from '../pages/ServerError';
import TeamDashboard from '../pages/TeamDashboard';
import UserAlerts from '../pages/UserAlerts';
import UserLearningPlan from '../pages/UserLearningPlan';
import Users from '../pages/Users';
import Registrations from '../pages/Registrations';
import BookingEvents from '../pages/BookingEvents';
import TeamRegistrations from '../pages/TeamRegistrations';
import TeamBookingEvents from '../pages/TeamBookingEvents';
import MyTeamElearningProgress from '../pages/MyTeamElearningProgress';
import AllUsersElearningProgress from '../pages/AllUsersElearningProgress';
import AllUserRegisteredInterest from '../pages/AllUserRegisteredInterest';
import UKPNServiceAdminDashboard from '../pages/UKPNServiceAdminDashboard';
import About from '../pages/About';

import CreateUsersPage from "../pages/CreateUsersPage";
import NewLogin from "../pages/NewLogin";
import GroupManagementPage from "../pages/GroupManagementPage";
import LearningItemsPage from "../pages/LearningItemsPage";
const AppRoutes = () => {
    return (
        <Routes>
            {
                (process.env.REACT_APP_SERVER === 'live' || process.env.REACT_APP_SERVER === 'test')?
                    window.location.hostname.split('.')[0] ==="principality"?
                    <Route path="/" element={<SamlAuth />} />
                    :
                    <Route path="/" element={<Navigate to="/lms/dashboard" replace={true} />} />
                :
                <Route path="/" element={<Navigate to="/lms/dashboard" replace={true} />} />
            }
            {/* <Route path="/" element={<Navigate to="/lms/dashboard" replace={true} />} /> */}
            
            {/* Legacy URLs */}
            <Route path='/admindashboard' element={<Navigate to='/lms/administration' replace={true} />} />
            <Route path='/administration' element={<Navigate to='/lms/administration' replace={true} />} />
            <Route path='/adminreports' element={<Navigate to='/lms/reports' replace={true} />} />
            <Route path='/alerts' element={<Navigate to='/lms/alerts' replace={true} />} />
            <Route path='/applicationsettings' element={<Navigate to='/lms/applicationsettings' replace={true} />} />
            <Route path='/catalogues' element={<Navigate to='/lms/catalogues' replace={true} />} />
            <Route path='/clientteamprofiles' element={<Navigate to='/lms/clientteamprofiles' replace={true} />} />
            <Route path='/clientdashboard' element={<Navigate to='/lms/myadmindashboard' replace={true} />} />
            <Route path='/companies' element={<Navigate to='/lms/companies' replace={true} />} />
            <Route path='/courselibrary' element={<Navigate to='/lms/courselibrary' replace={true} />} />
            <Route path='/dashboard' element={<Navigate to='/lms/dashboard' replace={true} />} />
            <Route path='/faqs' element={<Navigate to='/lms/faqs' replace={true} />} />
            <Route path='/learningplan' element={<Navigate to='/lms/learningplan' replace={true} />} />
            <Route path='/library' element={<Navigate to='/lms/courselibrary' replace={true} />} />
            <Route path='/manager' element={<Navigate to='/lms/teamdashboard' replace={true} />} />
            <Route path='/myadmindashboard' element={<Navigate to='/lms/myadmindashboard' replace={true} />} />
            <Route path='/mylearningplan' element={<Navigate to='/lms/mylearningplan' replace={true} />} />
            <Route path='/mylearningplanNew' element={<Navigate to='/lms/mylearningplanNew' replace={true} />} />
            <Route path='/news' element={<Navigate to='/lms/news' replace={true} />} />
            <Route path='/myteamprofiles' element={<Navigate to='/lms/myteamprofiles' replace={true} />} />
            <Route path='/myteamreports' element={<Navigate to='/lms/reports' replace={true} />} />            
            <Route path='/newsfeeditems' element={<Navigate to='/lms/newsfeeditems' replace={true} />} />
            <Route path='/reports' element={<Navigate to='/lms/reports' replace={true} />} />
             <Route path='/support' element={<Navigate to='/lms/faqs' replace={true} />} />
            <Route path='/teamalerts' element={<Navigate to='/lms/alerts' replace={true} />} />
            <Route path='/teamdashboard' element={<Navigate to='/lms/teamdashboard' replace={true} />} />
            <Route path='/training' element={<Navigate to='/lms/mylearningplan' replace={true} />} />
            <Route path='/users' element={<Navigate to='/lms/users' replace={true} />} />
            <Route path='/lms/support' element={<Navigate to='/lms/faqs' replace={true} />} />
            <Route path='/ukpnserviceadmindashboard' element={<Navigate to='/lms/ukpnserviceadmindashboard' replace={true} />} />
        
            <Route path='/lms/servererror' element={<ServerError />} />

            <Route path="/lms" element={<ProtectedLayout />}>
                <Route path='administration' element={<Administration />} />
                <Route path='alerts' element={<Alerts />} />
                <Route path='applicationsettings' element={<ApplicationSettings />} />
                <Route path='catalogues' element={<Catalogues />} />
                <Route path='clientteamprofiles' element={<ClientTeamProfiles />} />
                <Route path='companies' element={<Companies />} />
                <Route path='courselibrary' element={<CourseLibrary />} />
                <Route path='dashboard' element={<Dashboard />} />
                {process.env.REACT_APP_RNEXT === 'true' ? <Route path='experiment' element={<Experiment />} /> : null}
                <Route path='experiment' element={<Experiment />} />
                <Route path='faqs' element={<FAQs />} />
                <Route path='learningplan' element={<LearningPlan />} />
                <Route path='mysuperadmindashboard' element={<MySuperAdminDashboard />} />
                <Route path='myadmindashboard' element={<MyAdminDashboard />} />
                <Route path='mylearningplan' element={<MyLearningPlan />} />
               {/* < Route path='mylearningplanold' element={<MyLearningPlanOld />} /> */}
                <Route path='news' element={<News />} />
                <Route path='myteamprofiles' element={<MyTeamProfiles />} />
                <Route path='newsfeeditems' element={<NewsFeedItems />} />
                <Route path='reports' element={<Reports />} />
                <Route path='teamdashboard' element={<TeamDashboard />} />

                <Route path='qualifications' element={<Qualifications />} />
                {/* <Route path='pendingeventsaccordian' element={<PendingEventsAccordian />} /> */}
                {/* <Route path='qualificationsaccordian' element={<QualificationsAccordian />} /> */}
                <Route path='competenciesaccordian' element={<CompetenciesAccordian />} />

                <Route path='useralerts' element={<UserAlerts />} />
                <Route path='userlearningplan' element={<UserLearningPlan />} />
                <Route path='users' element={<Users />} />
                <Route path='registrations' element={<Registrations />} />
                <Route path='bookingEvents' element={<BookingEvents />} />
                <Route path='teamRegistrations' element={<TeamRegistrations />} />
                <Route path='teamBookingEvents' element={<TeamBookingEvents />} />
                <Route path='myTeamElearningProgress' element={<MyTeamElearningProgress />} />
                <Route path='allUsersElearningProgress' element={<AllUsersElearningProgress />} />
                <Route path='allUserRegisteredInterest' element={<AllUserRegisteredInterest />} />
                <Route path='ukpnserviceadmindashboard' element={<UKPNServiceAdminDashboard />} />
                <Route path='accesscarddashboard' element={<AccessCardDashboard />} />
        
                <Route path='about' element={<About />} />
                <Route path='createUsers' element={<CreateUsersPage />} />
                <Route path='groupManagement' element={<GroupManagementPage />} />
                <Route path='learningItems' element={<LearningItemsPage />} />
            </Route>

            <Route path='/login' element={<Login />} />
            <Route path='/newLogin' element={<NewLogin />} />
            <Route path='saml' element={<Saml />} />
            <Route path="/changePassword/:verificationKey" element={<ChangePassword />} />
            <Route path="/invitation/:verificationKey" element={<Invitation />} />

            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

export default AppRoutes
