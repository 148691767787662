import { useEffect } from 'react';

import { Row, Col } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import {NavButton, NavButtonSmallVariant} from './CustomButtons';
import { isSupportedDate, toLocaleDateString } from '../helpers/formatHelper';
import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import {useState } from 'react';

const filteredData = (rows, id) => !Array.isArray(rows)? []: id>0? rows.filter(x => (id === 1 && filterTodo(x) && !isPathway(x)) || (id === 2 && isOverdue(x) && !isPathway(x)) || (id === 3 && (isCompleted(x) || isAttended(x)) && !isPathway(x)) ) : rows.filter(x => filterTodo(x) && !isPathway(x));

 
const isCompleted = x => x && (x.statusName === 'Completed' || x.statusName==='Completed - Colleague' || (x.elearningInfo && x.elearningInfo.completionStatusName === 'Complete' && x.elearningInfo.sucessStatusName !== 'Failed'));
const isAttended = x => x && (x.statusName === 'Attended' || (x.instructorInfo && x.instructorInfo.statusName === 'Attended'));
const isOverdue = x => x && !isCompleted(x) && !isAttended(x) && (isSupportedDate(x.expiryDate) && (new Date(x.expiryDate) < new Date() ||  new Date(x.mandatoryExpectedDate) < new Date()))  ;

const filterTodo = x => x && !isCompleted(x) && !isAttended(x);
const isPathway = x => x &&  (x.regPathwayId != null && x.learnProgId==null);
export default function DashboardToDo(props) {
    const { myActiveSortedTrainingPlan, fetchMyActiveSortedTrainingPlanNew} = useLmsStore(state =>
    ({
        myActiveSortedTrainingPlan: state.myActiveSortedTrainingPlanNew.data,
        fetchMyActiveSortedTrainingPlanNew: state.fetchMyActiveSortedTrainingPlanNew
    }), shallow)
    const [overdueCount, setOverdueCount] = useState(0);
    const [completeCount, setCompleteCount] = useState(0);
    const [todoCount, setTodoCount] = useState(0);
 
    useEffect(() => {
        fetchMyActiveSortedTrainingPlanNew();
        if (myActiveSortedTrainingPlan){
              

         if(!myActiveSortedTrainingPlan.loading>0) {  
                setTodoCount(filteredData(myActiveSortedTrainingPlan,1).length);
                setOverdueCount(filteredData(myActiveSortedTrainingPlan,2).length);
                setCompleteCount(filteredData(myActiveSortedTrainingPlan,3).length);
        }
            
        
    }
        }, []);

    return (
        <Row className='mb-3  mt-2'>
            <Col xs={4}>

                <NavButtonSmallVariant className={"d-block d-sm-none"} variant='warning' title='To Do' to={{ pathname: '/lms/mylearningplan', search: 'filter=todo' }}>
                    {myActiveSortedTrainingPlan ? todoCount===0? filteredData(myActiveSortedTrainingPlan,1).length:todoCount : <Spinner animation="grow" variant="light" size="15" />}
                </NavButtonSmallVariant>

                <NavButton className={"d-none d-sm-block"} variant='warning' title='To Do' to={{ pathname: '/lms/mylearningplan', search: 'filter=todo' }}>
                    {myActiveSortedTrainingPlan ? todoCount===0? filteredData(myActiveSortedTrainingPlan,1).length: todoCount : <Spinner animation="grow" variant="light" size="15" />}
                </NavButton>
            </Col>
            <Col xs={4}>
                <NavButtonSmallVariant className={"d-block d-sm-none"} variant='danger' title='Overdue' to={{ pathname: '/lms/mylearningplan', search: 'filter=overdue' }}>
                    {myActiveSortedTrainingPlan ? overdueCount===0? filteredData(myActiveSortedTrainingPlan,2).length:overdueCount: <Spinner animation="grow" variant="light" size="15" />}
                </NavButtonSmallVariant>
                <NavButton className={"d-none d-sm-block"}  variant='danger' title='Overdue' to={{ pathname: '/lms/mylearningplan', search: 'filter=overdue' }}>
                    {myActiveSortedTrainingPlan ?  overdueCount===0? filteredData(myActiveSortedTrainingPlan,2).length:overdueCount: <Spinner animation="grow" variant="light" size="15" />}
                </NavButton>
            </Col>
            <Col xs={4}>
                <NavButtonSmallVariant className={"d-block d-sm-none"} variant='success' title='Completed' to={{ pathname: '/lms/mylearningplan', search: 'filter=completed' }}>
                    {myActiveSortedTrainingPlan ? completeCount===0? filteredData(myActiveSortedTrainingPlan,3).length:completeCount: <Spinner animation="grow" variant="light" size="15" />}
                </NavButtonSmallVariant>
                <NavButton className={"d-none d-sm-block"}  variant='success' title='Completed' to={{ pathname: '/lms/mylearningplan', search: 'filter=completed' }}>
                    {myActiveSortedTrainingPlan ?  completeCount===0? filteredData(myActiveSortedTrainingPlan,3).length:completeCount: <Spinner animation="grow" variant="light" size="15" />}
                </NavButton>
            </Col>
        </Row>
    );
}
