import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalElearningContinue = ({ closeFn = () => null, continueFn=() =>null,newAttemptFn=() =>null, open = false, continueUrl="", newAttemptUrl="",canHaveNewAttempt=false}) => {
    
    


   /*
   Show confirmation screen to see if you want to carry on with the new attempt
   */
    function handleNewAttemptConfirmation() {
       
       newAttemptFn();
    }

    
/*
     Go to the continue link in scorm
    */
     function handleContinue() {

        if (continueUrl!=""){           
            continueFn();
            window.open(continueUrl, 'blank');
        }   
        
    };

    

    return (
        <Modal fullscreen='md-down' show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>There is a previously suspended attempt of this course available.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p> Do you want to continue your previous attempt or start  a new attempt?</p>
            <div>
            <Button onClick={handleContinue} variant="primary" className='w-100' >
                Continue
            </Button>
            </div>

            {canHaveNewAttempt?
            <div style={{ marginTop: '0.50rem' }}>
            <Button onClick={handleNewAttemptConfirmation}  variant="secondary" size="sm"  >
                    New Attempt 
            </Button> 
            </div> : <p></p>}
                     
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
            </Modal.Footer>
        </Modal>
        
    );
};

export default ModalElearningContinue;