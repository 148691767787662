import React, {useState, useEffect} from "react";
import Accordion from 'react-bootstrap/Accordion';
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';
import MailTo from '../components/MailTo';
import useTheme from '../hooks/useTheme';
import FAQsCommonItems from '../components/FAQsCommonItems';
import ModalForgottenPassword from '../components/ModalForgottenPassword';
import {useAuth} from "../hooks/useAuth";
import {clearStore} from "../hooks/useLmsStore";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleQuestion} from '@fortawesome/pro-light-svg-icons';
import ModalTermsAndConditions from "../components/ModalTermsAndConditions";
import ModalForceChangePassword from '../components/ModalForceChangePassword';
import LoginButton from "../components/buttons/LoginButton";
import {useMsal} from "@azure/msal-react";
import {apiAxios} from "../helpers/axios";
import {useGetApplicationLoginPageConfiguration} from "../hooks/useGetApplicationLoginPageConfiguration";
// Wait up to 180 secs in dev mode else 60 secs
const LOGIN_TIMEOUT = process.env.NODE_ENV === 'development' ? 180000 : 90000;

export default function NewLogin() {
    const {instance, accounts} = useMsal();
    const {login} = useAuth();
    const {themeName} = useTheme();
    const {loginPageConfiguration, loading} = useGetApplicationLoginPageConfiguration()
    const [inProgress, setInProgress] = useState(false);
    const [terms, setTerms] = useState("");
    const [user, setUser] = useState(null);
    const [isOpenForgottenPsw, setisOpenForgottenPsw] = useState(false);
    const [isOpenTerms, setisOpenTerms] = useState(false);
    const [isOpenForceChangePsw, setisOpenForceChangePsw] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isEstablishingSession, setIsEstablishingSession] = useState(false);
    const [serviceLabel, setServiceLabel] = useState('Learning Service');
    const [serviceEmail, setServiceEmail] = useState('learning.service@wdr.co.uk');
    const establishTheSession = () => {
        return apiAxios.get('/api/account/setApReToSesion')
    }

    function toggleModalForgottenPsw() {
        setisOpenForgottenPsw(!isOpenForgottenPsw);
    }

    function toggleModalTerms() {
        setisOpenTerms(!isOpenTerms);
    }

    function toggleModalForceChangePsw() {
        setisOpenForceChangePsw(!isOpenForceChangePsw);
    }


    /*
    This function is called when the person login and the user is set sucessfully and is used to check if they require to accept terms and condition
    */
    function processAcceptTermsAndChangePsw() {
        if (user != null) {
            //Check if the user had not approved the terms and conditions
            if (!user.agreedTermsAndConditionsVersion) {
                //Open terms and conditions modal
                setTerms(user.terms);
                toggleModalTerms();
            } else {
                //Carry on with the login process
                processLogin();
            }
        }
    }

    /* After all the checking are done sucessfully, do final setting and go to the dashboard page */
    function processLogin() {
        setInProgress(false);
        login(user.token);

    }

    /* This function is call the when submit terms ad condition modal
    */
    function onCloseSubmitModalTerms(event) {

        //Check if require password reset if not then login
        if (user.requiresPasswordReset) {
            toggleModalTerms();
            toggleModalForceChangePsw();
            // handleShowForceChangePsw();
        } else {
            processLogin();
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const [showFAQs, setShowFAQs] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const handleCloseFAQs = () => setShowFAQs(false);
    const handleShowFAQs = () => setShowFAQs(true);
    const handleCloseSignUp = () => setShowSignUp(false);
    const handleShowSignUp = () => setShowSignUp(true);
    const onLoginWithOktaClick = () => {
        window.location.href = loginPageConfiguration.applicationLogInMethods.oktaSigninUrl
    }
    const onLoginWithMicrosoftAccountClick = () => {
        window.location.href = loginPageConfiguration.applicationLogInMethods.microsoftAccountSignInUrl
    }
    const renderLoginWithOkta = () => {
        if (loading) {
            return null
        }
        if (loginPageConfiguration && loginPageConfiguration.applicationLogInMethods.oktaSigninUrl) {
            return <Button
                type="button"
                style={{backgroundColor: 'var(--app-login-ovl)'}}
                variant="primary"
                onClick={onLoginWithOktaClick}
                className="w-100 mt-3">
                Log in with okta
            </Button>
        }
    }

    const renderLoginWithMicrosoftAccount = () => {
        if (loading) {
            return null
        }
        if (loginPageConfiguration && loginPageConfiguration.applicationLogInMethods.microsoftAccountSignInUrl) {
            return <Button
                type="button"
                style={{backgroundColor: 'var(--app-login-ovl)'}}
                variant="primary"
                onClick={onLoginWithMicrosoftAccountClick}
                className="w-100 mt-3">
                Log in with microsoft account
            </Button>
        }
    }

    const renderSpinner = () => {
        return isEstablishingSession ?
            <div style={{minHeight:"100vh"}} className="d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column justify-content-center align-items-center" >
                    <div className="row">
                        <div className="spinner-border mt-5 text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <strong>Establishing session</strong>
                    </div>
                </div>
            </div>
            :
            <></>
    }


    //Load when the user change after the login iss approved
    useEffect(() => {
        // Process terms and condtions and change password procedure
        processAcceptTermsAndChangePsw();

    }, [user]);


    useEffect(() => {
        if (window.location.hostname.includes('cerespoweracademy')) {
            setServiceLabel('Ceres Academy');
            setServiceEmail('ceres.academy@wdr.co.uk');
        }
    }, []);

    const cur = instance.getActiveAccount()
    useEffect(() => {
        const tokenRequest = {
            account: cur,
            scopes: ["https://wdrb2clms.onmicrosoft.com/d68c2106-fb2d-4d22-b81f-9f89bd87dd92/User.Read"],
        };
        if(cur){
            instance.acquireTokenSilent(tokenRequest).then(res => {
                login(res.accessToken)
                setIsEstablishingSession(true)
                establishTheSession().then(res => {
                    setIsEstablishingSession(false)
                    window.location.href = '/lms/dashboard';
                })

            }).catch(e => {
                setIsEstablishingSession(false)
            })
        }



    }, [instance, cur])


    clearStore();
    return (
        <>

            {!isEstablishingSession ?
            <>
                <Row className="m-0 ">
                    <Col className="cls-login-col-left p-0" md={6} lg={5} xl={4} xxl={3}>
                        <div className="cls-login-colour-ovl d-flex align-items-center justify-content-center">
                            <Row style={{width: '100%'}}>
                                <Col sm={2} md={1}></Col>
                                <Col>
                                    <Card className="mb-5" style={{width: '100%'}}>
                                        <div className="d-flex justify-content-end" style={{margin: ' 1rem 1.5rem 0 0'}}>
                                            {themeName ? <Card.Img variant="top"
                                                                   src={require(`../assets/instance/${themeName}/logo.png`)}
                                                                   style={{width: '140px'}}/> : <></>}
                                        </div>
                                        <Card.Body>
                                            <Card.Title>Sign In</Card.Title>
                                            <Form noValidate validated={validated}>
                                                <Form.Group className="mb-3">

                                                    {<LoginButton/>}
                                                    {renderLoginWithOkta()}
                                                    {renderLoginWithMicrosoftAccount()}
                                                </Form.Group>
                                            </Form>
                                            <Row className="mt-3">
                                                <Col style={{width: '60px'}}><Button variant="clear" size="lg"
                                                                                     onClick={handleShowFAQs}
                                                                                     disabled={inProgress}><FontAwesomeIcon
                                                    style={{color: 'var(--app-login-ovl)'}} icon={faCircleQuestion}
                                                    className='m-0'/></Button></Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={2} md={1}></Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="cls-login-col-right">
                        <div className="cls-login-colour-ovl"></div>
                    </Col>
                </Row>
                <Modal fullscreen='xl-down' size='xl' show={showFAQs} onHide={handleCloseFAQs}>
                    <Modal.Header closeButton>
                        <Modal.Title>FAQs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Learning Portal - FAQs Frequently Asked Questions</h5>
                        <p>The Learning Portal manages the administration and delivery of online learning (eLearning)
                            courses and learning or training materials, and at the same time tracks members’ online learning
                            progression.</p>
                        <Accordion>
                            <FAQsCommonItems serviceEmail={serviceEmail} serviceLabel={serviceLabel}/>
                        </Accordion>
                        <p className="mt-4 text-center">
                            <h6>Can't find the answer to your question?</h6>
                            <MailTo email={`${serviceEmail}?subject=LMS Support Query`}>Click Here</MailTo> to send us an
                            email.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseFAQs}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showSignUp} onHide={handleCloseSignUp}>
                    <Modal.Header closeButton>
                        <Modal.Title>Sign-up</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please send an email to <MailTo
                            email='wdrassist@wdr.co.uk?subject=LMS Access Request'>wdrassist@wdr.co.uk</MailTo> to request
                            access.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseSignUp}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <ModalForgottenPassword closeFn={toggleModalForgottenPsw}
                                        open={isOpenForgottenPsw}
                />
                <ModalTermsAndConditions closeFn={toggleModalTerms} closeAndSubmitFn={onCloseSubmitModalTerms}
                                         open={isOpenTerms} termsText={terms}/>
                <ModalForceChangePassword closeFn={toggleModalForceChangePsw} closeAndSubmitFn={processLogin}
                                          open={isOpenForceChangePsw}/>
            </>
                :
                renderSpinner()
            }

        </>
    );
}
