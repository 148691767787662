import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { clearStore } from "../hooks/useLmsStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { useParams } from 'react-router-dom';
import logo from '../assets/imgs/wdr_master_rgb.png';
import { toast } from 'react-toastify';
// Wait up to 180 secs in dev mode else 60 secs
const LOGIN_TIMEOUT = process.env.NODE_ENV === 'development' ? 180000 : 60000;


export default function ChangePassword()  {
    const [inProgress, setInProgress] = useState(false);
    const [mainPswError, setMainPswError] = useState(null);
  
    //Get the verification key from the url
    const { verificationKey } = useParams();
    const [confPassword, setConfPsw] = useState("");
    const [inProgressVal, setInProgressVal] = useState(false);
    const [errorVal, setErrorVal] = useState(null);
    const [newPassword, setPsw] = useState("");
   

    const handleChangeNewPsw = (event) => {
        setMainPswError("");
        setPsw(event.target.value);
    };
    const handleChangeConfPsw = (event) => {
         //Validate
        if ((newPassword!=undefined) && (newPassword!="") && (event.target.value!=newPassword)){   
            
            if (event.target.value.length>4){
            setMainPswError('Password does not match');
            }
        }
        else
        {
        setMainPswError("");
        }
        setConfPsw(event.target.value);
    };

     function handlePswError(e) {
        setMainPswError(e.message);
        setPsw("");
        setValidated(false);
        setInProgress(false);
        console.error(e);
    }

    function handleErrorVal(e) {
        setErrorVal(e.message);
        setInProgressVal(false);
        console.error(e);
     }

    function handleErrorWrongKey(e) {
        setErrorVal(e);
        setInProgressVal(false);
        console.error(e);
     }

    const [validated, setValidated] = useState(false);
    function handleChangePassword(event) {
        try {
            const form = event.currentTarget;
            if (!inProgress && form.checkValidity() === true) {
                setInProgress(true);
                setValidated(true);
                 axios({
                    method: 'post',
                    url: '/api/account/UpdatePasswordByVerificationKey',
                    timeout: LOGIN_TIMEOUT,
                    data: {   newPassword: newPassword,
                              verificationKey: verificationKey }
                })
                    .then((result) => {
                        try {
                            if (result.status === 204) {
                                throw new Error('Problem updating the password');
                            }
                             
                            toast.success("Password changed sucessfully", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });
                            setInProgress(false);
                            
                        } catch (e1) {
                            handlePswError(e1);
                        }
                    }).catch(handlePswError);
            }
        } catch (e2) {
            handlePswError(e2);
        }
        event.preventDefault();
        event.stopPropagation();
    }


    //Verify that the validation key sent is correct
    function handleValidateVerificationKey() {
       
        try {
           
            if (!inProgressVal && verificationKey) {
                setInProgressVal(true);
                 axios({
                    method: 'post',
                    url: '/api/account/ValidateVerificationKey/' + verificationKey,
                    timeout: LOGIN_TIMEOUT,
                    data: undefined
                })
                    .then((result) => {
                        try {
                            if (result.status === 204) {
                                throw new Error('Invalid verification key');
                            }

                            if (!result.data){
                                handleErrorWrongKey('Invalid key, please go to the login page and click on the "Forgot your password" password button again');
                            }
                          
                            setInProgressVal(false);
                           
                        } catch (e1) {
                            handleErrorVal(e1);
                        }
                    }).catch(handleErrorVal);
            }
        } catch (e2) {
            handleErrorVal(e2);
        }
     
    };
    clearStore();
  
    useEffect(() => {
             handleValidateVerificationKey();
       
    }, [verificationKey]);

  

    return (
        <>
            <Row className="m-0 ">
                <Col className="cls-login-col-left p-0" md={6} lg={5} xl={4} xxl={3}>
                    <div className="cls-login-colour-ovl d-flex align-items-center justify-content-center">
                        <Row style={{ width: '100%' }}>
                            <Col sm={2} md={1}></Col>
                            <Col>
                                <Card className="mb-5" style={{ width: '100%' }}>
                                    <div className="d-flex justify-content-end" style={{ margin: ' 1rem 1.5rem 0 0' }}>
                                        <Card.Img variant="top" src={logo} style={{ width: '140px' }} />
                                    </div>
                                  
                                  {errorVal ? 
                                   <Card.Body>
                                      <Card.Title>Portal Invalid Key!</Card.Title>
                                        <Card.Text> 
                                        <p className="text-danger">{errorVal}</p>  
                                        </Card.Text>
                                        <Form.Group className="mb-3">
                                            <a href="/login">Return to login</a>
                                           
                                        </Form.Group>
                                   </Card.Body>
                                  :
                                  <Card.Body>
                               
                                       <Card.Title>Change your Password?</Card.Title>
                                        <Card.Text> 
                                            A password reset has been requested for the 'applicationName' account associated with this email address.
                                            </Card.Text>
                                            <Card.Text> 
                                            If you, or somebody on your behalf, initiated this password reset then please supply your new password and submit your new password by pressing the 'Change Password' button
                                            </Card.Text>
                                            <Card.Text> 
                                            Your new password must be a minimum of six characters in length
                                        </Card.Text>
                                        <Form noValidate validated={validated} onSubmit={handleChangePassword}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control placeholder="New password" type="password" required value={newPassword} onChange={handleChangeNewPsw} disabled={inProgress} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a password.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-4">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control placeholder="Confirm password" type="password" required value={confPassword} onChange={handleChangeConfPsw} disabled={inProgress} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please confirm the password.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                {
                                                    inProgress ?
                                                        <Button variant="primary" className="w-100" disabled>
                                                            <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                                            Please wait...
                                                        </Button>
                                                        :
                                                        <Button type="submit" variant="primary" className="w-100">Change Password</Button>
                                                }
                                            </Form.Group>
                                            {mainPswError && (<p className="text-danger">{mainPswError}</p>)}
                                        
                                        </Form>
                                                                       
                                    </Card.Body>
                                  }
                                </Card>
                            </Col>
                            <Col sm={2} md={1}></Col>
                        </Row>
                    </div>
                </Col>
                <Col className="cls-login-col-right"><div className="cls-login-colour-ovl"></div></Col>
            </Row>
            
        </>
    );
}