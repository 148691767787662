import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

function DeleteGroupModal({toggle, show, onDelete, group, isDeleting}) {
    return (
        <Modal fullscreen='md-down' show={show} onHide={toggle} backdrop="static" keyboard={false} size="lg">

            <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to delte group {group.Name}?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Group {group.Name} will be deleted!
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isDeleting}  variant='primary' onClick={onDelete}>
                    {isDeleting ?
                        <><Spinner animation="border" variant="light" size="sm" /> Delete</>
                        :
                        "Delete"
                    }
                </Button>
                <Button variant='light' disabled={isDeleting} onClick={toggle}>
                    {isDeleting ?
                        <><Spinner animation="border" variant="light" size="sm" /> Close</>
                        :
                        "Close"
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteGroupModal;
