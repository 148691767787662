import { useEffect, useMemo, useState } from 'react';
import BaseContainer from "../components/BaseContainer";
import { TableContainer } from '../helpers/tableHelper'
import MaterialReactTable from 'material-react-table';
import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload ,faChevronLeft} from '@fortawesome/pro-regular-svg-icons';
import {Button,Col, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function TeamBookingEvents() {
    const navigate = useNavigate();
    const { teamBookingEvents,  fetchTeamBookingEvents } = useLmsStore(state =>
    ({
        teamBookingEvents: state.teamBookingEvents.data,
        fetchTeamBookingEvents: state.fetchTeamBookingEvents
    }), shallow);

    const downloadFile = ({ data, fileName, fileType }) => {
      const blob = new Blob([data], { type: fileType });

      const a = document.createElement("a");
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    };

    const exportToJson = (e) => {
      e.preventDefault();
      downloadFile({
        data: JSON.stringify(teamBookingEvents),
        fileName: "registrations.json",
        fileType: "text/json",
      });
    };
          
    const exportToCsv = (e) => {
      e.preventDefault();

      // Headers for each column
      let headers = ["FirstName,Surname,Employee ID,Email,Learning Item, Event DD/MM/YY, Time 00:00 - 00:00, Trainer, Venue, Status, Cancellation Reason"];

      // Convert users data to a csv
      let usersCsv = teamBookingEvents.reduce((acc, user) => {
        const { firstName,surname,employeeId, email,learningItem, event, time, venue, status, cancellationReason } = user;
        acc.push([firstName,surname,employeeId,email,learningItem, event, time, venue, status, cancellationReason].join(","));
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv].join("\n"),
        fileName: "BookingEvents.csv",
        fileType: "text/csv",
      });
    };

    // on load
    useEffect(() => {
        fetchTeamBookingEvents();
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: "firstName",
                header: (
                    <>
                        First<br />Name
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "surname",
                header: "Surname",
                //size: 30
            },
            {
                accessorKey: "employeeId",
                header: (
                    <>
                        Employee<br />ID
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "email",
                header: "Email",
                //size: 30
            },
            {
                accessorKey: "learningItem",
                header: "Learning Item",
                header: (
                    <>
                        Learning<br />Item
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "event",
                header: "Event Date",
                //size: 30
            },
            {
                accessorKey: "time",
                header: "Time",
                //size: 30
            },
            {
                accessorKey: "venue",
                header: "Venue",
                //size: 30
            },            
            {
                accessorKey: "status",
                header: "Status",
                //size: 30
            },
            {
                accessorKey: "cancellationReason",
                header: (
                    <>
                        Cancellation<br />Reason
                    </>
                ),
                //size: 30
            },
        ],
        [],
    );

    return (
        <BaseContainer title='My Team Events & Bookings Report'>
             <Button variant='outline-secondary' className='mt-2 mb-2' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faChevronLeft} /><span className='m-2'>Back</span></Button>
        
             <TableContainer className={"mb-2"} >
                <Col style={{ maxHeight: '750px', overflow: 'auto',  overflowX: 'hidden' }} className='border-end'>

                    <MaterialReactTable
                        columns={columns}
                        data={teamBookingEvents || []}
                        renderToolbarInternalActions={({ table }) => (
                            <>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Download Data</Tooltip>}>
                                    <Button variant='clear' className='pt-0 pb-0' onClick={exportToCsv}>
                                        <FontAwesomeIcon size='xl' icon={faDownload} className='text-primary' />
                                    </Button>
                                </OverlayTrigger>
                            </>
                        )}
                        initialState={{
                            pagination: { pageIndex: 0, pageSize: 7 },
                            showGlobalFilter: true
                        }}
                        enableStickyHeader={true}
                        useSticky
                        muiTableContainerProps={{ sx: { maxHeight: '600px',minHeight: '600px' } }}
                        state={{
                            isLoading: !teamBookingEvents
                        }}
                    />
                </Col>
            </TableContainer>
        </BaseContainer>
    );
}
