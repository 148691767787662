import React, { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import BaseContainer from '../components/BaseContainer';

import FAQsCommonItems from '../components/FAQsCommonItems';

import MailTo from '../components/MailTo';

export default function FAQs(props) {
    const [serviceLabel, setServiceLabel] = useState('Learning Service');
    const [serviceEmail, setServiceEmail] = useState('learning.service@wdr.co.uk');

    useEffect(() => {
        if (window.location.hostname.includes('cerespoweracademy')) {
            setServiceLabel('Ceres Academy');
            setServiceEmail('ceres.academy@wdr.co.uk');
        }
    }, []);

    return (
        <BaseContainer title='Learning Portal - FAQs Frequently Asked Questions'>
            <Row>
                <Col>
                    <Card className='shadow p-2'>
                        <Card.Body>
                            <p>The Learning Portal manages the administration and delivery of online learning (eLearning) courses and learning or training materials, and at the same time tracks members’ online learning progression.</p>
                            <h5 className='mt-4'>Access and Computer Requirements</h5>
                            <div style={{marginTop:'10px', maxHeight: '650px', overflow: 'auto', overflowX: 'hidden'}}>
                            <Accordion>
                                <FAQsCommonItems serviceEmail={serviceEmail} serviceLabel={serviceLabel} />
                                <Accordion.Item eventKey="20">
                                    <Accordion.Header>What if I forget to log out?</Accordion.Header>
                                    <Accordion.Body>
                                        For security purposes, we advise that you always log out of the system after you finish your session. If you have forgotten to log out, the system will automatically log you out after 45 minutes of inactivity.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="21">
                                    <Accordion.Header>I tried searching for eLearning in the Course Library using keyword(s) but nothing came up in the results?</Accordion.Header>
                                    <Accordion.Body>
                                        The learning items in your course library have been specifically selected for your individual role within your organisation. If you cannot find a learning item that you believe you should be able to access, then please contact the {serviceLabel} Team via the support page (the question mark ‘?’ in the left hand navigation bar) or email {serviceEmail} with the details.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="22">
                                    <Accordion.Header>I tried to open a document in the Learning Portal and I received a security warning. What should I do?</Accordion.Header>
                                    <Accordion.Body>
                                        Your browser security setting may have been set to restrict documents from the Learning Portal. Change your browser security settings to accept the Learning Portal website as a trusted website.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="23">
                                    <Accordion.Header>What should I do if I clicked to view a resource with eLearning and it opens a new window with an error message?</Accordion.Header>
                                    <Accordion.Body>
                                        The webpage may have moved. Report the incident to the {serviceLabel} Team at {serviceEmail} with the course details and resource link/name.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <h5 className='mt-4'>Using the Learning Portal</h5>
                            <Accordion>
                                <Accordion.Item eventKey="50">
                                    <Accordion.Header>What can I find in the Learning Portal?</Accordion.Header>
                                    <Accordion.Body>
                                        The Learning Portal contains learning items that have been specifically allocated to you. You can access these through your Course Library and your Learning Plan. Your Learning Plan may already be pre-populated with mandatory and recommended learning items relevant to your role. In addition to these you may have the option to add additional learning items from your Course Library to your Learning Plan.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="51">
                                    <Accordion.Header>Where can I view the synopsis/outline for a learning item?</Accordion.Header>
                                    <Accordion.Body>
                                        From both the Course Library page & your Learning Plan, select info to bring up the outline of the course. If the info button is greyed out or missing, then there is no synopsis available for that learning item.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="52">
                                    <Accordion.Header>How do I add a learning item to My Learning Plan?</Accordion.Header>
                                    <Accordion.Body>
                                        Go to the Course Library and underneath the learning item, you will find a plus icon . Click on this and the item will be added to your Learning Plan.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="53">
                                    <Accordion.Header>How do I remove a learning item from My Learning Plan?</Accordion.Header>
                                    <Accordion.Body>
                                        You are only able to remove learning items that you have added to your Learning Plan. These learning items will include a dustbin next to the item. Click on the dustbin delete_forever to remove the item from your plan. You will still be able to access the item from your course library and re-add to your Learning Plan if you wish at a later date. Any items that have been allocated by a system administrator or your manager you will not be able to remove.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="54">
                                    <Accordion.Header>Where do I go to start an eLearning course in the portal?</Accordion.Header>
                                    <Accordion.Body>
                                        Go to your Learning Plan, find the course you wish to start and click ‘start’ underneath the action column. If it’s a course that you have already completed, you can review the course in your Learning Plan under the completed tab.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="55">
                                    <Accordion.Header>I tried to launch an eLearning course, but nothing happened/I received an error message. What should I do?</Accordion.Header>
                                    <Accordion.Body>
                                        When you launch an eLearning course, it opens a popup window. Make sure that the popup blocker on your browser is disabled. Once you have disabled the browser popup blocker, relaunch the eLearning course.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="56">
                                    <Accordion.Header>Can I exit from an eLearning course before I complete the course?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes. Your progress will be bookmarked by the Learning Portal and you can proceed from where you left off the next time you access the course, or start a new attempt.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="57">
                                    <Accordion.Header>How can I view the eLearning courses that I have completed or that are still in progress?</Accordion.Header>
                                    <Accordion.Body>
                                        Your Learning Plan has different tabs for ‘To Do’ and ‘Completed’. Completed to view courses you have completed or click on ‘To Do’ to view courses you have not started or are in progress.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="58">
                                    <Accordion.Header>How do I update the information in ‘my profile’?</Accordion.Header>
                                    <Accordion.Body>
                                        The only information that you are able to update in my profile is your telephone number. If you notice that there is something that needs updating, then please contact the {serviceLabel} Team via the support page (the question mark ‘?’ in the left hand navigation bar) or email {serviceEmail} with the details.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <h5 className='mt-4'>Support</h5>
                            <Accordion>
                                <Accordion.Item eventKey="100">
                                    <Accordion.Header>How can I get help while I’m using the Learning Portal?</Accordion.Header>
                                    <Accordion.Body>
                                        Send a message to the {serviceLabel} Team via the Support page, the question mark in the left hand navigation bar.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="101">
                                    <Accordion.Header>Who should I contact if I have questions about the Learning Portal?</Accordion.Header>
                                    <Accordion.Body>
                                        If you have questions or need assistance in using the Learning Portal, please contact the {serviceLabel} Team via the support page the question mark ‘?’ in the left hand navigation bar or email {serviceEmail}.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Row className='mt-4'>
                                <Col lg={1} xl={2} xxl={3}></Col>
                                <Col>
                                    <Alert variant='dark' className='mt-4'>
                                        <h5 className='mb-4'>Need further assistance with the learning portal? We’re here to help!</h5>
                                        <p>Call 01403 268251 and ask for the {serviceLabel} team or <MailTo email={`${serviceEmail}?subject=LMS Support Query`}>Click Here</MailTo> to send us an email.</p>
                                        <p>Our team are available 08:45-17:15 Monday to Friday</p>
                                    </Alert>
                                </Col>
                                <Col lg={1} xl={2} xxl={3}></Col>
                            </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </BaseContainer>
    );
}