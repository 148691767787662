import BaseContainer from "../components/BaseContainer";
import { BeginButton } from '../components/CustomButtons'
import { useEffect,  useState } from 'react';
import { Row } from 'react-bootstrap';

import { faPeopleLine, faNewspaper, faFileChartColumn, faPersonChalkboard } from '@fortawesome/pro-solid-svg-icons';

export default function MyAdministrationDashboard(props) {
    const [showReportsGroup2, setShowReportsGroup2] = useState(false);
    useEffect(() => {
        setShowReportsGroup2(window.location.hostname.includes('cerespoweracademy') || window.location.hostname.includes('lms'));
    }, []);

    return (<BaseContainer title='My Admin Dashboard' >
        <div style={{marginTop:'10px', maxHeight: '600px', overflow: 'auto', overflowX: 'hidden'}}>
        <Row className='gx-4 gy-4 mt-0'>
            <BeginButton title='Team Profiles' description='View all users learning plans, learning pathways and review their progress.' icon={faPeopleLine} to='/lms/clientteamprofiles' />
            <BeginButton title='News Items' description='View, create, update or delete news items.' icon={faNewspaper} to='/lms/newsfeeditems' />
            <BeginButton title='Reports' description='Run reports.' icon={faFileChartColumn} to='/lms/reports' />
           
        
        </Row>
        </div>
    </BaseContainer>);
}