import React, {useEffect, useState} from 'react';
import BaseContainer from "../components/BaseContainer";
import {Badge, Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {toastSuccess} from "../hooks/useLmsStore";
import {useInfiniteScroll} from "../hooks/useInfiniteScroll";
import GroupManagementNavCard from "../components/groupManagementPageComponents/GroupManagementNavCard";
import GroupDetailTab from "../components/groupManagementPageComponents/groupManagementPageTabs/GroupDetailTab";
import GroupManagementGroupSearchResult
    from "../components/groupManagementPageComponents/groupManagementPageSearch/GroupManagementGroupSearchResult";
import {
    activeTabsEnum,
    initialUpdateCreateGroupPayload,
    summaryWidgetGroupManagementNames
} from "../helpers/constantsAndEnums";
import GroupManagementSearch
    from "../components/groupManagementPageComponents/groupManagementPageSearch/GroupManagementSearch";
import LearningPathwaysTab
    from "../components/groupManagementPageComponents/groupManagementPageTabs/LearningPathwaysTab";
import PermissionsTab from "../components/groupManagementPageComponents/groupManagementPageTabs/PermissionsTab";
import GroupMembersTab from "../components/groupManagementPageComponents/groupManagementPageTabs/GroupMembersTab";
import {getSummaryWidget} from "../helpers/generalHelpers";
import {apiAxiosCalls} from "../services/apiAxiosCalls";
import {faKey, faPeopleGroup, faRoad, faSquareList} from "@fortawesome/pro-solid-svg-icons";
import {Skeleton} from "@mui/material";

const pageSize = 30

const searchGroupsInitialPayload = {
    filter: "",
    itemOffset: 0,
    itemCount: pageSize,
}
const GroupManagementPage = () => {
    const [activeTab, setActiveTab] = useState(activeTabsEnum.GroupDetail)
    const [groups, setGroups] = useState([])
    const [activeGroup, setActiveGroup] = useState(null)
    const [updateCreateGroupPayload, setUpdateCreateGroupPayload] = useState({...initialUpdateCreateGroupPayload})
    const [hasDoneInitialGroupsFetch, setHasDoneInitialGroupsFetch] = useState(false)
    const [triggerSearch, setTriggerSearch] = useState(0)
    const [searchGroupsPayload, setSearchGroupsPayload] = useState({...searchGroupsInitialPayload})
    const [isUpdatingOrCreatingGroup, setIsUpdatingOrCreatingGroup] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")

    const [groupsCount, setGroupsCount] = useState(0)
    const groupsHasMore = (searchGroupsPayload.itemOffset) <= groupsCount

    const searchGroups = async () => {
        try {
            const result = await apiAxiosCalls.searchGroupsApiCall(searchGroupsPayload)
            setHasDoneInitialGroupsFetch(true)
            setGroupsCount(result.data.Value.Count)
            setGroups([...groups, ...result.data.Value.Items])
            setSearchGroupsPayload({...searchGroupsPayload, itemOffset: searchGroupsPayload.itemOffset + pageSize})
        } catch (e) {

        }
    }

    const {lastElementRef, isLoading} = useInfiniteScroll({loadMore: searchGroups})

    const createGroup = async (payload) => {
        try {
            setIsUpdatingOrCreatingGroup(true)
            const result = await apiAxiosCalls.createGroupApiCall(payload)
            setIsUpdatingOrCreatingGroup(false)
            if (payload.groupId === -1) {
                await onSuccessUpdateGroup({Id: result.data.Value.Id})
            } else {
                await onSuccessUpdateGroup({...payload, Id: payload.groupId})
            }
        } catch (e) {
            setIsUpdatingOrCreatingGroup(false)
        }
    }

    const onGroupDetailClick = () => {
        setActiveTab(activeTabsEnum.GroupDetail)
    }
    const onLearningPathWaysClick = () => {
        setActiveTab(activeTabsEnum.LearningPathways)
    }
    const onPermissionsClick = () => {
        setActiveTab(activeTabsEnum.Permisssions)
    }
    const onGroupMembersClick = () => {
        setActiveTab(activeTabsEnum.GroupMembers)
    }

    const CustomBadge = ({children}) => {
        return <Badge pill style={{width: "3rem"}} className={"p-1"}>{children}</Badge>
    }

    const renderSkeletonsForGroups = () => {
        return Array(10).fill("x").map((x, i) => {
            return <Skeleton key={i} variant="rectangular" style={{marginBottom:"1rem"}} width={"100%"} height={60}/>
        })
    }

    const renderCards = () => {
        return <div className={"mt-1 row gap-1 p-2"}>
            <GroupManagementNavCard
                title={"Group detail"}
                onClick={onGroupDetailClick}
                icon={faSquareList}
                count={10}
                isActive={activeTab === activeTabsEnum.GroupDetail}
                bottomText={<div className={"text-end fw-bold"}></div>}
            />
            <GroupManagementNavCard
                title={"Learning pathways"}
                onClick={onLearningPathWaysClick}
                icon={faRoad}
                count={10}
                isActive={activeTab === activeTabsEnum.LearningPathways}
                bottomText={<div
                    className={"text-end fw-bold"}
                >
                    <CustomBadge>
                        {getSummaryWidget(activeGroup, summaryWidgetGroupManagementNames.LearningPathways)?.GroupCount || 0}
                    </CustomBadge>
                </div>
                }
            />
            <GroupManagementNavCard
                title={"Permissions"}
                onClick={onPermissionsClick}
                icon={faKey}
                isActive={activeTab === activeTabsEnum.Permisssions}
                bottomText={<div
                    className={"text-end fw-bold"}
                >
                    <CustomBadge>
                        {getSummaryWidget(activeGroup, summaryWidgetGroupManagementNames.Permissions)?.GroupCount || 0}
                    </CustomBadge>
                </div>
                }
            />
            <GroupManagementNavCard
                title={"Group members"}
                onClick={onGroupMembersClick}
                icon={faPeopleGroup}
                isActive={activeTab === activeTabsEnum.GroupMembers}
                bottomText={<div
                    className={"text-end fw-bold"}
                >
                    <CustomBadge>

                        {getSummaryWidget(activeGroup, summaryWidgetGroupManagementNames.GroupMembers)?.GroupCount || 0}
                    </CustomBadge>
                </div>
                }
            />
        </div>
    }

    const onGroupDetailFormChange = (e) => {
        setActiveGroup({
            ...activeGroup,
            [e.target.name]: e.target.value
        })
    }

    const activeTabToRenderTabMapper = {
        [activeTabsEnum.GroupDetail]: () => <GroupDetailTab
            group={updateCreateGroupPayload.groupId < 0 ? updateCreateGroupPayload : activeGroup}
            onChange={onGroupDetailFormChange}
            onCreate={() => {
                createGroup(updateCreateGroupPayload)
            }}
            isUpdatingOrCreatingGroup={isUpdatingOrCreatingGroup}
        />,
        [activeTabsEnum.LearningPathways]: () => <LearningPathwaysTab group={activeGroup}
                                                                      onSuccessUpdateGroup={onSuccessUpdateGroup}/>,
        [activeTabsEnum.Permisssions]: () => <PermissionsTab group={activeGroup}
                                                             onSuccessUpdateGroup={onSuccessUpdateGroup}/>,
        [activeTabsEnum.GroupMembers]: () => <GroupMembersTab group={activeGroup}
                                                              onSuccessUpdateGroup={onSuccessUpdateGroup}/>,
    }

    const renderActiveTab = () => {
        if (!activeTabToRenderTabMapper.hasOwnProperty(activeTab)) {
            return () => <>Tab not mapped correctly</>
        }

        return activeTabToRenderTabMapper[activeTab]
    }

    const onSingleGroupClick = (group) => {
        setActiveGroup(group)
        setActiveTab(activeTabsEnum.GroupDetail)
    }
    const onSingleGroupIconClick = (group, activeTabType, e) => {
        e.stopPropagation()
        setActiveTab(activeTabType)
        setActiveGroup(group)
    }

    const onSearchSubmit = async () => {
        setSearchGroupsPayload({
            ...searchGroupsInitialPayload,
            filter: searchTerm
        })
        setGroups([])
        setGroupsCount(0)
        setActiveGroup(null)
        setTriggerSearch(prev => prev + 1)
    }

    const onSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const onSearchClear = () => {
        if(!searchTerm){
            return
        }
        setSearchTerm("")
        setSearchGroupsPayload({
            ...searchGroupsInitialPayload,
            filter: ""
        })
        setGroups([])
        setGroupsCount(0)
        setActiveGroup(null)
        setTriggerSearch(prev => prev + 1)
    }

    const renderLoadMoreGroupsComponent = () => {
        return <>
            {!isLoading && groupsHasMore ? <div style={{minHeight: "200px"}} ref={lastElementRef}>

            </div> : <></>}
        </>
    }
    const onSuccessDeleteGroup = (deletedGroup) => {
        const filtered = groups.filter((group) => {
            return group.Id !== deletedGroup.Id
        })

        const firstGroupInList = groups.length ? groups[0] : null
        setActiveGroup(firstGroupInList)

        setGroups(filtered)
    }

    const onSuccessUpdateGroup = async (updatedGroup) => {

        const result = await apiAxiosCalls.getGroupSummaryRecords([updatedGroup.Id])
        const group = result.data.Value ? result.data.Value[0] : null

        if (!group) {
            return
        }
        const groupsCopy = JSON.parse(JSON.stringify(groups))

        const index = groupsCopy.findIndex(group => {
            return group.Id === updatedGroup.Id
        })

        if (index > -1) {
            groupsCopy[index] = group
            setGroups(groupsCopy)
            setActiveGroup(group)
            toastSuccess("Group updated successfully")
        } else {
            groupsCopy.unshift(group)
            setGroups(groupsCopy)
            setActiveGroup(group)
            toastSuccess("Group created successfully")
        }

    }

    const renderGroups = () => {
        return <div style={{maxHeight: "600px"}}
                    className={"w-100 d-flex flex-column align-items-center overflow-auto"}>

            {
                groups.map((group) => {
                    return <GroupManagementGroupSearchResult key={group.Id} onSuccessDeleteGroup={onSuccessDeleteGroup}
                                                             isActive={activeGroup?.Id === group?.Id}
                                                             group={group} onClick={onSingleGroupClick}
                                                             onSingleGroupIconClick={onSingleGroupIconClick}/>
                })
            }
            {renderLoadMoreGroupsComponent()}
            {isLoading && <div className={"w-100 d-flex justify-content-center align-items-center"}>
                <div className="spinner-border text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}


        </div>
    }
    const onCreateGroupClick = () => {
        setActiveTab(activeTabsEnum.GroupDetail)
        setUpdateCreateGroupPayload({...initialUpdateCreateGroupPayload})
        setActiveGroup(
            {
                Description: "",
                Id: -1,
                Name: "",
                SummaryWidgets: []
            }
        )
    }

    const renderCreateNewGroupButton = () => {
        return <OverlayTrigger placement='top' delay={{show: 250, hide: 400}}
                               overlay={
                                   <Tooltip className='cls-theme-tooltip'>Add</Tooltip>
                               }
        >
            <Button variant='clear' className='pt-0 pb-0' onClick={() => onCreateGroupClick()}>
                <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary'/>
            </Button>
        </OverlayTrigger>


    }

    useEffect(() => {
        if (!hasDoneInitialGroupsFetch) {
            searchGroups()
        }
    }, [searchGroupsPayload])

    useEffect(() => {
        if (groups.length && !activeGroup) {
            setActiveGroup(groups[0])
        }
    }, [groups])

    useEffect(() => {
        if (triggerSearch) {
            searchGroups()
        }
    }, [triggerSearch])

    useEffect(() => {
        setUpdateCreateGroupPayload({
            ...initialUpdateCreateGroupPayload,
            groupId: activeGroup?.Id || -1,
            Description: activeGroup?.Description || "",
            Name: activeGroup?.Name || ""
        })
    }, [activeGroup])

    return (
        <BaseContainer title='Group management'>
            <div className={"bg-white rounded-2 p-2 mb-2"}>
                <div className={"row d-flex flex-column-reverse flex-md-row"}>
                    <div className={"col-12 col-md-4 border-end d-flex flex-column align-items-center"}
                    >

                        <div className={"w-100 mb-4 mt-4 d-flex align-items-center justify-content-end py-2"}>
                            <div className={"w-80"}>
                                <GroupManagementSearch onSearchSubmit={onSearchSubmit} value={searchTerm}
                                                       onSearchClear={onSearchClear} onChange={onSearchChange}/>
                            </div>
                            <div className={"w-20"}>
                                {renderCreateNewGroupButton()}
                            </div>
                        </div>
                        { (!hasDoneInitialGroupsFetch || (!groups.length & isLoading)) ?    renderSkeletonsForGroups() : renderGroups()}
                    </div>
                    <div className={"col-12 col-md-8"}>
                        {renderCards()}
                        {renderActiveTab()()}
                    </div>
                </div>
            </div>
        </BaseContainer>
    );
};

export default GroupManagementPage;
