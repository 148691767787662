import BaseContainer from '../components/BaseContainer';
import { Row, Col } from 'react-bootstrap';


import DashboardAlert from '../components/DashboardAlert';
import DashboardToDo from '../components/DashboardToDo';
import DashboardMyPlan from '../components/DashboardMyPlan';
import DashboardCarousel from '../components/DashboardCarousel';
import NewsStand from '../components/NewsStand';


export default function Dashboard(props) {
    return (
        <BaseContainer header={<NewsStand title='Dashboard' />}>
            <Row className='gx-4'>
                <Col xl={8}>
                    <Row className='justify-content-center'>
                        <DashboardToDo />
                    </Row>
                    <Row className='m-1'>
                        <DashboardMyPlan />
                    </Row>
                </Col>
                <Col xl={4}>
                    <Row className='justify-content-center'>
                        <DashboardAlert />
                    </Row>
                    <Row className='m-1'>
                        <DashboardCarousel />
                    </Row>
                </Col>
            </Row>
        </BaseContainer>
    );
}
