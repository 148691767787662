import { useState  } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalForceChangePassword = ({ closeFn = () => null,closeAndSubmitFn = () => null, open = false}) => {
    const [newPasswordF, setPswF] = useState("");
    const [confPasswordF, setConfPswF] = useState("");
  
    const [inProgressF, setInProgressF] = useState(false);
    const [validatedF, setValidatedF] = useState(false);
    const [errorF, setErrorF] = useState(null);

 // Wait up to 180 secs in dev mode else 60 secs
const LOGIN_TIMEOUT = process.env.NODE_ENV === 'development' ? 180000 : 60000;
    const handleChangeNewPswF = (event) => {
        setErrorF("");
        setPswF(event.target.value);
    };
    const handleChangeConfPswF = (event) => {
         //Validate
        if ((newPasswordF!=undefined) && (newPasswordF!="") && (event.target.value!=newPasswordF)){   
            
            if (event.target.value.length>4){
            setErrorF('Password does not match');
            }
        }
        else
        {
        setErrorF("");
        }
        setConfPswF(event.target.value);
    };

    function handleErrorF(e) {
        setErrorF(e.message);
        setValidatedF(false);
        setInProgressF(false);
        console.error(e);
    }

    function handleForceChangePassword(event) {
        try {
            event.preventDefault();
        
            const form = event.currentTarget;
            if (!inProgressF && form.checkValidity() === true) {
                setInProgressF(true);
                setValidatedF(true);
                 axios({
                    method: 'post',
                    url: '/api/account/UpdatePasswordSimple',
                    timeout: LOGIN_TIMEOUT,
                    data: {    newPassword: newPasswordF}
                })
                    .then((result) => {
                        try {
                            if (result.status === 204) {
                                throw new Error('Problem updating the password');
                            }                             
                            setInProgressF(false);   
                            closeAndSubmitFn();
                              
                        } catch (e1) {
                           handleErrorF(e1);
                        }
                    }).catch('handleFError');
            }
        } catch (e2) {
            handleErrorF(e2);
        }
        event.preventDefault();
        event.stopPropagation();
    }


    function ShowForceChangePassword(){  
        return (
            <Form id="frmForcePassword" name="frmForcePassword"  noValidate validated={validatedF} onSubmit={handleForceChangePassword}>             
                <Form.Group className="mb-3" controlId="formNewPasswordF">
                <Form.Label>New Password</Form.Label>
                 <Form.Control  required type="password" placeholder="New password"  onChange={handleChangeNewPswF} />
                 <Form.Control.Feedback type="invalid">
                 Please provide a password.
                </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formConfirmNewPasswordF">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control placeholder="Confirm password" type="password" required onChange={handleChangeConfPswF} disabled={inProgressF} />
                <Form.Control.Feedback type="invalid">
                 Please confirm the password.
                </Form.Control.Feedback>
                </Form.Group>
                {errorF && (<p className="text-danger">{errorF}</p>)}
                {inProgressF ?
                <Button variant="primary" className="w-100" disabled>
                    <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                    Please wait...
                </Button>   
                :         
                <Button  type="submit" variant="primary"  disabled={inProgressF} >
                    Save
                </Button>
                }               
                                        
            </Form>
        );
    }

    return (
        <Modal fullscreen='md-down' show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 {ShowForceChangePassword()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalForceChangePassword;