import { useEffect } from 'react';

import BaseContainer from '../components/BaseContainer';

import LearningPlanTableNew from '../components/LearningPlanTableNew';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

export default function MyLearningPlan(props) {
    const {
  //      trainingPlanSummary,
        myActiveSortedTrainingPlanNew,
  //      fetchTrainingPlanSummary,
        fetchMyActiveSortedTrainingPlanNew
    } = useLmsStore(state =>
    ({
   //     trainingPlanSummary: state.trainingPlanSummary,
        myActiveSortedTrainingPlanNew: state.myActiveSortedTrainingPlanNew,
    //    fetchTrainingPlanSummary: state.fetchTrainingPlanSummary,
        fetchMyActiveSortedTrainingPlanNew: state.fetchMyActiveSortedTrainingPlanNew

    }), shallow);

 
    useEffect(() => {
    //    fetchTrainingPlanSummary();
        fetchMyActiveSortedTrainingPlanNew();
    }, []);

    return (<>
        <BaseContainer title='My Learning Plan'>
            <LearningPlanTableNew
                dataActiveTrainingPlan={myActiveSortedTrainingPlanNew}
              //  dataUser={trainingPlanSummary}
                enableActions={true}
            />
        </BaseContainer>
    </>);
}
