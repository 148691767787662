import { useEffect, useMemo, useState } from 'react';
import BaseContainer from "../components/BaseContainer";
import { TableContainer } from '../helpers/tableHelper'
import MaterialReactTable from 'material-react-table';
import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload,faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import {Button, OverlayTrigger, Tooltip } from "react-bootstrap";


export default function AllUserRegisteredInterest() {
    const navigate = useNavigate();
    const { registrations,   fetchRegistrations } = useLmsStore(state =>
    ({
        registrations: state.registrations.data,
        fetchRegistrations: state.fetchRegistrations
    }), shallow);

    const downloadFile = ({ data, fileName, fileType }) => {
      const blob = new Blob([data], { type: fileType });

      const a = document.createElement("a");
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    };

    const exportToJson = (e) => {
      e.preventDefault();
      downloadFile({
        data: JSON.stringify(registrations),
        fileName: "registrations.json",
        fileType: "text/json",
      });
    };
          
    const exportToCsv = (e) => {
      e.preventDefault();

      //User", "Smail", "Employee Id", "Item Code", "Item Name", "Date"
      // Headers for each column
      let headers = ["User, Email, Employee ID, Item Code, Item Name, Date DD/MM/YY"];

      // Convert users data to a csv
      let usersCsv = registrations.reduce((acc, user1) => {
        const { user, email, employeeId, itemCode, itemName, Date } = user1;
        acc.push([user, email, employeeId, itemCode, itemName, Date].join(","));
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv].join("\n"),
        fileName: "Registrations.csv",
        fileType: "text/csv",
      });
    };

    // on load
    useEffect(() => {
        fetchRegistrations();
    }, []);

    const columns = useMemo(
        () => [ //user, email, employeeId, itemCode, itemName, Date
            {
                accessorKey: "user",
                header: "User",
                //size: 30
            },
            {
                accessorKey: "email",
                header: "Email",
                //size: 30
            },
            {
                accessorKey: "employeeId",
                header: "Employee ID",
                //size: 30
            },
            {
                accessorKey: "itemName",
                header: "Item Name",
                //size: 30
            },
            {
                accessorKey: "date",
                header: "Date DD/MM/YY",
                //size: 30
            }
        ],
        [],
    );

    return (
        <BaseContainer title='All Users Registered Interest Report'>
            <TableContainer>
            <Button variant='outline-secondary' className='mt-2' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faChevronLeft} /><span className='m-2'>Back</span></Button>
        
                <MaterialReactTable
                    columns={columns}
                    data={registrations || []}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Download Data</Tooltip>}>
                                <Button variant='clear' className='pt-0 pb-0' onClick={exportToCsv}>
                                    <FontAwesomeIcon size='xl' icon={faDownload} className='text-primary' />
                                </Button>
                            </OverlayTrigger>
                        </>
                    )}
                    initialState={{
                        pagination: { pageIndex: 0, pageSize: 10 },
                        showGlobalFilter: true
                    }}
                    state={{
                        isLoading: !registrations
                    }}
                />
            </TableContainer>
        </BaseContainer>
    );
}