import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import {EventType, PublicClientApplication} from "@azure/msal-browser";

//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

export const pca = new PublicClientApplication({
    auth:{
        clientId: "7fe1e81c-f201-4e7a-8cb7-d5c251629233",
        authority: 'https://wdrb2clms.b2clogin.com/wdrb2clms.onmicrosoft.com/B2C_1_sign_in_flow',
        knownAuthorities: ['wdrb2clms.b2clogin.com'],
        redirectUri: `${window.location.origin}/newLogin`,
        postLogoutRedirectUri: `${window.location.origin}/newLogin`,
    },
    system:{
        loggerOptions:{
            loggerCallback:(level,message,containsPii)=>{
                console.log(message)
            },
            logLevel:"Info"
        }
    }
})
pca.handleRedirectPromise()
    .then((tokenResponse) => {
        console.log(tokenResponse); // handle the response
        if(tokenResponse){
            pca.setActiveAccount(tokenResponse.account)

            // Define the request for the access token
            const silentRequest = {
                account: pca.getActiveAccount()
            };
            // Acquire the token
            pca.acquireTokenSilent(silentRequest)
                .then(response => {
                    console.log(response); // check the response for the access token
                })
                .catch(err => {
                    console.log(err);
                });
        }
    })
    .catch((error) => {
        console.error(error);
    });

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App msalInstance={pca} />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
