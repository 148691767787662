import React, { useEffect, useState } from 'react';

import BaseContainer from '../components/BaseContainer';

import { Row, Col } from 'react-bootstrap';

import { useSearchParams } from "react-router-dom";

import { CourseCard, CourseCardLoading } from '../components/CourseCard';

import { FilterClearButton, FilterButton, SearchTableButton } from '../components/CustomButtons';

import { searchObjectArray } from '../helpers/searchHelper';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import { learningItemName } from '../helpers/tableHelper';



const getUniqueCatalogues = (items) => {
    if (items && items.length > 0) {
        let catalogueFilterItems = items.map((item) => { return item.catalogueId > 0 ? { id: item.catalogueId, name: item.catalogueName } : undefined; });
        return [...new Map(catalogueFilterItems.map(item => [item.id, item])).values()].sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0); // Unique array & sort by name
    } else {
        return [];
    }
};

/**
 * Get unique catalogue items
 * 
 * @param {*} items 
 * @returns 
 */
const getUniqueCatalogueItems = (items) => [...new Map(items.map(item => [item.code, item])).values()];

export default function CourseLibrary(props) {
    const [filterId, setFilterId] = useState(0);
    const [searchParams] = useSearchParams();
    const [searchTerms, setSearchTerms] =  useState(decodeURI(searchParams.get("search") || '')); //useState('');
    const [tableData, setTableData] = useState([]);
    const [uniqueCatalogues, setUniqueCatalogues] = useState([]);

    const updateTableData = (applySearch = false, searchToSet = null) => {
        if (allPersonCatalogueItems) {
            if (searchToSet !== null) {
                setSearchTerms(searchToSet);
            }
            let search = searchToSet === null ? searchTerms : searchToSet,
                data = structuredClone(getUniqueCatalogueItems(allPersonCatalogueItems));
            if (filterId > 0) {
                // Apply filter
                let filter = uniqueCatalogues.find(x => x.id === filterId);
                if (filter) {
                    data = data.filter((rowData) => rowData.allCatalogueNames && rowData.allCatalogueNames.includes(filter.name));
                }
            }
            if (applySearch && search !== '') {
                // Apply search
                data = searchObjectArray(data, search, ['catalogueName', 'code', 'description', 'name', (obj) => learningItemName(obj.learningItemTypeId)]);
            }
            setTableData(data);
        }
    }

    const { allPersonCatalogueItems, fetchAllPersonCatalogueItems } = useLmsStore(state =>
    ({
        allPersonCatalogueItems: state.allPersonCatalogueItems.data,
        fetchAllPersonCatalogueItems: state.fetchAllPersonCatalogueItems
    }), shallow)

    // On load
    useEffect(() => {
        fetchAllPersonCatalogueItems();
    }, []);

    useEffect(() => {
        updateTableData(true);
    }, [uniqueCatalogues, filterId]);

    useEffect(() => {
        if (Array.isArray(allPersonCatalogueItems)) {
            setUniqueCatalogues(getUniqueCatalogues(allPersonCatalogueItems));
        }
    }, [allPersonCatalogueItems]);    
    return (
        <BaseContainer title='Course Library'>
            <Row style={{ paddingLeft: '0.75rem' }}>
                <Col>
                    <Row className='gx-3 gy-3'>
                        <Col key={0} sm='auto'>
                            <SearchTableButton placeholder="Search courses" onClick={(searchTermsToSet) => updateTableData(true, searchTermsToSet)} />
                        </Col>
                        {uniqueCatalogues.length === 0 ? [] : uniqueCatalogues.map((catalogueData, idx) => (<Col key={idx} sm='auto'><FilterButton title={catalogueData.name} onClick={() => setFilterId(filterId === catalogueData.id ? 0 : catalogueData.id)} active={filterId === catalogueData.id} /></Col>))}
                        <Col xs='auto'>
                            {filterId > 0 && <FilterClearButton onClick={() => setFilterId(0)} />}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{marginTop:'10px', maxHeight: '650px', overflow: 'auto', overflowX: 'hidden'}}>
            <Row className='gx-4 gy-4 mt-0 row-cols-auto'>
                {!allPersonCatalogueItems ? (<><CourseCardLoading key='1' /><CourseCardLoading key='2' /><CourseCardLoading key='3' /><CourseCardLoading key='4' /><CourseCardLoading key='5' /></>) : tableData.map((rowData, idx) => (<CourseCard rowData={rowData} key={idx} />))}
            </Row>
            </div>
        </BaseContainer>
    );
}
