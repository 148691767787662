import { useEffect, useMemo, useState } from 'react';

import BaseContainer from '../components/BaseContainer';
import LearningPlanEditorGroup from '../components/LearningPlanEditorGroup';
import LearningPlanEditorUser from '../components/LearningPlanEditorUser';

import MaterialReactTable from 'material-react-table';

import { TableContainer } from '../helpers/tableHelper';

import { Col, Row, Tab, Tabs } from 'react-bootstrap';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

export default function TeamProfile(props) {
    const [columns, setColumns] = useState(null);
    const [data, setData] = useState([]);
    // const [dataActivePlan, setDataActivePlan] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [dataSelected, setDataSelected] = useState([]);
    const [edit, setEdit] = useState('0');
    const [rowSelection, setRowSelection] = useState({});
    const [tabId, setTabId] = useState('0');

    const { applicationPeople, applicationGroups, fetchApplicationPeople, fetchApplicationGroups } = useLmsStore(state =>
    ({
        applicationPeople: state.applicationPeople.data,
        applicationGroups: state.applicationGroups.data,
        fetchApplicationPeople: state.fetchApplicationPeople,
        fetchApplicationGroups: state.fetchApplicationGroups
    }), shallow)

    useEffect(() => {
        let keys = Object.keys(rowSelection);
        if (keys.length === 1 && rowSelection[keys[0]] === true) {
            let id = parseInt(keys[0]);
            if (tabId === '1') {
                if (applicationGroups) {
                    setDataSelected(applicationGroups.find(x => x.id === id));
                }
            } else {
                if (applicationPeople) {
                    setDataSelected(applicationPeople.find(x => x.id === id));
                }
            }
        } else {
            setDataSelected(null);
        }
    }, [rowSelection]);


    const columns1 = useMemo(
        () => [
            {
                accessorKey: 'fullName',
                header: 'Full Name',
                Cell: ({ cell, row }) => {
                    return (<>
                        {row.original.fullName || ''} <br />
                        EID: {row.original.employeeId || 'None'}<br />
                        E: {row.original.email || 'None'}
                    </>)
                }
            }
        ],
        [],
    );

    const columns2 = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Full Name'
            }
        ],
        [],
    );

    // on load
    useEffect(() => {
        fetchApplicationPeople();
        fetchApplicationGroups();
    }, []);

    useEffect(() => {
        setDataLoading(!applicationGroups || !applicationPeople);
        if (tabId === '1') {
            setColumns(columns2);
            setData(applicationGroups || []);
        } else {
            setColumns(columns1);
            setData(applicationPeople || []);
        }
    }, [tabId, applicationGroups, applicationPeople]);

    return (
        <BaseContainer title='Learning Plan Admin'>
            <TableContainer>
                <Row>
                    {edit ? null :
                        <Col style={{ maxWidth: '450px' }} className='border-end'>
                            <Tabs activeKey={tabId} onSelect={(k) => setTabId(k)} className="mt-3 mb-2">
                                <Tab title='Users' eventKey='0' />
                                <Tab title='Groups' eventKey='1' />
                            </Tabs>
                            {columns ?
                                <MaterialReactTable
                                    columns={columns}
                                    data={data}
                                    enableColumnActions={false}
                                    enableColumnFilters={false}
                                    enableDensityToggle={false}
                                    enableFullScreenToggle={false}
                                    enableGrouping={false}
                                    enableHiding={false}
                                    enablePagination={true}
                                    enableRowVirtualization
                                    enableSorting={false}
                                    enableTopToolbar={true}
                                    getRowId={(originalRow) => originalRow.id}
                                    muiTableBodyRowProps={({ row }) => ({
                                        onClick: () =>
                                            setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                                        hover: false,
                                        selected: rowSelection[row.id],
                                        sx: {
                                            cursor: 'pointer',
                                        },
                                    })}
                                    muiTableContainerProps={{ sx: { maxHeight: 'max(242px, calc(100vh - 405px))' } }}
                                    muiTableHeadProps={{
                                        sx: {
                                            display: 'none'
                                        }
                                    }}
                                    muiToolbarAlertBannerProps={{
                                        sx: {
                                            display: 'none'
                                        }
                                    }}
                                    initialState={{
                                        pagination: { pageIndex: 0, pageSize: 10 },
                                        showGlobalFilter: true
                                    }}
                                    state={{
                                        isLoading: (dataLoading),
                                        rowSelection
                                    }}
                                /> : null}
                        </Col>}
                    <Col>
                        {tabId === '1' ?
                            <LearningPlanEditorGroup
                                dataSelected={dataSelected}
                                displayUserCard={true}
                                onEditChanged={(state) => setEdit(state)}
                            />
                            :
                            <LearningPlanEditorUser
                                dataSelected={dataSelected}
                                displayUserCard={true}
                                onEditChanged={(state) => setEdit(state)}
                            />
                        }
                    </Col>
                </Row>
            </TableContainer>
        </BaseContainer>);
}
