import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, InputGroup, Alert, Button, Modal } from "react-bootstrap";

import useLmsStore, { responseData, getUserInfo, getAllCompanyAddress, getAllDivisions, postUser, postPersonfilestore, getAllManagersByCompany, getSendInvitation, FORMCONFIRM, hasPermission  } from "../hooks/useLmsStore";

import Multiselect from 'multiselect-react-dropdown';
import AppAvatar from "../components/AppAvatar";
import moment from 'moment'
import ModalConfirmation from './ModalConfirmation';
import { toast } from 'react-toastify';
import { usePrompt } from '../hooks/usePrompt'

let showConfirm = false;

const FormUser = ({ user, userStatus, employeeTypes, companiesByApplication, catalogues, userRoles}) => {
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [userInfo, setUserInfo] = useState([]);
    const [userLoading, setUserLoading] = useState(true);
    const [userError, setUserError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [submitAnyway, setSubmitAnyway] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [divisionId, setDivisionId] = useState(0);
    const [departmentId, setDepartmentId] = useState(0);
    const [teamId, setTeamId] = useState(0);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showConfirmInvitation, setShowConfirmInvitation] = useState(false);
    const [divisionList, setDivisionList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [cataloguesList, setCataloguesList] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [showDialog, setShowDialog] = useState(false);   
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSwitchChange = event =>{
        let data = userInfo;
        data[event.target.id] = event.target.checked;
        setUserInfo(data);
        setShowDialog(true);
    };
    //console.log("Selected user : " + JSON.stringify(user)) 
    const sendInvitation = event =>{

        toggleModalInvitationConfirmation();
        getSendInvitation(userInfo.invitationId);
        toast.success("Invitation sent sucessfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    };

    const toggleModalInvitationConfirmation = event =>{
        setShowConfirmInvitation(!showConfirmInvitation);
    };

    const handleDivisionChange = event =>{
        setDivisionId(event.target.value);
        setDepartmentId(0);
        setTeamId(0);
        setTeamList([]);
        const departments = divisionList.filter(x=>x.id.toString() === event.target.value)??[];
        if(departments.length === 0 )
        {
            setDepartmentList([]);
        }else{
            setDepartmentList(departments[0].departments?departments[0].departments:[]);
        }   
    };

    const handleDepartmentChange = event =>{

        setDepartmentId(event.target.value);
        setTeamId(0);
        const teams = departmentList.length !== 0? departmentList.filter(x=>x.id.toString() === event.target.value)??[]:[];
        if(teams.length === 0)
        {
            setTeamList([]);
        }
        else{
            setTeamList(teams[0].teams?teams[0].teams:[]);
        }
    };

    const handleCompanyChange = event =>{
        setCompanyId(event.target.value);
        setDivisionId(0);
        setDepartmentId(0);
        setTeamId(0);
        getAllCompanyAddress(event.target.value).then((response) => {
            let data1 = responseData(response, []);
            if (data1) {
                setAddressList(data1);
            }
        });
        getAllDivisions(event.target.value).then((response) => {
            let data2 = responseData(response, []);
            if (data2) {
                setDivisionList(data2);
            }
        });
        getAllManagersByCompany(event.target.value).then((response) => {
            let data3 = responseData(response, []);
            if (data3) {
                setManagerList(data3)
            }
        });
    };

    const handleFormLoad = () => {
        setUserError(false);
        showConfirm=false;
        setShowDialog(false);
        setSubmitAnyway(false);
     
        if(user !==[] && user.id){
            setUserLoading(true);
            getUserInfo(user.id).then((response) => {
                let data = responseData(response, []);
                if (data.companyId) {
                    setCompanyId(data.companyId);
                    setDivisionId(data.divisionId??0);
                    setDepartmentId(data.departmentId??0);
                    setTeamId(data.teamId??0);

                    setCataloguesList(data.catalogues.map(function(c){ return {'title' : c.catalogueName, 'catalogueId' : c.catalogueId,  'personCatalogueId': c.personCatalogueId  }}));
                    getAllCompanyAddress(data.companyId).then((response) => {
                        let data1 = responseData(response, []);
                        if (data1) {
                            setAddressList(data1);
                        }
                    });
                    getAllDivisions(data.companyId).then((response) => {
                        let data2 = responseData(response, []);
                        if (data2) {
                            setDivisionList(data2);
                            const departments = data2.filter(x=>x.id === data.divisionId)??[];
                            if(departments.length === 0 )
                            {
                                setDepartmentList([]);
                                setTeamList([]);
                            }else{
                                setDepartmentList(departments[0].departments?departments[0].departments:[]);
                                const teams = departments[0].departments? departments[0].departments.filter(x=>x.id === data.departmentId)??[]:[];
                                if(teams.length === 0)
                                {
                                    setTeamList([]);
                                }
                                else{
                                    setTeamList(teams[0].teams?teams[0].teams:[]);
                                } 
                            }   
                        }
                    });
                    ///api/people/getAllManagersByCompany/1
                    getAllManagersByCompany(data.companyId).then((response) => {
                        let data3 = responseData(response, []);
                        if (data3) {
                            setManagerList(data3)
                        }
                    });

                }else{
                    setCompanyId("");
                }
                if (data.id) {
                    //if it is an update!
                    setUserInfo(data);
                    setUserLoading(false);
                }
                if(data.statusCode !== 200 || !data.value)
                {
                    setUserError(true);
                }
            });
        }
        else{
            //if it is a new user!
            setUserInfo({
                invitationId: -1,
                employmentType: null,
                workAddress: null,
                workPhoneNumber: null,
                mobileNumber: null,
                lastName: "",
                firstName: "",
                jobTitle: "",
                managerAuthorisationLimit: null,
                statusId: null,
                statusName: null,
                roleId: null,
                managerId: null,
                createCatalogueAlerts: false,
                startDateAtCompanyStr: "",
                leavingDateFromCompany: null,
                companyId: 0,
                employeeId: null,
                emailAddress: "",
                sendInvitation: true,
                canSendInvitation: true,
                id: -99792,
                photograph: "",
                catalogues: [],
                groups: [],
                staff: [],
                departmentId: null,
                divisionId: null,
                teamId: null
            });
            setCataloguesList([]);
            setUserLoading(false);
        }
    };

    const handleChange = e => {
        if (e.target.files.length) {
            const selectedFile = e.target.files[0];
           // alert('handleChange  selectedFile: ' + selectedFile.size )
            const fileSize = selectedFile.size;
            setSelectedFile(fileSize);
            //console.log('fileSize : ' + fileSize)
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };
    const [fileSize, setFileSize] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const handleUpload = async e => {
        e.preventDefault();

        const maxFileSize = process.env.REACT_APP_MAX_FILE_SIZE;

        if (!selectedFile) {
            setErrorMessage('Please select a file to upload.');
            return;
        }

        if (selectedFile) {            
            const formData = new FormData();
            formData.append("upload", image.raw);
            formData.append("person_id",user.id);
            formData.append("part_id",666);

            const kiloByte = 1048576;
            const fileSizeInKB = selectedFile / kiloByte;

            console.log('kiloByte : ' + kiloByte);
            console.log('fileSize : ' + selectedFile );
            console.log('maxFileSize : ' + maxFileSize );
            console.log('fileSizeInKB : ' + fileSizeInKB );
           
            if (fileSizeInKB <= maxFileSize) {  
                setErrorMessage('');              
                postPersonfilestore(useLmsStore, formData);                 
              } else {               
                setErrorMessage('File size exceeds the limit. Limit to 2 MB.');
              }
            } 
    };
    
    const handleSubmitBefore = async event => {
        const form = event.currentTarget;

        if(form["firstName"].value===user.firstName && form["lastName"].value===user.lastName && form["emailAddress"].value===user.emailAddress){
            setSubmitAnyway(true);
            handleSubmit(event, true);
        }
        else{
            if(submitAnyway || !user){
                    handleSubmit(event,false);
            }
            else{
                  handleSubmit(event, false);
                setShowConfirmDelete(true);
                setSubmitAnyway(true);
            }
        }
    };

    const handleCloseConfirmDelete = (event) => {
        setShowConfirmDelete(false);
    };

    const handleSubmit = async (event, submit) => {
        const form = event.currentTarget;
        if (!inProgress && form.checkValidity() === true) {
            let usrData= userInfo;
            var elements = Array.from(form.elements)
            elements.map(el => {
                if(el.localName ==='input')
                {
                    if(el.type === "number"){
                        usrData[el.id]= parseInt(el.value,0);
                    }
                    else if(el.type ==='checkbox')
                    {
                        //do nothing
                    }
                    else if(el.type ==='switch')
                    {
                        //do nothing
                    }
                    else if(el.type ==='date')
                    {
                        if(el.value != "" && el.value != null){
                            usrData[el.id+'Str']= moment(el.value).format('DD/MM/YYYY');
                            usrData[el.id]= el.value;
                        }
                    }
                    else if(el.readOnly!==true){
                        
                        usrData[el.id]=  el.value;
                    }
                }
                if(el.localName ==='select')
                {
                    usrData[el.id]= parseInt(el.value,0);
                }
            })

            if(submitAnyway || submit)
            {
                setShowConfirmDelete(false);
                setInProgress(true);
                let usr = await postUser(useLmsStore, usrData);
                setUserInfo(usr);
                setInProgress(false);
                setSubmitAnyway(false); 
                showConfirm=false; 
            
            }else{
                setUserInfo(usrData);
            }

        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
    };

    const setValueInForm = event =>{
        let data = userInfo;
        data[event.target.id] = event.target.value;
        setUserInfo(data);
        showConfirm =true; //It is key to stop the problem with adding one key at the time
        };

    const onSelect = (selectedList, selectedItem) => {
        let usrData= userInfo;
        usrData.catalogues.push({catalogueName: selectedItem.title, catalogueId: selectedItem.catalogueId, personCatalogueId: -1});
        setUserInfo(usrData);
        setCataloguesList(selectedList);
    };
    
    const onRemove = (selectedList, removedItem) => {
        let usrData= userInfo;
        const toRemove = (element) => element.catalogueId === removedItem.catalogueId;
        let idx = usrData.catalogues.findIndex(toRemove)
        usrData.catalogues.splice(idx, 1);
        setUserInfo(usrData);
        setCataloguesList(selectedList);
    };

    useEffect(() => {
        if(showConfirm)
        {
            let confirm = window.confirm(FORMCONFIRM);        
            if(confirm){
             handleFormLoad();
            }
        }
        else{
            handleFormLoad();
        }
    }, [user]);

    function ShowUser(props) {  
        return (
            userLoading? userError? 
            <> 
                <div className="m-3 justify-content-center">
                    <Alert variant="danger" >
                        <Alert.Heading>Oh snap! No data!</Alert.Heading>
                        <p>
                        No data was returned for this user!
                        </p>
                    </Alert>
                </div>
            </>
            :
            <> 
            <div className="d-flex justify-content-center">
                <div className="spinner-border mt-5 text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            </>
            :
            <>
            <Container fluid>
                {
                    userInfo !==[]  && userInfo.id?
                    <>
                    <Row>
                        <h1>{props.data.fullName}</h1>
                        <h3>{props.data.jobTitle}</h3>
                    </Row>
                    <Row className="mt-2 w-100"><hr/></Row>
                    </>
                    :
                    <><Row className="mt-2 w-100"></Row></>
                }
                <Row>
                    <Row> 
                        <Col sm={8}>
                        <h3>Personal Details</h3>   
                        <Form noValidate validated={validated} onSubmit={handleSubmitBefore} id="user-form"> 
                        <fieldset disabled={inProgress}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="firstName">
                                        <Form.Label>First Name <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="input"  placeholder="First Name" defaultValue={props.userInfo.firstName? props.userInfo.firstName : ""} className="no-border"  onChange={(e)=>  setValueInForm(e)} required/>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide user first name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3" controlId="lastName">
                                        <Form.Label>Last Name <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="input"   placeholder="Last Name" defaultValue={props.userInfo.lastName? props.userInfo.lastName : ""} className="no-border" onChange={(e)=> setValueInForm(e)} required/>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide user last name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="companyId">
                                        <Form.Label>Company <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                                        <InputGroup hasValidation>
                                        <Form.Select aria-label="Default select example" defaultValue={companyId}
                                            onChange={handleCompanyChange}
                                            required>
                                        <option disabled value="">Select Company</option>
                                        {props.companiesByApplication.map(status => (
                                            <option key={status.companyId} value={status.companyId}>
                                                {status.companyName} 
                                            </option> 
                                        ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a company.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3" controlId="jobTitle">
                                        <Form.Label>Job Title <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="input"  placeholder="Job Title" defaultValue={props.userInfo.jobTitle? props.userInfo.jobTitle : ""} className="no-border" onChange={(e)=> setValueInForm(e)} required/>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide user job title.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="roleId">
                                        <Form.Label>Role</Form.Label>
                                        <Form.Select aria-label="Default select example" defaultValue={props.userInfo.roleId} onChange={(e)=> setValueInForm(e)}>
                                        <option>Select role</option>
                                        {props.userRoles.map(role => (
                                            <option key={role.roleId}   value={role.roleId} >
                                                {role.title}
                                            </option>
                                        ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="managerId">
                                        <Form.Label>Line Manager</Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Select aria-label="Default select example" defaultValue={props.userInfo.managerId} onChange={(e)=> setValueInForm(e)} >
                                            <option  value="" >Line Manager</option>
                                            {managerList.map(manager => (
                                                <option key={manager.id} value={manager.id}>
                                                    {manager.fullName}
                                                </option>
                                            ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a line manager. You must select a company first.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="emailAddress">
                                        <Form.Label>Email Address <span title='Mandatory field' className='mandatory'>*</span></Form.Label>
                                        <InputGroup hasValidation>
                                            <Form.Control type="email"  placeholder="Email Address" defaultValue={props.userInfo.emailAddress? props.userInfo.emailAddress : ""} className="no-border" onChange={(e)=> setValueInForm(e)} required/>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid user email.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="divisionId">
                                        <Form.Label>Division</Form.Label>
                                        <Form.Select aria-label="Default select example" defaultValue={divisionId} onChange={handleDivisionChange}>
                                        <option>Select Division</option>
                                        {divisionList.map(division => (
                                            <option key={division.id} value={division.id} >
                                                {division.name}
                                            </option>
                                        ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="departmentId">
                                        <Form.Label>Department</Form.Label>
                                        <Form.Select aria-label="Default select example" defaultValue={departmentId} onChange={handleDepartmentChange}>
                                        <option>Select Department</option>
                                        {departmentList.map(department => (
                                            <option key={department.id} value={department.id} >
                                                {department.name}
                                            </option>
                                        ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="teamId">
                                        <Form.Label>Team</Form.Label>
                                        <Form.Select aria-label="Default select example" defaultValue={teamId} onChange={(e)=> setTeamId(e.target.value)}>
                                        <option>Select Team</option>
                                        {teamList.map(team => (
                                            <option key={team.id} value={team.id} >
                                                {team.name}
                                            </option>
                                        ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                <Form.Group className="mb-3" controlId="employeeId">
                                    <Form.Label>Employee ID</Form.Label>
                                        <Form.Control type="input"  placeholder="Employee ID" defaultValue={props.userInfo.employeeId? props.userInfo.employeeId : "None"} className="no-border" onChange={(e)=> setValueInForm(e)}/>
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3" controlId="workPhoneNumber">
                                        <Form.Label>Primary Phone</Form.Label>
                                        <Form.Control type="tel" placeholder="Phone" pattern="\d*" defaultValue={props.userInfo.workPhoneNumber? props.userInfo.workPhoneNumber : "None"} className="no-border" onChange={(e)=> setValueInForm(e)}/>
                                        <Form.Control.Feedback type="invalid">
                                                Please provide a valid valid telephone
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="mobileNumber">
                                        <Form.Label>Secondary Phone</Form.Label>
                                        <Form.Control type="tel" placeholder="Mobile Number" pattern="\d*" defaultValue={props.userInfo.mobileNumber? props.userInfo.mobileNumber: "None"} className="no-border" onChange={(e)=> setValueInForm(e)}/>
                                    </Form.Group>
                                    <Form.Control.Feedback type="invalid">
                                                Please provide a valid valid mobile number
                                            </Form.Control.Feedback>
                                    {props.userInfo.invitationId ===-1?
                                    <Form.Group className="mb-3" controlId="sendInvitation">
                                        <Form.Label>Invitation</Form.Label><br/>
                                        <Form.Check type="switch" style={{marginTop: '0.5rem', marginBottom: '22px' }} onChange={handleSwitchChange} label="send Invitation" defaultChecked={props.userInfo.sendInvitation} />
                                    </Form.Group>
                                     : props.userInfo.statusName === "Registration In Progress" && props.userInfo.canSendInvitation ?
                                     <Form.Group className="mb-3">
                                        <Form.Label>Invitation</Form.Label><br/>
                                        <Button type="button" className="btn btn-primary" onClick={toggleModalInvitationConfirmation} >Resend Invitation</Button>
                                    </Form.Group>
                                     :
                                    <Form.Group className="mb-3" controlId="statusId">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select aria-label="Default select example" defaultValue={props.userInfo.statusId} onChange={(e)=> setValueInForm(e)}>
                                        <option>Select status</option>
                                        {props.userStatus.map(status => (
                                            <option key={status.id} value={status.id}>
                                                {status.value}
                                            </option>
                                        ))}
                                        </Form.Select>
                                    </Form.Group>
                                    }
                                    <Form.Group className="mb-3" controlId="managerAuthorisationLimit">
                                        <Form.Label>Manager Authorisation Limit</Form.Label>
                                        <Form.Control type="number" placeholder="Manager Authorisation Limit" defaultValue={props.userInfo.managerAuthorisationLimit? props.userInfo.managerAuthorisationLimit : null} className="no-border" onChange={(e)=> setValueInForm(e)}/>
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3" controlId="workAddress">
                                        <Form.Label>Work Address</Form.Label>
                                        <Form.Select aria-label="Default select example" defaultValue={props.userInfo.workAddress} onChange={(e)=> setValueInForm(e)}>
                                        <option>Work address</option>
                                        {addressList.map(company => (
                                            <option key={company.id} value={company.id}>
                                                {company.address}
                                            </option>
                                        ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="employmentType">
                                        <Form.Label>Employment Type</Form.Label>
                                        <Form.Select aria-label="Default select example" defaultValue={props.userInfo.employmentType} onChange={(e)=> setValueInForm(e)}>
                                        <option>Employment Type</option>
                                        {employeeTypes.map(empType => (
                                            <option key={empType.id} value={empType.id} >
                                                {empType.value}
                                            </option>
                                        ))}
                                        </Form.Select>
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3" controlId="startDateAtCompany">
                                        <Form.Label>Start Date at Company</Form.Label>
                                        <Form.Control type="date" placeholder="Logged In"  defaultValue={props.userInfo.startDateAtCompany? moment(props.userInfo.startDateAtCompany).format('YYYY-MM-DD')  : ""} className="no-border" onChange={(e)=> setValueInForm(e)}/>
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3" controlId="lastLogin">
                                        <Form.Label>Last Logged in</Form.Label>
                                        <Form.Control type="input" className="no-border" defaultValue={props.userInfo.lastLogin? moment(props.userInfo.lastLogin).format('DD/MM/YYYY hh:mm:ss') : ""} readOnly />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="leavingDateFromCompany">
                                        <Form.Label>Leave date from company</Form.Label>
                                        <Form.Control type="date" placeholder="Leave date from company"  defaultValue={props.userInfo.leavingDateFromCompany? moment(props.userInfo.leavingDateFromCompany).format('YYYY-MM-DD')  : ""} className="no-border" onChange={(e)=> setValueInForm(e)}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </fieldset>
                        </Form>
                        <hr/>
                        { hasPermission(useLmsStore,'pe.edit')?
                        <Button variant='primary' onClick={handleFormLoad}>Cancel</Button>
                        :<></> }
                        {
                            hasPermission(useLmsStore,'pe.edit')?
                        inProgress || userLoading ?
                            <Button variant="primary" disabled className="mx-2">
                                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                Please wait...
                            </Button>
                            :
                            <Button variant="primary" className="mx-2" type="submit" form="user-form">
                                Save
                            </Button>
                            :<></>
                        }
                        </Col>
                        <Col sm={4}>
                            <Row className="justify-content-center">
                                <div style={{ clear: 'both', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Row>
                                        <Col sm={12} md={12} className="pt-3">
                                            <div style={{  clear: 'both', display: 'flex', alignItems: 'center' }}>
                                                <AppAvatar
                                                    size={85}
                                                    name={props.data === undefined ? " " : props.data.firstName + " " + props.data.lastName}
                                                    sx={{ width: 25, height: 25 }}
                                                    src={image.preview ? image.preview : props.data.photograph}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={12} md={9}>
                                            <Form>
                                                <Form.Group className="mb-3 ml-2">
                                                    <Form.Label>Change Profile Picture:</Form.Label>
                                                    <Form.Control type="file" placeholder="Select Picture" id="upload-button" onChange={handleChange}/>
                                                </Form.Group>
                                                {hasPermission(useLmsStore, 'pe.photoupload') ? (
                                                    <>
                                                        <Button type="button" className="btn btn-primary ml-2" onClick={handleUpload}>
                                                        Upload
                                                        </Button>
                                                        {errorMessage && <div className="text-danger">{errorMessage}</div>}
                                                    </>
                                                    ) : (
                                                    <></>
                                                    )}
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>
                            <Row>
                                <h5>Assigned Catalogues</h5> 
                                <Multiselect
                                    options={catalogues.map(function(c){ return {'title' : c.title, 'catalogueId' : c.catalogueId, 'personCatalogueId': null }})}
                                    displayValue="title"
                                    selectedValues={cataloguesList}
                                    onSelect={onSelect} // Function will trigger on select event
                                    onRemove={onRemove} // Function will trigger on remove event
                                    placeholder="Select/Add"
                                    showCheckbox={true}
                                />
                            </Row>
                            <Row>
                                <h5>Assigned Groups</h5> 
                                <span className="d-none">
                                    <Multiselect
                                        options={userInfo.groups.map(function(c){ return {'title' : c.groupName, 'catalogueId' : c.groupId }})}
                                        displayValue="title"
                                        disablePreSelectedValues={true}
                                        selectedValues={userInfo.groups.map(function(c){ return {'title' : c.groupName, 'catalogueId' : c.groupId }})}
                                        placeholder="Select/Add"
                                        showCheckbox={true}
                                        />
                                </span>
                                <ul className="pre-scrollable">
                                {
                                    userInfo.groups?
                                    userInfo.groups.map(group => (
                                        <li key={group.groupId}>{group.groupName}</li> 
                                    ))
                                    :
                                    <li>None</li>
                                }
                                </ul>
                            </Row>
                            <Row>
                                <h5>Direct Reports</h5> 
                                <span className="d-none"><Multiselect
                                    options={userInfo.staff.map(function(c){ return {'title' : c.fullName, 'catalogueId' : c.lmsPersonId }})}
                                    displayValue="title"
                                    disablePreSelectedValues={true}
                                    selectedValues={userInfo.staff.map(function(c){ return {'title' : c.fullName, 'catalogueId' : c.lmsPersonId }})}
                                /> </span>
                                <ul className="pre-scrollable">
                                {
                                    userInfo.staff?
                                    userInfo.staff.map(emp => (
                                        <li key={emp.lmsPersonId}>{emp.fullName}</li> 
                                    ))
                                    :
                                    <li>None</li>
                                }
                                </ul>
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </Container>
            <Modal fullscreen='md-down' show={showConfirmDelete} onHide={handleCloseConfirmDelete} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>You are about to amend key contact information for this user</h6>
                    <Button variant='primary px-3 w-25 mx-3 float-right' onClick={handleCloseConfirmDelete} >Cancel</Button>
                    <Button variant="primary px-3 w-25 mx-3" type="submit" form="user-form">Save</Button>
                </Modal.Body>
            </Modal>
            <ModalConfirmation closeFn={toggleModalInvitationConfirmation} yesFn={sendInvitation} noFn={toggleModalInvitationConfirmation} open={showConfirmInvitation}  title='Send Invitation' description='<p>Are you sure you want to send an invitation?</p>' />
            </>
        );
    };

    usePrompt(FORMCONFIRM, showDialog);

    return (
        <ShowUser data={user} userRoles={userRoles} userInfo={userInfo} userStatus={userStatus} employeeTypes={employeeTypes} companiesByApplication={companiesByApplication}/>
    );
};

export default FormUser;
