import { useState, useMemo, useEffect} from 'react';
import { TableContainer } from '../helpers/tableHelper'
import MaterialReactTable from 'material-react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencil, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Container, Row, Col, Form, InputGroup, Modal, Button } from "react-bootstrap";
import { isSupportedDate, toLocaleDateString } from '../helpers/formatHelper';
import useLmsStore, { responseData, getAllActiveEventsByPersonGroups, getEventFullInfo, createEventBookingNew, getCanCancelBooking, cancelEventBookingNew,createWaitingDatesNew, removeWaitingDatesNew } from "../hooks/useLmsStore";
import moment from 'moment'
import shallow from 'zustand/shallow';
import ModalConfirmation from './ModalConfirmation';
import Card from "react-bootstrap/Card";
import { grey } from '@mui/material/colors';

const ModalUserEventInfo = ({ closeFn = () => null, open = false,  userEventData, eventName, status }) => {
   
    const [events, setEvent] = useState([]);
   
    useEffect(() => {
       
    }, []);

 

    return (
        <Modal size="lg" aria-labelledby="example-modal-sizes-title-lg" show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                {eventName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container fluid>
                {
                  userEventData? <>
                  {userEventData.eventDates?
                    <>
                     {userEventData.eventDates.length>0?
                     <>  
                     <div>
                       {userEventData.eventDates.map(d => (<div key={d.date}>{
                        <>
                        <Row >
                        <Card className='shadow'  style={{backgroundColor:'#e9ecef', marginTop:'1rem'}} >
                            <Card.Header  style={{border:'none',backgroundColor:'#e9ecef'}}>
                                <strong>Event date:</strong> {toLocaleDateString(d.date)}
                            </Card.Header>
                            <Card.Body  style={{paddingTop:'0px'}}>
                         {
                            d.slots.map(s => (
                         <Row className='gx-3' style={{backgroundColor:'#ffffff', border:'ridge 0.2px #696969'}}>
                             
                         
                           <div><b>Time:</b> {s.startTime}-{s.endTime}</div>
                           <div><b>Room:</b> {s.roomName}</div>
                           <div><b>Venue:</b> {s.venueName}</div>
                           <div><b>Status:</b> {s.statusName}</div> 
                                
                            </Row>))
                         }
                         
                         </Card.Body>
                         </Card>
                         </Row>
                         <Row><></></Row>
                        </>                         
                       }</div>))} 
                    </div>
                    </>
                  :<>

<Card className='shadow'  style={{backgroundColor:'#e9ecef', marginTop:'1rem'}} >
                            <Card.Header  style={{border:'none',backgroundColor:'#e9ecef'}}>
                 
                            <strong>Event date: </strong> {toLocaleDateString(userEventData.startDate)}
                        </Card.Header>
                      
                            <Card.Body  style={{paddingTop:'0px'}}>
                            <Row className='gx-3' style={{backgroundColor:'#ffffff', border:'ridge 0.2px #696969'}}>
                  
                  <div><strong>Time: </strong>{userEventData.startTime}-{userEventData.endTime}</div>
                  <div><strong>Room: </strong> {userEventData.roomName}</div>
                  <div><strong>Venue: </strong> {userEventData.venueName}</div>
                  <div><strong>Status: </strong> {status}</div> 
                  </Row>
                  </Card.Body>
                        
                
                         </Card>
                         
                  </>
                     }
                    </>
                    :
                    <>  
                        <Card className='shadow'  style={{backgroundColor:'#e9ecef', marginTop:'1rem'}} >
                            <Card.Header  style={{border:'none',backgroundColor:'#e9ecef'}}>
                 
                            <strong>Event date: </strong> {toLocaleDateString(userEventData.startDate)}
                        </Card.Header>
                      
                            <Card.Body  style={{paddingTop:'0px'}}>
                            <Row className='gx-3' style={{backgroundColor:'#ffffff', border:'ridge 0.2px #696969'}}>
                  
                  <div><strong>Time: </strong>{userEventData.displayStartTime}-{userEventData.displayEndTime}</div>
                  <div><strong>Room: </strong> {userEventData.roomName}</div>
                  <div><strong>Venue: </strong> {userEventData.venueName}, {userEventData.venueCity}</div>
                  <div><strong>Status: </strong> {status}</div> 
                      </Row>
                  </Card.Body>
                   </Card>                         
                    </>
                  }
                  </>:
                  <>               
                  </>
                }
            </Container>
            </Modal.Body>
            <Modal.Footer>             
         
                <Button variant='primary' onClick={closeFn}>Close</Button>
          
            </Modal.Footer>
        </Modal>
        
    );
};

export default ModalUserEventInfo;