import { useState  } from 'react';
import { Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { faCarOn } from '@fortawesome/pro-light-svg-icons';

const ModalTermsAndConditions = ({ closeFn = () => null,closeAndSubmitFn = () => null, open = false, termsText='', requirePasswordReset=false}) => {
    const LOGIN_TIMEOUT = process.env.NODE_ENV === 'development' ? 180000 : 60000;
    const [email, setEmail] = useState("");
    const [inProgressTerms, setInProgressTerms] = useState(false);
    const [mainTermsError, setMainTermsError] = useState(null);
    const [validatedTerms, setValidatedTerms] = useState(false);
    const [isOpenForceChangePsw, setisOpenForceChangePsw] = useState(false);  
   // const handleShowForceChangePsw = () => setisOpenForceChangePsw(true);
   // const handleCloseForceChangePsw = () =>setisOpenForceChnagePsw(false);


    function handleTermsError(e) {
        setMainTermsError(e.message);
        setValidatedTerms(false);
        setInProgressTerms(false);
        console.error(e);
    }
   
    function handleTermsErrorStr(message) {
        setMainTermsError(message);
        setValidatedTerms(false);
        setInProgressTerms(false);
       
    }
   
    /*
     Submit Accept Terms and conditions and close the window to carry on with the process of force change password
    */
     function handleAcceptTerms(event) {

        try {
            const form = event.currentTarget;           
            if (!inProgressTerms  && form.checkValidity() === true) {
                setInProgressTerms(true);
                setValidatedTerms(true);
                axios({
                    method: 'post',
                    url: '/api/account/acceptTermsAndConditions',
                    timeout: LOGIN_TIMEOUT,
                    data: undefined
                })
                    .then((result) => {
                        try {
                            if (result.status === 204) {
                                throw new Error('Problems accepting terms and conditions');
                            }
                          
                            if (requirePasswordReset){
                          
                            }
                            setInProgressTerms(false);

                            //Close the window and carry processing the force change password
                            closeAndSubmitFn();
                           // closeFn();
                           
                        } catch (e1) {
                            handleTermsError(e1);
                        }
                    }).catch(handleTermsError);
            }
            else{
                if (form.checkValidity()===false){
                    handleTermsErrorStr(" Please accept terms and conditions");
                }
            }
        } catch (e2) {
            handleTermsError(e2);
        }
        event.preventDefault();
        event.stopPropagation();
    };

    function ShowTermsAndConditions() {  
        return (
                <Form name="frmTerms" id="frmTerms" noValidate validated={validatedTerms} onSubmit={handleAcceptTerms}> 
                  <Form.Group className="mb-3" controlId="formCkAccept">
                   <Form.Check required      
                    label={'You accept our terms and conditions'}
                    id="ckAccept"  />
                    <Form.Control.Feedback type="invalid">
                      Please accept terms and conditions.
                    </Form.Control.Feedback>
                </Form.Group>                 
                {mainTermsError && (<p className="text-danger">{mainTermsError}</p>)}  
              {inProgressTerms ?
                <Button variant="primary" className="w-100" disabled>
                    <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                    Please wait...
                </Button>   
                :                                                                                                    
                <Button type="submit" variant="primary"  disabled={inProgressTerms}>
                 Accept
                </Button>}
            </Form>
        );
    }

    return (
        <Modal fsize="lg" aria-labelledby="example-modal-sizes-title-lg" show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div id="Terms">
            <span dangerouslySetInnerHTML={{ __html: termsText }} />
             </div>
            {ShowTermsAndConditions()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
            </Modal.Footer>
        </Modal>
     
    );
};

export default ModalTermsAndConditions;