import { useEffect, useMemo, useState } from 'react';
import BaseContainer from "../components/BaseContainer";
import AppAvatar from "../components/AppAvatar";
import { TableContainer } from '../helpers/tableHelper'
import MaterialReactTable from 'material-react-table';
import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import FormUser from '../components/FormUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { hasPermission } from '../hooks/useLmsStore';

export default function UserAdministration(props) {
    const [tableInactive, setTableInactive] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [isReadyOnlyUser, setIsReadOnlyUser] = useState(false);
    const [employeeIdSearchString, setEmployeeIdSearchString] = useState(''); // New state for employee ID search
    
    const [user, setUser] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const [rowSelection, setRowSelection] = useState({});
    const [dataSelected, setDataSelected] = useState(null);

    const { users, userStatus, employeeTypes, companiesByApplication, catalogues, userRoles, fetchCatalogues, fetchUseStatus, fetchUserCompaniesByApplication, fetchUserEmpType, fetchUsers, fetchUserRoles,personSettings } = useLmsStore(state =>
    ({
        users: state.users.data,
        userStatus: state.userStatus.data,
        employeeTypes: state.employeeTypes.data,
        companiesByApplication: state.companiesByApplication.data,
        catalogues: state.catalogues.data,
        userRoles:  state.userRoles.data,
        fetchCatalogues: state.fetchCatalogues,
        fetchUseStatus: state.fetchUseStatus,
        fetchUserCompaniesByApplication: state.fetchUserCompaniesByApplication,
        fetchUserEmpType: state.fetchUserEmpType,
        fetchUsers: state.fetchUsers,
        fetchUserRoles: state.fetchUserRoles,
        personSettings: state.personSettings
    }), shallow)

         // Sorting function to sort companies by companyName
         const sortedUsers = useMemo(() => {
            if (users) {
                return [...users].sort((a, b) => a.firstName.localeCompare(b.firstName));
            }
            return [];
        }, [users]);

    const handleAdd = () => {
        setUser([]);
        setShowForm(true);
    }
        
    const handleEdit = (rowData) => {
        setUser(rowData);
        setShowForm(true);
    }
    useEffect(() => {
        if (dataSelected) {
            setUser(dataSelected);
            //console.log(user);
            setShowForm(true);
        } else {
            setShowForm(false);
        }
     }, [dataSelected]);
   
    useEffect(() => {  
        fetchUsers();
        fetchUseStatus();
        fetchUserEmpType();
        fetchUserCompaniesByApplication();
        fetchCatalogues();
       
        // setIsReadOnlyUser(isReadyOnly(useLmsStore, 'ac.view'));
        if (personSettings.data && hasPermission(useLmsStore, 'pe.add')===false){
            //console.log('no pe.add');
            setIsReadOnlyUser(true);
        };       
        fetchUserRoles();
    }, []);

    const onInactiveChange = (event) => {
        setTableInactive(!tableInactive);    
    }

    //fix issue with duplicates (delete when the server api is fixed) RM
    function getUnique(arr, index) {

        const unique = arr
             .map(e => e[index])      
             // store the keys of the unique objects
             .map((e, i, final) => final.indexOf(e) === i && i)
        
             // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);      
      
            return unique;
          //  return unique.filter(a=> a.firstName==='Jayne');         
    }
    
    const onGlobalFilterChange = (newSearchString) => {
        setSearchString(newSearchString);
    };
    
    const onEmployeeIdSearchChange = (newEmployeeIdSearchString) => {
        setEmployeeIdSearchString(newEmployeeIdSearchString);
    };

    const customGlobalFilterFn = (value, searchString) => {        
        if (typeof value === "string" && typeof searchString === "string") {
          return value?.toLowerCase().includes(searchString.toLowerCase());
        }
        return false;
    };

    useEffect(() => {
        let keys = Object.keys(rowSelection);
        if (keys.length === 1 && rowSelection[keys[0]] === true) {
            let id = parseInt(keys[0]);            
            if (users) {                
                setDataSelected(users.find(x => x.id === id));
            } else {
                setDataSelected(null);
            }
        } else {
            setDataSelected(null);
        }
    }, [rowSelection]);


   /* const withSelectedRows = (tableData, inactive) => {
               
        return getUnique(
            (tableData ?? []).filter((e) => {
                const companyName = e.companyName ? e.companyName.toLowerCase() : '';
                const manager = e.manager ? e.manager.toLowerCase() : '';
                const employeeId = e.employeeId || '';
                const RoleName = e.RoleName || '';
                const searchString = ""

                return (
                    (inactive || e.isDeleted !== true) &&
                    (
                        companyName.includes(searchString.toLowerCase()) ||
                        manager.includes(searchString.toLowerCase()) ||
                        RoleName.includes(searchString.toLowerCase()) ||
                        employeeId.toLowerCase().includes(employeeIdSearchString?.toLowerCase() || '')
                    )
                );
            }),
            "id"
        );
    };
   */
  
    //fix issue with duplicates (delete when the server api is fixed) RM
    function getUnique(arr, index) {

        const unique = arr
             .map(e => e[index])
      
             // store the keys of the unique objects
             .map((e, i, final) => final.indexOf(e) === i && i)
        
             // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);      
      
            return unique;
          //  return unique.filter(a=> a.firstName==='Jayne');
         
    }

    const withSelectedRows = (tableData, inactive) =>  getUnique((tableData??[]).filter(e => inactive || e.isDeleted !== true),"id");

    const columns = useMemo(
        () => [
            {
                accessorKey: "searchTerms",
                header: "User Data",    
                Cell: ({ cell, row }) => {
                    return (<>
                        <span style={{color: 'gray',  fontSize: '18px'}}>
                            <b>
                                {row.original.firstName || ''} {row.original.lastName || ''}
                            </b>
                        </span> <br />
                        <b>Employee Id:</b> {row.original.employeeId || 'None'}<br />
                        <b>Company:</b> {row.original.companyName || 'None'}<br />
                        <b>Email:</b> {row.original.emailAddress || 'None'}<br />
                    </>)                   
                }
            }
        ],
        [],
    );
/**
 * <AppAvatar
                            size={40}
                            name={row.original === undefined ? " " : row.original.firstName + " " + row.original.lastName + " " + row.original.employeeId + " " +
                                row.original.manager + " " + row.original.RoleName + " " + row.original.EmploymentTypeName + " " + row.original.emailAddress}
                            src={row.original.photograph}
                        /> 
 */
    return (
        <BaseContainer title='Users'>
            <TableContainer>
                <Row>
                    <Col style={{ maxWidth: '500px', minWidth: '400px' }} className='border-end'>
                        <>
                        <div className="mt-2 mx-2 form-check form-switch">
                                <input type="checkbox" id="isActive" className="form-check-input" onChange={onInactiveChange} />
                                <label title="" htmlFor="isActive" className="form-check-label">Include Inactive</label>
                            </div>                     
                        </>
                        <MaterialReactTable
                            columns={columns}
                            data={withSelectedRows(sortedUsers, tableInactive) || []}                          
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableGrouping={false}
                            enableHiding={false}
                            enablePagination={true}
                            enableBottomToolbar={true}
                            enableSorting={false}
                            enableTopToolbar={true}
                            enableFilterMatchHighlighting={true}
                            globalFilterFn="contains"//By default is fuzzy
                            //globalFilterFn={customGlobalFilterFn}
                            enableGlobalFilter={true} 
                            muiTableHeadProps={{
                                sx: {
                                    display: 'none'
                                }
                            }}                         
                            getRowId={(originalRow) => originalRow.id}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () =>
                                    setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                                hover: true,
                                selected: rowSelection[row.id],
                                sx: {
                                    cursor: 'pointer',
                                },
                            })}
                            onGlobalFilterChange={setSearchString} //zc - new for custom search                            
                            onColumnFilterChange={onEmployeeIdSearchChange} // For employee ID search                          
                            muiTableContainerProps={{ sx: { maxHeight: 'max(242px, calc(100vh - 405px))' } }}
                            renderToolbarInternalActions={({ table }) => (
                                <>
                                    {isReadyOnlyUser==false? 
                                <>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add</Tooltip>}>
                                        <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAdd()}>
                                            <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                        </Button>
                                    </OverlayTrigger>
                                </>
                                    :<></>
                                }
                                </>
                            )}
                            muiToolbarAlertBannerProps={{
                                sx: {
                                    display: 'none'
                                }
                            }}
                            initialState={{
                                pagination: { pageIndex: 0, pageSize: 10 },
                            
                                globalFilter: searchString, //zc - new for custom search
                                showGlobalFilter: true //This displays the seach generic way for the materia react table
                            }}
                            state={{
                                isLoading: !users,
                                globalFilter: searchString,//zc - new for custom search
                            }}
                        />
                    </Col>
                    <Col>
                        {showForm ?
                            <FormUser user={user}
                                userStatus={userStatus}
                                employeeTypes={employeeTypes}
                                companiesByApplication={companiesByApplication}
                                catalogues={catalogues}
                                userRoles={userRoles}
                                />
                            :
                            <span></span>
                        }
                    </Col>
                </Row>
            </TableContainer>
        </BaseContainer>
    );
}
