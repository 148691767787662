import BaseContainer from "../components/BaseContainer";
import { BeginButton } from '../components/CustomButtons'
import { useEffect,  useState } from 'react';
import { Row } from 'react-bootstrap';
import useLmsStore, { hrefToAdminServer,hasPermission } from '../hooks/useLmsStore';
import shallow from 'zustand/shallow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard,faUser } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
function ExternalTitle(props) {
    return (<>{props.title} <FontAwesomeIcon size='xs' icon={faArrowUpRightFromSquare} className='text-mutted' /></>);
};
export default function AccessCardDashboard(props) {
       const { personBasic,   fetchPersonBasic } = useLmsStore(state =>
        ({
            personBasic: state.personBasic.data,
            fetchPersonBasic: state.fetchPersonBasic,         

        }), shallow)
    useEffect(() => {
        
    //    setHasPermission(handlePermission(useLmsStore, 'ac.view'));
    }, []);

    
    return (<BaseContainer title='Access Card Dashboard'>
        <div style={{marginTop:'10px', maxHeight: '650px', overflow: 'auto', overflowX: 'hidden'}}>
        <Row className='gx-4 gy-4 mt-0'>
        <BeginButton title='Users' description='View user and upload user photo.' icon={faUser} to='/lms/users' />                
          { hasPermission(useLmsStore, 'ac.view')===true? <>
        <BeginButton title={<ExternalTitle title='Access Card' />} description='View, print, activate, re-issue and deactivate access cards.' icon={faAddressCard} href={hrefToAdminServer(personBasic, 'portal.admin.accessCards')} target='admin-tab' />
            </>: <></>}
        </Row>
        </div>
    </BaseContainer>);
}