import { Nav } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Badge from 'react-bootstrap/Badge';
import LinkContainer from '../../_libs/react-router-bootstrap/LinkContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button';
export function SideBarItem(props) {
    return (
        <>
         { (props.to) ?
            <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{props.label}</Tooltip>}                >
                <Nav.Item>
              
                    <LinkContainer to={props.to} isActive={props.isActive}>
                        <Nav.Link>
                            <FontAwesomeIcon icon={props.icon} />
                        </Nav.Link>
                    </LinkContainer>                    
                </Nav.Item>
            </OverlayTrigger>
              :
              <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{props.label}</Tooltip>}                >
              <Nav.Item>
              <Button  variant='clear' target='_blank'  href={props.href}> <FontAwesomeIcon icon={props.icon}  /></Button>
                             
          </Nav.Item>
      </OverlayTrigger>
}
        </>
    );
}

export function SideBarItemPill(props) {
    return (
        <>
            <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{props.label}</Tooltip>}                >
                <Nav.Item style={{ position: 'relative' }}>
                    <LinkContainer to={props.to} isActive={props.isActive}>
                        <Nav.Link>
                            <FontAwesomeIcon icon={props.icon} />
                            {props.countVariant && props.count !== null ? <Badge pill bg={props.countVariant} className={props.countClass}>{props.count}</Badge> : null}
                        </Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </OverlayTrigger>
        </>
    );
}
