import React from 'react';
import {Button} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

function SaveAndCancelButton({isLoading, onSave = () =>null, onCancel = () =>null, className=""}) {
    return <div className={`d-flex justify-content-between w-100 ${className}`}>
        <Button
            className={"mx-2"}
            onClick={() => {
                onSave()
            }}
            disabled={isLoading}
        >
            {isLoading ?
                <><Spinner animation="border" variant="light" size="sm" /> Save</>
                :
                "Save"
            }
        </Button>
        <Button
            disabled={isLoading}
            onClick={()=>{onCancel()}}
            variant={"light"}
        >
            {isLoading ?
                <><Spinner animation="border" variant="light" size="sm" /> Cancel</>
                :
                "Cancel"
            }
        </Button>
    </div>
}

export default SaveAndCancelButton;
