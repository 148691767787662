import { useState, useEffect } from 'react';
import { Container, Row, Col, Alert, Button, Modal } from "react-bootstrap";
import useLmsStore, { responseData, getCatalogueInfo, postCatalogue, FORMCONFIRM } from "../hooks/useLmsStore";

import Multiselect from 'multiselect-react-dropdown';
import { usePrompt } from '../hooks/usePrompt'

const FormCatalogue = ({catalogue, allLearningItems, applicationGroups, applicationPeople}) => {
    const [catalogueInfo, setCatalogueInfo] = useState([]);
    const [catalogueLoading, setCatalogueLoading] = useState(false);
    const [catalogueError, setCatalogueError] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [learningList, setLearningList] = useState([]);
    const [appGList, setAppGList] = useState([]);
    const [appPList, setAppPList] = useState([]);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [selectedListCopy, setSelectedListCopy ] = useState([]);
    const [removedItemCopy, setRemovedItemCopy ] = useState([]);
    const [removeId, setRemoveId ] = useState(0);
    const [title, setTitle ] = useState("");
    const [isActive, setIsActive ] = useState(false);
    const [applicationId, setApplicationId ] = useState(0);
    const [showDialog, setShowDialog] = useState(false);

    const handleFormLoad = () => {
        setCatalogueError(false);
        setCatalogueLoading(true);
        setShowDialog(false);
        if(catalogue && catalogue.id){
            getCatalogueInfo(catalogue.id).then((response) => {
                let data = responseData(response, []);
                if (data.title) {
                    setLearningList(data.catLearningItems.map(function(c){ return {'title' : c.code.concat(" - ",c.title), 'id' : c.id }}));
                    setAppGList(data.catGroups.map(function(c){ return {'title' : c.name, 'id' : c.id }}));
                    setAppPList(data.catPeople.map(function(c){ return {'title' : c.fullName.concat(" - ", c.employeeId ? c.employeeId : "none"), 'id' : c.id }}));
                    //if it is an update!
                    setCatalogueInfo(data);
                    setTitle(data.title?data.title:"");
                    setIsActive(data.isActive);
                    //console.log(data.applicationId);
                    setApplicationId(data.applicationId);
                    setCatalogueLoading(false);
                }
                if(data.statusCode !== 200 || !data.value)
                {
                    setCatalogueError(true);
                }
            });
        } else{
            setTitle("");
            setLearningList([]);
            setAppGList([]);
            setAppPList([]);

            //if it is a new catalogue!
            setCatalogueInfo({
                catalogueApplicationId:-1,
                applicationId:applicationId,
                isActive:false,
                isDeleted:false,
                catLearningItems:[],
                catGroups:[],
                catPeople:[],
                portalEnvironment:null
            });
            setCatalogueLoading(false);
        }
    };

    const handleSubmit = async event => {
        if (!inProgress) {
            let ctgData= catalogueInfo;
            ctgData["title"]=title;
            ctgData["isActive"] = isActive;
            setInProgress(true);
            await postCatalogue(useLmsStore, ctgData);
            setInProgress(false);
            setShowDialog(false);
        }
    };

    const handleCloseConfirmDelete = (event) => {
        handleFormLoad();
        setShowConfirmDelete(false);
    };

    const onLISelect = (selectedList, selectedItem) => {
        let ctgData= catalogueInfo;
        ctgData.catLearningItems.push({title: selectedItem.title, id: selectedItem.id, joinId: -1});
        setCatalogueInfo(ctgData);
        setLearningList(selectedList);
        setShowDialog(true);
    };
    
    const onLIRemove = (selectedList, removedItem) => {
        setShowConfirmDelete(true);
        setSelectedListCopy(selectedList);
        setRemovedItemCopy(removedItem); 
        setRemoveId(1);
        setShowDialog(true);
    };

    const onAGSelect = (selectedList, selectedItem) => {
        let ctgData= catalogueInfo;
        ctgData.catGroups.push({name: selectedItem.title, id: selectedItem.id, catGroupId: -1});
        setCatalogueInfo(ctgData);
        setAppGList(selectedList);
        setShowDialog(true);
    };
    
    const onAGRemove = (selectedList, removedItem) => {
        setShowConfirmDelete(true);
        setSelectedListCopy(selectedList);
        setRemovedItemCopy(removedItem); 
        setRemoveId(2);
        setShowDialog(true);
    };

    const onAPSelect = (selectedList, selectedItem) => {
        let ctgData= catalogueInfo;
        ctgData.catPeople.push({fullName: selectedItem.title, id: selectedItem.id, catPersonId: -1});
        setCatalogueInfo(ctgData);
        setAppPList(selectedList);
        setShowDialog(true);
    };
    
    const onAPRemove = (selectedList, removedItem) => {
        setShowConfirmDelete(true);
        setSelectedListCopy(selectedList);
        setRemovedItemCopy(removedItem); 
        setRemoveId(3);
        setShowDialog(true);
    };

    const onRemove = () => {
        if(removeId===1){
            let ctgData= catalogueInfo;
            const newlist = ctgData.catLearningItems.filter((item) => item.id !== removedItemCopy.id);
            ctgData.catLearningItems = newlist
            setCatalogueInfo(ctgData);
            setLearningList(selectedListCopy);
        } else if(removeId===2){
            let ctgData= catalogueInfo;
            const toRemove = (element) => element.id === removedItemCopy.id;
            let idx = ctgData.catGroups.findIndex(toRemove)
            ctgData.catGroups.splice(idx, 1);
            setCatalogueInfo(ctgData);
            setAppGList(selectedListCopy);
        }
        else if(removeId===3){
            let ctgData= catalogueInfo;
            const toRemove = (element) => element.id === removedItemCopy.id;
            let idx = ctgData.catPeople.findIndex(toRemove)
            ctgData.catPeople.splice(idx, 1);
            setCatalogueInfo(ctgData);
            setAppPList(selectedListCopy);
        }
        setRemoveId(0);
        setShowConfirmDelete(false);
    }

    useEffect(() => {
        if(showDialog)
        {
            let confirm = window.confirm(FORMCONFIRM);
            if(confirm){
                handleFormLoad();
            }
        }
        else{
            handleFormLoad();
        }
    }, [catalogue]);

    usePrompt(FORMCONFIRM, showDialog);

    return (
        catalogueLoading? catalogueError? 
        <> 
            <Alert variant="danger" >
                <Alert.Heading>Oh snap! No data!</Alert.Heading>
                <p>
                No data was returned for this user!
                </p>
            </Alert>
        </>
        :
        <> 
        <div className="d-flex justify-content-center">
            <div className="spinner-border mt-5 text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        </>
        :
        <>
        <Container fluid>
            <h3>Catalogue Details</h3>  
            <hr/>
            <Row>
                <fieldset disabled={inProgress} style={{display : "flex"}} className="mb-3">
                    <div className="w-100 row">
                        <label htmlFor="title" className="form-label col-form-label col-sm-2">Catalogue Title</label>
                        <div className="col-sm-10">
                            <div className="input-group has-validation">
                                <input placeholder="Catalogue Title" type="input" id="title" className="form-control" value={title} onChange={e => setTitle(e.target.value)}/>
                                <div className="invalid-feedback">Please catalogue title.</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="mt-2 mx-3 form-check form-switch">
                            <input type="checkbox" id="active" className="form-check-input" checked={isActive } onChange={e => setIsActive(e.target.checked)} />
                            <label title="" htmlFor="active" className="form-check-label">Active</label>
                        </div>
                    </div>
                </fieldset>
            </Row>
            <Row>
                <Col sm={4}>
                    <Row>
                        <h5>Learning Items</h5>
                        <Multiselect
                        options={allLearningItems? allLearningItems.map(function(c){ return {'title' : c.code.concat(" - ",c.title), 'id' : c.id }}): []}
                        displayValue="title"
                        selectedValues={allLearningItems?learningList:[]}
                        onSelect={onLISelect} // Function will trigger on select event
                        onRemove={onLIRemove} // Function will trigger on remove event
                        className="pre-scrollable-xl"
                        placeholder="Select/Add"
                        showCheckbox={true}
                        />
                    </Row>
                </Col>
                <Col sm={4}>
                <Row>
                    <h5>Assigned Groups</h5> 
                            <Multiselect
                            options={applicationGroups? applicationGroups.map(function(c){ return {'title' : c.name, 'id' : c.id }}): []}
                            displayValue="title"
                            selectedValues={appGList}
                            onSelect={onAGSelect} // Function will trigger on select event
                            onRemove={onAGRemove} // Function will trigger on remove event
                            className="pre-scrollable-xl"
                            placeholder="Select/Add"
                            showCheckbox={true}
                            />
                    </Row>
                </Col>
                <Col sm={4}>
                    <Row>
                    <h5>Assigned Users</h5> 
                            <Multiselect
                            options={applicationPeople? applicationPeople.map(function(c){ return {'title' : c.fullName.concat(" - ", c.email ? c.email : "none"), 'id' : c.id }}): []}
                            displayValue="title"
                            selectedValues={appPList}
                            onSelect={onAPSelect} // Function will trigger on select event
                            onRemove={onAPRemove} // Function will trigger on remove event
                            className="pre-scrollable-xl"
                            placeholder="Select/Add"
                            showCheckbox={true}
                            />
                    </Row>
                </Col>
            </Row>
            <Button variant='primary' className="mt-2 float-right" onClick={handleFormLoad}>Cancel</Button>
            {inProgress || catalogueLoading ?
                <Button variant="primary" disabled className="mx-2 mt-2 float-right">
                    <span className="spinner-border spinner-border-sm mt-1" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                    Please wait...
                </Button>
                :
                <Button variant="primary" className="mx-2 mt-2 float-right"  onClick={handleSubmit}>
                    Save
                </Button>
            }
        </Container>
        <Modal fullscreen='md-down' show={showConfirmDelete} onHide={handleCloseConfirmDelete} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>You are about to remove {removedItemCopy.title?removedItemCopy.title:removedItemCopy.name? removedItemCopy.name:removedItemCopy.fullName?removedItemCopy.fullName:'' } from {catalogueInfo.title} do you wish to continue?</h6>
                <Button variant='primary px-3 w-25 mx-3' onClick={onRemove} >Yes</Button>
                <Button variant='primary px-3 w-25 mx-3 float-right' onClick={handleCloseConfirmDelete} >No</Button>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default FormCatalogue;