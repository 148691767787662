import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {apiAxios} from "../../../hooks/useLmsStore";
import {getAdminPortalRpCall2} from "../../../hooks/useLegacyStore";
import SaveAndCancelButton from "../SaveAndCancelButton";
import GroupManagementTabSearchField from "../GroupManagementTabSearchField";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import {TableContainer} from "../../../helpers/tableHelper";
import MaterialReactTable from "material-react-table";
import GenericCheckBox from "../../common/GenericCheckBox";

const pageSize = 30

const initialSearchPayload = {
    groupId: null, filter: "", itemCount: pageSize, itemOffset: 0
}

const payload = {
    groupId: -1,
    Name: "",
    Description: "",
    Reports: [],
    GroupMembers: [],
    TrainingCards: [],
    Permissions: [],
    LearningItems: [],
    Groups: []
}

const createGroupApiCall = (payload) => {
    return apiAxios.post(getAdminPortalRpCall2('/api/groupManagement'), payload)
}

function LearningPathwaysTab({group, onSuccessUpdateGroup}) {
    const [searchTerm, setSearchTerm] = useState("")
    const [searchPayload, setSearchPayload] = useState({
        ...initialSearchPayload,
        groupId: group?.Id,
    })
    const [searchCount, setSearchCount] = useState(0)
    const [searchResults, setSearchResults] = useState([])
    const hasMore = (searchPayload.itemOffset) <= searchCount
    const [payloadForCards, setPayloadForCards] = useState([])
    const [isUpdating, setIsUpdating] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onUpdate = async () => {
        try {
            setIsUpdating(true)
            await createGroupApiCall({
                ...payload,
                groupId: group.Id,
                TrainingCards: payloadForCards,
                Name: group?.Name,
                Description: group?.Description,
            })
            setIsUpdating(false)
            await onSuccessUpdateGroup(group)
        } catch (e) {
            setIsUpdating(false)
        }
    }

    const getPathwaysForActiveGroup = async () => {
        try {
            if (group) {
                setIsLoading(true)
                const result = await apiAxiosCalls.getCardsBelongingToGroup({
                    ...searchPayload, groupId: group.Id
                })
                setSearchCount(result.data.Value.Count)
                setSearchResults([...searchResults, ...result.data.Value.Items])

                setSearchPayload({...searchPayload, itemOffset: searchPayload.itemOffset + pageSize})
            }


        } catch (e) {

        } finally {
            setIsLoading(false)
        }
    }


    const [triggerSearch, setTriggerSearch] = useState(0)

    const renderCell = (cell) => {

        return cell.row.original ? <GenericCheckBox
            payloadPart={payloadForCards}
            setPayloadPart={setPayloadForCards}
            singleSearchResult={cell.row.original}
        /> : <></>
    }

    const columns = useMemo(() => {
        return [

            {
                header: ' ',
                Cell: ({cell}) => renderCell(cell),
                size:10,
                muiCellProps: {
                    style: {
                        maxWidth: '10px',
                        display:"none"
                    },
                },

            },
            {
                header: 'Title',
                Cell: ({cell}) => {
                    return <div>
                        <div>
                            {cell.row.original.Title}
                        </div>
                    </div>
                }
            }
        ]
    }, [payloadForCards, setPayloadForCards, searchResults])

    const topToolbarStyles = {
        minHeight: isLoading ? '4px' : 'auto',
        padding: isLoading ? '0' : '0.5rem 1rem',
    };

    const tableContainerRef = useRef(null);
    const fetchMoreOnBottomReached = useCallback((containerRefElement) => {

        if (apiAxiosCalls.getCardsBelongingToGroupCancellationToken) {
            return
        }
        if (containerRefElement) {
            const {scrollHeight, scrollTop, clientHeight} = containerRefElement;

            if (
                scrollHeight - scrollTop - clientHeight < 20 && hasMore

            ) {
                getPathwaysForActiveGroup();
            }
        }
    }, [tableContainerRef, hasMore, getPathwaysForActiveGroup])

    const renderTable = useCallback(() => {
        return <TableContainer >
            <MaterialReactTable
                columns={columns}
                data={searchResults}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                muiTableBodyRowProps={{hover: false}}
                enableBottomToolbar={false}
                enableStickyHeader
                enableRowVirtualization={false}
                muiTopToolbarProps={{
                    style: topToolbarStyles,
                }}
                muiTableContainerProps={{
                    ref: tableContainerRef,
                    sx: { maxHeight: '400px'},
                    onScroll:(e)=>{
                        fetchMoreOnBottomReached(e.target)
                    }
                }}
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',

                    },
                }}
                state={{ showProgressBars: isLoading }}
            />
        </TableContainer>
    },[payloadForCards,searchResults,searchCount,searchTerm,setPayloadForCards,isLoading])

    const onSearchClick = () => {
        setSearchResults([])
        setSearchCount(0)
        setSearchPayload({
            ...initialSearchPayload, filter: searchTerm
        })
        setTriggerSearch(triggerSearch + 1)

    }

    useEffect(() => {
        if (triggerSearch) {
            getPathwaysForActiveGroup()
        }

    }, [triggerSearch])

    useEffect(() => {
        setSearchResults([])
        setSearchCount(0)
        setSearchTerm("")
        setSearchPayload({
            ...initialSearchPayload
        })
        setTriggerSearch(triggerSearch + 1)
    }, [group])

    return (<div>
        <GroupManagementTabSearchField onSearchClick={onSearchClick} setSearchTerm={setSearchTerm} searchTerm={searchTerm} placeHolder={"Search pathways"} />
        <div className={"d-flex flex-column align-items-center justify-content-center"}>
            {renderTable()}
        </div>
        <SaveAndCancelButton
            className={"mt-2"}
            isLoading={isUpdating}
            onSave={() => {
                onUpdate()
            }}
            onCancel={() => {setPayloadForCards([])}}
        />
    </div>);
}

export default LearningPathwaysTab;
