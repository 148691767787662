import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import SaveAndCancelButton from "../SaveAndCancelButton";
import GroupManagementTabSearchField from "../GroupManagementTabSearchField";
import {apiAxiosCalls} from "../../../services/apiAxiosCalls";
import {TableContainer} from "../../../helpers/tableHelper";
import GenericCheckBox from "../../common/GenericCheckBox";
import MaterialReactTable from "material-react-table";

const pageSize = 30

const initialSearchPayload = {
    groupId: null, filter: "", itemCount: pageSize, itemOffset: 0
}

const payload = {
    groupId: -1,
    Name: "",
    Description: "",
    Reports: [],
    GroupMembers: [],
    TrainingCards: [],
    Permissions: [],
    LearningItems: [],
    Groups: []
}

function PermissionsTab({group, onSuccessUpdateGroup}) {

    const [searchTerm, setSearchTerm] = useState("")
    const [searchPayload, setSearchPayload] = useState({
        ...initialSearchPayload,
        groupId: group?.Id,
    })
    const [searchCount, setSearchCount] = useState(0)
    const [searchResults, setSearchResults] = useState([])
    const hasMore = (searchPayload.itemOffset) <= searchCount
    const [payloadForPermissions, setPayloadForPermissions] = useState([])
    const [isUpdating, setIsUpdating] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onUpdate = async () => {
        try {
            setIsUpdating(true)
            await apiAxiosCalls.createGroupApiCall({
                ...payload,
                groupId: group.Id,
                Permissions: payloadForPermissions,
                Name: group?.Name,
                Description: group?.Description,
            })
            await onSuccessUpdateGroup(group)
            setIsUpdating(false)
        } catch (e) {
            setIsUpdating(false)
        }
    }

    const getPermissionsForActiveGroup = async () => {

        try {
            if (group) {
                setIsLoading(true)
                const result = await apiAxiosCalls.searchPermissions({
                    ...searchPayload, groupId: group.Id
                })
                setSearchCount(result.data.Value.Count)
                setSearchResults([...searchResults, ...result.data.Value.Items])

                setSearchPayload({...searchPayload, itemOffset: searchPayload.itemOffset + pageSize})
            }


        } catch (e) {
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }

    const [triggerSearch, setTriggerSearch] = useState(0)

    const onSearchClick = () => {
        setSearchResults([])
        setSearchCount(0)
        setSearchPayload({
            ...initialSearchPayload, filter: searchTerm
        })
        setTriggerSearch(triggerSearch + 1)
    }

    useEffect(() => {
        if (triggerSearch) {
            getPermissionsForActiveGroup()
        }

    }, [triggerSearch])

    useEffect(() => {
        setSearchResults([])
        setSearchCount(0)
        setSearchTerm("")
        setSearchPayload({
            ...initialSearchPayload
        })
        setTriggerSearch(triggerSearch + 1)
    }, [group])

    const renderCell = (cell) => {

        return cell.row.original ? <GenericCheckBox
            payloadPart={payloadForPermissions}
            setPayloadPart={setPayloadForPermissions}
            singleSearchResult={cell.row.original}
        /> : <></>
    }


    const columns = useMemo(() => {
        return [

            {
                header: ' ',
                Cell: ({cell}) => renderCell(cell),
                size:10,
                muiCellProps: {
                    style: {
                        maxWidth: '10px',
                        display:"none"
                    },
                },

            },
            {
                header: 'Name',
                Cell: ({cell}) => {
                    return <div>
                        <div className={"fw-bold"}>
                            {cell.row.original.Name}
                        </div>
                        <div>
                            {cell.row.original.Description}
                        </div>
                    </div>
                }
            }
        ]
    }, [payloadForPermissions, setPayloadForPermissions, searchResults])

    const tableContainerRef = useRef(null);
    const fetchMoreOnBottomReached = useCallback((containerRefElement) => {

        if (apiAxiosCalls.searchPermissionsCancellationToken) {
            return
        }
        if (containerRefElement) {
            const {scrollHeight, scrollTop, clientHeight} = containerRefElement;
            if (
                scrollHeight - scrollTop - clientHeight < 20 && hasMore

            ) {
                getPermissionsForActiveGroup();
            }
        }
    }, [tableContainerRef, hasMore, getPermissionsForActiveGroup])

    const topToolbarStyles = {
        minHeight: isLoading ? '4px' : 'auto',
        padding: isLoading ? '0' : '0.5rem 1rem',
    };
    const renderTable = useCallback(() => {
        return <TableContainer>
            <MaterialReactTable
                columns={columns}
                data={searchResults}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                muiTopToolbarProps={{
                    style: topToolbarStyles,
                }}
                muiTableBodyRowProps={{hover: false}}
                enableBottomToolbar={false}
                enableStickyHeader
                enableRowVirtualization={false}
                muiTableContainerProps={{
                    ref: tableContainerRef,
                    sx: {maxHeight: '400px'},
                    onScroll: (e) => {
                        fetchMoreOnBottomReached(e.target)
                    }
                }}

                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',

                    },
                }}
                state={{showProgressBars: isLoading}}
            />
        </TableContainer>
    }, [payloadForPermissions, searchResults, searchCount, searchTerm, setPayloadForPermissions, isLoading])

    return (
        <div className={''}>
            <GroupManagementTabSearchField onSearchClick={onSearchClick} setSearchTerm={setSearchTerm}
                                           searchTerm={searchTerm} placeHolder={"Search permissions"}/>
            <div className={"d-flex flex-column align-items-center justify-content-center"}>
                {renderTable()}
                <SaveAndCancelButton
                    className={"mt-2"}
                    isLoading={isUpdating}
                    onSave={() => {
                        onUpdate()
                    }}
                    onCancel={() => setPayloadForPermissions([])}
                />
            </div>
        </div>
    );
}

export default PermissionsTab;
