import React from 'react';
import {Form, InputGroup} from "react-bootstrap";
import SaveAndCancelButton from "../SaveAndCancelButton";

function GroupDetailTab({group, onChange = () => null, onCreate = () => null, isUpdatingOrCreatingGroup}) {
    return <div className={"px-1 mr-auto"}>
        <Form>
            <Form.Group className="mb-3  " controlId="Name">
                <Form.Label>Group name</Form.Label>
                <InputGroup>
                    <Form.Control name={"Name"} type="input" placeholder="Group name" value={group?.Name || ""}
                                  className="no-border"
                                  onChange={(e) => {
                                      onChange(e)
                                  }}/>
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3  " controlId="Description">
                <Form.Label>Group description</Form.Label>
                <InputGroup className={""}>
                    <Form.Control name={"Description"} as="textarea" placeholder="Group description"
                                  value={group?.Description || ""}
                                  className="no-border"
                                  onChange={(e) => {
                                      onChange(e)
                                  }}/>
                </InputGroup>
            </Form.Group>
        </Form>
        <SaveAndCancelButton
            isLoading={isUpdatingOrCreatingGroup}
            onSave={() => {
                onCreate()
            }}
            onCancel={() => null}
        />

    </div>
}

export default GroupDetailTab;
