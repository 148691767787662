import React, {  useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { useSearchParams } from "react-router-dom";

const validateJwt = (token) => {
    try {
        let base64Url = token.split('.')[1],
            base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
            jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join('')),
            accessToken = JSON.parse(jsonPayload);
        if (accessToken.isSAML === true || (Date.now() > accessToken.exp * 1000)) {
            throw new Error('Invalid token');
        }
    } catch (e) {
        console.error(e);
        console.dir(token);
        throw e;
    }
};

export default function Saml() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { login } = useAuth();
    useEffect(() => {
        const totenStr = searchParams.get("token");
        if(window.localStorage.getItem('token') !== totenStr && totenStr){
            //validateJwt(totenStr);
            login(totenStr);
        } 

        window.location.href = '/lms/dashboard';
        //<p>{searchParams.get("token")}</p><p>{window.localStorage.getItem('token')}</p>
       
    }, []);

    return (
        <></>
    );
}