import { useState  } from 'react';
import { Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { toast } from 'react-toastify';
const ModalForgottenPassword = ({ closeFn = () => null, open = false}) => {
    const LOGIN_TIMEOUT = process.env.NODE_ENV === 'development' ? 180000 : 60000;
    const [email, setEmail] = useState("");
    const [inProgressPass, setInProgressPass] = useState(false);
    const [mainError, setMainError] = useState(null);
    const [validated, setValidated] = useState(false);
 

    function handleError(e) {
        setMainError(e.message);
        setValidated(false);
        setInProgressPass(false);
        console.error(e);
    }
   
    const handleChangeEmail = (event) => {
        setMainError("");
        setEmail(event.target.value);
    };

    
    /*
     Submit forgotten password
    */
     function handleSendForgotPsw(event) {

        try {
            const form = event.currentTarget;           
            if (!inProgressPass  && form.checkValidity() === true) {
                setInProgressPass(true);
                setValidated(true);
                axios({
                    method: 'post',
                    url: '/api/account/ResetPassword/' + email,
                    timeout: LOGIN_TIMEOUT,
                    data: undefined
                })
                    .then((result) => {
                        try {
                            if (result.status === 204) {
                                throw new Error('Invalid email address or password');
                            }
                          
                            toast.success("Your request has been processed successfully. You will receive an email with instructions of how to change your password", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });

                            setInProgressPass(false);

                            closeFn();
                           
                        } catch (e1) {
                            handleError(e1);
                        }
                    }).catch(handleError);
            }
        } catch (e2) {
            handleError(e2);
        }
        event.preventDefault();
        event.stopPropagation();
    };

    function ShowForgottenPassword() {  
        return (
           
                <Form name="frmForgottenPassword" id="frmForgottenPassword" noValidate validated={validated} onSubmit={handleSendForgotPsw}>
                <Form.Group className="mb-3" controlId="formEmail">
             
                    <Form.Label>Email address</Form.Label>
                    <Form.Control required  type="email"  placeholder="Enter an email address"  onChange={handleChangeEmail}   />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid email.
                    </Form.Control.Feedback>
                </Form.Group>  
                 {inProgressPass ?
                <Button variant="primary" className="w-100" disabled>
                    <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                    Please wait...
                </Button>   
                :                      
                <Button type="submit" variant="primary"  disabled={inProgressPass}>
                    Submit
                </Button>
              }
            {mainError && (<p className="text-danger">{mainError}</p>)}
           
            </Form>
        );
    }

    return (
        <Modal fullscreen='md-down' show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Please provide the email address used when creating your account and then press 'Send'.</p>
            <p>On receipt of your request we'll email you instructions on how to complete the password reset process.</p>                
      
            {ShowForgottenPassword()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalForgottenPassword;