import { cloneElement, useEffect, useState } from 'react';

function setData(base, baseOpposite, added, addedOpposite, exclusiveSelection, selected, setDataFn, unidPropertyName, debugLabel, debugLabelOpposite) {
    if (exclusiveSelection) {
        let data = base.filter(x => !addedOpposite.includes(x[unidPropertyName])).concat(baseOpposite.filter(x => added.includes(x[unidPropertyName]))).map(x => { return { ...x, isRowSelected: selected.includes(x[unidPropertyName]) }; });
        if (debugLabel) console.log(`DataLeftRight.setData${debugLabel}(array length=${data.length}) added${debugLabelOpposite}=${addedOpposite.length} base${debugLabelOpposite}=${baseOpposite.length}`);
        setDataFn(data);
    } else {
        if (debugLabel) console.log(`DataLeftRight.useEffect setData${debugLabel}(array length=${base.length})`);
        setDataFn(base.map(x => { return { ...x, isRowSelected: selected.includes(x[unidPropertyName]) }; }));
    }
}

/**
 * Return an object in the format 
 * 
 *      If exclusiveSelection: { updated: object[], added: object[], removed: int[] | string[], tracker: object }
 *      If not exclusiveSelection: { updated: object[], tracker: object }
 * 
 * @param {object[]} data 
 * @param {integer[] | string[]} updates 
 * @param {integer[] | string[]} added 
 * @param {integer[] | string[]} addedOpposite 
 * @param {boolean} exclusiveSelection 
 * @param {string} unidPropertyName 
 * @returns change details
 */
function getUpdated(data, updates, added, addedOpposite, exclusiveSelection, tracker, unidPropertyName) {
    if (exclusiveSelection) {
        return {
            updated: data.filter(x => updates.includes(x[unidPropertyName])),
            added: data.filter(x => added.includes(x[unidPropertyName])),
            removed: addedOpposite,
            tracker: tracker
        };
    } else {
        return {
            updated: data.filter(x => updates.includes(x[unidPropertyName])),
            tracker: tracker
        };
    }
};

function moveSelected(added, addedOpposite, exclusiveSelection, selectedOpposite, setAddedFn, setAddedOppositeFn, setSelectedOppositeFn, debugLabel, debugLabelOpposite) {
    if (exclusiveSelection) {
        let idsToRemoveFromAdded = selectedOpposite.filter(id => addedOpposite.includes(id)),
            addedData = added.concat(selectedOpposite.filter(id => !idsToRemoveFromAdded.includes(id) && !added.includes(id))),
            addedDataOpposite = addedOpposite.filter(id => !idsToRemoveFromAdded.includes(id));
        if (debugLabel) {
            console.log(`DataLeftRight.moveSelected${debugLabel}...`);
            console.log(`\t[01] setAdded${debugLabel}(array length=${addedData.length})`);
            console.log(`\t[01] setAdded${debugLabelOpposite}(${addedDataOpposite.length})`);
            console.log(`\t[01] setSelected${debugLabelOpposite}(array length=0)`);
        }
        setAddedFn(addedData);
        setAddedOppositeFn(addedDataOpposite);
        setSelectedOppositeFn([]);
    } else {
        console.error('moveSelected can only be used when exclusive selection is set.');
    }
}

function select(ids, data, exclusiveSelection, selected, setSelectedFn, setSelectedOppositeFn, unidPropertyName) {
    if (ids === 'none') {
        setSelectedFn([]);
    } else if (ids === 'all') {
        setSelectedFn(data.map(x => x[unidPropertyName]));
        if (exclusiveSelection) {
            setSelectedOppositeFn([]);
        }
    } else {
        setSelectedFn(selected.concat([].concat(ids || []).filter(x => !selected.includes(x))));
        if (exclusiveSelection) {
            setSelectedOppositeFn([]);
        }
    }
}

function toggle(ids, exclusiveSelection, single, selected, setSelectedFn, setSelectedOppositeFn) {
    if (ids) {
        let idArray = [].concat(ids || []);
        if (single === true) {
            if (idArray.length > 0) {
                let id = idArray[0];
                setSelectedFn(selected.includes(id) ? [] : [id]);
            }
        } else {
            idArray.forEach(id => setSelectedFn(selected.includes(id) ? selected.filter(x => x !== id) : selected.concat(id)));
        }
    }
    if (exclusiveSelection) {
        setSelectedOppositeFn([]);
    }
}

function updateBase(items, base, selected, updates, setBaseFn, setUpdatesFn, setSelectedFn, unidPropertyName) {
    setBaseFn(base.map(x => items.find(y => y[unidPropertyName] === x[unidPropertyName]) || x));
    let idsUpdated = items.map(x => x[unidPropertyName]).filter(x => !updates.includes(x)),
        idsToRemove = items.filter(x => x.isRowSelected === false).map(x => x[unidPropertyName]);
    if (idsUpdated.length) {
        setUpdatesFn(updates.concat(idsUpdated));
    }
    if (idsToRemove.length) {
        setSelectedFn(selected.filter(x => !idsToRemove.includes(x)));
    }
}

export default function DataLeftRight(props) {
    const [addedLeft, setAddedLeft] = useState([]);
    const [addedRight, setAddedRight] = useState([]);
    const [baseLeft, setBaseLeft] = useState([]);
    const [baseRight, setBaseRight] = useState([]);
    const [dataLeft, setDataLeft] = useState([]);
    const [dataRight, setDataRight] = useState([]);
    const [debug, setDebug] = useState(false);
    const [exclusiveSelection, setExclusiveSelection] = useState(true);
    const [originalLeft, setOriginalLeft] = useState([]);
    const [originalRight, setOriginalRight] = useState([]);
    const [selectedLeft, setSelectedLeft] = useState([]);
    const [selectedRight, setSelectedRight] = useState([]);
    const [tracker, setTracker] = useState({});
    const [updatesLeft, setUpdatesLeft] = useState([]);
    const [unidPropertyNameLeft, setUnidPropertyNameLeft] = useState('id');
    const [unidPropertyNameRight, setUnidPropertyNameRight] = useState('id');
    const [updatesRight, setUpdatesRight] = useState([]);

    useEffect(() => {
        if (Array.isArray(baseLeft) && Array.isArray(baseRight)) {
            setData(baseLeft, baseRight, addedLeft, addedRight, exclusiveSelection, selectedLeft, setDataLeft, unidPropertyNameLeft, debug && 'Left', 'Right');
            setData(baseRight, baseLeft, addedRight, addedLeft, exclusiveSelection, selectedRight, setDataRight, unidPropertyNameRight, debug && 'Right', 'Left');
        }
        // eslint-disable-next-line 
    }, [baseLeft, baseRight, addedLeft, addedRight, selectedLeft, selectedRight, unidPropertyNameLeft, unidPropertyNameRight]);

    useEffect(() => {
        if ((baseLeft || []).length + (baseRight || []).length === 0) {
            setTracker({});
        }
        // eslint-disable-next-line 
    }, [baseLeft, baseRight]);

    /**
     * Allows for ad-hoc tracking of ids
     * 
     * Actions:
     *    "add": Add id to the array "propertyName" if not already in.
     *    "remove": Remove id to the "propertyName" array.
     *    "clear": Clear the array if "propertyName" is specified or all arrays if not
     *    "dataLeft": Return the array data
     * 
     * @param {*} action 
     * @param {*} id 
     * @param {*} propertyName 
     */
    const customTracker = (action, id, propertyName) => {
        let data = tracker;
        if (action === 'clear') {
            if (propertyName) {
                data[propertyName] = [];
                setTracker(data);
            } else {
                setTracker({});
            }
        } else {
            if (propertyName && typeof propertyName !== 'string') {
                throw new Error('propertyName not specified.');
            }
            if (!Array.isArray(data[propertyName])) {
                data[propertyName] = [];
            }
            if (action === 'add' || action === 'remove') {
                if (typeof id !== 'undefined') {
                    if (action === 'add') {
                        if (!data[propertyName].includes(id)) {
                            data[propertyName].push(id);
                        }
                    } else if (action === 'remove') {
                        data[propertyName] = data[propertyName].filter(x => x !== id);
                    }
                    setTracker(data);
                } else {
                    throw new Error('id undefined');
                }
            } else if (action === 'dataLeft') {
                return structuredClone(dataLeft || []).filter(x => (data[propertyName] || []).includes(x[unidPropertyNameLeft]));
            } else if (action === 'dataRight') {
                return structuredClone(dataRight || []).filter(x => (data[propertyName] || []).includes(x[unidPropertyNameRight]));
            } else if (action === 'ids') {
                return data[propertyName];
            } else {
                throw new Error('action not supported. Expected "add", "clear", "dataLeft", "dataRight", "ids" or "remove".');
            }
        }
    };

    const getUpdatedLeft = () => getUpdated(dataLeft, updatesLeft, addedLeft, addedRight, exclusiveSelection, tracker, unidPropertyNameLeft);

    const getUpdatedRight = () => getUpdated(dataRight, updatesRight, addedRight, addedLeft, exclusiveSelection, tracker, unidPropertyNameRight);

    /**
     *      true: Clear 'added', 'selected', 'updated' and reset data to original value
     *      array: Clear 'added', 'selected', 'updated' and set original value to array
     *      false: On Opposite: Clear 'added', 'selected', 'updated'
     *      default: do nothing
     * 
     * @param {*} leftData 
     * @param {*} rightData 
     */
    const initData = (leftData, rightData) => {
        if (debug) {
            console.log(`DataLeftRight.initData(${leftData === true || leftData === false || !leftData ? leftData : `array length=${leftData.length}`}, ${rightData === true || rightData === false || !rightData ? rightData : `array length=${rightData.length}`}) method called (exclusiveSelection=${exclusiveSelection})`);
        }
        if (rightData === false || leftData === true || Array.isArray(leftData)) {
            if (debug) {
                console.log('\t[01] setAddedLeft(array length=0)');
                console.log('\t[01] setSelectedLeft(array length=0)');
                console.log('\t[01] setUpdatesLeft(array length=0)');
            }
            setAddedLeft([]);
            setSelectedLeft([]);
            setUpdatesLeft([]);
        }
        if (leftData === false || rightData === true || Array.isArray(rightData)) {
            if (debug) {
                console.log('\t[02] setAddedRight(array length=0)');
                console.log('\t[02] setSelectedRight(array length=0)');
                console.log('\t[02] setUpdatesRight(array length=0)');
            }
            setAddedRight([]);
            setSelectedRight([]);
            setUpdatesRight([]);
        }
        let _baseLeft = null,
            _baseRight = null;
        if (Array.isArray(leftData)) {
            if (Array.isArray(rightData)) {
                _baseLeft = exclusiveSelection ? leftData.filter(x => !rightData.find(y => y[unidPropertyNameRight] === x[unidPropertyNameLeft])) : leftData;
                _baseRight = exclusiveSelection ? rightData.filter(x => !leftData.find(y => y[unidPropertyNameLeft] === x[unidPropertyNameRight])) : rightData;
            } else if (rightData === true) {
                _baseRight = exclusiveSelection ? originalRight.filter(x => !leftData.find(y => y[unidPropertyNameLeft] === x[unidPropertyNameRight])) : originalRight;
                _baseLeft = exclusiveSelection && _baseRight ? leftData.filter(x => !_baseRight.find(y => y[unidPropertyNameRight] === x[unidPropertyNameLeft])) : leftData;
            } else if (rightData === false) {
                _baseLeft = exclusiveSelection && baseRight ? leftData.filter(x => !baseRight.find(y => y[unidPropertyNameRight] === x[unidPropertyNameLeft])) : leftData;
            } else {
                _baseLeft = exclusiveSelection && rightData ? leftData.filter(x => !rightData.find(y => y[unidPropertyNameRight] === x[unidPropertyNameLeft])) : leftData;
            }
        }
        if (Array.isArray(rightData)) {
            if (leftData === true) {
                _baseLeft = exclusiveSelection ? originalLeft.filter(x => !rightData.find(y => y[unidPropertyNameRight] === x[unidPropertyNameLeft])) : originalLeft;
                _baseRight = exclusiveSelection && _baseLeft ? rightData.filter(x => !_baseLeft.find(y => y[unidPropertyNameLeft] === x[unidPropertyNameRight])) : rightData;
            } else if (leftData === false) {
                _baseRight = exclusiveSelection && baseLeft ? rightData.filter(x => !baseLeft.find(y => y[unidPropertyNameLeft] === x[unidPropertyNameRight])) : rightData;
            } else {
                _baseRight = exclusiveSelection && leftData ? rightData.filter(x => !leftData.find(y => y[unidPropertyNameLeft] === x[unidPropertyNameRight])) : rightData;
            }
        }
        if (Array.isArray(leftData)) {
            if (debug) {
                console.log(`\t[03] setOriginalLeft(array length=${leftData.length})`);
            }
            setOriginalLeft(leftData);
        }
        if (Array.isArray(rightData)) {
            if (debug) {
                console.log(`\t[04] setOriginalRight(array length=${rightData.length})`);
            }
            setOriginalRight(rightData);
        }
        if (_baseLeft) {
            if (debug) {
                console.log(`\t[05] setBaseLeft(array length=${_baseLeft.length})`);
            }
            setBaseLeft(_baseLeft);
        }
        if (_baseRight) {
            if (debug) {
                console.log(`\t[06] setBaseRight(array length=${_baseRight.length})`);
            }
            setBaseRight(_baseRight);
        }
    };

    const moveSelectedLeft = () => moveSelected(addedLeft, addedRight, exclusiveSelection, selectedRight, setAddedLeft, setAddedRight, setSelectedRight, debug && 'Left', 'Right');

    const moveSelectedRight = () => moveSelected(addedRight, addedLeft, exclusiveSelection, selectedLeft, setAddedRight, setAddedLeft, setSelectedLeft, debug && 'Right', 'Left');

    const selectLeft = (ids) => select(ids, dataLeft, exclusiveSelection, selectedLeft, setSelectedLeft, setSelectedRight, unidPropertyNameLeft);

    const selectRight = (ids) => select(ids, dataRight, exclusiveSelection, selectedRight, setSelectedRight, setSelectedLeft, unidPropertyNameRight);

    /**
     * 
     * @param {object} properties - e.g. {exclusiveSelection: false, leftId: 'id', rightId: 'personId'}
     */
    const setOptions = (properties) => {
        let debugMe = properties.debug || debug,
            exclusive = exclusiveSelection,
            leftId = properties.leftId || unidPropertyNameLeft,
            rightId = properties.rightId || unidPropertyNameRight;
        if (properties) {
            if (debugMe !== debug) {
                setDebug(debugMe);
            }
            if (properties.exclusiveSelection === false) {
                exclusive = false;
            } else if (properties.exclusiveSelection === true) {
                exclusive = true;
            }
            if (exclusiveSelection !== exclusive) {
                setExclusiveSelection(exclusive);
            }
            if (exclusive) {
                if (leftId !== rightId) {
                    throw new Error('leftId and rightId must be identical when exclusiveSelection is true.');
                }
            }
            if (leftId !== unidPropertyNameLeft) {
                setUnidPropertyNameLeft(leftId);
            }
            if (rightId !== unidPropertyNameRight) {
                setUnidPropertyNameRight(rightId);
            }
        }
    }

    const toggleLeft = (ids, single) => toggle(ids, exclusiveSelection, single, selectedLeft, setSelectedLeft, setSelectedRight);

    const toggleRight = (ids, single) => toggle(ids, exclusiveSelection, single, selectedRight, setSelectedRight, setSelectedLeft);

    const updateBaseLeft = (items) => updateBase(items, baseLeft, selectedLeft, updatesLeft, setBaseLeft, setUpdatesLeft, setSelectedLeft, unidPropertyNameLeft);

    const updateBaseRight = (items) => updateBase(items, baseRight, selectedRight, updatesRight, setBaseRight, setUpdatesRight, setSelectedRight, unidPropertyNameRight);

    return (<>
        {cloneElement(props.children, {
            customTracker: customTracker,
            dataLeft: dataLeft,
            dataRight: dataRight,
            getUpdatedLeft: getUpdatedLeft,
            getUpdatedRight: getUpdatedRight,
            hasSelectedLeft: selectedLeft.length === 0 ? 'none' : selectedLeft.length === dataLeft.length ? 'all' : 'some',
            hasSelectedRight: selectedRight.length === 0 ? 'none' : selectedRight.length === dataRight.length ? 'all' : 'some',
            hasUpdates: addedLeft.length + addedRight.length + updatesLeft.length + updatesRight.length > 0,
            initData: initData,
            moveSelectedLeft: moveSelectedLeft,
            moveSelectedRight: moveSelectedRight,
            selectedLeft: selectedLeft,
            selectedRight: selectedRight,
            selectLeft: selectLeft,
            selectRight: selectRight,
            setOptions: setOptions,
            toggleLeft: toggleLeft,
            toggleRight: toggleRight,
            updateBaseLeft: updateBaseLeft,
            updateBaseRight: updateBaseRight
        })}
    </>);
};