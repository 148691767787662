import React, {useState} from 'react';

function GenericCheckBox({
                                       singleSearchResult,
                                       setPayloadPart = () => null,
                                       payloadPart = [],
                                   }) {
    const isWithinPayloadPart = () => {
        const isIt = payloadPart.filter(part => {
            return part.changeObject === singleSearchResult.Id
        })
        return isIt[0]
    }


    const [isChecked, setIsChecked] = useState(isWithinPayloadPart() ? !!!isWithinPayloadPart().changeType : singleSearchResult.IsGroupMember)
    const handleChangesInState = (newChange) => {
        const payload = {
            changeType: +!newChange,
            changeObject: singleSearchResult.Id
        }
        const exists = payloadPart.find(x => {
            return x.changeObject === payload.changeObject
        })

        if (exists) {
            if (newChange === singleSearchResult.IsGroupMember) {
                const newPayload = payloadPart.filter(x => {
                    return x.changeObject !== payload.changeObject
                })
                setPayloadPart(newPayload)
                return
            }
        }

        if (exists) {
            payload.changeType = +!exists.changeType
        }

        let final = payloadPart.filter(x => {
            return x.changeObject !== payload.changeObject
        })

        final = [...final, payload]

        setPayloadPart([
            ...final
        ])
    }

    const onCheckChange = (e) => {
        handleChangesInState(e)
        setIsChecked(e)
    }

    return (
        <div
            onClick={() => {
                onCheckChange(!isChecked)
            }}
            className={`d-flex`}
        >
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value={isChecked}
                       readOnly={true} checked={isChecked}/>
            </div>
        </div>
    );
}

export default GenericCheckBox;
