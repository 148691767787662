import { useEffect, useState } from 'react';

import { mergeDeep } from '../helpers/mergeHelper'

import themes from '../styling/_themes.json';

const useTheme = () => {
    const [themeName, setThemeName] = useState(null);
    const [theme, setTheme] = useState(null);

    const setNewTheme = (newTheme) => {
        if (themeName !== newTheme) {
            // Is valid theme name?
            if (newTheme && themes[newTheme] && typeof themes[newTheme] === 'object') {
                setThemeName(newTheme);
                setTheme(mergeDeep({}, themes.default, themes[newTheme]));
            } else if (themeName !== 'default') {
                // Fall back
                setThemeName('default');
                setTheme(themes.default);
            }
        }
    };

    useEffect(() => {
        // to test a new branding comment out the below line
        const firstPartUrl = window.location.hostname.split('.')[0];

        if (firstPartUrl.includes('ukpn'))
        setNewTheme('ukpn');
        else
        setNewTheme(window.location.hostname.split('.')[0]);

        //and add your new theme setting here - assuming the settings have been set in _themes.json
      //  setNewTheme('ukpn');
        
    }, []);

    return { theme, themeName, setNewTheme };
};

export default useTheme;