import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import BaseContainer from '../components/BaseContainer';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import LearningPlanTableNew from '../components/LearningPlanTableNew';

export default function UserLearningPlan(props) {
    const [activeSortedTrainingPlan, setDataActiveSortedTrainingPlan] = useState({ data: null, loading: true });
   // const [dataPathWays, setDataPathWays] = useState({ data: null, loading: true });
    const [dataUser, setDataUser] = useState({ data: null, loading: true });
    const [searchParams] = useSearchParams();
    const [userId, setUserId] = useState(0);

    const {
    //    fetchPersonPathWays,
        fetchPersonTrainingPlanSummary,
        fetchActiveSortedTrainingPlanNew,
     //   fetchPersonPathWayDetail
    } = useLmsStore(state =>
    ({
     //   fetchPersonPathWays: state.fetchPersonPathWays,
      //  fetchPersonPathWayDetail: state.fetchPersonPathWayDetail,
        fetchPersonTrainingPlanSummary: state.fetchPersonTrainingPlanSummary,
        fetchActiveSortedTrainingPlanNew: state.fetchActiveSortedTrainingPlanNew

    }), shallow);

 //   const getPersonPathWayDetail = (pathway) =>  fetchPersonPathWayDetail(`${userId}-${pathway.id}-${pathway.registrationPathwayId}`);
    
    // on load
    useEffect(() => {
        let id = parseInt(searchParams.get("id"));
        setUserId(id > 0 ? id : 0);
    }, [searchParams]);

    useEffect(() => {
        if (userId > 0) {
            setDataActiveSortedTrainingPlan({ data: null, loading: true });
           // setDataPathWays({ data: null, loading: true });
            setDataUser({ data: null, loading: true });
         //   fetchPersonPathWays(userId).then((d) => {
           //     setDataPathWays({ data: d || [], loading: false });
        //   });
            fetchPersonTrainingPlanSummary(userId).then((d) => {
                // In LMS 2022 the user object is d.value
                // In the old lms it is d
                setDataUser({ data: (d.value?.personId ? d.value : d) || [], loading: false });
            });
            fetchActiveSortedTrainingPlanNew(userId).then((d) => {
                setDataActiveSortedTrainingPlan({ data: d || [], loading: false });
            });
        }
    }, [userId]);

    return (<>
        <BaseContainer title='Learning Plan'>
            <LearningPlanTableNew
                dataActiveTrainingPlan={activeSortedTrainingPlan}
             //   dataPathWays={dataPathWays}
                dataUser={dataUser}
                displayUserCard={true}
                enablePw={true}
              //  getPathWayDetail={getPersonPathWayDetail}
            />
        </BaseContainer>
    </>);
}