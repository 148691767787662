import { useEffect, useMemo, useState } from 'react';
import BaseContainer from "../components/BaseContainer";
import { TableContainer } from '../helpers/tableHelper'
import MaterialReactTable from 'material-react-table';
import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload,faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import {Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function AllUsersElearningProgress() {
    const navigate = useNavigate();
    const { applicationTrainingPlans,  fetchApplicationTrainingPlans} = useLmsStore(state =>
    ({
        applicationTrainingPlans: state.applicationTrainingPlans.data,
        fetchApplicationTrainingPlans: state.fetchApplicationTrainingPlans
    }), shallow);

    const downloadFile = ({ data, fileName, fileType }) => {
      const blob = new Blob([data], { type: fileType });
      const a = document.createElement("a");
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    };

    const exportToJson = (e) => {
      e.preventDefault();
      downloadFile({
        data: JSON.stringify(applicationTrainingPlans),
        fileName: "registrations.json",
        fileType: "text/json",
      });
    };
          
    const exportToCsv = (e) => {
      e.preventDefault();

      // Headers for each column
      let headers = ["Company Name, Employee ID, First Name, Surname, Job Title, Primary Email, Person Status Name, Division, Department, Team, Line Manager Name, Cost Centre, Start Date DD/MM/YY, Catalogue Name, Item Name, Item Status Name, Item Type Name, Due Date DD/MM/YY, Number Of Attempts, Is Mandatory, Completion Date DD/MM/YY"];
        
      // Convert users data to a csv
      let usersCsv = applicationTrainingPlans.reduce((acc, user) => {
        const { companyName, employeeId, firstName, surname, jobTitle, primaryEmail, personStatusName, division, department, team, lineManagerName, costCentre, startDate, catalogueName, itemName, itemStatusName, itemTypeName, dueDate, numberOfAttempts, isMandatory, completionDate } = user;
        acc.push([companyName, employeeId, firstName, surname, jobTitle, primaryEmail, personStatusName, division, department, team, lineManagerName, costCentre, startDate, catalogueName, itemName, itemStatusName, itemTypeName, dueDate, numberOfAttempts, isMandatory, completionDate].join(","));
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv].join("\n"),
        fileName: "AllUsersElearningProgress.csv",
        fileType: "text/csv",
      });
    };

    // on load
    useEffect(() => {
        fetchApplicationTrainingPlans();       
    }, []);

         
    const columns = useMemo(
        () => [
            {
                accessorKey: "companyName",
                header: "Company Name",
                header: (
                    <>
                        Company<br />Name
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "employeeId",                
                header: (
                    <>
                        Employee<br />ID
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "firstName",
                header: "First Name",
                header: (
                    <>
                        First<br />Name
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "surname",
                header: "Surname",
                //size: 30
            },
            {
                accessorKey: "jobTitle",
                header: "Job Title",
                //size: 30
            },
            {
                accessorKey: "primaryEmail",
                header: 'Email',
                //size: 30
            },
            { 
                accessorKey: "personStatusName",
                header: (
                    <>
                        Person<br />Status
                    </>
                ),
                //size: 30
            },
            { 
         
                accessorKey: "division",
                header: "Division",
                //size: 30
            },
            { 
         
                accessorKey: "department",
                header: "Department",
                //size: 30
            },
            { 
         
                accessorKey: "team",
                header: "Team",
                //size: 30
            },
            { 
         
                accessorKey: "lineManagerName",
                header: (
                    <>
                        Line<br />Manager
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "costCentre",
                header: "Cost Centre",
                header: (
                    <>
                        Cost<br />Centre
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "startDate",
                header: (
                    <>
                        Start<br />Date
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "catalogueName",
                header: "Catalogue Name",
                header: (
                    <>
                        Catalogue<br />Name
                    </>
                ),
                //size: 30
            },
            { 
                accessorKey: "itemName",
                header: "Item Name",
                //size: 30
            },
            {
                accessorKey: "itemStatusName",
                header: (
                    <>
                        Item<br />Status
                    </>
                ),
                //size: 30
            },            
            {
                accessorKey: "itemTypeName",
                header: (
                    <>
                        Item<br />Item
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "dueDate",
                header: (
                    <>
                        Due<br />Due
                    </>
                ),
                //size: 30
            },
            {
                accessorKey: "numberOfAttempts",
                header: "Number Of Attempts",
                header: (
                    <>
                        Number<br />Of Attempts
                    </>
                ),
                //size: 30
            },           
            {
                accessorKey: "isMandatory",
                header: "Is Mandatory",
                //size: 30
            },           
            {
                accessorKey: "completionDate",
                header: (
                    <>
                        Completion<br />Date
                    </>
                ),
                //size: 30
            }
        ],
        [],
    );

    return (
        <BaseContainer title='All Users eLearning Progress Report'>
            <Button variant='outline-secondary' className='mt-2 mb-2' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faChevronLeft} /><span className='m-2'>Back</span></Button>
            <TableContainer className={"mb-2 rounded-2"}  >
                    <MaterialReactTable
                        columns={columns}
                        data={ applicationTrainingPlans || []}
                        renderToolbarInternalActions={({ table }) => (
                            <>
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Download Data</Tooltip>}>
                                    <Button variant='clear' className='pt-0 pb-0' onClick={exportToCsv}>
                                        <FontAwesomeIcon size='xl' icon={faDownload} className='text-primary' />
                                    </Button>
                                </OverlayTrigger>
                            </>
                        )}
                        initialState={{
                            pagination: { pageIndex: 0, pageSize: 7 },
                            showGlobalFilter: true
                        }}
                        enableStickyHeader={true}
                        useSticky
                        muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
                        state={{
                            isLoading: !applicationTrainingPlans
                        }}
                    />
            </TableContainer>
        </BaseContainer>
    );
}
