import { useEffect, useMemo, useState } from 'react';
import BaseContainer from "../components/BaseContainer";

import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { TableContainer } from '../helpers/tableHelper'

import MaterialReactTable from 'material-react-table';

import useLmsStore, { responseData } from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import FormCatalogue from '../components/FormCatalogue';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

export default function CatalogueAdministration(props) {
    const [catalogue, setCatalogue] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [tableRetired, setTableRetired] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [dataSelected, setDataSelected] = useState(null);

    const { loading, catalogues, allLearningItems, applicationGroups, applicationPeople, fetchCatalogues, fetchAllLearningItems, fetchApplicationGroups, fetchApplicationPeople } = useLmsStore(state =>
    ({
        allLearningItems: state.allLearningItems.data,
        applicationGroups: state.applicationGroups.data,
        applicationPeople: state.applicationPeople.data,
        catalogues: state.catalogues.data,
        loading: state.catalogues.loading,
        fetchCatalogues: state.fetchCatalogues,
        fetchAllLearningItems: state.fetchAllLearningItems,
        fetchApplicationGroups: state.fetchApplicationGroups,
        fetchApplicationPeople: state.fetchApplicationPeople
    }), shallow)

    const onInactiveChange = (event) => {
        setTableRetired(!tableRetired);
    }

    const handleAdd = () => {
        setCatalogue([]);
        setShowForm(true);
    }

    const handleEdit = (rowData) => {
        setCatalogue(rowData);
        setShowForm(true);
    }
    useEffect(() => {        
        if (dataSelected) {
            setCatalogue(dataSelected);
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    }, [dataSelected]);
    // on load
    useEffect(() => {
        fetchCatalogues();
        fetchAllLearningItems();
        fetchApplicationGroups();
        fetchApplicationPeople();
        fetchCatalogues().then((response) => {
            setCatalogue(response[0]);
            setShowForm(true);
        });
    }, []);

    useEffect(() => {
        let keys = Object.keys(rowSelection);
        if (keys.length === 1 && rowSelection[keys[0]] === true) {
            let id = parseInt(keys[0]);
            if (catalogues) {
                setDataSelected(catalogues.find(x => x.catalogueId === id));
            } else {
                setDataSelected(null);
            }
        } else {
            setDataSelected(null);
        }
    }, [rowSelection]);

    const withSelectedRows = (tableData, inactive) =>  (tableData??[]).filter(e => inactive || e.isActive === true);
    const columns = useMemo(
        () => [
            {
                accessorKey: "title",
                header: "Catalogue Data"
            }
        ],
        [],
    );

    return (
        <BaseContainer title='Catalogues'>
            <TableContainer>
                <Row>
                    <Col style={{ maxWidth: '450px' }} className='border-end'>
                            <>
                                <div className="mt-2 mx-2 form-check form-switch">
                                    <input type="checkbox" id="isRetired" className="form-check-input" onChange={onInactiveChange} />
                                    <label title="" htmlFor="isRetired" className="form-check-label">Include Retired</label>
                                </div>
                            </>                    
                            <MaterialReactTable
                                columns={columns}
                                data={withSelectedRows(catalogues, tableRetired) || []}
                                enableBottomToolbar={true}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                enableGrouping={false}
                                enableHiding={false}
                                enablePagination={true}
                                enableSorting={false}
                                enableTopToolbar={true}
                                getRowId={(originalRow) => originalRow.catalogueId}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () =>
                                        setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
                                    hover: false,
                                    selected: rowSelection[row.id],
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                muiTableHeadProps={{
                                    sx: {
                                        display: 'none'
                                    }
                                }}
                                muiTableContainerProps={{ sx: { maxHeight: 'max(242px, calc(100vh - 405px))' } }}
                                renderToolbarInternalActions={({ table }) => (
                                    <>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add</Tooltip>}>
                                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAdd()}>
                                                <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                )}
                                initialState={{
                                    pagination: { pageIndex: 0, pageSize: 10 },
                                    showGlobalFilter: true
                                }}
                                state={{
                                    isLoading: !catalogues
                                }}
                            />
                        
                    </Col>
                    <Col className="mt-3 mx-3">
                        {showForm ?
                            <FormCatalogue catalogue={catalogue} allLearningItems={allLearningItems} applicationGroups={applicationGroups} applicationPeople={applicationPeople} />
                            :
                            <span></span>
                        }
                    </Col>
                </Row>
            </TableContainer>
        </BaseContainer>
    );
}