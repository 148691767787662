import ReactDOM from 'react-dom';

/**
 * Return a Promise with true if the imgUrl exists.
 * 
 * Unlike other options, this approach does not generate CORS errors.
 */
export async function checkThatImageExists(imgUrl) {
    if (!imgUrl) {
        return false;
    }
    return new Promise(res => {
        const image = new Image();
        image.onload = () => res(image);
        image.onerror = () => res(null);
        image.src = imgUrl;
    });
}

export async function getBase64Image(imgUrl) {
    if (!imgUrl || imgUrl.indexOf('.') === -1) {
        return false;
    }
    const outputFormat = 'image/' + imgUrl.substring(imgUrl.lastIndexOf('.') + 1);
    return new Promise(res => {
        const image = new Image();
        image.crossOrigin='Anonymous';
        image.onload = () => {
            let canvas = document.createElement('CANVAS'),
                ctx = canvas.getContext('2d'),
                dataURL;
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            ctx.drawImage(image, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            res(dataURL);
        }
        image.onerror = () => res(null);
        image.src = imgUrl;
    });
}
