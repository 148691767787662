import { useEffect } from 'react';
import { NavLink } from "react-router-dom";

import { Col, Row } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';

import DashboardCarouselCard from './DashboardCarouselCard';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

export default function DashboardCarousel(props) {
    const { allTopPersonCatalogueItems, fetchAllTopPersonCatalogueItems } = useLmsStore(state =>
    ({
        allTopPersonCatalogueItems: state.allTopPersonCatalogueItems.data,
        fetchAllTopPersonCatalogueItems: state.fetchAllTopPersonCatalogueItems
    }), shallow)
    useEffect(() => {
        fetchAllTopPersonCatalogueItems();
    }, []);
    return (
        <Card className='cls-carousel shadow'>
            <Card.Body>
                <Card.Title>Courses</Card.Title>
                {allTopPersonCatalogueItems && allTopPersonCatalogueItems.length > 0 ?
                    <Carousel variant='dark'>
                        {allTopPersonCatalogueItems.map((rowData, idx) => (
                            <Carousel.Item
                                interval={5500}
                                key={idx}
                            >
                                <NavLink style={{ color: "var(--bs-body-color)", textDecoration: 'none' }} to={"/lms/courselibrary?search=" + rowData.code}>
                                    <DashboardCarouselCard key={idx} rowData={rowData} />
                                </NavLink>
                            </Carousel.Item>
                        ))}
                    </Carousel> :
                    null
                }
            </Card.Body>
        </Card>
    );
}
