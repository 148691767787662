import { useState, useMemo, useEffect} from 'react';
import { TableContainer } from '../helpers/tableHelper'
import MaterialReactTable from 'material-react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPencil, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Container, Row, Col, Form, InputGroup, Modal, Button } from "react-bootstrap";
import useLmsStore, { responseData, getAllActiveEventsByPersonGroups, getEventFullInfo, createEventBookingNew, getCanCancelBooking, cancelEventBookingNew,createWaitingDatesNew, removeWaitingDatesNew } from "../hooks/useLmsStore";
import moment from 'moment'
import shallow from 'zustand/shallow';
import ModalConfirmation from './ModalConfirmation';

const ModalBookingForm = ({ closeFn = () => null, open = false,  refLearningItemId, refLearningItemData }) => {
    const {
        allBookingCancellationReasons,
        fetchAllBookingCancellationReasons
    } = useLmsStore(state =>
    ({
        allBookingCancellationReasons: state.allBookingCancellationReasons,
        fetchAllBookingCancellationReasons: state.fetchAllBookingCancellationReasons

    }), shallow);

    const [events, setEvent] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(false);
    const [info, setInfo] = useState(false);
    const [infoData, setInfoData] = useState("");
    const [createBooking, setCreateBooking] = useState(false);
    const [postingBooking, setPostingBooking] = useState(false);
    const [postingWaitingDates, setPostingWaitingDates] = useState(false);
    const [postingRemoveWaitingDates, setPostingRemoveWaitingDates] = useState(false);
    const [bookingData, setBookingData] = useState("");
    const [booking, setBooking] = useState([]);
    const [bookingLoading, setBookingLoading] = useState(false);
    const [selectReason, setSelectReason] = useState(1);
    const [comments, setComments] = useState("");
    const [title, setTitle] = useState("Book an Event");
    const [isOpenRemoveRegisterInterest, setisOpenRemoveRegisterInterest] = useState(false);
    const canWaitDates = x => x && x.statusName!=='Awaiting Dates' &&  x.statusName!=='Confirmed' && x.statusName!=='Attended';
    const canRemoveWaitDates = x => x && x.statusName=='Awaiting Dates';
    const canBook = x =>  x && (x.instructorInfo==null || (x.statusName!=='Confirmed' && x.statusName!=='Attended' && x.instructorInfo && x.instructorInfo.statusId === 6));

 
    const handleCloseBooking = () => {
        setCreateBooking(false);
    }

    
    const handlePostBooking = () => {
        let data = {
            bookingInfo: {
                eventId: bookingData.eventId,
                learningItemId: refLearningItemId,
                eventName: bookingData.name,
                startDate: bookingData.startDate
            },
            registrationGroupId: -1,
            trainingPlanId: refLearningItemData.id,
            learningItemId: refLearningItemId
        }
        //console.log(`Post booking=> ${refLearningItemId} data:  `, data);
        setPostingBooking(true);
        createEventBookingNew(useLmsStore, data).then(() => {
            setPostingBooking(false);
            setCreateBooking(false);
            closeFn();
        });
    }

    const handleWaitingDates = () => {
        setPostingWaitingDates(true);
        createWaitingDatesNew(useLmsStore, refLearningItemId).then(() => {
            setPostingWaitingDates(false);
                  
            closeFn();
        });
    }

    const handleRemoveWaitingDates = () => {
     
        removeWaitingDatesNew(useLmsStore, refLearningItemId).then(() => {
            setPostingRemoveWaitingDates(false);
                  
            closeFn();
        });
    }

    const handleCloseInfo = ()=> {
        setInfo(false);
    };

    const handleInfo = (id) => {
        setInfo(true);
        getEventFullInfo(id).then((response) => {
            let data = responseData(response, []);
            //console.log(`data => `, data);
            if(data){
                setInfoData(data);
            }
        });
    };

    const timeConvert = (n) => {
        const num = n;
        const hours = (num / 60);
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);
        // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
        return rhours + " hour(s) and " + rminutes + " minute(s).";
        }
   
    const handleBooking = (row) => {
        //http://lms-angular.test.wdr.co.uk/api/booking/createBooking
        setBookingData(row);
        setCreateBooking(true);
    };

    const handleCancelBooking =()=>{
        let data = {
            bookingCancelInfo: {
                bookingId: refLearningItemData.instructorInfo.bookingId,
                comments: comments,
                reasonId: selectReason
            },
            registrationGroupId: -1,
            trainingPlanId: refLearningItemData.id,
            learningItemId: refLearningItemId
        }
        //console.log(`Post cancel booking=> ${refLearningItemId} data:  `, data);
        setBookingLoading(true);
        cancelEventBookingNew(useLmsStore, data).then(() => {
            setBookingLoading(false);
            closeFn();
        });
    }

   /*
     Give a warning to the user that if the want to doremove register interest
   */
     function onHandleRemoveRegisterInterest() {
        //setPostingRemoveWaitingDates(false);  
        toggleModalRemoveRegisterInterest();  
        handleRemoveWaitingDates();         

    };

    function toggleModalRemoveRegisterInterest() {
        setPostingRemoveWaitingDates(true);
        setisOpenRemoveRegisterInterest(!isOpenRemoveRegisterInterest);
        
    }

    useEffect(() => {
        setCreateBooking(false);
         if(refLearningItemId && refLearningItemId > 0){
            if(refLearningItemData.instructorInfo && refLearningItemData.instructorInfo.statusId === 1)
            {
                setTitle("Cancel Booking");
                setEventsLoading(true);
                //console.log(`instructorInfo=> `, refLearningItemData.instructorInfo);
                // http://lms-angular.test.wdr.co.uk/api/event/GetCanCancelBooking/26447
                getCanCancelBooking(refLearningItemData.instructorInfo.bookingId).then((response) => {
                    let data = responseData(response, []);
                    //console.log(`data => `, data);
                    if(data){
                        setBooking(data);
                        setBookingLoading(false);
                    }
                });
            }
            else{
                setEventsLoading(true);
                setTitle("Book an Event");
                 //http://lms-angular.test.wdr.co.uk/api/event/GetAllActiveEventsByPersonGroups/33221
                getAllActiveEventsByPersonGroups(refLearningItemId).then((response) => {
                    let data = responseData(response, []);
                     if(data){
                        setEvent(data);
                        setEventsLoading(false);
                           
                    } 
                });
                
            }
        }
    }, [refLearningItemId]);

    useEffect(() => {
        setBookingLoading(true);
        setEventsLoading(true);
        setPostingBooking(false);
        setPostingWaitingDates(false);
        // http://lms-angular.test.wdr.co.uk/api/booking/getAllBookingCancellationReasons
        fetchAllBookingCancellationReasons();
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: "searchTerms",
                header: "Info",
                align: "left",
                size: 400,
                Cell: ({ cell, row }) => {
                    return (<div className="float-start">
                        {"Event " + row.original.name + " on " + moment(row.original.startDate).format('DD/MM/YYYY HH:mm:ss') + " ends " + moment(row.original.endDate).format('DD/MM/YYYY HH:mm:ss') + " Duration " + row.original.duration + " " + row.original.durationUnit }<br/>
                        {row.original.venueName + " " + row.original.venueCity + " - Room " + row.original.roomName }
                    </div>)
                }
            },
            {
                accessorKey: "eventId",
                header: "Action",
                align: "center",
                size: 40,
                Cell: ({ cell, row }) => {
                    return (<>
                        <Button variant='clear' className='pt-0 pb-0' onClick={() => handleInfo(row.original.eventId)}>
                            Event Info
                        </Button><br/>
                        <Button variant='primary' className='pt-0 pb-0' onClick={() => handleBooking(row.original)}>
                            Book
                        </Button><br/>
                        <span>{row.original.placesLeft} Places</span>
                    </>)
                }
            }
        ],
        [],
    );

    return (
        <Modal size="lg" aria-labelledby="example-modal-sizes-title-lg" show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                   {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container fluid>
                {
                  refLearningItemData.instructorInfo && (refLearningItemData.instructorInfo.statusId === 1 || refLearningItemData.statusName==='Confirmed' || refLearningItemData.instructorInfo.statusName==='Confirmed')? 
                        bookingLoading?
                        <>
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border mt-5 text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        </>
                        :
                        !booking.canCancel?
                            <>
                                <div>To continue cancelling your booking on the {booking.eventInfo.name} for the event being held at {booking.eventInfo.venueName}, {booking.eventInfo.venueCity} - Room {booking.eventInfo.roomName} on {moment( booking.eventInfo.startDate).format('DD/MM/YYYY')} between {booking.eventInfo.startTime} and {booking.eventInfo.endTime}<br/>
                                <div className="alert alert-danger">WARNING: Bookings cannot be cancelled within {booking.eventInfo.duration} {booking.eventInfo.durationUnit} of the event. Please contact your Manager.</div>
                                </div>
                            </>
                            :
                            <>
                                <h6>Cancellation of your booking on the {booking.eventInfo.name} for the event being held at {booking.eventInfo.venueName}, {booking.eventInfo.venueCity} - Room {booking.eventInfo.roomName} on {moment( booking.eventInfo.startDate).format('DD/MM/YYYY')} between {booking.eventInfo.startTime} and {booking.eventInfo.endTime}</h6>
                                <div>
                                    <div className="mb-3">
                                    <label htmlFor="select_reason">Reason:</label>
                                    <select className="form-select" id="select_reason" onChange={e => setSelectReason(e.target.value)}>
                                        {(allBookingCancellationReasons.data??[]).map(status => (
                                            <option key={status.id} value={status.id}>
                                                {status.value}
                                            </option>
                                        ))}
                                    </select>
                                    </div>
                                    <div className="mb-3">
                                    <label htmlFor="comments">Comments:</label>
                                    <textarea id="comments" className="form-control" onChange={e => setComments(e.target.value)}>

                                    </textarea>
                                    </div>
                                </div>
                            </>
                    :
                    info?
                        <>
                        <h3>Info for event {refLearningItemData.eventName}</h3> 
                            {infoData ?
                            <>
                            <div><h6>Places remaining {infoData.placesLeft}</h6><div> {moment(infoData.startDate).format('DD/MM/YYYY')}- {timeConvert(infoData.eventDates[0].duration)}..<br/> Time slot between {infoData.displayStartTime} and {infoData.displayEndTime} ({ infoData.eventDates[0].duration} mins) <br/>  At {infoData.roomName}, {infoData.venueName}</div></div>
                            <Button variant='primary' onClick={handleCloseInfo}>Back To Booking</Button>
                            </>
                            :
                            <>
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border mt-5 text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            </>
                            }
                        </>
                        :
                        createBooking || postingWaitingDates || postingRemoveWaitingDates ?
                            postingBooking || postingWaitingDates || postingRemoveWaitingDates?
                                <>
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border mt-5 text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <h3>Confirm the booking the event '{bookingData.name}' on {moment(bookingData.startDate).format('DD/MM/YYYY')}</h3>
                                </>
                            :
                            <>
                                <h3>We have the following scheduled events for:</h3>
                                <TableContainer>
                                    <MaterialReactTable
                                        columns={columns}
                                        data={events || []}
                                        enableColumnActions={false}
                                        enableColumnFilters={false}
                                        enableDensityToggle={false}
                                        enableFullScreenToggle={false}
                                        enableGrouping={false}
                                        enableHiding={false}
                                        enablePagination={false}
                                        enableSorting={false}
                                        enableTopToolbar={true}
                                        muiTableHeadProps={{
                                            sx: {
                                                display: 'none'
                                            }
                                        }}
                                        muiTableBodyCellProps={ {
                                            align: 'center',
                                        }}
                                        getRowId={(originalRow) => originalRow.id}
                                        muiToolbarAlertBannerProps={{
                                            sx: {
                                                display: 'none'
                                            }
                                        }}
                                        initialState={{
                                            pagination: { pageIndex: 0, pageSize: 10 },
                                            showGlobalFilter: true
                                        }}
                                        state={{
                                            isLoading: eventsLoading
                                        }}
                                    />
                                </TableContainer>

                            </>
                }
            </Container>
            </Modal.Body>
            <Modal.Footer>
                {
                      refLearningItemId && refLearningItemId > 0 && canWaitDates(refLearningItemData) && !createBooking && !postingWaitingDates?
                        <>
                        <Button variant='primary' onClick={handleWaitingDates}>Register Interest</Button>
                        </>
                    :
                    <>
                    </>
                 }
                   {
                      refLearningItemId && refLearningItemId > 0 && canRemoveWaitDates(refLearningItemData) && !createBooking && !postingRemoveWaitingDates?
                        <>
                        <Button variant='primary' onClick={toggleModalRemoveRegisterInterest}>Remove Register Interest</Button>
                        </>
                    :
                    <>
                    </>
                 }
                {                

                    refLearningItemId && refLearningItemId > 0 && !canBook(refLearningItemData) && refLearningItemData.instructorInfo  && (refLearningItemData.instructorInfo.statusId && refLearningItemData.instructorInfo.statusId === 1) && booking && booking.canCancel?
                    <>
                        <Button variant='primary' onClick={handleCancelBooking} disabled={bookingLoading} >Cancel Booking</Button>
                    </>
                    :
                    <>
                    </>
                }
                {
                    refLearningItemId && refLearningItemId > 0 && /*(canBook(refLearningItemData)*/ !info && createBooking && !postingBooking?
                    <>
                        <Button variant='primary px-3 w-25' onClick={handlePostBooking} disabled={postingBooking} >Yes</Button>
                        <Button variant='primary px-3 w-25' onClick={handleCloseBooking} disabled={postingBooking} >No</Button>
                    </>
                    :
                    <>
                    </>   
                }
               

         
                <Button variant='primary' onClick={closeFn}>Close</Button>
                <ModalConfirmation closeFn={toggleModalRemoveRegisterInterest} yesFn={onHandleRemoveRegisterInterest} noFn={toggleModalRemoveRegisterInterest} open={isOpenRemoveRegisterInterest} title='Confirm remove from register interest' description='<p>Are you sure you want to be removed from the register interest?</p>' />
   
            </Modal.Footer>
        </Modal>
        
    );
};

export default ModalBookingForm;