import React, {useEffect, useState} from 'react';
import BaseContainer from "../components/BaseContainer";
import LearningItemTabCard from "../components/learningItemsPageComponents/LearningItemTabCard";
import {learningItemsCardNames, learningItemsTabEnum} from "../helpers/constantsAndEnums";
import SearchInput from "../components/inputs/SearchInput";
import {apiAxiosCalls} from "../services/apiAxiosCalls";
import LearningItemSingleSearchResult from "../components/learningItemsPageComponents/LearningItemSingleSearchResult";
import {useInfiniteScroll} from "../hooks/useInfiniteScroll";

const pageSize = 30

const searchGroupsInitialPayload = {
    filter: "", itemOffset: 0, itemCount: pageSize,
}

const LearningItemsPage = () => {
    const [activeTab, setActiveTab] = useState(learningItemsTabEnum.Detail)
    const [learningItems, setLearningItems] = useState([])
    const [learningItemsCount, setLearningItemsCount] = useState(0)
    const [searchLearningItemsPayload, setSearchLearningItemsPayload] = useState(searchGroupsInitialPayload)
    const [hasDoneInitialFetch, setHasDoneInitialFetch] = useState(false)
    const learningItemsHasMore = (searchLearningItemsPayload.itemOffset) <= learningItemsCount
    const [searchTerm, setSearchTerm] = useState("")
    const [triggerSearch, setTriggerSearch] = useState(0)

    const onTabCardClick = (tab) => {
        setActiveTab(tab)
    }

    const onSearchClick = () => {
        setLearningItemsCount(0)
        setLearningItems([])

        setSearchLearningItemsPayload({...searchGroupsInitialPayload, filter: searchTerm})
        setTriggerSearch(prev => prev + 1)
    }

    const activeTabMapper = {
        [learningItemsTabEnum.Images]: () => <>images</>,
        [learningItemsTabEnum.ELearning]: () => <>ELearning</>,
        [learningItemsTabEnum.Synopsis]: () => <>Synopsis</>,
        [learningItemsTabEnum.Detail]: () => <>Detail</>,
    }

    const searchLearningItems = async () => {
        try {
            const result = await apiAxiosCalls.searchLearningItems(searchLearningItemsPayload)
            setHasDoneInitialFetch(true)
            setLearningItemsCount(result.data.Value.Count)
            setLearningItems([...learningItems, ...result.data.Value.Items])
            setSearchLearningItemsPayload({
                ...searchLearningItemsPayload, itemOffset: searchLearningItemsPayload.itemOffset + pageSize
            })
        } catch (e) {
            console.log(e)
        }
    }

    const {lastElementRef, isLoading} = useInfiniteScroll({loadMore: searchLearningItems})

    const renderLoadMoreGroupsComponent = () => {
        return <>
            {!isLoading && learningItemsHasMore ? <div style={{minHeight: "200px"}} ref={lastElementRef}>

            </div> : <></>}
        </>
    }

    const renderActiveTab = () => {
        return activeTabMapper[activeTab]
    }

    useEffect(() => {
        searchLearningItems()
    }, [])

    useEffect(() => {
        if (triggerSearch) {
            searchLearningItems()
        }
    }, [triggerSearch])

    console.log(learningItems)

    const renderLearningItems = () => {
        return <div style={{maxHeight: "600px"}}
                    className={"w-100 d-flex flex-column align-items-center overflow-auto"}>
            {learningItems.map(learningItem => {
                return <LearningItemSingleSearchResult key={learningItem.Id} learningItem={learningItem}/>
            })}
            {renderLoadMoreGroupsComponent()}

            {isLoading && <div className={"w-100 d-flex justify-content-center align-items-center"}>
                <div className="spinner-border text-primary" style={{width: '5rem', height: '5rem'}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
        </div>
    }
    const renderCards = () => {
        return <div className={"mt-1 row gap-1 p-1"}>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Detail)
            }} title={learningItemsCardNames.Detail} isActive={activeTab === learningItemsTabEnum.Detail}/>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.ELearning)
            }} title={learningItemsCardNames.ELearning} isActive={activeTab === learningItemsTabEnum.ELearning}/>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Images)
            }} title={learningItemsCardNames.Images} isActive={activeTab === learningItemsTabEnum.Images}/>
            <LearningItemTabCard onClick={() => {
                onTabCardClick(learningItemsTabEnum.Synopsis)
            }} title={learningItemsCardNames.Synopsis} isActive={activeTab === learningItemsTabEnum.Synopsis}/>
        </div>
    }

    return (<BaseContainer title='Learning items'>
            <div className={"bg-white rounded-2 p-2"}>
                <div className={"row d-flex"}>
                    <div className={"col-3"}>
                        <SearchInput placeholer={"Search"} label={"Search learning items"} onClick={() => {
                            onSearchClick()
                        }} onChange={e => setSearchTerm(e.target.value)}/>
                        {renderLearningItems()}
                    </div>
                    <div className={"col-9"}>
                        {renderCards()}
                        {renderActiveTab()()}
                    </div>

                </div>
            </div>
        </BaseContainer>);
};

export default LearningItemsPage;
