import { useState  } from 'react';
import useLmsStore, { postPasswordChange } from '../hooks/useLmsStore';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalPassword = ({ closeFn = () => null, open = false, personSummaryError, personSummaryLoading, personSummary}) => {
    const [inProgress, setInProgress] = useState(false);
    const [formInput, setFormInput] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: "",
      });
    
      const [formError, setFormError] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: "",
      });
    
      const handleUserInput = (name, value) => {
        setFormInput({
          ...formInput,
          [name]: value,
        });
      };
    
      const validateFormInput = (event) => {
        event.preventDefault();
        let inputError = {
          oldPassword: "",
          password: "",
          confirmPassword: "",
        };
    
        if (!formInput.oldPassword && !formInput.password) {
          setFormError({
            ...inputError,
            email: "Enter old password",
            password: "Password should not be empty",
          });
          return
        }
    
        if (!formInput.oldPassword) {
          setFormError({
            ...inputError,
            email: "Enter old password",
          });
          return
        }
    
        if (formInput.confirmPassword !== formInput.password) {
          setFormError({
            ...inputError,
            confirmPassword: "Password and confirm password should be same",
          });
          return;
        }
    
        if (!formInput.password) {
          setFormError({
            ...inputError,
            password: "Password should not be empty",
          });
          return
        }
    
        setFormError(inputError);
        handleSubmit(event);
      };

    const handleSubmit = async event => {
        const form = event.currentTarget;
        if (!inProgress) {

            if(form.elements.password.value === form.elements.confirmPassword.value)
            {
                let data = {
                    oldPassword: form.elements.oldPassword.value,
                    newPassword: form.elements.password.value
                }
                setInProgress(true);
                let success = await postPasswordChange(useLmsStore, data);
                setInProgress(false);
                if(success){
                    closeFn();
                    setFormInput({
                      oldPassword: "",
                      password: "",
                      confirmPassword: "",
                    });
                }
                else{
                    //do something!
                }
                
            }
            else
            {
            }
        }
    };

    return (
        <Modal fullscreen='md-down' show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={validateFormInput} id="passwordchange-form">
                <div className="mb-3">
                    <label className="form-label" htmlFor="oldPassword">Old Password</label>
                    <input
                      value={formInput.oldPassword}
                      onChange={({ target }) => {
                        handleUserInput(target.name, target.value);
                      }}
                      name="oldPassword"
                      type="password"
                      className="form-control"
                      placeholder="Enter old password"
                    />
                    <p className="invalid-feedback d-block">{formError.oldPassword}</p>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="password">New Password</label>
                    <input
                      value={formInput.password}
                      onChange={({ target }) => {
                        handleUserInput(target.name, target.value);
                      }}
                      name="password"
                      type="password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <p className="invalid-feedback d-block">{formError.password}</p>
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="confirmPassword">Confirm New Password</label>
                    <input
                      value={formInput.confirmPassword}
                      onChange={({ target }) => {
                        handleUserInput(target.name, target.value);
                      }}
                      name="confirmPassword"
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                    />
                    <p className="invalid-feedback d-block">{formError.confirmPassword}</p>
                  </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
                {inProgress ?
                    <Button variant="primary" disabled>
                        <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                        Please wait...
                    </Button>
                    :
                    <Button variant="primary" type="submit" form="passwordchange-form">
                        Save
                    </Button>}

            </Modal.Footer>
        </Modal>
    );
};

export default ModalPassword;