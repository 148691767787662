import React, {useMemo, useState} from 'react';
import BaseContainer from "../components/BaseContainer";
import UploadUsersDropZone from "../components/UploadUsersDropZone";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import Papa from 'papaparse';
import {TableContainer} from "../helpers/tableHelper";
import MaterialReactTable from "material-react-table";
import useLmsStore, {postCreateUsers, resetCreateUsersState} from "../hooks/useLmsStore";
import moment from "moment/moment";

const exampleCSVHeaders = [['firstName', 'lastName', 'email', 'username', 'phoneNumber', 'employeeId', 'team', 'address', 'jobTitle', 'department', "managerEmail", "division", "startDateAtCompany", "leavingDateFromCompany"]];

function CreateUsersPage(props) {
    const [usersCSV, setUsersCSV] = useState(null)
    const {createUsers} = useLmsStore((state) => {
        return {
            createUsers: state.createUsers
        }
    })

    const createUsersResponseDataWithErrors = createUsers.data.filter(validatedDto => {
        const flattened = Object.entries(validatedDto).map(entry => {
            return entry[1]
        })
        return flattened.some(x => x.errorMessage)
    })

    const transformUsersWithErrorsAndCheckForGeneralError = () => {
        return createUsersResponseDataWithErrors.map(userWithError => {
            const hasGeneralError = userWithError.generalError.errorMessage
            if (hasGeneralError) {
                Object.keys(userWithError).forEach(key => {
                    userWithError[key].errorMessage = hasGeneralError
                })
                return userWithError
            }
            return userWithError
        })
    }


    const createUsersResponseDataWithoutErrors = createUsers.data.filter(validatedDto => {
        const flattened = Object.entries(validatedDto).map(entry => {
            return entry[1]
        })
        return flattened.every(x => !x.errorMessage)
    })

    const [tableState, setTableState] = useState({
        density: 'compact', expanded: true, isLoading: false,

    })

    const [pagination, setPagination] = useState({
        pageIndex: 0, pageSize: 10,
    })

    const [papaParseOptions, setPapaParseOptions] = useState({
        header: true, skipEmptyLines: true
    })
    const [parsedCSV, setParsedCSV] = useState(null)

    const onSelectFile = (file) => {
        setUsersCSV(file)
        parseTheCsv(file)
    }

    const downloadCSVWithErrors = () => {
        const csvRows = []
        const copy = [...transformUsersWithErrorsAndCheckForGeneralError()]
        copy.forEach(dto => {
            const csvRow = []
            exampleCSVHeaders[0].forEach(header => {
                csvRow.push(dto[header].value)
            })
            csvRows.push(csvRow)
        })

        const csv = Papa.unparse([...exampleCSVHeaders, ...csvRows]);
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users_with_errors.csv');
        link.click();
    }

    const renderTheDropzone = () => {
        return !usersCSV ? <div className={'d-flex flex-column'}>
            <UploadUsersDropZone onSelectFile={onSelectFile}/>
            <Button type="button" className="btn btn-primary d-block mt-2" onClick={() => {
                createCSV()
            }}>Download example CSV</Button>
        </div> : <></>
    }

    const createUsersFunction = async () => {

        await postCreateUsers(useLmsStore, convertPayloadToServerRequirement(parsedCSV))
    }

    const convertPayloadToServerRequirement = (payload) => {
        const newPayload = payload.map(row => {
            row.startDateAtCompany = row.startDateAtCompany ? moment(row.startDateAtCompany, "MM/DD/YYYY").format("YYYY-MM-DD") : ""
            row.leavingDateFromCompany = row.leavingDateFromCompany ? moment(row.leavingDateFromCompany, "MM/DD/YYYY").format("YYYY-MM-DD") : ""
            return row
        })

        return newPayload
    }

    const onCancel = () => {
        setUsersCSV(null)
        setParsedCSV(null)
    }

    const parseTheCsv = async (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const csvData = event.target.result;
            const csv = Papa.parse(csvData, papaParseOptions);
            setParsedCSV(csv.data)
        };

        reader.readAsText(file);
    }

    const onClearAndRepeat = () => {
        setUsersCSV(null)
        setParsedCSV(null)
        resetCreateUsersState(useLmsStore)
    }

    const columns = useMemo(() => [{
        accessorKey: 'firstName', header: 'First Name', size: 100,
    }, {
        accessorKey: 'lastName', header: 'Last Name', size: 100,
    }, {
        accessorKey: 'email', header: 'Email', size: 100,
    }, {
        accessorKey: 'username', header: 'Username', size: 100,
    }, {
        accessorKey: 'phoneNumber', header: 'Phone number', size: 100,
    }, {
        accessorKey: 'employeeId', header: 'Employee Id', size: 100,
    }, {
        accessorKey: 'team', header: 'Team', size: 100,
    }, {
        accessorKey: 'address', header: 'Address', size: 100,
    }, {
        accessorKey: 'jobTitle', header: 'Job title', size: 100,
    },
        {
            accessorKey: 'department', header: 'Department', size: 100,
        },
        {
            accessorKey: 'managerEmail', header: 'Manager Email', size: 100,
        },
        {
            accessorKey: 'division', header: 'Division', size: 100,
        },
        {
            accessorKey: 'startDateAtCompany', header: 'Start date', size: 100,
        },
        {
            accessorKey: 'leavingDateFromCompany', header: 'Leaving date from company', size: 100,
        }
        ,], [],);


    const renderCell = (cell) => {
        const cellValue = cell.getValue()
        return cellValue.errorMessage ? <>
            <OverlayTrigger
                placement='top'
                delay={{show: 250, hide: 400}}

                overlay={<Tooltip className='cls-theme-tooltip'>{cellValue.errorMessage}</Tooltip>}>
                <div className={"text-danger"}>
                    {cell.getValue().value || "N/A"}
                </div>
            </OverlayTrigger>
        </> : <div>
            {cell.getValue().value}
        </div>
    }

    const responseTableColumns = useMemo(() => [
        {
            accessorKey: 'firstName', header: 'First Name', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'lastName', header: 'Last Name', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'email', header: 'Email', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'username', header: 'Username', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'phoneNumber', header: 'Phone number', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'employeeId', header: 'Employee Id', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'team', header: 'Team', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'address', header: 'Address', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'jobTitle', header: 'Job title', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'department', header: 'Department', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'managerEmail', header: 'Manager email', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'division', header: 'Division', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'startDateAtCompany', header: 'Start date', size: 100, Cell: ({cell}) => renderCell(cell)
        },
        {
            accessorKey: 'leavingDateFromCompany', header: 'Leaving date from company', size: 100, Cell: ({cell}) => renderCell(cell)
        }
    ], [createUsers.data],);

    const renderSelectedUsersTable = () => {
        return (parsedCSV && !createUsers.data.length) ? <div className={"w-100"} >
            <TableContainer>
                <MaterialReactTable
                    columns={columns}
                    data={parsedCSV || []}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableGlobalFilter={false}
                    enableGrouping={false}
                    enableHiding={false}
                    enablePagination={true}
                    enableSorting={false}
                    enableTopToolbar={false}
                    muiTableBodyRowProps={{hover: false}}
                    enableBottomToolbar={true}
                    onPaginationChange={setPagination}
                    muiTableProps={{
                        sx: {
                            tableLayout: 'fixed',

                        },
                    }}
                    state={{...tableState, pagination}}
                />

            </TableContainer>
        </div> : <></>
    }

    const renderOnClearAndRepeatButton = () => {
        return createUsers.data.length ?
            <Button type="button" className="btn btn-primary mt-3" onClick={() => {
                onClearAndRepeat()
            }}>Clear and repeat</Button>
            :
            <></>
    }

    const renderCreateUsersResponseUsersWithErrors = () => {
        return transformUsersWithErrorsAndCheckForGeneralError().length ?
            <div className={"w-100"}>
                <h5>Users with errors</h5>
                <TableContainer>
                    <MaterialReactTable
                        columns={responseTableColumns}
                        data={transformUsersWithErrorsAndCheckForGeneralError() || []}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableGlobalFilter={false}
                        enableGrouping={false}
                        enableHiding={false}
                        enablePagination={true}
                        enableSorting={false}
                        enableTopToolbar={false}
                        muiTableBodyRowProps={{hover: false}}
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',

                            },
                        }}

                    />

                </TableContainer>
                <Button type="button" className="btn btn-primary mt-3 m-auto d-block" onClick={() => {
                    downloadCSVWithErrors()
                }}>Download the CSV with errors and try again</Button>
            </div>
            :
            <></>
    }

    const renderCreateUsersResponseUsersWithoutErrors = () => {
        return createUsersResponseDataWithoutErrors.length ?
            <div className={"w-100"}>
                <h5 className={"mt-5"}>Successfully created/updated users</h5>
                <TableContainer>
                    <MaterialReactTable
                        columns={responseTableColumns}
                        data={createUsersResponseDataWithoutErrors || []}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enableGlobalFilter={false}
                        enableGrouping={false}
                        enableHiding={false}
                        enablePagination={true}
                        enableSorting={false}
                        enableTopToolbar={false}
                        muiTableBodyRowProps={{hover: false}}
                        muiTableProps={{
                            sx: {
                                tableLayout: 'fixed',

                            },
                        }}

                    />

                </TableContainer>
            </div>
            :
            <></>
    }

    const createCSV = () => {

        const csv = Papa.unparse(exampleCSVHeaders);
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users_example.csv');
        link.click();
    }

    const renderCancelAndUploadUsersButtons = () => {
        return (usersCSV && !createUsers.data.length) ? <>
            <div className={"create_users_page__buttons_container"}>
                <Button type="button" className="btn btn-primary" onClick={() => {
                    onCancel()
                }}>Cancel</Button>
                <Button type="button" className="btn btn-primary" onClick={() => {
                    createUsersFunction()
                }}>Upload users</Button>
            </div>
        </> : <></>
    }

    const renderLegendForUsersWithErrorsTable = () => {
        return transformUsersWithErrorsAndCheckForGeneralError().length ?
            <div className={"w-100"}>
                <div className={"fw-bold"}>Errors table legend</div>
                <ul>
                    <li className={"text-muted"}>
                        Hovering on red fields will display the error message for that specific field
                    </li>
                    <li className={"text-muted"}>
                        If all fields are red and and the error message says "There was an error creating this user",
                        the user creation failed for unspecified reason. Please do a spell check for certain fields.
                    </li>
                </ul>
            </div>
            :
            <></>
    }

    return <BaseContainer title='Create users'>
        <div className={"create_users_page"}>

            {renderTheDropzone()}


            {renderSelectedUsersTable()}
            {renderCancelAndUploadUsersButtons()}
            {renderLegendForUsersWithErrorsTable()}
            {renderCreateUsersResponseUsersWithErrors()}
            {renderCreateUsersResponseUsersWithoutErrors()}
            {renderOnClearAndRepeatButton()}

        </div>
    </BaseContainer>;
}

export default CreateUsersPage;
