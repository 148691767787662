import { useRef, useState, useEffect } from "react"


export const useInfiniteScroll = ({
                                      loadMore,
                                      rootMarginInPx = 0,
                                      threshold = 0.1,
                                      root = null,
                                  }) => {
    const [isLoading, setIsLoading] = useState(false)
    const observer = useRef(null)

    const observerElement = useRef(null)

    useEffect(() => {
        if (isLoading) return

        if (observer.current) observer.current.disconnect()

        observer.current = new IntersectionObserver(
            async (entries) => {

                const [entry] = entries

                if (entry.isIntersecting) {
                    setIsLoading(true)
                    await loadMore()

                    setIsLoading(false)
                }
            },
            { root: root, rootMargin: `${rootMarginInPx}px`, threshold: threshold },
        )

        if (observerElement.current) {
            observer.current.observe(observerElement.current)
        }

        return () => {
            if (observer.current) observer.current.disconnect()
        }
    }, [isLoading, loadMore])

    return { isLoading, lastElementRef: observerElement }
}
