import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, InputGroup, Modal, Button } from "react-bootstrap";
import RedAsterisk from '../helpers/RedAsterisk';

const ModalAddress = ({ closeFn = () => null, open = false, submitFn = (obj) => null, address, addressTypes, allCountries, allCounties, allTowns }) => {
    const [validated, setValidated] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const handleSubmit = event => {
        const form = event.currentTarget;
        if (!inProgress && form.checkValidity() === true) {
            let data = address;
            var elements = Array.from(form.elements)
            elements.map(el => {
                if(el.localName ==='input')
                {
                    if(el.type === "number"){
                        data[el.id]= parseInt(el.value,0);
                    }
                    else if(el.type ==='checkbox')
                    {
                        //do nothing
                    }
                    else if(el.type ==='switch')
                    {
                        //do nothing
                    }
                    else if(el.type ==='date')
                    {
                        data[el.id]= el.value;
                    }
                    else if(el.readOnly!==true){
                        
                        data[el.id]=  el.value;
                    }
                }
                if(el.localName ==='select')
                {
                    data[el.id+'Name']=el.selectedOptions[0].text;
                    data[el.id]= parseInt(el.value,0);
                }
            })
            data['fullAddress'] = data['addressLine1'].concat(', ' + data['townIdName'] + ', ' + data['countyName'] + ', ' + data['postcode'])
            setInProgress(true);
            submitFn(data);
            setInProgress(false);
            closeFn();
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
    };

    useEffect(() => {
        // setAddressDetailLoading(true);
        // setAddressDetailLoading(false);
    }, [address]);

    function ShowAddress(props) {  
        return (
            <Container fluid>
                <Row>
                    <h3>Address</h3>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}  id="address-form">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="priority">
                                    <Form.Label>Priority</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control type="input" min="0" placeholder="Priority" 
                                            defaultValue={props.address.priority? props.address.priority : ""} 
                                            className="no-border" 
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide priority.
                                            </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="addressType">
                                    <Form.Label>Address  <RedAsterisk />
                                    </Form.Label>                                    
                                    <InputGroup hasValidation>
                                        <Form.Select aria-label="Default select example" 
                                            defaultValue={props.address.addressType} 
                                            required>
                                            <option disabled value="">Address Type</option>
                                            {addressTypes.map(empType => (
                                                <option key={empType.id} value={empType.id} >
                                                    {empType.value}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Address Type.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="addressLine1">
                                    <Form.Label>Address Line 1 <RedAsterisk /></Form.Label>
                                    <InputGroup hasValidation>
                                    <Form.Control type="input" 
                                        placeholder="Address Line 1" 
                                        defaultValue={props.address.addressLine1? props.address.addressLine1 : ""}
                                        className="no-border" required
                                        />
                                        <Form.Control.Feedback type="invalid" >
                                            Please provide address Line 1.
                                        </Form.Control.Feedback>
                                    </InputGroup>                                  
                                </Form.Group>
                                
                                <Form.Group className="mb-3" controlId="addressOthers">
                                    <Form.Label>Address Others</Form.Label>
                                    <Form.Control type="input" placeholder="Address Others"  defaultValue={props.address.addressOthers? props.address.addressOthers : ""} className="no-border" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="townId">
                                    <Form.Label>Town  <RedAsterisk/>
                                    </Form.Label>
                                    <Form.Select aria-label="Town"
                                        defaultValue={props.address.townId} 
                                        required>
                                        <option disabled value="">Select Town</option>                                    
                                        {allTowns.map(empType => (
                                            <option key={empType.id} value={empType.id} >
                                                {empType.value}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Town.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="county">
                                    <Form.Label>County  <RedAsterisk />
                                    </Form.Label>
                                    <Form.Select aria-label="county" defaultValue={props.address.county} required > 
                                        <option disabled value="">Select County</option>
                                        {allCounties.map(empType => (
                                            <option key={empType.id} value={empType.id} >
                                                {empType.value}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select County.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="postcode">
                                    <Form.Label>Postcode <RedAsterisk /></Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control type="input" placeholder="Postcode" defaultValue={props.address.postcode? props.address.postcode : ""}  className="no-border" required/>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide Postcode.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>Country  <RedAsterisk />
                                    </Form.Label>
                                    <Form.Select aria-label="country" defaultValue={props.address.country} required> 
                                    <option disabled value="">Select Country</option>
                                    {allCountries.map(empType => (
                                        <option key={empType.id} value={empType.id} >
                                            {empType.value}
                                        </option>
                                    ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Country.
                                    </Form.Control.Feedback> 
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Container>
        );
    }
    
    return (
        <Modal size="lg" aria-labelledby="example-modal-sizes-title-lg" show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>          
                <Modal.Title>{address.addressType ? 'Edit Address' : 'Add Address'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <ShowAddress address={address}/> 
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
                {inProgress ?
                    <Button variant="primary" disabled>
                        <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                        Please wait...
                    </Button>
                    :
                    <Button variant="primary" type="submit" form="address-form">
                        Save
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAddress;