import { useEffect } from 'react';

import BaseContainer from '../components/BaseContainer';
import UserProfilesTable from '../components/UserProfilesTable';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

export default function TeamProfile(props) {

    const { applicationTrainingPlanSummaryS, fetchApplicationTrainingPlanSummaryS } = useLmsStore(state =>
    ({
        applicationTrainingPlanSummaryS: state.applicationTrainingPlanSummaryS.data,
        fetchApplicationTrainingPlanSummaryS: state.fetchApplicationTrainingPlanSummaryS
    }), shallow)

    // on load
    useEffect(() => {
        fetchApplicationTrainingPlanSummaryS();
    }, []);


    return (
        <BaseContainer title='Team Profiles'>
            <UserProfilesTable
                data={applicationTrainingPlanSummaryS}
            />
        </BaseContainer>);
}
