//import React from 'react';
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import BaseContainer from '../components/BaseContainer';

const About = ({ closeFn = () => null, open = false, personSummaryError, personSummaryLoading, personSummary}) => {
    const currentYear = new Date().getFullYear();
    const copyrightSymbol = String.fromCharCode(169);
    
  return (
    <BaseContainer title='About this application'>
            <Row>
                <Col>
                    <Card className='shadow p-2'>
                        <Card.Body>
                            <p>Our learning portal has been designed to provide users with an intuitive and engaging way to access all their learning content on the go and businesses with a cost-effective way to easily administer and track every aspect of their learning cycle.</p>
                            {/* <h5 className='mt-4'>Some words about this page</h5> */}
                           
                            <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="application" className="col-sm-4 col-form-label">Application: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="application" readOnly tabIndex="-1" disabled value="LMS Portal 2022"/>
                                </div>
                            </div>
                            {/* <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="release" className="col-sm-4 col-form-label">Release: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="release" readOnly tabIndex="-1" disabled/>
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="version" className="col-sm-4 col-form-label">Version: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="version" readOnly tabIndex="-1" disabled  value="3.2 alpha"/>
                                </div>
                            </div>
                            {/* <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="build" className="col-sm-4 col-form-label">Build: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="build"  readOnly tabIndex="-1" disabled/>
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="copyright" className="col-sm-4 col-form-label">Copyright: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="copyright" readOnly tabIndex="-1" disabled value={`${copyrightSymbol}${currentYear} WDR Limited`} />
                                </div>
                            </div>
     
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </BaseContainer>
  );
};

export default About;