import { useMemo, useEffect, useState } from 'react';
import BaseContainer from "../components/BaseContainer";
import { Row } from 'react-bootstrap';
import MaterialReactTable from 'material-react-table';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useLmsStore, { hrefToAdminServer } from '../hooks/useLmsStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay} from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { BeginButton } from '../components/CustomButtons'
import { faAward,faUserGear } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';


function ExternalTitle(props) {
    return (<>{props.title} <FontAwesomeIcon size='xs' icon={faArrowUpRightFromSquare} className='text-mutted' /></>);
};
export default function UKPNServiceAdminDashboard(props) {
  
    const { personBasic, fetchPersonBasic } = useLmsStore(state =>
        ({
            personBasic: state.personBasic.data,
            fetchPersonBasic: state.fetchPersonBasic
        }), shallow);
    
        useEffect(() => {
            fetchPersonBasic();
               }, []);
    return (<BaseContainer title='UKPN Services Admin Dashboard' >
        <Row className='gx-4 gy-4 mt-0'>
        <BeginButton title={<ExternalTitle title='Team Competencies' />} description='View, upload demonstrations and approve people competencies.' icon={faUserGear} href={hrefToAdminServer(personBasic, 'portal.teamcompetencies')} target='admin-tab' />
        <BeginButton title={<ExternalTitle title='People Qualifications' />} description='View people qualifications.' icon={faAward} href={hrefToAdminServer(personBasic, 'portal.teamqualifications')} target='admin-tab' />     
          
        </Row>
    </BaseContainer>);
}