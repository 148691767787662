import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalAbout = ({ closeFn = () => null, open = false, personSummaryError, personSummaryLoading, personSummary}) => {
    const currentYear = new Date().getFullYear();
    const copyrightSymbol = String.fromCharCode(169);
    
    return (
        <Modal fullscreen='md-down' show={open} onHide={closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>About this application</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                        <form>
                            <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="application" className="col-sm-4 col-form-label">Application: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="application" readOnly value="LMS Portal 2022"/>
                                </div>
                            </div>
                            {/* <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="release" className="col-sm-4 col-form-label">Release: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="release" readOnly />
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="version" className="col-sm-4 col-form-label">Version: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="version" readOnly  value="3.2 alpha"/>
                                </div>
                            </div>
                            {/* <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="build" className="col-sm-4 col-form-label">Build: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="build" readOnly />
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <label style={{fontWeight: 'bold'}} htmlFor="copyright" className="col-sm-4 col-form-label">Copyright: </label>
                                <div className="col-sm-8">
                                    <input style={{border:'none'}} type="text" className="form-control" id="copyright" readOnly value={`${copyrightSymbol}${currentYear} WDR Limited`} />
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>   
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAbout;