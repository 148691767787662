import { useEffect, useMemo, useState } from 'react';
import { Badge, Button, Col, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";
import { columnIconLearningItem, TableContainer } from '../helpers/tableHelper';
import { isSupportedDate, toLocaleDateString } from '../helpers/formatHelper';
import UserCard from './UserCard';
import { FilterClearButton, FilterBadgeButton } from '../components/CustomButtons';
import MaterialReactTable from 'material-react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faCircleCheck, faCirclePlay, faTrash, faCircleXmark, faFileArrowDown, faArrowUp, faArrowDown, faChevronsUp,faChevronsDown } from '@fortawesome/pro-solid-svg-icons';
import ModalElearningContinue from './ModalElearningContinue';
import ModalConfirmation from './ModalConfirmation';
import ModalElearningRefresher from './ModalElearningRefresher';
import ModalUserEventInfo from './ModalUserEventInfo';
import ModalEvent from './ModalEvent';
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { useSignalRHandler } from '../hooks/useSignalRHandler';
import { useSignalrConnection } from '../hooks/useSignalrConnection';
import moment from 'moment';
import usePrevious from '../hooks/usePrevious';
import useLmsStore, { postRemoveToPlan, getSynopsis, responseData, getElearningLinks, postCompleteTrainingPlanAndPathwayNew, setMyActiveSortedTrainingPlanNew, inValidateMyActiveSortedTrainingPlan, WDR_HUB_URL, REFRESH_AFTER, updateLearningPlanOpenNew,updatePathwayOpen,updatePathwayOpenTeam, updatePathWaiDetailsOpen, getCanCancelBooking, hasPermission } from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

const filteredData = (rows, id) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && filterTodo(x) && !isPathwayItem(x)) || (id === -2 && isOverdue(x) && !isPathway(x)) || (id === -3 && x.isMandatory  && !isPathway(x)) || (id===-4 && filterTodo(x) && x.expiryDate!==undefined && x.expiryDate!==null) || (id===-5 && x.statusName==='Awaiting Dates' && !isPathway(x)) || (id === -6 && filterComplete(x) )) : rows.filter(x => filterTodo(x) && !isPathwayItem(x));
const filteredDataS = (rows, id) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && filterTodo(x) ) || (id === -2 && isOverdue(x) && !isPathway(x)) || (id === -3 && x.isMandatory  && !isPathway(x)) || (id===-4 && filterTodo(x) && x.expiryDate!==undefined && x.expiryDate!==null) || (id===-5 && x.statusName==='Awaiting Dates' && !isPathway(x)) || (id === -6 && filterComplete(x))) :rows.filter(x => filterTodo(x) && !isPathwayItem(x));

const filterComplete = x => x && (isCompleted(x) || isAttended(x))  && !isPathway(x);
const filterTodo = x => x && !isCompleted(x) && !isAttended(x);
//const filteredData = (rows, id, search) => !Array.isArray(rows) ? [] : id < 0 ? rows.filter(x => (id === -1 && !isCompleted(x) && !isAttended(x)  &&  search==null) || (id === -2 && isOverdue(x) && !isPathway(x)) || (id === -3 && x.isMandatory  && !isPathway(x)) || (id === -4 && (isCompleted(x) || isAttended(x))  && !isPathway(x))) : rows;

const isPathway = x => x &&  (x.regPathwayId != null && x.learnProgId==null);
const isPathwayItem = x => x &&   x.learnProgId!=null;

const isCompleted = x => x && (x.statusName === 'Completed' || x.statusName==='Completed - Colleague' || (x.elearningInfo && x.elearningInfo.completionStatusName === 'Complete' && x.elearningInfo.sucessStatusName !== 'Failed'));

const isConfirmed = x => x && (x.statusName === 'Confirmed' || (x.instructorInfo && x.instructorInfo.statusName === 'Confirmed'));
const isAttended = x => x && (x.statusName === 'Attended' || (x.instructorInfo && x.instructorInfo.statusName === 'Attended'));

const canPreviewElearning = (rowData) => rowData && isCompleted(rowData);

const isOverdue = x => x && !isCompleted(x) && (isSupportedDate(x.expiryDate) && (new Date(x.expiryDate) < new Date() ||  new Date(x.mandatoryExpectedDate) < new Date()))  ;

const isPassed = x => x && x.elearningInfo && x.elearningInfo.sucessStatusName === 'Passed';
const canDoElearning = (rowData) => {
    if (rowData) {
        if (rowData.elearningInfo) {
            if (canPreviewElearning(rowData))
                return true;

            //Chekc if there is setting for max numbers of attempts and if yes then 
            if (rowData.elearningInfo.maxAttempts && rowData.elearningInfo.totalAttemptGroups) {
                if (rowData.elearningInfo.maxAttempts <= rowData.elearningInfo.totalAttemptGroups) {
                    return false;
                }
            }
        }
    }
    return true;
}



const hideAggregatedCell = ({ cell, table }) => { return cell.id.startsWith('pwgrpName:') ? { sx: { display: 'none' } } : {} };

export default function LearningPlanTableNew(props) {
    const [canHaveNewAttempt, setCanHaveNewAttempt] = useState("");
    const [completedCount, setCompletedCount] = useState(null);
    const [continueLink, setContinueLink] = useState("");
    const [dataActivePlan, setDataActivePlan] = useState(null);
    const [filterId, setFilterId] = useState(-1);
    const [isOpenElearningContinue, setisOpenElearningContinue] = useState(false);
    const [isOpenElearningRefresher, setisOpenElearningRefresher] = useState(false);
    const [isOpenEvent, setisOpenEvent] = useState(false);
    const [refLearningItemData, setRefLearningItemData] = useState("");
    const [isOpenNewAttemptConfirmation, setisOpenNewAttemptConfirmation] = useState(false);
    const [mandatoryCount, setMandatoryCount] = useState(null);
    const [newAttemptLink, setNewAttemptLink] = useState("");
    const [overdueCount, setOverdueCount] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedLearningItem, setSelectedLearningItem] = useState(-1);
    const [refLearningItem, setRefLearningItem] = useState(-1);
    const [selectedRows, setSelectedRows] = useState([]);    
    const [showSynopsis, setShowSynopsis] = useState(false);
    const [synopsisSections, setSynopsisSections] = useState([]);
    const [synopsisTitle, setSynopsisTitle] = useState(null);
    const [tabId, setTabId] = useState('-1');
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableLoadingPw, setTableLoadingPw] = useState(false);   
    const [todoCount, setToDoCount] = useState(null);
    const [awaitingCount, setAwaitingCount] = useState(null);
    const [upComingCount, setUpcomingCount] = useState(null);
    const [isElearningOpenId, setIsElearningOpenId] = useState(0);
    const [isElearningOpen, setIsElearningOpen] = useState(false);
    const [trainingPlanId, setTrainingPlanId] = useState(0);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showByGroup, setShowByGroup] = useState(false);
    const [searchString, setSearchString] = useState(decodeURI(searchParams.get("search") || ''));
    const [eventData, setEventData] = useState([]);
    const [openClosePw, setOpenClosePw] = useState(false);
    const [isOpenUserEvent, setIsOpenUserEvent] = useState(false);
    const [userEventInfo, setUserEventInfo] = useState(null);
   
    useEffect(() => {
        return () => {
       setCustomFilterData(-1);
       setRefLearningItem(-1);
        };
    }, []);


    useEffect(() => {
       
        setCustomFilterData(filterId);
        setShowByGroup(tabId !== '0' && (!searchString || searchString === '') && !tableLoading);
    }, [tabId, searchString, tableLoading]);

    // SignalR section start
    const [srConnection, setSRConnection] = useState(null);
    const { hubConnectionState, error } = useSignalrConnection(srConnection);

    useEffect(() => {
        setSRConnection(
            new HubConnectionBuilder()
                .withUrl(WDR_HUB_URL, { skipNegotiations: true, transport: HttpTransportType.ServerSentEvents })
                .withAutomaticReconnect()
                .build()
        )
    }, [setSRConnection]);
    
    useEffect(() => { if (error) console.error(error); }, [error]);
    useEffect(() => { if (process.env.NODE_ENV === 'development') console.log(`SignalR Connection: ${hubConnectionState}`); }, [hubConnectionState]);
    useSignalRHandler(srConnection, "svr_eLearning_close", (message) => onSrveLearningClose(message));
    useEffect(() => {
        
        if (isElearningOpenId > 0) {
          updateLearningPlanOpenNew(useLmsStore, isElearningOpenId, isElearningOpen);
            
        }
    
    }, [isElearningOpen, isElearningOpenId]);

    const onCloseRow = (learningItemId) => {
        selectedRows.forEach((row) => {
            if (row.learningItemId === learningItemId) {
                row.isActive = false;
                row.isOpen = false;
            }
        });

        setIsElearningOpenId(learningItemId);
        setIsElearningOpen(false);
        setSelectedRows(selectedRows);
        
    }

    const onSrveLearningClose = (message) => {
      //Check if it is not a preview, so information can be updated. If it is preview, it does not require any update
        if (!message.isPreview) {
           
             //Update the store with the latest status and elearning info
            if (setMyActiveSortedTrainingPlanNew(useLmsStore, message)) {
                     //Check if we need to unlock the items, if it is coming from SAvePlayer or from postaback with a complete status
                    if (message.unlockItem) {
                        onCloseRow(message.learningItemId);
                    }
            }        
        }
        else{
            onCloseRow(message.learningItemId);
        }
       

        return;
    };

  /*  const { myActiveSortedTrainingPlanNew } = useLmsStore(state =>
    ({
        myActiveSortedTrainingPlanNew: state.myActiveSortedTrainingPlanNew.data
    }), shallow)
*/
    const columnIconSynopsis = {
        accessorKey: 'hasSynopsis',
        header: 'Has Synopsis',
        maxSize: 75,
        minSize: 75,
        muiTableBodyCellProps: hideAggregatedCell,
        Cell: ({ cell, row }) => cell.getValue() === true && row.original.learningItemId > 0 ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Synopsis</Tooltip>}>
            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleShowSynopsis(row.original.learningItemId,row.original.name)}>
                <FontAwesomeIcon size='xl' icon={faCircleInfo} className='text-primary' />
            </Button>
        </OverlayTrigger>) : ''
    };

    const columnIconSynopsisLink = {
        accessorKey: 'synopsisLink',
        header: 'Synopsis',
        maxSize: 75,
        minSize: 75,
       muiTableBodyCellProps: hideAggregatedCell,
        Cell: ({ cell, row }) =>  row.original.synopsisLink !='' && row.original.synopsisLink!=undefined? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Course outline</Tooltip>}>
            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleOpenSynopsisLink(row.original.synopsisLink) } >
            <FontAwesomeIcon size='xl' icon={faFileArrowDown} className='text-primary' />              
            </Button>
        </OverlayTrigger>) : ''
    };

    const renderTxtForEvent = (rowData) => {

        if (rowData.instructorInfo){
        if (isAttended(rowData)){
          if (rowData.instructorInfo.venueCity!=undefined && rowData.instructorInfo.venueCity!='')
            return `Confirmation of you attendance for the event on the ${rowData.name} for the event being held at ${rowData.instructorInfo.venueName}, ${rowData.instructorInfo.venueCity} - Room ${rowData.instructorInfo.roomName} on ${moment(rowData.instructorInfo.startDate).format("DD/MM/YYYY")} between ${rowData.instructorInfo.displayStartTime} and ${rowData.instructorInfo.displayEndTime}`;
         else  
         return `Confirmation of you attendance for the event on the ${rowData.name} for the event being held at ${rowData.instructorInfo.venueName} - Room ${rowData.instructorInfo.roomName} on ${moment(rowData.instructorInfo.startDate).format("DD/MM/YYYY")} between ${rowData.instructorInfo.displayStartTime} and ${rowData.instructorInfo.displayEndTime}`;
      
        }          
            else
            return  `Confirmation of your booking on the ${rowData.name} for the event being held at ${rowData.instructorInfo.venueName}, ${rowData.instructorInfo.venueCity} - Room ${rowData.instructorInfo.roomName} on ${moment(rowData.instructorInfo.startDate).format("DD/MM/YYYY")} between ${rowData.instructorInfo.displayStartTime} and ${rowData.instructorInfo.displayEndTime}`;    
        }
        return "";
        };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const openModal= (data) => {      
      setIsModalOpen(true);
      setModalData(data);      
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };  

    const columnAboutPW = {
        accessorKey: 'name',
        header: 'Course',
        muiTableBodyCellProps: ({ cell, table }) => { return cell.id.startsWith('pwgrpName:') ? { sx: { display: 'none' } } : { classes: { root: 'fw-unset' } } },
        Cell: ({ cell, row }) => (
            <>
                <div className='fw-bold'>{cell.getValue()}</div>
            
                     {row.original.description ? <div>{row.original.description}</div> : null}
                <Row className='gx-1' >
                    {isCompleted(row.original) && row.original.learningItemTypeId !== 2 ?
                        <Col xs='auto'>
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completed</Tooltip>}>
                                <FontAwesomeIcon size='xl' icon={faCircleCheck} className='text-success' />
                            </OverlayTrigger>
                        </Col> : <></>}
                   
                      
                    {row.original.elearningInfo ?
                        row.original.elearningInfo.totalAttemptGroups >= 0 ?
                            <>
                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Number of times that a new attempt has been made at this eLearning course.</Tooltip>}>
                                        <Badge bg='secondary'>
                                            Attempt {row.original.elearningInfo.totalAttemptGroups}
                                        </Badge>
                                    </OverlayTrigger>
                                </Col>
                                {row.original.elearningInfo.maxAttempts > 0 ?
                                    <Col xs='auto'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>The max number of attempts for this course is.</Tooltip>}>
                                            <Badge bg='secondary'>
                                                Max Attempts: {row.original.elearningInfo.maxAttempts}
                                                {row.original.elearningInfo.totalAttemptGroups >= row.original.elearningInfo.maxAttempts ? <span> done</span> : null}
                                            </Badge>
                                        </OverlayTrigger>
                                    </Col> : null}
                                
                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completion Status - {row.original.elearningInfo.completionStatusName} ({toLocaleDateString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                        <Badge bg={isCompleted(row.original) ? 'success' : 'danger'}>CS</Badge>
                                    </OverlayTrigger>
                                </Col>
                                { isCompleted(row.original) ?
                                  isPassed(row.original)?
                                <Col xs='auto'>                          
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.sucessStatusName} ({toLocaleDateString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                        <Badge bg='success'>
                                            SS
                                        </Badge>
                                    </OverlayTrigger>
                                </Col>: <></>
                                :
                                <Col xs='auto'>                          
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.sucessStatusName} ({toLocaleDateString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                    <Badge bg={isPassed(row.original) ? 'success' : 'warning'}>
                                        SS
                                    </Badge>
                                </OverlayTrigger>
                                </Col>
                                    }
                            </> : null : null}
                
 
                </Row>
                { (isSupportedDate(row.original.expiryDate) || isConfirmed(row.original) || isAttended(row.original)) && row.original.instructorInfo!=null?
                   
                   <div  style={{opactity:1,marginTop:'0.5rem',color: '#00008B	'}}>
                      { isConfirmed(row.original) || isAttended(row.original)?
                      <>
                     <strong  >Event Date: {toLocaleDateString(row.original.instructorInfo.startDate)}</strong>, {row.original.instructorInfo.eventDates!=null? <>{row.original.instructorInfo.eventDates.length} {row.original.instructorInfo.eventDates.length>1?' days, ': ' day, '}</>: '1 day, '}
                         {row.original.instructorInfo.displayStartTime}-{row.original.instructorInfo.displayEndTime} 
                          <Button  variant='primary px-3 w-0 mx-3' style={{lineHeight: '1rem'}} className='pt-0 pb-0' onClick={() => handleOpenUserEventInfo(row.original)}>
                          <Badge   className='p-0 w-0 h-0'  >
                             Event Detail                             
                          </Badge>                            
                           </Button>  
                           </>:<></>}
                     {isSupportedDate(row.original.expiryDate) ?
                     <div>
                     <strong >Due Date: {toLocaleDateString(row.original.expiryDate)}</strong>
                     </div> : null}
                                                    
                     </div> : null}
          
               
            </>
        )
    };

    const columnAbout= {
        accessorKey: 'name',
        header: 'Course',
        muiTableBodyCellProps: ({ cell, table }) => { return cell.id.startsWith('pwgrpName:') ? { sx: { display: 'none' } } : { classes: { root: 'fw-unset' } } },
        Cell: ({ cell, row }) => (
            <>
                <Row>
                <Col xs="6" >
                <div className='fw-bold'>{cell.getValue()}</div>
            
                { row.original.description ? (row.original.description.length>190? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'> { row.original.description}</Tooltip>}>
            {<div> { row.original.description.substring(0,190)} ...</div>}
        </OverlayTrigger>): <diV>{row.original.description}</diV> ): ''
    }
                   </Col>
                <Col xs='1'>
               { row.original.hasSynopsis ? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Synopsis</Tooltip>}>
            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleShowSynopsis(row.original.learningItemId,row.original.name)}>
                <FontAwesomeIcon size='xl' icon={faCircleInfo} className='text-primary' />
            </Button>
        </OverlayTrigger>) : ''
    }
                    </Col>
                    <Col xs='1'>
                    {row.original.synopsisLink !='' && row.original.synopsisLink!=undefined? (<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Course outline</Tooltip>}>
            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleOpenSynopsisLink(row.original.synopsisLink) } >
            <FontAwesomeIcon size='xl' icon={faFileArrowDown} className='text-primary' />              
            </Button>
        </OverlayTrigger>) : ''}
                    </Col>
                    <Col xs='2'>                   
                  
                    {row.original.statusName}
                    
                    </Col>
                </Row>
                <Row className='gx-1' >
                    {isCompleted(row.original) && row.original.learningItemTypeId !== 2 ?
                        <Col xs='auto'>
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completed</Tooltip>}>
                                <FontAwesomeIcon size='xl' icon={faCircleCheck} className='text-success' />
                            </OverlayTrigger>
                        </Col> : <></>}
                                        
                    {row.original.elearningInfo ?
                        row.original.elearningInfo.totalAttemptGroups > 0 ?
                            <>
                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Number of times that a new attempt has been made at this eLearning course.</Tooltip>}>
                                        <Badge bg='secondary'>
                                            Attempt {row.original.elearningInfo.totalAttemptGroups}
                                        </Badge>
                                    </OverlayTrigger>
                                </Col>
                                {row.original.elearningInfo.maxAttempts > 0 ?
                                    <Col xs='auto'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>The max number of attempts for this course is.</Tooltip>}>
                                            <Badge bg='secondary'>
                                                Max Attempts: {row.original.elearningInfo.maxAttempts}
                                                {row.original.elearningInfo.totalAttemptGroups >= row.original.elearningInfo.maxAttempts ? <span> done</span> : null}
                                            </Badge>
                                        </OverlayTrigger>
                                    </Col> : null}
                                
                                <Col xs='auto'>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Completion Status - {row.original.elearningInfo.completionStatusName} ({toLocaleDateString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                        <Badge bg={isCompleted(row.original) ? 'success' : 'danger'}>CS</Badge>
                                    </OverlayTrigger>
                                </Col>
                                { isCompleted(row.original) ?
                                  isPassed(row.original)?
                                <Col xs='auto'>                          
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.sucessStatusName} ({toLocaleDateString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                        <Badge bg='success'>
                                            SS
                                        </Badge>
                                    </OverlayTrigger>
                                </Col>: <></>
                                :
                                <Col xs='auto'>                          
                                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Satisfaction Status - {row.original.elearningInfo.sucessStatusName} ({toLocaleDateString(row.original.elearningInfo.dateUpdated)})</Tooltip>}>
                                    <Badge bg={isPassed(row.original) ? 'success' : 'warning'}>
                                        SS
                                    </Badge>
                                </OverlayTrigger>
                                </Col>
                                    }
                            </> : <></> : <></>}
                {row.original.isOpen?
                <>                
                {
                row.original.children!=null?<>             
               <Row >
                <div>{' '}</div>
                <MaterialReactTable
                columns={tableColumnsPw}
                data={withSelectedRows(row.original.children, selectedRows)}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={false}
                enableGrouping={false}
                enableStickyHeader={true}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                muiTableBodyRowProps= {{ hover: false, sx: { borderBottom: '1.25rem solid var(--app-nav-primary)',  } }}
                muiTableContainerProps={{ sx: { backgroundColor: 'var(--app-nav-primary)', padding: '0 1rem 1rem 1rem',  margin: '0.5rem 0rem 0rem 0rem' } }}
                muiTableHeadProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                muiToolbarAlertBannerProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                muiTopToolbarProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                  initialState={{
                    //globalFilter: decodeURI(searchParams.get("search") || ''),
                   // globalFilter: searchString,
                   // showGlobalFilter: false
                }}
                state={
                    {
                        expanded: true,
                        grouping: ['pwgrpName'],
                        isLoading: tableLoadingPw,
                     
                    }
                    }
            />
          </Row>
                            
                           </>:<></> 
    }        
                                  
                </>
                           :<></>
                }
                        
                </Row>
                { (isSupportedDate(row.original.expiryDate) || isConfirmed(row.original) || isAttended(row.original)) && row.original.instructorInfo!=null?
                   
                      <div  style={{opactity:1,marginTop:'0.5rem',color: '#00008B	'}}>
                         { isConfirmed(row.original) || isAttended(row.original)?
                         <>
                        <strong  >Event Date: {toLocaleDateString(row.original.instructorInfo.startDate)}</strong>, {row.original.instructorInfo.eventDates!=null? <>{row.original.instructorInfo.eventDates.length} {row.original.instructorInfo.eventDates.length>1?' days, ': ' day, '}</>: '1 day, '}
                            {row.original.instructorInfo.displayStartTime}-{row.original.instructorInfo.displayEndTime} 
                             <Button  variant='primary px-3 w-0 mx-3' style={{lineHeight: '1rem'}} className='pt-0 pb-0' onClick={() => handleOpenUserEventInfo(row.original)}>
                             <Badge   className='p-0 w-0 h-0'  >
                                Event Detail                             
                             </Badge>                            
                              </Button>  
                              </>:<></>}
                        {isSupportedDate(row.original.expiryDate) ?
                        <div>
                        <strong >Due Date: {toLocaleDateString(row.original.expiryDate)}</strong>
                        </div> : null}
                                                       
                        </div> : null}
             

               
            </>
        )
    };

    const pwRow = (item) => {
  return (
        <>
    {item.name}  - {item.learningItemTypeId}
  </>
  )
    }

    const columnAction = {
        accessorKey: 'id',
        header: 'Id',
        maxSize: 200,
        minSize: 200,
        muiTableBodyCellProps: hideAggregatedCell,
        Cell: ({ cell, row }) => {
            return <>
        
                {canShowStart(row.original) ? row.original.learningItemTypeId === 2  ?
                     !isAttended(row.original)?
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                        overlay={< Tooltip className='cls-theme-tooltip' > {(isConfirmed((row.original)) ? 'Cancel ' : 'Book ') + row.original.name}</Tooltip >}>

                        <Button variant='clear' onClick={() => handleStart(row.original)}>
                            <FontAwesomeIcon size='xl' icon={isConfirmed(row.original) ? faCircleXmark:faCirclePlay} className='text-primary' /><span className='m-2 text-body'>{isConfirmed(row.original) ? 'Cancel' : 'Book'}</span>
                        </Button>

                    </OverlayTrigger>
                        : <></>
                    :
                    canShowStart(row.original) && row.original.learningItemTypeId === 3 && (row.original.isOpen) && row.original.isSelected && !isCompleted(row.original)?
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                            overlay={< Tooltip className='cls-theme-tooltip' > {(isCompleted(row.original) ? 'Review ' : 'Start ') + row.original.name}</Tooltip >}>

                            <Button variant='clear' onClick={() => toggleModalElearningRefresher(row.original.learningItemId)}>
                                <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>Refresh</span>
                            </Button>

                        </OverlayTrigger>
                        :
                        <>
                            {!isPathway(row.original)?
                            !row.original.isSelected ?
                                < OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                                    overlay={< Tooltip className='cls-theme-tooltip' > {(isCompleted(row.original) ? 'Review2 ' : 'Start ') + row.original.name}</Tooltip >}>
                                    {/* <Button variant='clear' onClick={() => handleStart(row.original)}> */}
                                    <Button variant='clear' onClick={() => isCompleted(row.original) ? openModal(row.original) : handleStart(row.original)}>
                                        <FontAwesomeIcon size='xl' icon={faCirclePlay} className='text-primary' /><span className='m-2 text-body'>{isCompleted(row.original) ? 'Review ' : 'Start '}</span>
                                    </Button>
                                </OverlayTrigger >
                                :
                                <Button variant="clear" disabled>
                                    <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                </Button>
                                :
                                row.original.isSelected ?
                                <Button variant="clear" disabled>
                                    <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                </Button>
                                :
                                <Button variant='clear'
                                aria-label="expand row"
                                size="small"
                                onClick={() =>setRowPwSelection(row.original)}>                               
                                   <FontAwesomeIcon size='xl' icon={row.original.isOpen?faChevronsUp: faChevronsDown} className='text-primary' /><span className='m-2 text-body'>{row.original.isOpen?'Close':'Open'}</span>                             
                               
                              </Button>
                             
                            }
                        </>
                    :
                    row.original.IsPathway?'Expand':
                    ''
                }
                {row.original.canBeRemoved && !isCompleted(row.original)?
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }}
                        overlay={< Tooltip className='cls-theme-tooltip' > {'Remove ' + row.original.name}</Tooltip >}>
                        <Button variant='clear' onClick={() => handleRemove(row.original)}>
                            <FontAwesomeIcon size='xl' icon={faTrash} className='text-primary' />
                        </Button>
                    </OverlayTrigger >
                    :
                    <>
                    </>
                }
            </>
        }
    };

    const columnActionPw = {
        accessorKey: 'id',
        header: 'Id',
        maxSize: 200,
        minSize: 200,
        muiTableBodyCellProps: hideAggregatedCell,
        Cell: ({ cell, row }) => {
            return <>
                {isPathway(row.original)?
                          <>
                                <Button variant='clear'
                                aria-label="expand row"
                                size="small"
                                onClick={() =>setRowPwSelectionTeam(row.original)}>                               
                                   <FontAwesomeIcon size='xl' icon={row.original.isOpen?faChevronsUp: faChevronsDown} className='text-primary' /><span className='m-2 text-body'>{row.original.isOpen?'Close':'Open'}</span>                             
                               
                              </Button>
                             
                            </>
                            :<></>                       
                   
                }
             
            </>
        
    }
}

    const handleShowSynopsis = (learningItemId,itemName) => {
        getSynopsis(learningItemId).then((response) => {
            let data = responseData(response, []);
            setSynopsisSections(data);
         
            setShowSynopsis(true);
            setSynopsisTitle(itemName);
        });
    };

    const synopsisSectionsContent=synopsisSections.map((item,id)=>{
        return <div key={id}>
          <h5>{item.tagTitle}</h5>
          <p><span dangerouslySetInnerHTML={{ __html:  item.tagHtml }} /></p>
        </div>
    })

    const handleCloseSynopsis = (event) => {
        setShowSynopsis(false);
    };

    const handleCloseConfirmDelete = (event) => {
        setShowConfirmDelete(false);
    };

    const handleReferenceMaterialStart = async (rowData) => {

        if (rowData.url !== "") {
            if (!isCompleted(rowData)) {
                await postCompleteTrainingPlanAndPathwayNew(useLmsStore, rowData.learningItemId);
            }
            onCloseRow(rowData.learningItemId);
            //Always open he link
            window.open(rowData.url, "_blank");
        }
    }


    const handleOpenSynopsisLink = async (url) => {
        window.open(url, "_blank");
    }
    const handleRemove = async (rowData) => {
        setShowConfirmDelete(true);
        setTrainingPlanId(rowData.id);
        //postRemoveToPlan(useLmsStore, rowData.id)
    }

    const handlePostRemove = async () => {
        postRemoveToPlan(useLmsStore, trainingPlanId)
        setShowConfirmDelete(false);
    }

    const handleEventStart = (rowData) => {
        setRefLearningItem(rowData.learningItemId);
        setRefLearningItemData(rowData);
        //setTrainingPlanId(rowData.id);
        setisOpenEvent(true);
    }

   /* const handleElearningStart = async (rowData) => {
        let canHaveContinue = false;
        let canHaveNewAttempt = false;
        let pwGroupId = -1;
        let regItemId = -1;
        let myregId = -1;
        let isComplete = isCompleted(rowData);
        if (rowData.pwgrpId) {
            pwGroupId = rowData.pwgrpId;
            myregId = rowData.myregid;
            regItemId = rowData.registrationItemId;
            if (!isComplete && rowData.elearningInfo != null && rowData.elearningInfo != undefined) { //It is not started
                canHaveContinue = true;
            }
            canHaveNewAttempt = (rowData.elearningInfo ? (rowData.elearningInfo.canHaveNewAttempt ?? true) : true);
        }
        else {
            canHaveNewAttempt = (rowData.elearningInfo ? (rowData.elearningInfo.canHaveNewAttempt ?? true) : true);
            if (!isComplete && rowData.statusName !== 'Not Started' && rowData.statusId != null && rowData.statusId != undefined) { //It is not started
                canHaveContinue = true;
            }
        }

        getElearningLinks(rowData.learningItemId, rowData.id, myregId, regItemId, -1).then((response) => {
            let data = responseData(response,);
            if (data) {
             
                if (isComplete && data.PreviewLink !== "") {
                    setIsElearningOpenId(rowData.learningItemId);
                    setIsElearningOpen(true);
                    window.open(data.previewLink, "_blank");

                }
                else {

                    //It is a start 
                    if ((data.newAttemptLink !== null) && canHaveNewAttempt && ((data.continueAttemptLink === null) || !canHaveContinue)) {
                        //It is used to check if a refresher button is required                           
                        setIsElearningOpenId(rowData.learningItemId);
                        setIsElearningOpen(true);
                        window.open(data.newAttemptLink, '_blank');
                    }
                    else {
                        if (data.continueAttemptLink !== null && canHaveContinue) { //&& canHaveContinue) {
                            if (!canHaveNewAttempt) {
                                //It is used to check if a refresher button is required
                                setIsElearningOpenId(rowData.learningItemId);
                                setIsElearningOpen(true);
                                window.open(data.continueAttemptLink, '_blank');//, this.createWindowAttributeString(result.properties));
                            }
                            else {
                                //Open continue elearning modal
                                toggleModalElearningContinue(data.continueAttemptLink, data.newAttemptLink, canHaveNewAttempt);
                            }
                        }
                    }
                }
            }
        });
    }
    */

    const handleElearningStart = async (rowData) => {
        let canHaveContinue = false;
        let canHaveNewAttempt = false;
        let pwGroupId = -1;
        let regItemId = -1;
        let tpId = -1;
        let myregId = -1;
        let isComplete = isCompleted(rowData);
        if (rowData.regPathwayId) {
            pwGroupId = rowData.addedFromPathway;
            myregId = rowData.regPathwayId;
            regItemId = rowData.id;
            if (!isComplete && rowData.elearningInfo != null && rowData.elearningInfo != undefined) { //It is not started
                canHaveContinue = true;
            }
            canHaveNewAttempt = (rowData.elearningInfo ? (rowData.elearningInfo.canHaveNewAttempt ?? true) : true);
        }
        else {
            tpId = rowData.id;
            canHaveNewAttempt = (rowData.elearningInfo ? (rowData.elearningInfo.canHaveNewAttempt ?? true) : true);
            if (!isComplete && rowData.statusName !== 'Not Started' && rowData.statusId != null && rowData.statusId != undefined) { //It is not started
                canHaveContinue = true;
            }
        }

        getElearningLinks(rowData.learningItemId, tpId, myregId, regItemId, -1).then((response) => {
            let data = responseData(response,);
            if (data) {
             
                if (isComplete && data.PreviewLink !== "") {
                    setIsElearningOpenId(rowData.learningItemId);
                    setIsElearningOpen(true);
                    window.open(data.previewLink, "_blank");

                }
                else {

                    //It is a start 
                    if ((data.newAttemptLink !== null) && canHaveNewAttempt && ((data.continueAttemptLink === null) || !canHaveContinue)) {
                        //It is used to check if a refresher button is required                           
                        setIsElearningOpenId(rowData.learningItemId);
                        setIsElearningOpen(true);
                        window.open(data.newAttemptLink, '_blank');
                    }
                    else {
                        if (data.continueAttemptLink !== null && canHaveContinue) { //&& canHaveContinue) {
                            if (!canHaveNewAttempt) {
                                //It is used to check if a refresher button is required
                                setIsElearningOpenId(rowData.learningItemId);
                                setIsElearningOpen(true);
                                window.open(data.continueAttemptLink, '_blank');//, this.createWindowAttributeString(result.properties));
                            }
                            else {
                                //Open continue elearning modal
                                toggleModalElearningContinue(data.continueAttemptLink, data.newAttemptLink, canHaveNewAttempt);
                            }
                        }
                    }
                }
            }
        });
    }


    const setRowSelection = (rowData) => {
        //Add the item as selected
        setSelectedLearningItem(rowData.learningItemId);
        let index = selectedRows.findIndex((item) => item.learningItemId === rowData.learningItemId);
        if (index < 0) {
            selectedRows.push(
                { learningItemId: rowData.learningItemId, isActive: true, isOpen: false });
        }
        else {
            if (!selectedRows[index].isActive) {
                selectedRows[index].isActive = true;
                selectedRows[index].isOpen = false;
            }
        }

        setSelectedRows(selectedRows);
      
        return;
    }

    const setRowPwSelection = (rowData) => {
            //Add the item as selected
            setTableLoadingPw(true);
        //   setOpenClosePw(true);
            updatePathwayOpen(useLmsStore, rowData.id, !rowData.isOpen);

            setTableLoadingPw(false);
         
setTimeout(() => {
    setOpenClosePw(false);
 }, 1000);
       
     
        return;
    }

    //This expand the pathway row only when it is used by my team screen as the data is not in uselmsstore but locally
    const setRowPwSelectionTeam = (rowData) => {
       
        setTableLoadingPw(true);
       
        if(props.dataActiveTrainingPlan){
          //Pathway only show in filter 1
          let transformedData = structuredClone(filteredDataS(dataActivePlan,1));
           let idx =transformedData.findIndex(x => x.id === rowData.id);

            if (idx > -1){
             
              transformedData[idx].isOpen =  !rowData.isOpen;
              transformedData[idx].isSelected = true;           
              setTableData(transformedData);
              setTableLoadingPw(false);
     
                setTimeout(() => {
                setOpenClosePw(false);
                }, 1000);                   
            }
        }       
 
    return;
   }

    const handleStart = (rowData) => {
        //Add the item as selected
        setRowSelection(rowData);
        switch (rowData.learningItemTypeId) {
            case 2: //events
                handleEventStart(rowData);                
                break;
            case 3: //eLearning
                handleElearningStart(rowData);
                break;
            case 8:
                handleReferenceMaterialStart(rowData);
                break;
            default:
                alert('You click on start default' + rowData.name);
                break;
        }
        setIsModalOpen(false);
    };

    const canShowStart = (rowData) => {
        // alert('You click on start ' + rowData.name);
        let result = true;
        
        

        switch (rowData.learningItemTypeId) {
            case 2: //instructor led
              result =hasPermission(useLmsStore,'ev.booking');
            break;
            case 3: //eLearning
                result = canDoElearning(rowData);
                break;
            case 8:
                break;
            default:
                break;
        }
        return result;
    };
  
    /*
     Give a warning to the user that if the want to do a new attempt
   */
    function onHandleNewAttempt() {

        if (newAttemptLink !== "") {
            setIsElearningOpenId(selectedLearningItem);
            setIsElearningOpen(true);
            //toggleIsOpenElearning();
            setisOpenNewAttemptConfirmation(false);
            setisOpenElearningContinue(false);
            window.open(newAttemptLink, '_blank');
        }
    };

    /*
      Trigger when the user click on the elearning continue button
    */
    function onHandleContinueElearning() {
        setisOpenElearningContinue(false);
        //Set the elearnig as open so therefresh button can appear       
        setIsElearningOpenId(selectedLearningItem);
        setIsElearningOpen(true);
    };

    function toggleModalElearningContinue(continueLink, newAttemptLink, canHaveNewAttempt) {
        setContinueLink(continueLink);
        setNewAttemptLink(newAttemptLink);
        setCanHaveNewAttempt(canHaveNewAttempt);
        setisOpenElearningContinue(!isOpenElearningContinue);
    }

    function onCloseModalElearningContinue() {
        setisOpenElearningContinue(false);
        onCloseRow(selectedLearningItem);
      }
   
    function toggleModalNewAttemptConfirmation() {
        setisOpenNewAttemptConfirmation(!isOpenNewAttemptConfirmation);
    }

    function toggleModalElearningRefresher(learningItemId) {
        setRefLearningItem(learningItemId);
        setisOpenElearningRefresher(!isOpenElearningRefresher);
    }

    function onCloseAndRefreshModalElearningRefresher() {
        onCloseModalElearningRefresher();

        //We need to refresh the page
        window.location.reload(false);
    }

    function onCloseModalElearningRefresher() {
        setRefLearningItem(-1);
        setisOpenElearningRefresher(false);
    }

    const onCloseModalEvent = () => {
        setRefLearningItem(0);
        setisOpenEvent(false);
    }

    const onCloseModalUserEvent = () => {
        setIsOpenUserEvent(false);
        setUserEventInfo(null);
    }
    
    function handleOpenUserEventInfo(userEventInfo) {
       setUserEventInfo(userEventInfo);
       setIsOpenUserEvent(true);
    
  }

    // on searchParams
    useEffect(() => {
        if (searchParams.has('search') || searchParams.has('filter')) {
            if (searchParams.has('search')) {
                searchParams.delete('search');
            }
            if (searchParams.has('filter')) {
                switch (searchParams.get("filter")) {
                    case "todo":
                        setFilterId(filterId === -1 ? 0 : -1);
                        break;
                    case "overdue":
                        setFilterId(filterId === -2 ? 0 : -2);
                        break;
                    case "upcoming":
                            setFilterId(filterId === -4 ? 0 : -4);
                            break;
                    case "awaiting dates":
                            setFilterId(filterId === -5 ? 0 : -5)
                            break;
                    case "completed":
                        setFilterId(filterId === -6 ? 0 : -6)
                        break;
                    default:
                        setFilterId(-1)
                }
                searchParams.delete('filter');
            }
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    // on dataActiveTrainingPlan change
    useEffect(() => {
      
        if (props.dataActiveTrainingPlan.loading>0) {
            
            setTableLoading(true);
        } else {
            if (Array.isArray(props.dataActiveTrainingPlan.data)) {
                let todo = 0,
                    overdue = 0,
                    mandatory = 0,
                    completed = 0,
                    awaiting = 0,
                    upComing = 0,
                    count = 0;
                props.dataActiveTrainingPlan.data.forEach(r => {

                  if (!isPathway(r)) {
                    if (isConfirmed(r)) {
                        count++;
                        todo++;
                        if (isOverdue(r)) overdue++;
                        if (r.instructorInfo!=null){
                        getCanCancelBooking(r.instructorInfo.bookingId).then((response) => {
                            let data = responseData(response, []);
                            if(data){
                                eventData.push({id:r.instructorInfo.bookingId, msg:`Confirmation of your booking on the ${data.eventInfo.name} for the event being held at ${data.eventInfo.venueName}, ${data.eventInfo.venueCity} - Room ${data.eventInfo.roomName} on ${moment( data.eventInfo.startDate).format("DD/MM/YYYY")} between ${data.eventInfo.startTime} and ${data.eventInfo.endTime}`});
                                setEventData(eventData);
                            }
                            count--;
                        });
                    }
                    } else if (isCompleted(r) || isAttended(r)) {
                        completed++;
                    } else {
                        todo++;
                        if (isOverdue(r)) overdue++;

                        if (r.statusName==='Awaiting Dates')
                        awaiting++;

                        if ((r.expiryDate!==undefined) && (r.expiryDate!==null))
                         upComing++
                    }
                    if (r.isMandatory) mandatory++;
                  }
                });
                setToDoCount(todo);
                setOverdueCount(overdue);
                setMandatoryCount(mandatory);
                setCompletedCount(completed);
                setAwaitingCount(awaiting);
                setUpcomingCount(upComing);
                setDataActivePlan(props.dataActiveTrainingPlan.data);
                setTableLoading(false);
            } else {
                setDataActivePlan([]);
                setMandatoryCount(0);
            }
        }
    }, [props.dataActiveTrainingPlan]);

    // on dataPathWays change
   /* useEffect(() => {
        setDataPathWays(props.dataPathWays);
    }, [props.dataPathWays]);

*/
    // on tabId change
    useEffect(() => {     
          if (tabId === '0' && !props.dataActiveTrainingPlan.loading>0) {
            setCustomFilterData(filterId);
    
        }
        else {
         //  setTableLoading(false);
         
        if (props.dataActiveTrainingPlan.loading>0){
        setTableLoading(true);
        }
        else{
            
        setTableLoading(false);
            setTabId('0');
        }
        }
        // eslint-disable-next-line
    }, [tabId, filterId, dataActivePlan, props.dataActiveTrainingPlan]);


const setCustomFilterData = (filterId) => {
    if (props.dataActiveTrainingPlan.loading>0){
        
    setTableLoading(true);
    }
   else{
    setTableLoading(false);
    
   }

     if (searchString!=undefined && searchString!==''){
    setTableData(filteredDataS(dataActivePlan, filterId));
    }
    else{
    
    setTableData(filteredData(dataActivePlan, filterId));
    }
   
    
    if (props.dataActiveTrainingPlan.loading>0){
       setTableLoading(true);
    }
    else{
     
    setTableLoading(false);
       
    }
}

    const columnsFn = (props) => {
        let columns = [
            {
                accessorKey: 'pwgrpName',
                header: 'PathWay',
                GroupedCell: ({ cell }) => <div>{cell.getValue()}</div>,
                muiTableBodyCellProps: ({ cell, table }) => {
                    return !tableLoading && cell.getIsGrouped() ? {
                        colSpan: 4,
                        sx: {
                            backgroundColor: 'var(--app-nav-primary)',
                            color: 'white',
                            display: cell.getValue() ? 'table-cell' : 'none !important',
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            padding: '2rem 0 0 0',
                    
                            verticalalign:'top'
                        }
                    } :
                        {
                            sx: {
                                display: 'none'
                            }
                        }
                }
            },                          // Table 2 only
            columnIconLearningItem(),   // Both tables
            columnAbout,                // Both tables
         /*   columnIconSynopsis,         // Both tables
            columnIconSynopsisLink,    
            {
                accessorKey: 'statusName',
                header: 'Status',
                maxSize: 120,
                minSize: 120,
            } */                         // Table 1 only
        ];
        if (props.enableActions) {
            columns.push(columnAction);
        }

        if (props.enablePw) {
            columns.push(columnActionPw);
        }
        return columns;
    };

  
    const columnsFnPw = (props) => {
        let columns = [
            {
                accessorKey: 'pwgrpName',
                header: 'PathWay',
                GroupedCell: ({ cell }) => <div>{cell.getValue()}</div>,
                muiTableBodyCellProps: ({ cell, table }) => {
                    return !tableLoading && cell.getIsGrouped() ? {
                       colSpan: 4,
                        sx: {
                            backgroundColor: 'var(--app-nav-primary)',
                            color: 'white',
                            display: cell.getValue() ? 'table-cell' : 'none !important',
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            padding: '2rem 0 0 0',
                            
                        }
                    } :
                        {
                            sx: {
                                display: 'none'
                            }
                        }
                }
            },                          // Table 2 only
           columnIconLearningItem(),   // Both tables
            columnAboutPW,                // Both tables
            columnIconSynopsis,         // Both tables
           columnIconSynopsisLink,    
            {
                accessorKey: 'statusName',
                header: 'Status',
                maxSize: 120,
                minSize: 120,
                muiTableBodyCellProps: hideAggregatedCell,             
                 sx: {
                            backgroundColor: 'var(--app-nav-primary)',
                            color: 'white',
                            display:  'table-cell', //: 'none !important',
                            fontSize: '1.25rem',
                            fontWeight: 'bold',

                            padding: '2rem 0 0 0',
                            
                        }
            }                        // Table 1 only
        ];
        if (props.enableActions) {
            columns.push(columnAction);
        }
        return columns;
    };

  
        
  

    const tableColumns = useMemo(() => columnsFn(props), [tableLoading],);
    const tableColumnsPw = useMemo(() => columnsFnPw(props), [tableLoading],);
    //zc working  const withSelectedRows = (tableData, selectedRows) => tableData.map(item => { return { ...item, isSelected: selectedRows.some(item2 => item2.isActive && item2.learningItemId === item.learningItemId), isOpen: selectedRows.some(item2 => item2.isOpen && item2.isActive && item2.learningItemId === item.learningItemId) }; });
     const withSelectedRows = (tableData, selectedRows) => tableData.map(item => { return { ...item, isSelected: selectedRows.some(item2 => item2.isActive && (item2.learningItemId === item.learningItemId ))}});
   
    const myCustomFilterFn= (row, id, filterValue) =>
    row.getValue(id).startsWith(filterValue);

    const customGlobalFilterFn = (value, searchString) => {        
        if (typeof value === "string" && typeof searchString === "string") {
            
          return value?.toLowerCase().includes(searchString.toLowerCase());
        }
        return false;
    };

    return (<>
        <TableContainer >
            {props.displayUserCard ? <UserCard {...props.dataUser.data} displayBackButton={true} loading={props.dataUser.loading} /> : null}
            <Tabs activeKey={tabId} onSelect={(k) => setTabId(k)} className="pt-3" style={props.displayUserCard ? {} : {backgroundColor: 'var(--app-bg)'}}>
                <Tab title='My Plan' key={0} eventKey={0} />
              
            </Tabs>
            <MaterialReactTable
                columns={tableColumns}
                data={withSelectedRows(tableData, selectedRows)}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={true}
                enableGrouping={false}
                globalFilterFn={customGlobalFilterFn}  
              //  filterFns={
                // myCustomFilterFn}                      
               // globalFilterFn= 'myCustomFilterFn'
                enableStickyHeader={true}
                enableHiding={false}
                enablePagination={false}
                enableSorting={false}
                enableTopToolbar={true}
                muiTableBodyRowProps={showByGroup ? { hover: false, sx: { borderBottom: '0.25rem solid var(--app-nav-primary)' } } : { hover: false }}
                muiTableContainerProps={showByGroup ? { sx: { backgroundColor: 'var(--app-nav-primary)', padding: '0 1rem' } } : {}}
                muiTableHeadProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                muiToolbarAlertBannerProps={{
                    sx: {
                        display: 'none'
                    }
                }}
                onGlobalFilterChange={setSearchString}
                renderTopToolbarCustomActions={({ table }) => {
                    return (tabId === '0' ? <Row>
                        <Col key='-1' xs='auto'>
                            <FilterBadgeButton variant={todoCount > 0 ? 'info' : 'info'} title='To Do' onClick={() => setFilterId(filterId === -1 ? 0 : -1)} active={filterId === -1}>
                                {todoCount}
                            </FilterBadgeButton>
                        </Col>
                        <Col key='-2' xs='auto'>
                            <FilterBadgeButton variant={overdueCount > 0 ? 'danger' : 'danger'} title='Overdue' onClick={() => setFilterId(filterId === -2 ? 0 : -2)} active={filterId === -2}>
                                {overdueCount}
                            </FilterBadgeButton>
                        </Col>
                        <Col key='-3' xs='auto'>
                            <FilterBadgeButton variant={mandatoryCount > 0 ? 'warning' : 'warning'} title='Mandatory' onClick={() => setFilterId(filterId === -3 ? 0 : -3)} active={filterId === -3}>
                                {mandatoryCount}
                            </FilterBadgeButton>
                        </Col>
                        <Col key='-4' xs='auto'>
                            <FilterBadgeButton variant={upComingCount > 0 ? 'warning' : 'warning'} title='Upcoming' onClick={() => setFilterId(filterId === -4 ? 0 : -4)} active={filterId === -4}>
                                {upComingCount}
                            </FilterBadgeButton>
                        </Col>
                        <Col key='-5' xs='auto'>
                            <FilterBadgeButton variant={awaitingCount > 0 ? 'warning' : 'warning'} title='Awaiting Dates' onClick={() => setFilterId(filterId === -5 ? 0 : -5)} active={filterId === -5}>
                                {awaitingCount}
                            </FilterBadgeButton>
                        </Col>
                        <Col key='-6' xs='auto'>
                            <FilterBadgeButton variant={completedCount > 0 ? 'success' : 'success'} title='Completed' onClick={() => setFilterId(filterId === -6 ? 0 : -6)} active={filterId === -6}>
                                {completedCount}
                            </FilterBadgeButton>
                        </Col>
                        <Col key='-7' xs='auto'>
                            {filterId < 0 && <FilterClearButton onClick={() => setFilterId(0)} />}
                        </Col>
                    </Row> : <><Col></Col></>)
                }}
                initialState={{
                    //globalFilter: decodeURI(searchParams.get("search") || ''),
                    globalFilter: searchString,
                    showGlobalFilter: true,
                   // isLoading:tableLoading,
                }}
                state=  {
                    {
                        isLoading: tableLoading,
                        columnVisibility: {
                            pwgrpName: false
                        },
                        //globalFilter: decodeURI(searchParams.get("search") || searchString),
                        globalFilter: searchString
                    }}
                    
            />
        </TableContainer>

        <Modal fullscreen='md-down' show={showSynopsis} onHide={handleCloseSynopsis} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{synopsisTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>           
             {synopsisSectionsContent}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={handleCloseSynopsis}>Close</Button>
            </Modal.Footer>
        </Modal>
        <Modal fullscreen='md-down' show={showConfirmDelete} onHide={handleCloseConfirmDelete} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Are you sure you wish to remove this item from your learning plan?</h6>
                <Button variant='primary px-3 w-25 mx-3' onClick={handlePostRemove} >Yes</Button>
                <Button variant='primary px-3 w-25 mx-3 float-right' onClick={handleCloseConfirmDelete} >No</Button>
            </Modal.Body>
        </Modal>

        <ModalElearningContinue closeFn={onCloseModalElearningContinue} continueFn={onHandleContinueElearning} newAttemptFn={toggleModalNewAttemptConfirmation} open={isOpenElearningContinue} newAttemptUrl={newAttemptLink} continueUrl={continueLink} canHaveNewAttempt={canHaveNewAttempt} />
        <ModalConfirmation closeFn={toggleModalNewAttemptConfirmation} yesFn={onHandleNewAttempt} noFn={toggleModalNewAttemptConfirmation} open={isOpenNewAttemptConfirmation} title='Confirm new attempt' description='<p>Are you sure you want to start a new attempt?</p><p>If you do this you will lose your current progress</p>' />
        <ModalElearningRefresher closeFn={onCloseModalElearningRefresher} yesFn={onCloseAndRefreshModalElearningRefresher} open={isOpenElearningRefresher} refLearningItemId={refLearningItem} />
        <ModalEvent closeFn={onCloseModalEvent} open={isOpenEvent} refLearningItemId={refLearningItem} refLearningItemData={refLearningItemData} />  
        {userEventInfo?
        <ModalUserEventInfo closeFn={onCloseModalUserEvent}  open={isOpenUserEvent} userEventData={userEventInfo.instructorInfo} eventName={userEventInfo.name} status={userEventInfo.statusName} />  
:<></>}
   
        {isModalOpen && (
            <Modal show={isModalOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title><b>My Learning Plan:</b> {modalData.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You've already completed this course and your result has already been recorded. <b>Review</b> is a read only view of the content.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleStart(modalData)}>
                        Continue to eLearning
                    </Button>
                </Modal.Footer>
            </Modal>
        )}

        { openClosePw && (
            <Modal show={openClosePw} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title><b>This action will take sometime</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This action will take sometime</p>
            </Modal.Body>
            <Modal.Footer>               
            </Modal.Footer>
        </Modal>

        )
        }
    </>);
} 
