import React, { useEffect } from 'react';
import Button from "react-bootstrap/Button";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const LoginButton = () => {
    const { instance, accounts } = useMsal();

    const loginRequest = {
        scopes: ["openid"]
    };

    const onAdB2CClick = () => {
        instance.loginRedirect();
    };

    const onLogout = () => {
        instance.logoutRedirect({
            account: instance.getActiveAccount(), // The account that needs to be signed out
        });
    };

    const renderLogoutButton = () => {

        return instance.getActiveAccount() ?
            <Button type="button" style={{ backgroundColor: 'var(--app-login-ovl)' }} variant="primary" onClick={onLogout} className="w-100 mt-3">Logout</Button>
            :
            <></>
    }

    return (
        <>
            <Button type="button" style={{ backgroundColor: 'var(--app-login-ovl)' }} variant="primary" onClick={onAdB2CClick} className="w-100 mt-3">B2C Login</Button>
            {renderLogoutButton()}
        </>
    );
};

export default LoginButton;
