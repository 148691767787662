import React from 'react';
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-light-svg-icons";

const SearchInput = ({onClick = () =>null, onChange=()=>null, placeholer= "" ,label="" }) => {
    return (
        <div className={"w-100"} >
            <Form onSubmit={(e)=>{
                e.preventDefault()
                onClick()
            }} className={"px-1 mr-auto"}>
                <Form.Group className="mb-3  " controlId="firstName">
                    <Form.Label>{label}</Form.Label>
                    <InputGroup className={"cls-search-input-group"} >
                        <Form.Control type="input" placeholder={placeholer} defaultValue={""} className="no-border"
                                      onChange={(e)=>{onChange(e)}}/>
                        <Button variant='search' onClick={()=>{onClick()}}>
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </InputGroup>
                </Form.Group>
            </Form>
        </div>
    );
};

export default SearchInput;
