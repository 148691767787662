import { useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import useLmsStore from '../hooks/useLmsStore';
import shallow from 'zustand/shallow';

export default function BaseContainer(props) {
    const { personSettings, fetchPersonSettings } = useLmsStore(state =>
    ({
        personSettings: state.personSettings.data,
        fetchPersonSettings: state.fetchPersonSettings
    }), shallow)

    useEffect(() => {
        fetchPersonSettings();
    }, []);

    return (
        <>{
            personSettings ?
                <>
                    <TopBar />
                    <Container fluid>
                        <Row>
                            <Col id="id-sidebar-wrapper">
                                <SideBar />
                            </Col>
                            <Col id="id-content-wrapper">
                                <Row>
                                    {props.header ||
                                        <Col style={{ height: '6rem' }}>
                                            <h4 className="mt-2">{props.title}</h4>
                                            <p className='text-muted'>{props.description || 'Learning Management System'}</p>
                                        </Col>}
                                </Row>
                                {props.children}
                            </Col>
                        </Row>
                    </Container>
                </>
                : null
        }
        </>);
}
