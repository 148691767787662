

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';


export default function NotFound() {
    return (
        <Container style={{ height: '100vh' }}>
            <Row style={{ padding: '4rem 0' }} className='justify-content-center'>
                <Col md='auto'>
                    <p style={{ fontSize: '3rem', fontWeight: 400 }}><span className='text-danger'>Oops!</span> Page not found.</p>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col md='auto'>
                    <Button href='/'>Go Home</Button>
                </Col>
            </Row>
        </Container>
    );
}