import { useEffect, useState } from 'react';

import axios from 'axios';

import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import ModalMyProfile from '../components/ModalMyProfile';
import ModalPassword from '../components/ModalPassword';
import ModalAbout from '../components/ModalAbout';

import AppAvatar from "../components/AppAvatar";

import { useAuth } from "../hooks/useAuth";
import useLmsStore, { postLogout, postLogoutSaml, toastError, HEADERS_JSON } from '../hooks/useLmsStore';
import shallow from 'zustand/shallow';
import {useMsal} from "@azure/msal-react";
import useTheme from "../hooks/useTheme";
const getApiUrl = (urlFragment) => {
    let urlSrv = '/rpscall-learningportal.test';
    return `${urlSrv}${urlFragment}`;
};
export default function TopBar(props) {
    const {instance} = useMsal()
    const logoutB2C = () => {
        const acc = instance.getActiveAccount()
        if(acc){
            instance.logoutRedirect({
                account: instance.getActiveAccount(), // The account that needs to be signed out
            });
        }

    };
    const { themeName } = useTheme();

    const { personBasic, personSummary, fetchPersonBasic, fetchPersonSettings, fetchPersonSummary } = useLmsStore(state =>
    ({
        personBasic: state.personBasic.data,
        personSummary: state.personSummary.data,
        fetchPersonBasic: state.fetchPersonBasic,
        fetchPersonSettings: state.fetchPersonSettings,
        fetchPersonSummary: state.fetchPersonSummary
    }), shallow)

    const apiAxios = axios.create({
        withCredentials: true,
        headers: {'authorization': `Bearer ${!window.localStorage.getItem('token')=== null?"": window.localStorage.getItem('token').replace(/"/g, '')}`}
    });
    
    useEffect(() => {
        fetchPersonBasic();
        fetchPersonSettings();
        fetchPersonSummary();
    }, []);

    useEffect(() => {
        if (process.env.REACT_APP_RNEXT === 'true' && personBasic && personBasic.personIdentifier) {
            
          //  apiAxios.post('/rpcall-localhost:53269/api/account/singleSignOnFromNewLms', { ccid: personBasic.personIdentifier }, HEADERS_JSON)      
            apiAxios.post('/rpscall-learningportal.test/api/account/singleSignOnFromNewLms', { ccid: personBasic.personIdentifier }, HEADERS_JSON)
          //  apiAxios.post('/rpscall-lms-admin/api/account/singleSignOnFromNewLms', { ccid: personBasic.personIdentifier }, HEADERS_JSON)
                .then((response) => console.log('TopBar: Signed on to legacy Lms.'))
                .then(res=>{
                    apiAxios.get(getApiUrl('/api/account/getApplicationRegistration'))
            })
                .catch(toastError);
        }
    }, [personBasic]);

    const [isLogout, setLogout] = useState(false);
    const { logout } = useAuth();

    function handleError(e) {
        console.error(e);
    }

    const [isOpenProfile, setisOpenProfile] = useState(false);
    const [isOpenPassword, setisOpenPassword] = useState(false);
    const [isOpenAbout, setisOpenAbout] = useState(false);
    const [image, setImage] = useState({ preview: "", raw: "" });

    function toggleModalProfile() {
        setisOpenProfile(!isOpenProfile);
    }
    function toggleModalPassword() {
        setisOpenPassword(!isOpenPassword);
    }
    function toggleImage(image) {
        setImage({
            preview: image.preview,
            raw: image.row
        });
        fetchPersonSummary(true);
    }
    function toggleModalAbout() {
        setisOpenAbout(!isOpenAbout);
    }
    function handleLogout() {
        setLogout(true);
        //postLogoutSaml(useLmsStore);
       logoutB2C()
        postLogout(useLmsStore)
            .then((result) => {
                try {
                    if (result.status === 204) {
                        throw new Error('Invalid email address or password');
                    }
                    // Logout
                    logout();
                    setLogout(result);

                } catch (e1) {
                    handleError(e1);
                }

            });
    };


    return (<Navbar sticky='top' id='id-topbar-wrapper' className='d-flex justify-content-between justify-content-lg-end'>
        {themeName ?
              <span className="m-1 p-1 rounded d-flex d-lg-none" style={{display: 'flex', justifyContent:'center', alignItems: 'center', background:"white"}}>
                      <img
                          className={"d-lg-none"}
                          src={require(`../assets/instance/${themeName}/icon_small.png`)}
                          style={{  height:"50xp", width:"100px"}}/>

              </span>
        : <></>}
        <Nav className="mr-3" style={{ minWidth: '150px' }} >
            <Nav.Item className='justify-content-end pt-3'>
                <h6>
                    {!personBasic ? 'Loading...' : personBasic.fullName}
                </h6>
            </Nav.Item>
            <Nav.Item className='justify-content-end'>
                <Dropdown>
                    <Dropdown.Toggle variant='clear-no-arrow' size='2rem' className='p-0-1-0-1' id='topBarAccountDropdown'>

                            {image.preview !=="" ?
                                <AppAvatar
                                size={46}
                                color={'var(--app-topbar-avatar-default)'}
                                name={personSummary && personSummary.fullName}
                                src={image.preview }
                                />
                            :
                                <AppAvatar
                                size={46}
                                color={'var(--app-topbar-avatar-default)'}
                                name={personSummary && personSummary.fullName}
                                src={personSummary && personSummary.photograph}
                                />
                            }
                        
                    </Dropdown.Toggle>
                    <Dropdown.Menu align='end'>
                        <Dropdown.Item onClick={toggleModalProfile}>Profile</Dropdown.Item>
                        <Dropdown.Item onClick={toggleModalPassword}>Change Password</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
                        <hr />
                        <Dropdown.Item onClick={toggleModalAbout}>About</Dropdown.Item>
                    </Dropdown.Menu>

                </Dropdown>
            </Nav.Item>
        </Nav>
        <ModalMyProfile closeFn={toggleModalProfile}
            updateImageFn={toggleImage}
            open={isOpenProfile}
            personSummary={personSummary} />
        <ModalPassword closeFn={toggleModalPassword}
            open={isOpenPassword}
            personSummary={personSummary} />
        <ModalAbout closeFn={toggleModalAbout}
            open={isOpenAbout} />
    </Navbar>
    );
}
