import create from "zustand";
import { devtools } from 'zustand/middleware'
import axios from 'axios';
import { responseData, fetchAsynTransformFn, fetchByIdFn, fetchFn, getLockId, postBooleanPromiseFn, postDataPromiseFn, throwIfDataIndexNotFound, throwIfDataNotObject, throwIfDataNotValid, throwIfStatusNot200, timeElapsed, toastError, toastSuccess, until, HEADERS_JSON, REFRESH_AFTER } from '../hooks/useLmsStore';

const apiAxios = axios.create({
    withCredentials: true,
    headers: { 'authorization': `Bearer ${window.localStorage.getItem('token') === null ? "" : window.localStorage.getItem('token').replace(/"/g, '')}` }
});

export const getAdminPortalRpCall = () => {
    let location = window.location.hostname.split('.')[0],
        urlFragment = null;
    if (process.env.REACT_APP_LEGACY_SERVER !== 'live') {
        if (process.env.NODE_ENV === 'development') {
            urlFragment = 'test';
        }
        // Other configs used again in future?
        // else if (process.env.NODE_ENV === 'test') {
        //     urlFragment = 'test';
        // }
    }
    if (urlFragment) {
        // Development / test / qa / etc...
        switch (location) {
            case 'principality':
                return `/rpcall-principality-portal.${urlFragment}`;
            case 'cerespoweracademy':
                return `/rpcall-cerespoweracademy-admin.${urlFragment}`;
            case 'gwct':
                return `/rpcall-gwct-admin.${urlFragment}`;
            default:
                return `/rpcall-learningportal.${urlFragment}`;
        }
    } else {
        // Production
        switch (location) {
            case 'principality':
                return "/rpcall-principality-admin";
            case 'cerespoweracademy':
                return "/rpcall-cerespoweracademy-admin";
            case 'gwct':
                return "/rpcall-gwct-admin";
            default:
                return "/rpcall-lms-admin";
        }
    }
};

export const getAdminPortalRpCall2 = (urlMethod) => {
    let location = window.location.hostname.split('.')[0],
        urlFragment = 'test';
     //   location='localhost';
    if (process.env.REACT_APP_LEGACY_SERVER !== 'live') {
        if (process.env.NODE_ENV === 'development') {
            urlFragment = 'test';
        }
        // Other configs used again in future?
     
        // else if (process.env.NODE_ENV === 'test') {
        //     urlFragment = 'test';
        // }
    }
    if (urlFragment) {
        // Development / test / qa / etc...
        switch (location) {
            case 'principality':
                return `/rpscall-principality-portal.${urlFragment}`;
            case 'cerespoweracademy':
                return `/rpscall-cerespoweracademy-admin.${urlFragment}`;
            case 'gwct':
                return `/rpscall-gwct-admin.${urlFragment}`;
          //  case 'localhost':
            //    return `/rpcall-localhost:53269${urlMethod}`;
            default:
                return `/rpscall-learningportal.${urlFragment}${urlMethod}`;
        }
    } else {
        // Production
        switch (location) {
            case 'principality':
                return "/rpscall-principality-admin";
            case 'cerespoweracademy':
                return "/rpscall-cerespoweracademy-admin";
            case 'gwct':
                return "/rpscall-gwct-admin";
            default:
               console.log('rps call' + `/rpscall-lms-admin${urlMethod}`);
              //  return `/rpscall-localhost:53269${urlMethod}`;
              return `/rpscall-lms-admin${urlMethod}`;// http://
        }
    }
};

const store = (set, get) => ({
    applicationRegistrationDetail: { data: undefined },
    applicationReports :  { data: undefined },
    searchGroups :  { data: undefined },
    fetchApplicationRegistrationDetail: fetchFn(set, get, 'applicationRegistrationDetail', getAdminPortalRpCall2('/api/application/getApplicationRegistrationDetail/10')),
  
});

export const getApplicationRegistrationDetail = (learningItemId) => apiAxios(getAdminPortalRpCall2(`/api/application/getApplicationRegistrationDetail/10`));

export const postSearchApplicationReports = postDataPromiseFn(getAdminPortalRpCall2('/api/application/searchApplicationReports'),
    (store, postData, response) => {
        if (store.getState().applicationReports ) {
            if (!(store.getState().applicationReports.loading > 0)) {
                try {

                    let data = responseData(response);
                    console.log('data', data);
                    return true;
                } catch (err) {
                    toastError(err);
                    return false;
                }
            } 
        }
    return true;
    },
    (store, postData) => false
);

export const postSearchGroups = postDataPromiseFn(getAdminPortalRpCall2('/api/groupManagement/search'),
    (store, postData, response) => {
        if (store.getState().searchGroups ) {
            if (!(store.getState().searchGroups.loading > 0)) {
                try {

                    let data = responseData(response);
                    console.log('data', data);
                    return true;
                } catch (err) {
                    toastError(err);
                    return false;
                }
            } 
        }
    return true;
    },
    (store, postData) => false
);

const useLegacyStore = process.env.NODE_ENV === 'development' ? create(devtools(store)) : create(store);
export default useLegacyStore;
if (process.env.NODE_ENV === 'development') {
    Object.defineProperty(window, 'legacyStore', {
        get() {
            return useLegacyStore.getState();
        },
    });
}
