import { useState, useMemo, useCallback, useReducer, useRef } from 'react';
import { Row, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { TableContainer } from '../helpers/tableHelper';
import MaterialReactTable from 'material-react-table';
import ModalCompanyPeople from './ModalCompanyPeople';

const TabCompanyPeople= ({submitFn = (obj) => null, hasChangedFn = () => null, closeFn = (obj) => null,people, companyId}) => {
const [showModalPeople, setShowModalPeople] = useState(false);
const [peopleVar, setPeople] = useState(people); 
const [inProgress, setInProgress] = useState(false);
const [apply, setApply] = useState(false); 

const [trainerToRemove, setTrainerToRemove] = useState(null);
const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
   
const editAddPeopleRowFn = (obj) => {
    //const test = obj.filter((a) => a.isRowSelected);
    console.log('test',obj);
    setApply(true);
    hasChangedFn();
    toggleModalPeople();
    const newPeople = peopleVar.concat(obj);

    setPeople(newPeople);}   

    const handleSubmit = async event => {
        setInProgress(true);
        await submitFn(peopleVar);
        setInProgress(false);
        setApply(false);
    };

     const toggleModalPeople = () => {
        setShowModalPeople(!showModalPeople);
    }   
    
    const handlePersonAdd = () => {
        setApply(true);  
        hasChangedFn();      
       // setPerson({"isNew":-true,id:"-1"});
        setShowModalPeople(true);
    }

    const cancelRemoveTrainer = () => {
        setShowConfirmRemoveModal(false);
    };

    const confirmRemoveTrainer = () => {
        if (trainerToRemove) {
            setApply(true);
            hasChangedFn();
            const index = peopleVar.findIndex((item) => item.id === trainerToRemove.id);
           
            if (index !== -1) {
                 peopleVar.splice(index, 1);
                 setPeople([...peopleVar]);
            }          
            setShowModalPeople(false);
            setShowConfirmRemoveModal(false);
        }
    };

    const handleTrainerRemove = useCallback(
        (row) => {
            setTrainerToRemove(row);
            setShowConfirmRemoveModal(true);
        },
        [],
    );

    const columnsPeople = useMemo(
        () => [            
            {
                accessorKey: "fullName",
                header: "Trainer"
            },
            {
                accessorKey: "employeeId",
                header: "Employee Id"              
            },            
            {
                accessorKey: "email",
                header: "Email"              
            },
            {
                accessorKey: 'id',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset'
                    }
                },
                size: 75,
                Cell: ({ cell, row }) => {
                    return (<>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Remove trainer</Tooltip>}>
                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleTrainerRemove(row.original)}>
                                <FontAwesomeIcon size='xl' icon={faTrash} className='text-primary' />
                            </Button>
                        </OverlayTrigger>
               
                    </>)
                }
            }
        ],
        [],
    );

    return (
        <>
        <Row>
            <TableContainer>
                <h5>Trainers</h5>
                <MaterialReactTable
                    columns={columnsPeople}
                    data={peopleVar || []}
                    enableBottomToolbar={true}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableGrouping={false}
                    enableHiding={false}
                    enablePagination={true}
                    enableSorting={false}
                    enableTopToolbar={true}
                    enableEditing={false}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add new trainer</Tooltip>}>
                                <Button variant='clear' className='pt-0 pb-0' onClick={() => handlePersonAdd()}>
                                    <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                </Button>
                            </OverlayTrigger>
                        </>
                    )}
                    initialState={{
                        pagination: { pageIndex: 0, pageSize: 10 },
                        showGlobalFilter: true
                    }}
                    state={{
                        isLoading: false
                    }}
                />
            </TableContainer>
        </Row>
       
        {/* <Button variant='primary' className="mt-2 float-right" onClick={closeFn}>Close</Button> */}
        {
            inProgress ?
            <Button variant="primary" className="mx-2 mt-2 float-right" disabled>
                <span className="spinner-border spinner-border-sm mx-2 mt-2" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                Please wait...
            </Button>
            : apply ?
            <Button variant="primary" className="mx-2 mt-2 float-right" onClick={handleSubmit}>
                Save
            </Button>
            :
            <Button variant="primary" className="mx-2 mt-2 float-right" disabled>
                Save
            </Button>
        }

        <ModalCompanyPeople companyId={companyId} selectedData={peopleVar} closeFn={toggleModalPeople} open={showModalPeople} submitFn={(obj) => editAddPeopleRowFn(obj)} />   
        
        <Modal show={showConfirmRemoveModal} onHide={cancelRemoveTrainer}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Remove</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to remove this trainer?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelRemoveTrainer}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={confirmRemoveTrainer}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default TabCompanyPeople;