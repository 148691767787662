import React from 'react';

const LearningItemTabCard = ({title = "Title", onClick = () => null, isActive}) => {
    return (
        <div
            role={"button"}
            onClick={() => {
                onClick()
            }}
            className={"d-flex  flex-column rounded p-2 cursor-pointer text-white col-sm hover-darken custom-time-animation"}
            style={{background: isActive ? "var(--app-nav-primary-link)" : "var(--app-topbar-bg)"}}
        >
            {title}
        </div>
    );
};

export default LearningItemTabCard;
