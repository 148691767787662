import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Nav } from "react-bootstrap";
import { SideBarItem, SideBarItemPill } from './SideBarItem';
import ProtectedAccess from './ProtectedAccess';
import useLmsStore, { hrefToAdminServer } from '../hooks/useLmsStore'
import shallow from 'zustand/shallow';
import useTheme from '../hooks/useTheme';
import { faGears } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { faObjectsColumn, faList, faFileCertificate, faBookOpen, faCircleQuestion, faBell, faPeopleGroup, faCog, faScrewdriverWrench, faNewspaper, faCircleInfo, faToolbox, faFileChartColumn, faUserGear, faUsersGear, faAddressCard } from '@fortawesome/pro-light-svg-icons';

const activateRules = [
    {
        match: [
            '/lms/applicationsettings',
            '/lms/catalogues',
            '/lms/companies',
            '/lms/learningplan',
            '/lms/reports',
            '/lms/users'
        ],
        personSettings: 'isAdministrator',
        activate: 'administration'
    },
    {
        match: [
            '/lms/clientteamprofiles',
            '/lms/newsfeeditems',
            '/lms/reports',
            '/lms/useralerts',
            '/lms/userlearningplan'
        ],
        personSettings: 'isApplicationAdministrator',
        activate: 'myadmindashboard'
    },
    {
        match: [
            '/lms/users',
            '/lms/clientteamprofiles',
            '/lms/newsfeeditems',
            '/lms/reports',
            '/lms/useralerts',
            '/lms/userlearningplan',
            '/lms/catalogues',
            '/lms/learningplan',
        ],
        personSettings: 'isSuperApplicationAdministrator',
        activate: 'mysuperadmindashboard'
    },
    {
        match: [
            '/lms/myteamprofiles',
            '/lms/reports',
            '/lms/useralerts',
            '/lms/userlearningplan'
        ],
        personSettings: 'isManager',
        activate: 'teamdashboard'
    },
    {
        match: [
            '/lms/ukpnserviceadmindashboard'
        ],
        personSettings: 'isCompetencyApprover',
        activate: 'compapproverdashboard'
    },
    {
        match: [
            '/lms/ukpnserviceadmindashboard'
        ],
        personSettings: 'isCompetencyUser',
        activate: 'competencyUser'
    },
    {
        match: [
            '/lms/accesscarddashboard'
        ],
        personSettings: 'hasAccessCard',
        activate: 'accesscarddashboard'
    },
    {
        personSettings: 'showReport',
        activate: 'userreport'
    }

];

export default function SideBar(props) {
    const navigate = useNavigate();
    const { themeName } = useTheme();
    const [activate, setActivate] = useState(null)
    const location = useLocation();

    // if (location.pathname.includes('ukpn')) {
    //     console.log('The location includes "ukpn" : ' + location.pathname.includes('ukpn'));
    // } else {
    //     console.log('The location does not include "ukpn" : ' + location.pathname.includes('ukpn'));
    // }

    const { myLatestAlerts, myLatestPersonNews, personSettings, personBasic, fetchMyLatestAlerts, fetchMyLatestPersonNews, fetchAllNews, fetchPersonBasic } = useLmsStore(state =>
    ({
        myLatestAlerts: state.myLatestAlerts.data,
        myLatestPersonNews: state.myLatestPersonNews.data,
        personBasic: state.personBasic.data,
        fetchMyLatestAlerts: state.fetchMyLatestAlerts,
        fetchMyLatestPersonNews: state.fetchMyLatestPersonNews,
        fetchPersonBasic: state.fetchPersonBasic
    }), shallow)

    useEffect(() => {
        fetchPersonBasic();
        fetchMyLatestAlerts();
        fetchMyLatestPersonNews();
    }, []);

    useEffect(() => {
        let activate = null;
        if (personSettings) {
            for (let i = 0; i < activateRules.length; i++) {
                let rule = activateRules[i];
                if (rule.personSettings) {
                    if (personSettings[rule.personSettings] === true) {
                        if (rule.match.includes(location.pathname)) {
                            activate = rule.activate;
                            break;
                        }
                    }
                } else if (rule.match.includes(location.pathname)) {
                    activate = rule.activate;
                    break;
                }
            }
        }
        setActivate(activate);
    }, [location, personSettings]);

    return (<>
        <span className="m-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{themeName ?
            <img
                className={"d-none d-lg-block"}
                src={require(`../assets/instance/${themeName}/icon_small.png`)}
                style={{
                    display: 'flex', marginBottom: '10px', marginLeft: 'auto', marginRight: 'auto',
                    paddingBottom: '5px', paddingLeft: '5px', paddingRight: '5px', maxWidth: '80%'
                }} />
            : <></>}
        </span>
        <Nav className="justify-content-center">
            <SideBarItem to='/lms/dashboard' label='Dashboard' icon={faObjectsColumn} />
            <SideBarItem to='/lms/mylearningplan' label='My Learning Plan' icon={faList} />
            <SideBarItem to='/lms/courselibrary' label='Library' icon={faBookOpen} />
            <SideBarItem to='/lms/faqs' label='Support' icon={faCircleQuestion} />
            <SideBarItemPill to='/lms/alerts' label='Alerts' icon={faBell} countVariant='warning' countClass='text-dark' count={!myLatestAlerts || myLatestAlerts.length === 0 ? null : myLatestAlerts.length} />
            <SideBarItemPill to='/lms/news' label='News' icon={faNewspaper} countVariant='warning' countClass='text-dark' count={!myLatestPersonNews || myLatestPersonNews.length === 0 ? null : myLatestPersonNews.length} />

            {/* This link is only available for UKPN */}
            {location.pathname.includes('ukpn') ? (
                <SideBarItemPill to='/lms/qualifications' label='My Qualifications' icon={faFileCertificate} countVariant='warning' countClass='text-dark'/>
            ) : (
                null
            )}

            <ProtectedAccess
                adminComponent={<>
                    <SideBarItem to='/lms/teamdashboard' isActive={activate === 'teamdashboard'} label='Team Dashboard' icon={faPeopleGroup} />
                    <SideBarItem to='/lms/mysuperadmindashboard' isActive={activate === 'mysuperadmindashboard'} label='Client Super User Dashboard' icon={faGears} />
                    <SideBarItem to='/lms/myadmindashboard' isActive={activate === 'myadmindashboard'} label='My Admin Dashboard' icon={faCog} />
                    <SideBarItem to='/lms/administration' isActive={activate === 'administration'} label='Admin Dashboard' icon={faScrewdriverWrench} />
                </>}
                competencyUserComponent={<SideBarItem href={hrefToAdminServer(personBasic, 'portal.mydashboard')} isActive={activate === 'competencyUser'} label='User Competencies' icon={faUserGear} />}
                userReportComponent={<SideBarItem href={hrefToAdminServer(personBasic, 'portal.myReports')} isActive={activate === 'userreport'} label='Reports' icon={faFileChartColumn} />}
                appSuperAdminComponent={<SideBarItem to='/lms/mysuperadmindashboard' isActive={activate === 'mysuperadmindashboard'} label='My Super Admin Dashboard' icon={faGears} />}
                appAdminComponent={<SideBarItem to='/lms/myadmindashboard' isActive={activate === 'myadmindashboard'} label='My Admin Dashboard' icon={faCog} />}
                managerComponent={<SideBarItem to='/lms/teamdashboard' isActive={activate === 'teamdashboard'} label='Team Dashboar' icon={faPeopleGroup} />}
                competencyApproverComponent={<SideBarItem to='/lms/ukpnserviceadmindashboard' isActive={activate === 'compapproverdashboard'} label='UKPN Services Admin Dashboard' icon={faToolbox} />}
                accessCardComponent={<SideBarItem to='/lms/accesscarddashboard' isActive={activate === 'accesscarddashboard'} label='Access Card Dashboard' icon={faAddressCard} />}
            />
            <SideBarItem to='/lms/about' label='About' icon={faCircleInfo} />
        </Nav>
    </>);
}
