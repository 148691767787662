import {apiAxiosCalls} from "../services/apiAxiosCalls";
import {useEffect, useState} from "react";

export function useGetApplicationLoginPageConfiguration() {
    const [configuration, setConfiguration] = useState()
    const [loading, setLoading] = useState(false)

    const getConfiguration = async () => {
        setLoading(true)

        try {
            const {data} = await apiAxiosCalls.getApplicationLogInPageConfiguration()
            setConfiguration(data)
        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    }

    useEffect(() => {
        getConfiguration()

    }, [])

    return {
        loginPageConfiguration: configuration,
        loading
    }
}
