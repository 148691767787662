import { useEffect, useMemo, useState } from 'react';

import MaterialReactTable from 'material-react-table';

import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import LinkContainer from '../../_libs/react-router-bootstrap/LinkContainer'

import MailTo from './MailTo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faList } from '@fortawesome/pro-light-svg-icons';

export default function UserProfilesTable(props) {
    const [tableData, setTableData] = useState([]);
    const [tableLoading, setTableLoading] = useState(true);

    useEffect(() => {
        setTableLoading(!props.data);
        setTableData(props.data || []);
    }, [props.data]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'fullName',
                header: 'Full Name',
                size: 300
            },
            {
                accessorKey: 'emailAddress',
                header: 'email Address',
                size: 300,
                Cell: ({ cell, table }) => {
                    let email = cell.getValue();
                    return email && email.includes('@') ? (<MailTo email={email}>{email}</MailTo>) : null;
                }
            },
            {
                accessorKey: 'jobTitle',
                header: 'Job Title',
                size: 200
            },
            {
                accessorKey: 'totalToDo',
                header: 'To Do',
                muiTableBodyCellProps: {
                    classes: { root: 'fw-unset' }   // Flexible width
                },
                Cell: ({ cell, row }) => ''
            },
            {
                accessorKey: 'personId',
                header: 'Id',
                size: 200,
                Cell: ({ cell, row }) => {
                    return (
                        <Row>
                            <Col>
                                <LinkContainer to={{ pathname: '/lms/userlearningplan', search: `id=${cell.getValue()}` }}>
                                    <Button variant='clear' className='pt-0 pb-0'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Learning Plan</Tooltip>}>
                                            <FontAwesomeIcon size='xl' icon={faList} className='text-primary' />
                                        </OverlayTrigger>
                                    </Button>
                                </LinkContainer>
                            </Col>
                            <Col>
                                <LinkContainer to={{ pathname: '/lms/useralerts', search: `id=${cell.getValue()}` }}>
                                    <Button variant='clear' className='pt-0 pb-0'>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Alerts</Tooltip>}>
                                            <FontAwesomeIcon size='xl' icon={faBell} className='text-primary' />
                                        </OverlayTrigger>
                                    </Button>
                                </LinkContainer>
                            </Col>
                        </Row >
                    )
                }
            }
        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={tableData}
            enableBottomToolbar={true}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableGrouping={false}
            enableHiding={false}
            enablePagination={true}
            enableSorting={false}
            enableTopToolbar={true}
            muiTableBodyRowProps={{ hover: false }}
            muiTableHeadProps={{
                sx: {
                    display: 'none'
                }
            }}
            muiTableProps={{
                sx: {
                    tableLayout: 'fixed',
                },
            }}
            positionActionsColumn="last"
            initialState={{
                pagination: { pageIndex: 0, pageSize: 10 },
                showGlobalFilter: true
            }}
            state={{
                isLoading: tableLoading
            }}
        />
    );
}
