import { useEffect, useState } from 'react'

import { Col, Row } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { checkThatImageExists } from '../helpers/imgs';

import imgGrey266x140 from '../assets/imgs/img-grey-266x140.png';
import imgGrey266x140Blurred from '../assets/imgs/img-grey-266x140-blurred.png';
import userblank from '../assets/imgs/userblank.jpg';

import { learningItemName } from '../helpers/tableHelper';

import useLmsStore, { getSynopsis, responseData, postAddToPlan } from '../hooks/useLmsStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faCheck, faPlus } from '@fortawesome/pro-solid-svg-icons';

export function CourseCard(props) {
    const [image, setImage] = useState(imgGrey266x140)
    useEffect(() => {
        if (props.rowData.imageUrl && props.rowData.imageUrl.endsWith('/userblank.jpg')) {
            setImage(userblank);
        } else {
            checkThatImageExists(props.rowData.imageUrl).then((img) => {
                setImage(img ? img.src : imgGrey266x140);
            })
        }
    }, [props.rowData.imageUrl])

    return (
        <Col className='cls-card-signature-colour'>
            <Card className='m-2 h-100 shadow' style={{ minHeight: '420px', width: '300px' }}>
                <Card.Header>
                    <Row>
                        <Col>{learningItemName(props.rowData.learningItemTypeId)}</Col>
                        <Col sm='auto' className='p-0'>{props.rowData.duration > 0 ? props.rowData.duration + ' hours' : ''}</Col>
                        <Col className='d-flex justify-content-end'>{props.rowData.code}</Col>
                    </Row>
                </Card.Header>
                <div className='ratio' style={{ '--bs-aspect-ratio': '60%' }}>
                    <Card.Img variant='middle' src={image} />
                </div>
                <Card.Body>
                    <Card.Title style={{ fontSize: '1rem' }}>{props.rowData.name}</Card.Title>
                    <p style={{ fontSize: '0.85rem' }}>{props.rowData.description}</p>
                </Card.Body>
                <Card.Footer className='cls-clear'>
                    <div className='d-flex justify-content-between'>
                        <div className='p-0' style={{ maxWidth: '50%' }}>
                            <CourseCardCatalogueName {...props} />
                        </div>
                        <div className='p-0'></div>
                        <div className='p-0'>
                            <CourseCardInfo {...props} />
                        </div>
                    </div>
                </Card.Footer>
            </Card>
        </Col>
    );
}

function CourseCardCatalogueName(props) {
    const names = props.rowData.allCatalogueNames;
    const name = props.rowData.catalogueName;
    return (<>
        {names && names.length > 1 ?
            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{names.map((n, idx) => <div key={idx}>{n}</div>)}</Tooltip>}>
                <Badge pill bg='info' style={{ lineHeight: '1.2rem', width: '3rem' }}>...</Badge>
            </OverlayTrigger>
            :
            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>{name}</Tooltip>}>
                <Badge pill bg='info' style={{ lineHeight: '1.2rem', maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{name}</Badge>
            </OverlayTrigger>
        }
    </>);
}

function CourseCardInfo(props) {
    const [showSynopsis, setShowSynopsis] = useState(false);
    const [addedToPlan, setAddedToPlan] = useState(false);
    const [synopsisTitle, setSynopsisTitle] = useState('');
    const [synopsisHtml, setSynopsisHtml] = useState('');
    const handleShowSynopsis = (event) => {
        getSynopsis(props.rowData.learningItemId).then((response) => {
            let data = responseData(response, []);
            if (data.length > 0) {
                setSynopsisTitle(data[0].tagTitle || '');
                setSynopsisHtml(data[0].tagHtml || '');
                setShowSynopsis(true);
            }
        });
    };
    const handleAddToPlan = () => {
        setAddedToPlan(true);
        // *XB-080822* Not sure why all this data must be posted but for now simply post the same data posted by the angular code (previous iteration)
        postAddToPlan(
            useLmsStore,
            {
                CatalogueId: props.rowData.catalogueId,
                IsMandatory: props.rowData.isMandatory,
                IsRenewable: props.rowData.isRenewable,
                ItemGroupId: props.rowData.learinigItemGroupId,
                ItemPersonId: props.rowData.learinigItemPersonId,
                LearningItemId: props.rowData.learningItemId,
                LevelOfApproval: props.rowData.levelOfApproval,
                StatusId: props.rowData.statusId ? props.rowData.statusId : 11
            }
        )
            .then((success) => setAddedToPlan(success));
    };
    const handleCloseSynopsis = (event) => {
        //event.preventDefault();
        setShowSynopsis(false);
    };
    return (<>
        {props.rowData.hasSynopsis === true && props.rowData.learningItemId > 0 ?
            <>
                <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>View Synopsis</Tooltip>}>
                    <Button variant='clear' className='pt-0 pb-0' onClick={handleShowSynopsis}>
                        <FontAwesomeIcon size='xl' icon={faCircleInfo} className='text-primary' />
                    </Button>
                </OverlayTrigger>
                <Modal fullscreen='md-down' show={showSynopsis} onHide={handleCloseSynopsis} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.rowData.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6>{synopsisTitle}</h6>
                        <p><span dangerouslySetInnerHTML={{ __html: synopsisHtml }} /></p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='primary' onClick={handleCloseSynopsis}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </>
            :
            null
        }
        {props.rowData.trainingPlanId > 0 || addedToPlan === true ?
            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Added to plan</Tooltip>}>
                <Button variant='clear' className='pt-0 pb-0'>
                    <FontAwesomeIcon size='xl' icon={faCheck} className='fa-fw' style={{ color: 'var(--app-success)' }} />
                </Button>
            </OverlayTrigger>
            :
            <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add To Plan</Tooltip>}>
                <Button variant='clear' className='pt-0 pb-0' onClick={() => handleAddToPlan(props.rowData)}>
                    <FontAwesomeIcon size='xl' icon={faPlus} className='fa-fw' />
                </Button>
            </OverlayTrigger>
        }
    </>);
}

export function CourseCardLoading(props) {
    return (
        <Col className='cls-card-signature-colour'>
            <Card className='m-2 h-100 shadow cls-blurred cls-card-anim' style={{ minHeight: '420px', width: '300px' }}>
                <Card.Header>
                    <Row>
                        <Col>Lorem Ipsum</Col>
                        <Col sm='auto' className='p-0'>1 hora</Col>
                        <Col className='d-flex justify-content-end'>Curiosus</Col>
                    </Row>
                </Card.Header>
                <div className='ratio' style={{ '--bs-aspect-ratio': '60%' }}>
                    <Card.Img variant='middle' src={imgGrey266x140Blurred} />
                </div>
                <Card.Body>
                    <Card.Title style={{ fontSize: '1rem' }}>Vultus secretum</Card.Title>
                    <p style={{ fontSize: '0.85rem' }}>Responsio ad vitam, universum et omnia sane 42. Hoc a duce obtinuit.</p>
                </Card.Body>
                <Card.Footer className='cls-clear'>
                    <Row>
                        <Col>Duc ad Galaxy</Col>
                        <Col sm='auto' className='d-flex justify-content-end'>BTN</Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Col>
    );
}