import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-regular-svg-icons";
import {apiAxios, toastSuccess} from "../../../hooks/useLmsStore";
import {getAdminPortalRpCall2} from "../../../hooks/useLegacyStore";
import DeleteGroupModal from "../modals/DeleteGroupModal";

/*"var(--app-nav-primary-link)" : "var(--app-bg-filter-active)"*/
const deleteGroup = (groupId) => {
    return apiAxios.delete(getAdminPortalRpCall2(`/api/groupManagement/${groupId}`))
}

function GroupManagementGroupSearchResult({group, onClick = () => null, onSingleGroupIconClick = () => null, isActive=false, onSuccessDeleteGroup}) {

    const [showModal, setShowModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const onDeleteClick = async () => {
        try {
            setIsDeleting(true)
            await deleteGroup(group.Id)
            setIsDeleting(false)
            onSuccessDeleteGroup(group)
            setShowModal(false)
            toastSuccess("Group deleted successfully")
        }catch (e){
            setShowModal(false)
            setIsDeleting(false)
        }
    }

    return <div
        key={group.Id}
        onClick={() => {
            onClick(group)
        }}
        className={"row p-3 w-100 mb-1 align-items-center justify-content-center  hover-darken custom-time-animation border-bottom"}
        style={{background: isActive ? "var(--app-bg-filter-active)" : "white"}}
        role={"button"}

    >
        <DeleteGroupModal isDeleting={isDeleting} show={showModal} group={group} toggle={()=>{setShowModal(!showModal)}} onDelete={onDeleteClick} />
        <div className={"row text-dark"}>
            <div className={"col-10"}>
                {group.Name}
            </div>
            <div className={"col-2 "}>
                <FontAwesomeIcon  onClick={()=>setShowModal(true)} role={"button"} size='xl' icon={faTrash} className='fa-fw text-primary'/>
            </div>
        </div>

    </div>;
}

export default GroupManagementGroupSearchResult;

