import { HubConnection } from "@microsoft/signalr";
import { useEffect } from "react";

/**
 * Registers a handler that will be invoked when the hub method with the specified method name is invoked.
 * @param {HubConnection} hubConnection The signalR hub connection.
 * @param {string} methodName The name of the hub method to define.
 * @param {Function} handler The handler that will be raised when the hub method is invoked.
 */
export function useSignalRHandler(hubConnection, methodName, handler) {
    useEffect(() => {
        if (!!hubConnection) {
            hubConnection.on(methodName, handler);
            return () => {
                hubConnection.off(methodName, handler);
            }
        }
    }, [hubConnection, handler, methodName]);
};