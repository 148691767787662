import React from 'react';
import {Button, Form, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-light-svg-icons";

function GroupManagementTabSearchField({onSearchClick, searchTerm, setSearchTerm, placeHolder=""}) {
    return <Form
        onSubmit={(e) => {
            e.preventDefault()
            onSearchClick()
        }}
        className={"px-1 mr-auto"}>
        <Form.Group className="mb-3">
            <Form.Label>Search</Form.Label>
            <InputGroup className={"cls-search-input-group"}>
                <Form.Control type="input" placeholder={placeHolder} value={searchTerm}
                              className="no-border"
                              onChange={(e) => {
                                  setSearchTerm(e.target.value)
                              }}/>
                <Button variant='search' onClick={() => {
                    onSearchClick()
                }}>
                    <FontAwesomeIcon icon={faSearch}/>
                </Button>
            </InputGroup>
        </Form.Group>
    </Form>
}

export default GroupManagementTabSearchField;
