export const activeTabsEnum = {
    GroupDetail: "GroupDetail",
    LearningPathways: "LearningPathways",
    Permisssions: "Permisssions",
    GroupMembers: "GroupMembers",
}


export  const summaryWidgetGroupManagementNames =  {
    LearningPathways: "Training Cards",
    GroupMembers: "Group Members",
    Permissions: "Permissions",
    GroupDetails: "Group Details",
}

export  const learningItemsTabEnum = {
    Detail: "Detail",
    ELearning: "ELearning",
    Images: "Images",
    Synopsis: "Synopsis",
}

export  const learningItemsCardNames = {
    Detail: "Learning item Detail",
    ELearning: "eLearning",
    Images: "Images",
    Synopsis: "Synopsis",
}


export const initialUpdateCreateGroupPayload = {
    groupId: -1,
    Name: "",
    Description: "",
    Reports: [],
    GroupMembers: [],
    TrainingCards: [],
    Permissions: [],
    LearningItems: [],
    Groups: []
}


export const pageSizeGroupPage = 30

export const searchGroupsInitialPayload = {
    filter: "", itemOffset: 0, itemCount: pageSizeGroupPage,
}
