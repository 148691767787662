import { useEffect, useState } from 'react';

import { Button, Col, Container, Form, InputGroup, Modal, Row, Spinner } from "react-bootstrap";

import cloneDeep from 'lodash/cloneDeep';

import useLmsStore, { postApplicationSettings } from '../hooks/useLmsStore';

const ModalApplicationSettings = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        setFormData(cloneDeep(props.data));
    }, [props.data]);

    const handleUpdate = async e => {
        setIsSaving(true);
        postApplicationSettings(useLmsStore, formData).then((success) => {
            setIsSaving(false);
            props.closeFn();
        });
    };

    return (
        <Modal size='xl' fullscreen='xl-down' show={props.open} onHide={props.closeFn} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{formData && formData.name} Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Container className='mr-2 ml-2'>
                        {(formData||[]).map((row, rowIdx) => (
                            <Row className='border rounded mb-3 pt-2 pb-2' key={`${row.applicationId}-${row.id}`}>
                                <h5>{row.name} Settings</h5>
                                {row && row.settings ? row.settings.map((setting, settingIdx) => (
                                    <Col md={6} xl={3} key={setting.id > 0 ? setting.id : settingIdx.toString().concat(row.id)} className={settingIdx < 3 ? 'border-end' : ''}>
                                        <h6>{setting.settingTypeName}:</h6>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number"
                                                id={`period-${setting.id > 0 ? setting.id : settingIdx.toString().concat(row.id)}-v`}
                                                defaultValue={setting.reminderPeriod}
                                                placeholder='No. of'
                                                onChange={(e) => formData[rowIdx].settings[settingIdx].reminderPeriod = parseInt(e.target.value)}
                                            />
                                            <Form.Select
                                                size="sm"
                                                defaultValue={setting.reminderPeriodUnit}
                                                onChange={(e) => formData[rowIdx].settings[settingIdx].reminderPeriodUnit = parseInt(e.target.value)}
                                            >
                                                <option value='0'>Select</option>
                                                <option value='1'>Days</option>
                                                <option value='2'>Weeks</option>
                                                <option value='3'>Months</option>
                                                <option value='4'>Years</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <h6>Audience:</h6>
                                        <Form.Check
                                            type='switch'
                                            id={`audience-${setting.id > 0 ? setting.id : settingIdx.toString().concat(row.id)}-1`}
                                            label='Learners'
                                            defaultChecked={setting.isLearnerAudience}
                                            onChange={(e) => formData[rowIdx].settings[settingIdx].isLearnerAudience = e.target.checked}
                                        />
                                        <Form.Check
                                            type='switch'
                                            id={`audience-${setting.id > 0 ? setting.id : settingIdx.toString().concat(row.id)}-2`}
                                            label='Line Managers'
                                            defaultChecked={setting.isManagerAudience}
                                            onChange={(e) => formData[rowIdx].settings[settingIdx].isManagerAudience = e.target.checked}
                                        />
                                        <Form.Check
                                            type='switch'
                                            id={`audience-${setting.id > 0 ? setting.id : settingIdx.toString().concat(row.id)}-3`}
                                            label='Client Admins'
                                            defaultChecked={setting.isClientAppAudience}
                                            onChange={(e) => formData[rowIdx].settings[settingIdx].isClientAppAudience = e.target.checked}
                                        />
                                    </Col>
                                )) : null}
                            </Row>
                        ))}
                    </Container>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={props.closeFn} disabled={isSaving}>Close</Button>
                {isSaving ?
                    <Button variant="primary">
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: '0.25rem' }}
                        />
                        Saving...
                    </Button> :
                    <Button variant="primary" type="submit" onClick={handleUpdate}>Save</Button>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default ModalApplicationSettings;