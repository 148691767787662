import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import { useSearchParams } from "react-router-dom";


export default function ServerError() {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState('500');

    useEffect(() => setError(searchParams.get("error") || ''), [searchParams]);

    return (
        <Container style={{ height: '100vh' }}>
            <Row style={{ padding: '4rem 0' }} className='justify-content-center'>
                <Col md='auto'>
                    <p style={{ fontSize: '3rem', fontWeight: 400 }}><span className='text-danger'>Oops!</span>{error === '504' ? <> The server is currently unavailable.</> : <> An internal server error {error} occurred.</>}<br />Please contact us if the issue persists.</p>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col md='auto'>
                    <Button href='/'>Try Again</Button>
                </Col>
            </Row>
        </Container>
    );
}