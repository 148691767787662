import { useEffect, useMemo, useState } from 'react';

import BaseContainer from '../components/BaseContainer';

import MaterialReactTable from 'material-react-table';

import { TableContainer } from '../helpers/tableHelper'

import { Button, OverlayTrigger, Tab, Table, Tabs, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-regular-svg-icons';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import ModalApplicationSettings from '../components/ModalApplicationSettings';

const filteredData = (rows, tabId) => {
    let id = parseInt(tabId);
    return !Array.isArray(rows) || id < 1 ? [] : rows.filter(x => x.applicationId === id);
}

const uniqueApps = (rows) => {
    let apps = [];
    if (Array.isArray(rows)) {
        rows.forEach(row => {
            if (!apps.find(e => e.applicationId === row.applicationId)) {
                apps.push({ applicationId: row.applicationId, applicationName: row.applicationName });
            }
        });
    }
    return apps;
};

const TdAudience = (props) => <td style={{ backgroundColor: props.isSet ? 'var(--app-info)' : 'transparent', textAlign: 'center', width: '20%' }}>{props.children}</td>;

function periodUnitLabel(periodUnit) {
    switch (periodUnit) {
        case 1:
            return 'Days';
        case 2:
            return 'Weeks';
        case 3:
            return 'Months';
        case 4:
            return 'Years';
        default:
            return ''
    };
}

export default function ApplicationSettings(props) {
    const [tabId, setTabId] = useState('0');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [formData, setFormData] = useState();

    const { allApplications, fetchAllApplications } = useLmsStore(state =>
    ({
        allApplications: state.allApplications.data,
        fetchAllApplications: state.fetchAllApplications
    }), shallow)

    // on load
    useEffect(() => {
        fetchAllApplications();
    }, []);

    useEffect(() => {
        if (tabId === '0' && allApplications && allApplications.length) {
            setTabId('' + allApplications[0].applicationId);
        }
    }, [allApplications]);

    const handleEdit = (rowData) => {
        if (allApplications && allApplications.length) {
            setFormData(allApplications.filter(x => x.applicationId === rowData.applicationId));
            setIsOpenModal(true);
        }
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
                muiTableBodyCellProps: ({ cell, table }) => {
                    return cell.getIsGrouped() ? {
                        classes: { root: 'border-top' },
                        sx: {
                            backgroundColor: '#f8f8f8',
                            color: 'var(--app-h4-colour)',
                            display: cell.getValue() ? 'table-cell' : 'none !important',
                            fontSize: '1.25rem'
                        }
                    } :
                        {
                            sx: {
                                display: 'none'
                            }
                        }
                },
                GroupedCell: ({ cell }) => <>{cell.getValue()} Settings</>,
            },
            {
                accessorKey: 'id',
                header: 'Id',
                muiTableBodyCellProps: ({ cell, table }) => { return cell.id.startsWith('name:') ? { sx: { display: 'none' } } : { classes: { root: 'fw-unset ml-5' } } },
                Cell: ({ cell, row }) =>
                    <Table key={cell.getValue()} bordered style={{ margin: '0 0 0 2rem', width: '650px' }}>
                        <tbody>
                            {row.original.settings.map((setting, idx) => (
                                <tr key={setting.id > 0 ? setting.id : idx}>
                                    <td style={{ width: '20%' }}>{setting.settingTypeName}</td>
                                    <td style={{ width: '20%' }}>{setting.reminderPeriod} {periodUnitLabel(setting.reminderPeriodUnit)}</td>
                                    <TdAudience isSet={setting.isLearnerAudience}>Learners</TdAudience>
                                    <TdAudience isSet={setting.isManagerAudience}>Line Managers</TdAudience>
                                    <TdAudience isSet={setting.isClientAppAudience}>Client Admins</TdAudience>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
            }
        ],
        [],
    );

    return (
        <BaseContainer title='Application Settings'>
            <TableContainer>
                <Tabs activeKey={tabId} onSelect={(k) => setTabId(k)} className="pt-3 mb-2" style={{ backgroundColor: 'var(--app-bg)' }}>
                    {!allApplications ? [] : uniqueApps(allApplications).map((row, idx) => (<Tab title={row.applicationName} key={row.applicationId} eventKey={row.applicationId}>
                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Edit</Tooltip>}>
                            <Button variant='clear' style={{ float: 'right' }} onClick={() => handleEdit(row)}>
                                <FontAwesomeIcon size='xl' icon={faPencil} className='text-primary' />
                            </Button>
                        </OverlayTrigger>
                    </Tab>))}
                </Tabs>
                <MaterialReactTable
                    columns={columns}
                    data={filteredData(allApplications, tabId)}
                    enableBottomToolbar={false}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableGlobalFilter={false}
                    enableGrouping={false}
                    enableHiding={false}
                    enablePagination={false}
                    enableSorting={false}
                    enableTopToolbar={false}
                    muiTableBodyRowProps={{ hover: false }}
                    muiTableHeadProps={{
                        sx: {
                            display: 'none'
                        }
                    }}
                    muiTableProps={{
                        sx: {
                            tableLayout: 'fixed',
                        },
                    }}
                    state={{
                        density: 'compact',
                        expanded: true,
                        grouping: ['name'],
                        isLoading: !allApplications
                    }}
                />
            </TableContainer>
            <ModalApplicationSettings closeFn={() => setIsOpenModal(!isOpenModal)}
                open={isOpenModal}
                data={formData} />
        </BaseContainer>
    );
}
