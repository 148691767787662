import { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import MaterialReactTable from 'material-react-table';

import { Typography } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faSquareCheck, faSquareMinus } from '@fortawesome/pro-light-svg-icons';

export default function LearningPlanEditorGroupTableSelect(props) {
    const [headerClickState, handleHeaderClick] = useReducer(function (state, action) { return { count: state.count + 1, flag: action }; }, { count: 0, flag: 'none' }); // count to ensure a change of headerClickState on every click
    const [tableLoading, setTableLoading] = useState(true);
    const headerSquareCheckRef = useRef();
    const headerSquareMinusRef = useRef();
    const headerSquareRef = useRef();

    const { fetchAllLearningItems } = useLmsStore(state =>
    ({
        fetchAllLearningItems: state.fetchAllLearningItems,
    }), shallow)

    useEffect(() => {
        initDataRight();
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        props.selectRight(headerClickState.flag);
        // eslint-disable-next-line 
    }, [headerClickState]);

    useEffect(() => refreshGlobalInputCheckbox(),
        // eslint-disable-next-line 
        [props.hasSelectedRight]);

    const initDataRight = () => {
        setTableLoading(true);
        if (props.dataSelected && props.dataSelected.id > 0) {
            fetchAllLearningItems().then(data => {
                props.initData(false, structuredClone(data || []));
                setTableLoading(false);
            });
        } else {
            props.initData(false, []);
            setTableLoading(false);
        }
    };

    const handleCellClick = (cell, event) => {
        props.toggleRight(cell.row.original.id);
    };

    const refreshGlobalInputCheckbox = () => {
        if (headerSquareRef.current && headerSquareCheckRef.current && headerSquareMinusRef.current) {
            headerSquareRef.current.style.display = props.hasSelectedRight === 'none' ? 'inline-block' : 'none';
            headerSquareMinusRef.current.style.display = props.hasSelectedRight === 'some' ? 'inline-block' : 'none';
            headerSquareCheckRef.current.style.display = props.hasSelectedRight === 'all' ? 'inline-block' : 'none';
        }
    };

    const columns = useMemo(() => [
        {
            accessorKey: 'isRowSelected',
            header: 'Select',
            Header: ({ column }) => (
                <>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquare} style={{ cursor: 'pointer', fontSize: '1rem' }} ref={headerSquareRef} onClick={() => handleHeaderClick('all')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareMinusRef} onClick={() => handleHeaderClick('all')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Deselect All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareCheck} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareCheckRef} onClick={() => handleHeaderClick('none')} />
                    </OverlayTrigger>
                </>
            ),
            muiTableBodyCellProps: {
                sx: {
                    cursor: 'pointer'
                }
            },
            size: 50,
            Cell: ({ cell, row }) => <FontAwesomeIcon icon={cell.getValue() === true ? faSquareCheck : faSquare} style={{ fontSize: '1rem' }} />
        },
        {
            accessorKey: 'code',
            enableEditing: false,
            header: 'Code',
            size: 100,
            muiTableBodyCellProps: ({ cell, table }) => {
                return {
                    sx: {
                        cursor: 'pointer',
                        fontWeight: cell.row.original.isRowSelected ? 'bold' : 'normal'
                    }
                }
            }
        },
        {
            accessorKey: 'title',
            enableEditing: false,
            header: 'Title',
            muiTableBodyCellProps: ({ cell, table }) => {
                return {
                    classes: { root: 'fw-unset' },   // Flexible width
                    sx: {
                        cursor: 'pointer',
                        fontWeight: cell.row.original.isRowSelected ? 'bold' : 'normal'
                    }
                }
            },
            muiTableHeadCellProps: { classes: { root: 'fw-unset' } } // Flexible width
        }
    ], [],);

    return (<>
        <MaterialReactTable
            columns={columns}
            data={props.dataRight}
            enableBottomToolbar={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableEditing={false}
            enableFullScreenToggle={false}
            enableGrouping={false}
            enableHiding={false}
            enablePagination={false}
            enableRowVirtualization
            enableSorting={false}
            enableTopToolbar={true}
            muiTableBodyCellProps={({ cell }) => ({
                onClick: (event) => {
                    handleCellClick(cell, event);
                },
            })}
            muiTableBodyRowProps={{ hover: false }}
            muiTableContainerProps={{ sx: { maxHeight: 'max(170px, calc(100vh - 477px))' } }}
            renderTopToolbarCustomActions={() => (
                <Typography component="span" variant="h6">
                    Available Titles
                </Typography>
            )}
            initialState={{
                showGlobalFilter: true
            }}
            state={{
                isLoading: tableLoading
            }}
        />
    </>);
}