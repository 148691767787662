import { Link } from "react-router-dom";

const MailTo = ({ email, ...props }) => {
    return (
        <a href={`mailto:${email}`}>
            {props.children}
        </a>
    );
};

export default MailTo;