import { useState, useMemo } from 'react';
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye, faCheck , faXmark, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { TableContainer } from '../helpers/tableHelper';
import MaterialReactTable from 'material-react-table';

const TabCompanyDivisions = ({ submitFn = (obj) => null, hasChangedFn = () => null, closeFn = (obj) => null, divisions }) => {
    const [divisionsVar, setDivisions] = useState(divisions);
    const [departments, setDepartments] = useState([]);
    const [teams, setTeams] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [showDepartment, setShowDepartment] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [apply, setApply] = useState(false);    
    const [tableInactive, setTableInactive] = useState(false);
    const [tableDepartmentInactive, setTableDepartmentInactive] = useState(false);
    const [tableTeamInactive, setTeamTableInactive] = useState(false);

    const withSelectedRows = (tableData, inactive) =>  (tableData??[]).filter(e => inactive == true || ( inactive == false && e.isActive == true) );  

    const onInactiveChange = (event) => {
        setTableInactive(!tableInactive);
    }

    const onDepartmentInactiveChange = (event) => {
        setTableDepartmentInactive(!tableDepartmentInactive);
    }

    const onTeamInactiveChange = (event) => {
        setTeamTableInactive(!tableTeamInactive);    
    }

    const handleSubmit = async event => {
        setInProgress(true);
        await submitFn(divisionsVar);
        setInProgress(false);
        setApply(false);
    };

    const handleDivAdd = (rowData) => {
        
        let newId =( divisionsVar? (-1) * divisionsVar.length:-1);
        
        if (!rowData){
            rowData = { departments:[], id: newId, isActive:true, name:"Double-click to rename!"};
        }
        
        setApply(true);
        hasChangedFn();
        divisionsVar.push(rowData);
   
        setDivisions([...divisionsVar]);
        console.log('div var add', JSON.stringify(divisionsVar));
    }

    const handleDepAdd = (rowData) => {
        setApply(true);
        hasChangedFn();
        let newId =( departments? (-1)*departments.length:-1);
        if (!rowData){
            rowData =  { teams:[], id:newId, isActive:true, name:"Double-click to rename!"};
        }
        departments.push(rowData);
        setDepartments([...departments]);
        const newList = divisionsVar.filter((item) => item.id !== selectedDivision.id);
        const newItem = divisionsVar.filter((item) => item.id === selectedDivision.id);
        newItem[0].departments = departments;
        newList.push(newItem[0]);
        setDivisions([...newList]);
        console.log('dep var add -div', divisionsVar);
    }

    const handleTeamAdd = (rowData) => {
        setApply(true);
        hasChangedFn();
        let newId =( teams? (-1) * teams.length:-1);
        teams.push({ teams:[], id:-1, isActive:true, name:"Double-click to rename!"});
        setTeams([...teams]);
        const newList = divisionsVar.filter((item) => item.id !== selectedDivision.id);
        const newItem = divisionsVar.filter((item) => item.id === selectedDivision.id);
        const newListD = departments.filter((item) => item.id !== selectedDepartment.id);
        const newItemD = departments.filter((item) => item.id === selectedDepartment.id);
        newItemD[0].teams = teams;
        newListD.push(newItemD[0]);
        setDepartments([...newListD]);
        newItem[0].departments = departments;
        newList.push(newItem[0]);
        setDivisions([...newList]);
    }

    const selectDivision = (rowData) => {
        rowData.departments.forEach(x=> x['divId']= rowData.id);
        setDepartments([...rowData.departments]);
        setSelectedDivision(rowData);
        setShowDepartment(true);
        setShowTeam(false);
    }

    const selectDepartment = (rowData) => {
        console.log( "rowData :  " + JSON.stringify(rowData))
        rowData.teams.forEach((x)=>{
            x['depId']= rowData.id;
            x['divId']= rowData.divId;
        });
        setTeams([...rowData.teams]);
        setSelectedDepartment(rowData);
        setShowTeam(true);
    }

    const handleSaveDivCell= async (cell,value ) => {
        setApply(true);
        hasChangedFn();
        if ( cell.column.id!=='isActive'){
            divisionsVar[cell.row.index][cell.column.id] = value;
        }
        else{          
            divisionsVar[cell.row.index][cell.column.id] = (value==='true');
        }
        setDivisions([...divisionsVar]);   
    };    

    const handleSaveDepCell= async (cell,value ) => {
        setApply(true);
        hasChangedFn();
        const idx = divisionsVar.findIndex((item) => item.id ===selectedDivision.id);

        if (idx > -1) {
            const deps = divisionsVar[idx].departments;
            if ( cell.column.id!=='isActive'){            
                deps[cell.row.index][cell.column.id] = value;
            }
            else{
                deps[cell.row.index][cell.column.id] = (value==='true');
            }
            setDepartments([...deps]);
            divisionsVar[idx].departments = deps;
        }
        setDivisions([...divisionsVar]);   
    };

    const handleSaveTeamCell= async (cell,value ) => {    
        setApply(true);
        hasChangedFn();       
        const idxDiv = divisionsVar.findIndex((item) => item.id ===selectedDivision.id);

        if (idxDiv>-1){
            const deps = divisionsVar[idxDiv].departments;

            const idxDep = deps.findIndex((item) => item.id ===selectedDepartment.id);

            if (idxDep>-1){
                const teams = deps[idxDep].teams;
                if ( cell.column.id!=='isActive'){
                    teams[cell.row.index][cell.column.id] = value;
                }
                else{
                    teams[cell.row.index][cell.column.id] = (value==='true');
                }
                setTeams([...teams]);
                deps[idxDep].teams = teams;
                setDepartments([...deps]);
                divisionsVar[idxDiv].departments = deps;
                setDivisions([...divisionsVar]);
            }
        }
        setDivisions([...divisionsVar]);   
    };

    const handleSaveDivRow = async ({ exitEditingMode, row, values }) => {
        console.log('div var 1', divisionsVar);
        console.log(1);
        setApply(true);
        console.log(2);
        hasChangedFn();
        console.log(3);       
        console.log('row', row);
        console.log('row index', row.index)
        let obj = divisionsVar[row.index];
        console.log(4);
        for(var key in obj) {
            console.log(5);
            if(values[key])
            {
                console.log(6);
                if(key==='isActive')
                {
                    console.log(7);
                    obj[key]= values[key]==='true';
                }
                else{
                    console.log(8);
                    obj[key]= values[key];
                }                
            }   
        }
        console.log(9);
        divisionsVar[row.index] = obj;
        console.log(10);
        setDivisions([...divisionsVar]);
        console.log(11);
        exitEditingMode(); //required to exit editing mode
        console.log('div var end', divisionsVar);
        console.log(12);
    };
    
    const handleSaveDepRow = async ({ exitEditingMode, row, values }) => {
        setApply(true);
        hasChangedFn();
        const idx = divisionsVar.findIndex((item) => item.id === row.original.divId);
        if (idx > -1) {
            const dep = divisionsVar[idx].departments;
            console.log(`Departments`,dep)
            let obj = dep[row.index];
            for(var key in obj) {
                if(values[key])
                {
                    if(key==='isActive')
                    {
                        obj[key]= values[key]==='true';
                    }
                    else{
                        obj[key]= values[key];
                    }
                }   
            }
            dep[row.index] = obj;
            setDepartments([...dep]);
            divisionsVar[idx].departments = dep;
            setDivisions(divisionsVar);
        }
        exitEditingMode(); //required to exit editing mode
    };

    const handleSaveTeamRow = async ({ exitEditingMode, row, values }) => {
        setApply(true);
        hasChangedFn();
        const idx = divisionsVar.findIndex((item) => item.id === row.original.divId);
        if (idx > -1) {
            const idxD = divisionsVar[idx].departments.findIndex((item) => item.id === row.original.depId);
            if (idxD > -1) {
                const tms = divisionsVar[idx].departments[idxD].teams;
                let obj = tms[row.index];
                for(var key in obj) {
                    if(values[key])
                    {
                        if(key==='isActive')
                        {
                            obj[key]= values[key]==='true';
                        }
                        else{
                            obj[key]= values[key];
                        }
                    }   
                }
                tms[row.index] = obj;
                setTeams([...tms]);
                const dep = divisionsVar[idx].departments;
                dep[idxD].teams = tms;
                setDepartments([...dep]);
                divisionsVar[idx].departments = dep;
                setDivisions(divisionsVar);
            }
        }
        exitEditingMode(); //required to exit editing mode
    };

    const columnsDiv = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                //size:150,
                Cell: ({ cell, row }) => {

                    const tooltip = (
                        <Tooltip >
                            Double-click to edit division name
                        </Tooltip>
                    );
                    return (
                        <> 
                            <OverlayTrigger placement="top" overlay={tooltip}>
                                <div>{cell.getValue()}</div>
                            </OverlayTrigger>
                       </>
                    )
                }
            },
            {
                accessorKey: "level",
                header: "level"
            },
            {
                accessorKey: "departments",
                header: "Departments",
                enableEditing:false,               
            },
            {
                accessorKey: "isActive",
                header: "Active",
                size: 10,
                Cell: ({ cell, row }) => {
                    return (
                        cell.getValue()?
                        <FontAwesomeIcon size='xl' icon={faCheck} className='text-primary' />
                        :
                        <FontAwesomeIcon size='xl' icon={faXmark} className='text-primary' />
                    )
                }
            },
            {
                accessorKey: 'id',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        textOverflow: 'unset',        
                    }
                },
                // headerProps: {
                //     align: 'right', // Right-align the header content
                //     sx: {
                //       textAlign: 'right', // Right-align the header text
                //     },
                //   },      
                size: 50,
                // headerProps: {
                //     align: 'right', // Right-align the header content 
                //style={{ marginLeft: level2Count > 0 ? '60px' : '70px', display: 'inline-block', alignItems: 'center' }}
                // },
                enableEditing:false,                
                Cell: ({ cell, row }) => {
                    const level2Count = row.original.departments.filter(department => department.level === 2).length;
                    return (
                        <>
                            <OverlayTrigger
                                    placement="top"   
                                    overlay={<Tooltip className='cls-theme-tooltip'>
                                        {level2Count > 0 ? 'View departments' : 'Add department'}
                                        </Tooltip>}
                                    >
                                    <span >
                                        <Button
                                            variant='contained'
                                            onClick={() =>
                                                cell.getValue() > 0
                                                ? selectDivision(row.original)
                                                : selectDivision(row.original)
                                            }
                                            >
                                            <FontAwesomeIcon
                                                size='lg'
                                                icon={level2Count > 0 ? faEye : faPlus}
                                                className='text-primary'
                                            />
                                        </Button>
                                    </span>
                            </OverlayTrigger>
                        </>
                    );
                }
            }
        ],
        [],
    );

    const columnsDep = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                Cell: ({ cell, row }) => {
                    const tooltip = (
                        <Tooltip >
                            Double-click to edit department name
                        </Tooltip>
                    );
                    return (
                        <> 
                            <OverlayTrigger placement="top" overlay={tooltip}>
                                <div>{cell.getValue()}</div>
                            </OverlayTrigger>
                       </>
                    )
                }
            },
            {
                accessorKey: "level",
                header: "level"
            },
            {
                accessorKey: "teams",
                header: "teams"
            },
            {
                accessorKey: "isActive",
                header: "Active",
                size: 10,
                Cell: ({ cell, row }) => {
                    return (
                        cell.getValue()?
                        <FontAwesomeIcon size='xl' icon={faCheck} className='text-primary' />
                        :
                        <FontAwesomeIcon size='xl' icon={faXmark} className='text-primary' />
                    )
                }
            },
            {
                accessorKey: 'id',
                header: 'Actions',
                muiTableBodyCellProps: {
                    align: 'center', // Right-align the cell content
                    sx: {
                        textOverflow: 'unset',
                    }
                },
                size: 50,
                // headerProps: {
                //     align: 'right', // Right-align the header content
                //     sx: {
                //       textAlign: 'right', // Right-align the header text
                //     },
                //   },        
                
                enableEditing:false,
                Cell: ({ cell, row }) => {
                    return (
                        <OverlayTrigger
                            placement="top"   
                            overlay={<Tooltip className='cls-theme-tooltip'>
                                {cell.getValue() > 0 ? 'View teams' : 'Add team'}
                                </Tooltip>}
                            >
                             
                            {/* <span style={{ marginLeft: cell.getValue().length > 0 ? '60px' : '70px', display: 'inline-block', alignItems: 'center' }} > */}
                                <Button
                                    variant='contained'
                                    onClick={() =>
                                        cell.getValue() > 0
                                        ? selectDepartment(row.original)
                                        : selectDepartment(row.original)
                                    }
                                    >
                                    <FontAwesomeIcon
                                        size='lg'
                                        icon={cell.getValue() > 0 ? faEye : faPlus}
                                        className='text-primary'
                                    />
                                </Button>
                            {/* </span> */}
                        </OverlayTrigger>
                    )
                }
            } 
        ],
        [],
    );

    const columnsTeam = useMemo(
        () =>[
            {
                accessorKey: "name",
                header: "Name",
                Cell: ({ cell, row }) => {
                    const tooltip = (
                        <Tooltip >
                            Double-click to edit department name
                        </Tooltip>
                    );
                    return (
                        <> 
                            <OverlayTrigger placement="top" overlay={tooltip}>
                                <div>{cell.getValue()}</div>
                            </OverlayTrigger>
                        </>
                    )
                }
            },
            {
                accessorKey: "level",
                header: "Level"
            },
            {
                accessorKey: "teams",
                header: "Teams"
            },
            {
                accessorKey: "isActive",
                header: "Active",                
                size: 10,             
                Cell: ({ cell, row }) => {
                    return (
                        cell.getValue()?
                        <FontAwesomeIcon size='xl' icon={faCheck} className='text-primary' />
                        :                        
                        <FontAwesomeIcon size='xl' icon={faXmark} className='text-primary' />
                    )
                }
            },         
        ],
        [],
    );

    return (
        <>
            <Row>
                <Col>
                    <TableContainer>
                        <h5 className="mx-2">Divisions</h5>
                        <div className="mt-2 mx-2 form-check form-switch">
                            <input type="checkbox" id="isDivisionsActive" className="form-check-input" onChange={onInactiveChange} />
                            <label title="" htmlFor="isDivisionsActive" className="form-check-label">Include Inactive</label>
                        </div>   
                        <MaterialReactTable
                            columns={columnsDiv}
                            data={withSelectedRows(divisionsVar, tableInactive) || []}
                            initialState={{ columnVisibility: { level: false, departments :false } }}
                            enableBottomToolbar={true}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            enableGrouping={false}
                            enableHiding={false}
                            enablePagination={false}
                            enableSorting={false}
                            enableTopToolbar={true}
                            editingMode="cell"
                            enableEditing={true}
                            muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                                //onBlur is more efficient, but could use onChange instead
                                onBlur: (event) => {
                                    handleSaveDivCell(cell, event.target.value);
                                },
                                variant: 'outlined',
                            })}                 
                            renderToolbarInternalActions={({ table }) => (
                                <>
                                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add new Division</Tooltip>}>
                                        <Button variant='clear' className='pt-0 pb-0' onClick={() => handleDivAdd()}>
                                            <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                        </Button>
                                    </OverlayTrigger>
                                </>
                            )}
                            state={{
                                isLoading: false
                            }}
                        />
                    </TableContainer>
                </Col>
                <Col>
                {
                    showDepartment?
                    <>                    
                        <TableContainer>
                            <h5 className="mx-2">Departments from <i>{ selectedDivision.name }</i></h5>
                            <div className="mt-2 mx-2 form-check form-switch">
                                <input type="checkbox" id="isDepartmentsActive" className="form-check-input" onChange={onDepartmentInactiveChange} />
                                <label title="" htmlFor="isDepartmentsActive" className="form-check-label">Include Inactive</label>
                            </div>
                            <MaterialReactTable
                                columns={columnsDep}
                                data={withSelectedRows(departments, tableDepartmentInactive) || []}
                                initialState={{ columnVisibility: { level: false, teams :false } }}
                                enableBottomToolbar={true}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                enableGrouping={false}
                                enableHiding={false}
                                enablePagination={false}
                                enableSorting={false}
                                enableTopToolbar={true}
                                editingMode="cell"
                                muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                                    //onBlur is more efficient, but could use onChange instead
                                    onBlur: (event) => {
                                        handleSaveDepCell(cell, event.target.value);
                                    },
                                    variant: 'outlined',
                                })} 
                                enableEditing={true}
                                renderToolbarInternalActions={({ table }) => (
                                    <>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add new Department</Tooltip>}>
                                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleDepAdd()}>
                                                <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                )}
                                state={{
                                    isLoading: false
                                }}
                            />
                        </TableContainer>
                    </>
                    :
                    <></>
                }
                {
                    showTeam?
                    <>     
                        <hr className="my-3" />               
                        <TableContainer>
                            <h5 className="mx-2">Teams from <i>{ selectedDepartment.name }</i></h5>                        
                            <div className="mt-2 mx-2 form-check form-switch">
                                <input type="checkbox" id="isTeamActive" className="form-check-input" onChange={onTeamInactiveChange} />
                                <label title="" htmlFor="isTeamActive" className="form-check-label">Include Inactive</label>
                            </div>   
                            <MaterialReactTable
                                columns={columnsTeam}
                                data={withSelectedRows(teams, tableTeamInactive) || []}
                                initialState={{ columnVisibility: { level: false, teams :false } }}
                                enableBottomToolbar={true}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                enableGrouping={false}
                                enableHiding={false}
                                enablePagination={false}
                                enableSorting={false}
                                enableTopToolbar={true}
                                editingMode="cell"
                                enableEditing={true}
                                muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                                    //onBlur is more efficient, but could use onChange instead
                                    onBlur: (event) => {
                                        handleSaveTeamCell(cell, event.target.value);
                                    },
                                    variant: 'outlined',
                                })} 
                                renderToolbarInternalActions={({ table }) => (
                                    <>
                                        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Add new Team</Tooltip>}>
                                            <Button variant='clear' className='pt-0 pb-0' onClick={() => handleTeamAdd()}>
                                                <FontAwesomeIcon size='xl' icon={faPlus} className='text-primary' />
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                )}
                                state={{
                                    isLoading: false
                                }}
                            />
                        </TableContainer>
                    </>
                    :
                    <></>
                }
                </Col>
            </Row>
            <Button variant='primary' className="mt-2 float-right" onClick={closeFn}>Close</Button>
            {
                inProgress ?
                <Button variant="primary" className="mx-2 mt-2 float-right" disabled>
                    <span className="spinner-border spinner-border-sm mx-2 mt-2" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                    Please wait...
                </Button>
                : apply ?
                <Button variant="primary" className="mx-2 mt-2 float-right" onClick={handleSubmit}>
                    Save
                </Button>
                :
                <Button variant="primary" className="mx-2 mt-2 float-right" disabled>
                    Save
                </Button>
            }
        </>
    );
};

export default TabCompanyDivisions;