import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, InputGroup, Alert, Button } from "react-bootstrap";
import useLmsStore, { responseData, getAdminNewsItem, postAdminNewsItem, postDeleteAdminNewsItem, FORMCONFIRM } from "../hooks/useLmsStore";
import moment from 'moment'
import Multiselect from 'multiselect-react-dropdown';
import { usePrompt } from '../hooks/usePrompt'

const FormAdminNewsItem = ({ refreshFn = (obj) => null, adminNewsItem, applicationGroups }) => {
    const [newsItem, setNewsItem] = useState(adminNewsItem);
    const [newsItemLoading, setNewsItemLoading] = useState(true);
    const [newsItemError, setNewsItemError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [appGList, setAppGList] = useState([]);
    const [showDialog, setShowDialog] = useState(false);

    const today = new Date();

    const handleFormLoad = () => {
        setNewsItemError(false);
        setNewsItemLoading(true);
        setShowDialog(false);
        if (adminNewsItem && adminNewsItem.id) {
            getAdminNewsItem(adminNewsItem.id).then((response) => {
                let data = responseData(response, []);
                if (data.id) {
                    setNewsItem(data);
                    setAppGList(data.groups.map(function (c) { return { 'title': c.name, 'id': c.id, 'groupId': c.groupId } }));
                    setNewsItemLoading(false);
                }
                if (data.statusCode !== 200 || !data.value) {
                    setNewsItemError(true);
                    setNewsItemLoading(false);
                }
            });
        }
        else {
            //if it is a new adminNewsItem!
            //[{id: 1, groupId: 18, name: "Application Administrator"}],
            setNewsItem({
                applicationId: 3,
                createdBy: 0,
                description: "",
                expiryDate: null,
                expiryDateStr: null,
                groups: [],
                id: -1,
                isActive: true,
                link: null,
                linkDescription: "",
                linkType: null,
                orderNumber: null,
                tittle: "",
            });
            setNewsItemLoading(false);
        }
    };

    const handleSubmit = async event => {
        const form = event.currentTarget;
        if (!inProgress && form.checkValidity() === true) {
            let data = newsItem;
            var elements = Array.from(form.elements)
            elements.map(el => {
                if (el.localName === 'input') {
                    if (el.type === "number") {
                        data[el.id] = parseInt(el.value, 0);
                    }
                    else if (el.type === 'checkbox') {
                        //do nothing
                    }
                    else if (el.type === 'switch') {
                        //do nothing
                    }
                    else if (el.type === 'date') {
                        if(el.value != "" && el.value != null){
                            data[el.id + 'Str'] = moment(el.value).format('DD/MM/YYYY');
                            data[el.id] = el.value;
                        }
                    }
                    else if (el.readOnly !== true) {

                        data[el.id] = el.value;
                    }
                }
                if (el.localName === 'select') {
                    data[el.id] = parseInt(el.value, 0);
                }
            })

            setInProgress(true);
            await postAdminNewsItem(useLmsStore, data);
            setNewsItem(data);
            setInProgress(false);
            refreshFn(adminNewsItem);
            setShowDialog(false);
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDelete = async () => {
        setInProgress(true);
        await postDeleteAdminNewsItem(useLmsStore, newsItem.id);
        setInProgress(false);
        refreshFn(adminNewsItem);
    };

    const handleSwitchChange = event => {
        console.log('event.target',event.target);
        console.log('event.target.id',event.target.id);
        console.log('event.target.checked',event.target.checked);
        if (event.target.id !== "") {
            let data = newsItem;

            data[event.target.id] = event.target.checked;
            setNewsItem(data);
            setShowDialog(true);
        }
    };

    const onAGSelect = (selectedList, selectedItem) => {
        let ctgData = newsItem;
        ctgData.groups.push({ name: selectedItem.title, groupId: selectedItem.id, id: -1 });
        setNewsItem(ctgData);
        setAppGList(selectedList);
    };

    const onAGRemove = (selectedList, removedItem) => {
        let ctgData = newsItem;
        const toRemove = (element) => element.id === removedItem.id;
        let idx = ctgData.groups.findIndex(toRemove)
        ctgData.groups.splice(idx, 1);
        setNewsItem(ctgData);
        setAppGList(selectedList);
    };

    const setValueInForm = event =>{
        let data = newsItem;
        data[event.target.id] = event.target.value;
        setNewsItem(data);
    }

    useEffect(() => {
        if(showDialog)
        {
            let confirm = window.confirm(FORMCONFIRM);
            if(confirm){
                handleFormLoad();
            }
        }
        else{
            handleFormLoad();
        }
    }, [adminNewsItem]);

    function ShowAdminNewsItem(props) {
        return (
            newsItemLoading ? newsItemError ?
                <>
                    <Alert variant="danger" >
                        <Alert.Heading>Oh snap! No data!</Alert.Heading>
                        <p>
                            No data was returned for this user!
                        </p>
                    </Alert>
                </>
                :
                <>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border mt-5 text-primary" style={{ width: '5rem', height: '5rem' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </>
                :
                <Container fluid>
                    <Row className="mt-3">
                        <Col sm={9}>
                            <Row>
                                <Form noValidate validated={validated} onSubmit={handleSubmit} id="news-form">
                                    <fieldset disabled={inProgress}>
                                        <Form.Group className="mb-2" controlId="title">
                                            <Form.Label>Headline</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control type="input" placeholder="News item headline" defaultValue={props.data.title ? props.data.title : ""} className="no-border" onChange={(e)=> setValueInForm(e)}  required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a Headline.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-2">
                                            <Form.Check id="isActive" type="switch" onChange={handleSwitchChange} label="Active" defaultChecked={props.data.isActive /*&& props.data.expiryDate && moment(props.data.expiryDate).isAfter(today)*/ } />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="description">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control type="input" placeholder="Description" defaultValue={props.data.description ? props.data.description : ""} onChange={(e)=> setValueInForm(e)}  />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="link">
                                            <Form.Label>Link (include https://)</Form.Label>
                                            <Form.Control type="input" placeholder="Link" defaultValue={props.data.link ? props.data.link : ""} onChange={(e)=> setValueInForm(e)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="linkDescription">
                                            <Form.Label>Link text</Form.Label>
                                            <Form.Control type="input" placeholder="link txt here" defaultValue={props.data.linkDescription ? props.data.linkDescription : ""} onChange={(e)=> setValueInForm(e)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="linkType">
                                            <Form.Label>Link Type</Form.Label>
                                            <Form.Select aria-label="Default select example" defaultValue={props.data.linkType} onChange={(e)=> setValueInForm(e)} >
                                                <option value={1}>External</option>
                                                {/* <option value={2}>Video</option> */}
                                                <option value={3}>Pdf</option>
                                                <option value={4}>Word</option>
                                                <option value={5}>Internal</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="expiryDate">
                                            <Form.Label>Expiry Date</Form.Label>
                                            <Form.Control type="date" placeholder="Expiry Date" defaultValue={props.data.expiryDate ? moment(props.data.expiryDate).format('YYYY-MM-DD') : ""} className="no-border" onChange={(e)=> setValueInForm(e)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="orderNumber">
                                            <Form.Label>Order Number</Form.Label>
                                            <Form.Control type="number" placeholder="order" defaultValue={props.data.orderNumber ? props.data.orderNumber : ""} onChange={(e)=> setValueInForm(e)} />
                                        </Form.Group>
                                    </fieldset>
                                </Form>
                            </Row>
                            <Row>
                                <Col sm={1}>{inProgress ?
                                    <Button variant='primary' disabled>Delete</Button>
                                    :
                                    <Button variant='primary' onClick={handleDelete}>Delete</Button>
                                }
                                </Col>
                                <Col sm={8}></Col>
                                <Col sm={3}>
                                    {inProgress ?
                                        <Button variant="primary" className="float-right" disabled>
                                            <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                            Please wait...
                                        </Button>
                                        :
                                        <Button variant="primary" className="float-right" type="submit" form="news-form">
                                            Save
                                        </Button>
                                    }
                                    <Button variant='primary' className="mx-2 float-right" onClick={handleFormLoad}>Cancel</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3}>
                            <h5>Assigned Groups</h5>
                            <Multiselect
                                options={applicationGroups.map(function (c) { return { 'title': c.name, 'id': c.id } })}
                                displayValue="title"
                                selectedValues={appGList}
                                onSelect={onAGSelect} // Function will trigger on select event
                                onRemove={onAGRemove} // Function will trigger on remove event
                                placeholder="Select/Add"
                                showCheckbox={true}
                            />
                            <p className='text-info'>Leave above empty to select All Users</p>
                        </Col>
                    </Row>
                </Container>
        );
    };

    usePrompt(FORMCONFIRM, showDialog);

    return (
        <ShowAdminNewsItem data={newsItem} />
    );
};

export default FormAdminNewsItem;