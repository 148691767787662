import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { clearStore } from "../hooks/useLmsStore";
import { useParams } from 'react-router-dom';
import logo from '../assets/imgs/wdr_master_rgb.png';
import { toast } from 'react-toastify';
import { useAuth } from "../hooks/useAuth";
// Wait up to 180 secs in dev mode else 60 secs
const LOGIN_TIMEOUT = process.env.NODE_ENV === 'development' ? 180000 : 60000;


export default function Invitation()  {
    const [inProgress, setInProgress] = useState(false);
    const [mainError, setMainError] = useState(null);
  
    //Get the verification key from the url
    const { verificationKey } = useParams();
    const [confPassword, setConfPsw] = useState("");
    const [inProgressVal, setInProgressVal] = useState(false);
    const [errorVal, setErrorVal] = useState(null);
    const [newPassword, setPsw] = useState("");
    const [username, setUserId] = useState("");
    const { login } = useAuth();
    const validateJwt = (token) => {
        try {
            let base64Url = token.split('.')[1],
                base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
                jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join('')),
                accessToken = JSON.parse(jsonPayload);
            if (accessToken.isSAML === true || (Date.now() > accessToken.exp * 1000)) {
                throw new Error('Invalid token');
            }
        } catch (e) {
            console.error(e);
            console.dir(token);
            throw e;
        }
    };

    const handleChangeUserId = (event) => {
        setMainError("");
        setUserId(event.target.value);
    };

    const handleChangePsw = (event) => {
        setMainError("");
        setPsw(event.target.value);
    };
    const handleChangeConfPsw = (event) => {
        if ((newPassword!=undefined) && (newPassword!="") && (event.target.value!=newPassword)){   
            
            if (event.target.value.length>4){
            setMainError('Password does not match');
            console.log('error',mainError);
            }
        }
        else
        {
            setMainError("");
        }
       
        setConfPsw(event.target.value);
    };

     function handleError(e) {
        setMainError(e.message);
        setPsw("");
        setValidated(false);
        setInProgress(false);
        console.error(e);
    }

    function handleErrorVal(e) {
        setErrorVal(e.message);
        setInProgressVal(false);
        console.error(e);
    }

    function handleErrorWrongKey(e) {
        setErrorVal(e);
        setInProgressVal(false);
        console.error(e);
    }

    const [validated, setValidated] = useState(false);
    function handleCompletePortalInvitation(event) {
        try {
            const form = event.currentTarget;
            if (!inProgress && form.checkValidity() === true) {
                setInProgress(true);
                setValidated(true);
                axios({
                    method: 'post',
                    url: '/api/account/completePortalInvitationNewAccount',
                    timeout: LOGIN_TIMEOUT,
                    data: {   
                              confirmPassword: confPassword,
                               password: newPassword,
                               username: username,
                               invitationToken: verificationKey}
                })
                    .then((result) => {
                        try {
                            if (result.status === 204) {
                                throw new Error('Problem updating the password');
                            }
                            
                            toast.success("Account created sucessfully", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                            });                            
                            setInProgress(false);
                            // Validate token
                            console.log('result inv', result);
                            validateJwt(result.data.token);
                            login(result.data.token);
                            window.location.href = '/lms/dashboard';
                            
                        } catch (e1) {
                            handleError(e1);
                        }
                    }).catch(handleError);
            }
        } catch (e2) {
            handleError(e2);
        }
        event.preventDefault();
        event.stopPropagation();
    }


    //Verify that the validation key sent is correct
    function handleValidateVerificationKey() {
       
        try {
           
            if (!inProgressVal && verificationKey) {
                setInProgressVal(true);
                 axios({
                    method: 'post',
                    url: '/api/account/verifyinvitation',
                    timeout: LOGIN_TIMEOUT,
                    data: {
                        password: undefined,
                        invitationToken: verificationKey

                    }
                })
                    .then((result) => {
                        try {
                            if (result.status === 204) {
                                throw new Error('Invalid verification key');
                            }

                            if (result.data!=""){
                                handleErrorWrongKey('There was a problem with the invitation, please contact the administrator');
                            }
                          
                            setInProgressVal(false);
                           
                        } catch (e1) {
                            handleErrorVal(e1);
                        }
                    }).catch(handleErrorVal);
            }
        } catch (e2) {
            handleErrorVal(e2);
        }
     
    };
    clearStore();
  
    useEffect(() => {
             handleValidateVerificationKey();
       
    }, [verificationKey]);

  

    return (
        <>
            <Row className="m-0 ">
                <Col className="cls-login-col-left p-0" md={6} lg={5} xl={4} xxl={3}>
                    <div className="cls-login-colour-ovl d-flex align-items-center justify-content-center">
                        <Row style={{ width: '100%' }}>
                            <Col sm={2} md={1}></Col>
                            <Col>
                                <Card className="mb-5" style={{ width: '100%' }}>
                                    <div className="d-flex justify-content-end" style={{ margin: ' 1rem 1.5rem 0 0' }}>
                                        <Card.Img variant="top" src={logo} style={{ width: '140px' }} />
                                    </div>
                                  
                                  {errorVal ? 
                                   <Card.Body>
                                      <Card.Title>Portal Invitation Error!</Card.Title>
                                        <Card.Text> 
                                        <p className="text-danger">{errorVal}</p>  
                                        </Card.Text>                                       
                                   </Card.Body>
                                  :
                                  <Card.Body>
                               
                                       <Card.Title>Complete Portal Invitation?</Card.Title>
                                        <Card.Text> 
                                        You have been pre-registered and invited to sign up to the portal. In order to gain access to your personalised content please create an account by providing the requested details.
                                            </Card.Text>
                                            <Card.Text> 
                                            Your new password must be a minimum of six characters in length
                                            </Card.Text>
                                            
                                        <Form noValidate validated={validated} onSubmit={handleCompletePortalInvitation}>
                                        <Form.Group className="mb-3">
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control placeholder="Enter email" type="text" required value={username} onChange={handleChangeUserId} disabled={inProgress} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid email.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control placeholder="New password" type="password" required value={newPassword} onChange={handleChangePsw} disabled={inProgress} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a password.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-4">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control placeholder="Confirm password" type="password" required value={confPassword} onChange={handleChangeConfPsw} disabled={inProgress} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please confirm the password.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                {
                                                    inProgress ?
                                                        <Button variant="primary" className="w-100" disabled>
                                                            <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                                            Please wait...
                                                        </Button>
                                                        :
                                                        <Button type="submit" variant="primary" className="w-100">Complete Invitation</Button>
                                                }
                                            </Form.Group>
                                            {mainError && (<p className="text-danger">{mainError}</p>)}
                                        
                                        </Form>
                                                                       
                                    </Card.Body>
                                  }
                                </Card>
                            </Col>
                            <Col sm={2} md={1}></Col>
                        </Row>
                    </div>
                </Col>
                <Col className="cls-login-col-right"><div className="cls-login-colour-ovl"></div></Col>
            </Row>
            
        </>
    );
}