import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faAsterisk } from '@fortawesome/pro-regular-svg-icons';

const RedAsterisk = () => {
  return (
    <span style={{ color: 'red' }}>
      <FontAwesomeIcon icon={faAsterisk} />
    </span>
  );
};

export default RedAsterisk;
