import { useEffect, useMemo, useState } from 'react';

import { Row, Col } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

import {FilterBadgeButtonDashboard, FilterBadgeButtonDashboardSmallVariant} from './CustomButtons';

import useLmsStore from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import LinkContainer from '../../_libs/react-router-bootstrap/LinkContainer'

import MaterialReactTable from 'material-react-table';
import { columnIconLearningItem } from '../helpers/tableHelper';

import { toLocaleDateString } from '../helpers/formatHelper';
const isCompleted = x => x && (x.statusName === 'Completed' || x.statusName==='Completed - Colleague' || (x.elearningInfo && x.elearningInfo.completionStatusName === 'Complete' && x.elearningInfo.sucessStatusName !== 'Failed'));
const isAttended = x => x && (x.statusName === 'Attended' || (x.instructorInfo && x.instructorInfo.statusName === 'Attended'));

const filterTodo = x => x && !isCompleted(x) && !isAttended(x);
const isPathway = x => x &&  (x.regPathwayId != null && x.learnProgId==null);

    const filteredData = (rows, id) => !Array.isArray(rows)? []: id>0? rows.filter(x => (id === 1 && filterTodo(x) && !isPathway(x)) || (id === 2 && x.isMandatory && !isPathway(x)) || (id === 3 && filterTodo(x) && x.expiryDate!==undefined  && x.expiryDate!==null && x.expiryDate!==''  && !isPathway(x)) ) : rows.filter(x => filterTodo(x) && !isPathway(x));

 
export default function DashboardMyPlan(props) {
    const [dataType, setDataType] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [todoCount, setTodoCount] = useState(0);
    const [mandatoryCount, setMandatoryCount] = useState(0);
    const [upcomingCount, setUpcomingCount] = useState(0);
  
    const {myActiveSortedTrainingPlan,  fetchMyActiveSortedTrainingPlanNew } = useLmsStore(state =>
    ({
        myActiveSortedTrainingPlan: state.myActiveSortedTrainingPlanNew.data,
        fetchMyActiveSortedTrainingPlanNew: state.fetchMyActiveSortedTrainingPlanNew
    }), shallow)

    useEffect(() => {
       fetchMyActiveSortedTrainingPlanNew();
        if (myActiveSortedTrainingPlan){
            if(!myActiveSortedTrainingPlan.loading>0) {             
                setTableData(filteredData(myActiveSortedTrainingPlan,dataType));               
            } 
        }
       
    }, [fetchMyActiveSortedTrainingPlanNew]);


    useEffect(() => {
    fetchMyActiveSortedTrainingPlanNew();
   
        setTodoCount(filteredData(myActiveSortedTrainingPlan,1).length);
        setMandatoryCount(filteredData(myActiveSortedTrainingPlan,2).length);
        setUpcomingCount(filteredData(myActiveSortedTrainingPlan,3).length);
    
  
    },[]);
   // on tabId change
   useEffect(() => {
  if (myActiveSortedTrainingPlan){
    if(!myActiveSortedTrainingPlan.loading>0) {
         setTableData(filteredData(myActiveSortedTrainingPlan, dataType));
       } 
}
   
}, [dataType]);

  useEffect(() => {
    if (mandatoryCount===0){
        if (filteredData(myActiveSortedTrainingPlan,2).length>0){
            setDataType(2);
        }
        else if (todoCount===0){
            if (filteredData(myActiveSortedTrainingPlan,1).length>0){
            setDataType(1);
            }
        }else if (upcomingCount===0){
            if (filteredData(myActiveSortedTrainingPlan,3).length>0)
            setDataType(3);
        else
        setDataType(1);        
        } else {
            setDataType(1);
        }
       
    }else {
        setDataType(2);
    }
    
      
        // eslint-disable-next-line
    }, [myActiveSortedTrainingPlan]); 

    const columns = useMemo(
        () => [
            columnIconLearningItem('You', null, (cell, row) => { return { pathname: '/lms/mylearningplan', search: `search=${encodeURI(row.original.name)}` }; }),
            {
                accessorKey: 'name',
                header: 'Name'
            },
            {
                accessorKey: 'statusName',
                header: 'Status Name',
                Cell: ({ cell, row }) => {
                    let status = cell.getValue();
                    return <span className={status === 'Not Started' ? 'text-danger' : ''}>{status}</span>
                }
            },
            {
                accessorKey: 'expiryDate',
                header: 'Expiry Date',
                Cell: ({ cell, row }) => {
                     let dateValue = cell.getValue();
                     let isBooking = row.original.instructorInfo && row.original.instructorInfo.bookingId>0;
                
                     let   date = (!isBooking? ( dateValue ? new Date(dateValue) : null):row.original.instructorInfo.startDate),
                        
                        isNear = date && row.original.statusName !== 'Completed' && date < new Date().getTime(); /* + (30 * 24 * 60 * 60 * 1000) */
                    
                    return date ? <span className={isNear ? 'text-danger' : ''}> {isBooking? 'Event date' : 'Due by'} {toLocaleDateString(date)}</span> : null
                }
            }
        ],
        [],
    );

    return (<MaterialReactTable
        columns={columns}
        data= {tableData}
        enableColumnActions={false}
        muiTableHeadProps={{
            sx: {
                display: 'none'
            }
        }}
        muiTableBodyRowProps={{
            hover: false,
            sx: {
                height: '50px' // adjust this value to decrease the height
            }
        }}
        muiTableBodyCellProps={{
            sx: {
                padding: '6px', // adjust padding to reduce cell height
            }
        }}
        renderTopToolbar={({ table }) => (
            <>
                <Row>
                    <Col>
                        <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>My Learning Plan</Tooltip>}                >
                            <div className='w-100 d-flex justify-content-end p-2'>
                                <LinkContainer to='/lms/mylearningplan'>
                                    <Button variant="a">View All</Button>
                                </LinkContainer>
                            </div>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col xs={4}>
                        <FilterBadgeButtonDashboardSmallVariant className={"d-block d-sm-none"} variant='info' title='My Plan' onClick={() => setDataType(1)} active={dataType === 1}>
                        {myActiveSortedTrainingPlan ? todoCount===0? filteredData(myActiveSortedTrainingPlan,1).length:todoCount : <Spinner animation="grow" variant="light" size="15" />}
                        </FilterBadgeButtonDashboardSmallVariant>
                        <FilterBadgeButtonDashboard className={"d-none d-sm-block"} variant='info' title='My Plan' onClick={() => setDataType(1)} active={dataType === 1}>
                        {myActiveSortedTrainingPlan ? todoCount===0? filteredData(myActiveSortedTrainingPlan,1).length:todoCount  : <Spinner animation="grow" variant="light" size="15" />}
                        </FilterBadgeButtonDashboard>
                    </Col>
                    <Col xs={4}>
                        <FilterBadgeButtonDashboardSmallVariant className={"d-block d-sm-none"}  variant='info' title='Mandatory' onClick={() => setDataType(2)} active={dataType === 2}>
                        {myActiveSortedTrainingPlan ? mandatoryCount===0? filteredData(myActiveSortedTrainingPlan,2).length:mandatoryCount  : <Spinner animation="grow" variant="light" size="15" />}
                        </FilterBadgeButtonDashboardSmallVariant>
                        <FilterBadgeButtonDashboard className={"d-none d-sm-block"} variant='info' title='Mandatory' onClick={() => setDataType(2)} active={dataType === 2}>
                        {myActiveSortedTrainingPlan ? mandatoryCount===0? filteredData(myActiveSortedTrainingPlan,2).length:mandatoryCount  : <Spinner animation="grow" variant="light" size="15" />}
                        </FilterBadgeButtonDashboard>
                    </Col>
                    <Col xs={4}>
                        <FilterBadgeButtonDashboardSmallVariant className={"d-block d-sm-none"} variant='info' title='Upcoming' onClick={() => setDataType(3)} active={dataType === 3}>
                        {myActiveSortedTrainingPlan ? upcomingCount===0? filteredData(myActiveSortedTrainingPlan,3).length:upcomingCount  : <Spinner animation="grow" variant="light" size="15" />}
                        </FilterBadgeButtonDashboardSmallVariant>
                        <FilterBadgeButtonDashboard className={"d-none d-sm-block"} variant='info' title='Upcoming' onClick={() => setDataType(3)} active={dataType === 3}>
                        {myActiveSortedTrainingPlan ? upcomingCount===0? filteredData(myActiveSortedTrainingPlan,3).length:upcomingCount  : <Spinner animation="grow" variant="light" size="15" />}
                        </FilterBadgeButtonDashboard>
                    </Col>
                </Row>
            </>
        )}
        initialState={{
            pagination: { pageIndex: 0, pageSize: 5 }
        }}
        state={{
            isLoading: !myActiveSortedTrainingPlan
        }}
    />);
}
