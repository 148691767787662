import { useState, useEffect } from 'react';
import { Row, Col, Form, InputGroup, Button,  Modal } from "react-bootstrap";
import moment from 'moment'
import RedAsterisk from '../helpers/RedAsterisk';

const TabCompanyDetails = ({ submitFn = (obj) => null, hasChangedFn = () => null, closeFn = (obj) => null, companyDetails }) => {
    const [validated, setValidated] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [companyDetail, setCompanyDetail] = useState(companyDetails);
    const [initCompanyName, setInitCompanyName] = useState(companyDetails);
    const [showConfirmSave, setShowConfirmSave] = useState(false);
    const [submitAnyway, setSubmitAnyway] = useState(false);
  
    const handleCloseConfirmSave = (event) => {
        setShowConfirmSave(false);
        setSubmitAnyway(false);
    };

    const handleSubmitBefore = async event => {
        const form = event.currentTarget;
       
        if(initCompanyName===companyDetail.companyName || companyDetail.companyId<=0){          
            setSubmitAnyway(false);
            handleSubmit(event, true);
        }
        else{
          
            if(submitAnyway || !companyDetail){              
                setShowConfirmSave(false);  
                setSubmitAnyway(false);          
                handleSubmit(event,false);
            }
            else{             
               setShowConfirmSave(true);
               setSubmitAnyway(true);
            }
            
        }
    };

    const handleSubmit = async event => {
        const form = event.currentTarget;
        if (!inProgress && form.checkValidity() === true) {
            let data = companyDetails;
            var elements = Array.from(form.elements)
            elements.map(el => {
                if(el.localName ==='input')
                {
                    if(el.type === "number"){
                        data[el.id]= parseInt(el.value,0);
                    }
                    else if(el.type ==='checkbox')
                    {
                        //do nothing
                    }
                    else if(el.type ==='switch')
                    {
                        //do nothing
                    }
                    else if(el.type ==='date')
                    {
                        data[el.id]= el.value===""? null: el.value;
                    }
                    else if(el.readOnly!==true)
                    {
                        data[el.id]=  el.value;
                    }
                    else if(el.type ==='input')
                    {
                        data[el.id]= el.value===""? null: el.value;
                    }
                }
                if(el.localName ==='select')
                {
                    data[el.id]= parseInt(el.value,0);
                }
            })
            
            setInProgress(true);
            await submitFn(data);
            setCompanyDetail(data);
            setInitCompanyName(data.companyName);
            setInProgress(false);
        }
        setValidated(true);
        event.preventDefault();
        event.stopPropagation();
    };

    useEffect(() => {
        setCompanyDetail(companyDetails);
        setInitCompanyName(companyDetails.companyName);

    }, [companyDetails]);

    const handleSwitchChange = event =>{
        let data = companyDetail;
        data[event.target.id] = event.target.checked;
        setCompanyDetail(data);
    
        hasChangedFn();
    }

    const setValueInForm = event =>{
        let data = companyDetail;
        data[event.target.id] = event.target.value;
        setCompanyDetail(data);
        hasChangedFn();
    };

    function ShowForm(props) {
        return (

            <Row>
                <h3>Company Details</h3>
                <Form noValidate validated={validated} onSubmit={handleSubmitBefore} id="form-company">
                <Row> 
                    <Col>
                        <Form.Group className="mb-3" controlId="code">
                            <Form.Label>Company CODE</Form.Label>
                            <Form.Control type="input" placeholder="Company CODE" defaultValue={props.data.code? props.data.code : ""} className="no-border" readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="mainContactName">
                            <Form.Label>Main Contact</Form.Label>
                            <Form.Control type="input" placeholder="Main Contact" defaultValue={props.data.mainContactName? props.data.mainContactName : ""} className="no-border" onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="mainContactPhone">
                            <Form.Label>Main Contact Phone</Form.Label>
                            <Form.Control type="input" placeholder="Phone" defaultValue={props.data.mainContactPhone? props.data.mainContactPhone : ""} className="no-border" onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="mainContactEmail">
                            <Form.Label>Main Contact Email</Form.Label>
                            <Form.Control type="input" placeholder="Email" defaultValue={props.data.mainContactEmail? props.data.mainContactEmail : ""} className="no-border" onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="companyName">
                            <Form.Label>Company Name <RedAsterisk /></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control type="input" placeholder="Company Name" defaultValue={props.data.companyName? props.data.companyName : ""} className="no-border" required={props.data.companyName===""}  onChange={(e)=> setValueInForm(e)}  />
                                <Form.Control.Feedback type="invalid">
                                    Please provide company name.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="accountContactName">
                            <Form.Label>Account Contact</Form.Label>
                            <Form.Control type="input" placeholder="Account Contact" defaultValue={props.data.accountContactName? props.data.accountContactName : ""} className="no-border"  onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="accountContactPhone">
                            <Form.Label>Account Contact Phone</Form.Label>
                            <Form.Control type="input" placeholder="Phone" defaultValue={props.data.accountContactPhone? props.data.accountContactPhone : ""} className="no-border"  onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="accountContactEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="input" placeholder="Email" defaultValue={props.data.accountContactEmail? props.data.accountContactEmail : ""} className="no-border"  onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <hr/>
                    <Col>
                        <Form.Group className="mb-3" controlId="isActive">
                            <Form.Check type="switch" onChange={handleSwitchChange} label="is Active" defaultChecked={props.data.isActive}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="canBeShare">
                            <Form.Check type="switch" onChange={handleSwitchChange} label="Can Be Shared?" defaultChecked={props.data.canBeShare}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="mailByPost">
                            <Form.Check type="switch" onChange={handleSwitchChange} label="Mail by Post" defaultChecked={props.data.mailByPost}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="mailElectronically">
                            <Form.Check type="switch" onChange={handleSwitchChange} label="Mail electronically" defaultChecked={props.data.mailElectronically}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <hr/>
                    <Col>
                        <Form.Group className="mb-3" controlId="mainSwitchboard">
                            <Form.Label>Main Switchboard</Form.Label>
                            <Form.Control type="input" placeholder="Main Switchboard" defaultValue={props.data.mainSwitchboard? props.data.mainSwitchboard: ""} className="no-border"  onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="website">
                            <Form.Label>Web Address</Form.Label>
                            <Form.Control type="input" placeholder="Web Address" defaultValue={props.data.website? props.data.website: ""} className="no-border"  onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="isTrainingSupplier">
                            <Form.Check type="switch" onChange={handleSwitchChange} label="Training Supplier" defaultChecked={props.data.isTrainingSupplier} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="isVenue">
                            <Form.Check type="switch" onChange={handleSwitchChange} label="Training Venue" defaultChecked={props.data.isVenue}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="isClient">
                            <Form.Check type="switch" onChange={handleSwitchChange} label="Client" defaultChecked={props.data.isClient}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="companyEmail">
                            <Form.Label>General Email Address</Form.Label>
                            <Form.Control type="input" placeholder="General Email Address" defaultValue={props.data.companyEmail? props.data.companyEmail : ""} className="no-border"  onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="auditDate">
                            <Form.Label>Audit Date</Form.Label>
                            <Form.Control type="date" placeholder="Audit Date" defaultValue={props.data.auditDate? moment(props.data.auditDate).format('YYYY-MM-DD') : ""} className="no-border"  onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="auditBy">
                            <Form.Label>Audit By</Form.Label>
                            <Form.Control type="input" placeholder="Audit By" defaultValue={props.data.auditBy} className="no-border"  onChange={(e)=> setValueInForm(e)}/>
                        </Form.Group>
                        <Row>
                            <hr/>
                            <Col>
                                <Form.Group className="mb-3" controlId="isApproved">
                                    <Form.Check type="switch" onChange={handleSwitchChange} label="Approved" defaultChecked={props.data.isApproved} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="isExternal">
                                    <Form.Check type="switch" onChange={handleSwitchChange} label="External" defaultChecked={props.data.isExternal}/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <hr/>
                    <Form.Group className="mb-3" controlId="cancellationTerms">
                        <Form.Label>Cancellation Terms</Form.Label>
                        <Form.Control type="text" placeholder="Cancellation terms go here" defaultValue={props.data.cancellationTerms? props.data.cancellationTerms : ""} className="no-border"/>
                    </Form.Group>
                    <hr/>
                    <Form.Group className="mb-3" controlId="comments">
                        <Form.Label>Comments</Form.Label>
                        <Form.Control type="text" placeholder="Comments get saved here" defaultValue={props.data.comments? props.data.comments : ""} className="no-border"/>
                    </Form.Group>
                </Row>
                <Button variant='primary' className="mt-2 float-right" onClick={closeFn}>Cancel</Button>
                {
                    inProgress ?
                    <Button variant="primary" className="mx-2 mt-2 float-right" disabled>
                        <span className="spinner-border spinner-border-sm mx-2 mt-2" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                        Please wait...
                    </Button>
                    :
                    <Button variant="primary" className="mx-2 mt-2 float-right" type="submit" form="form-company">
                        Save
                    </Button>
                }
                </Form> 
            </Row>);
    }
    return (
        <>
            <ShowForm data={companyDetail}/> 
            <Modal fullscreen='md-down' show={showConfirmSave} onHide={handleCloseConfirmSave} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>You are about to amend the company name</h6>
                    <Button variant='primary px-3 w-25 mx-3 float-right' onClick={handleCloseConfirmSave} >Cancel</Button>
                    <Button variant="primary px-3 w-25 mx-3" type="submit" form="form-company">Save</Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TabCompanyDetails;