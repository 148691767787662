import { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { Button, Col, Form, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';

import { ModalConfirmCancel } from './ModalConfirmation';

import { toDDsMMsYYYYString, toISODateTimeString } from '../helpers/formatHelper';

import { columnBlankIfWidth, columnIconLearningItem, columnIfWidth, muiTableBodyCellEditFieldPropsCheckboxFn } from '../helpers/tableHelper';

import useLmsStore, { postAdminTrainingPlanGroupInfo } from "../hooks/useLmsStore";
import shallow from 'zustand/shallow';

import MaterialReactTable from 'material-react-table';
import LearningPlanEditorGroupTableDate from './LearningPlanEditorGroupTableDate';
import LearningPlanEditorGroupTableSelect from './LearningPlanEditorGroupTableSelect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleCheck, faSquare, faSquareCheck, faSquareMinus, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-light-svg-icons';

const empty = (tableData) => !tableData || tableData.length === 0;

export default function LearningPlanEditorGroupTable(props) {
    const [dateToApply, setDateToApply] = useState('');
    const [edit, setEdit] = useState(false);
    const [editMode, setEditMode] = useState('1');
    const [headerClickState, handleHeaderClick] = useReducer(function (state, action) { return { count: state.count + 1, flag: action }; }, { count: 0, flag: 'none' }); // count to ensure a change of headerClickState on every click
    const [showCancelEditModal, setShowCancelEditModal] = useState(false);
    const [showCancelModeModal, setShowCancelModeModal] = useState(0); // Store the edit mode id
    const [showCancelSelectModal, setShowCancelSelectModal] = useState(0); // Store the id
    const [tableLoading, setTableLoading] = useState(true);
    const headerSquareCheckRef = useRef();
    const headerSquareMinusRef = useRef();
    const headerSquareRef = useRef();

    const { fetchAdminTrainingPlanItemsByGroup } = useLmsStore(state =>
    ({
        fetchAdminTrainingPlanItemsByGroup: state.fetchAdminTrainingPlanItemsByGroup,
    }), shallow)

    useEffect(() => {
        initDataLeft(initDataPromise, []);
        // eslint-disable-next-line 
    }, [props.dataSelected]);

    useEffect(() => {
        setEditMode(edit && empty(props.dataLeft) ? '3' : '1');
        props.onEditChanged(edit);
        // eslint-disable-next-line 
    }, [edit]);

    useEffect(() => {
        initDataLeft(initDataPromise, editMode === '3' ? null : []).then(() => {
            if (editMode !== '2') {
                setDateToApply('');
            }
            if (!editModeLessThan2()) {
                refreshGlobalInputCheckbox();
            }
            // Allow selections on both sides when in mode 2
            props.setOptions(editMode !== '2' ? { exclusiveSelection: true, leftId: 'id', rightId: 'id' } : { exclusiveSelection: false, leftId: 'id', rightId: 'personId' });
        });
        // eslint-disable-next-line 
    }, [editMode]);

    useEffect(() => refreshGlobalInputCheckbox(),
        // eslint-disable-next-line 
        [props.hasSelectedLeft]);

    useEffect(() => {
        props.selectLeft(headerClickState.flag);
        // eslint-disable-next-line 
    }, [headerClickState]);

    const editModeLessThan2 = () => parseInt(editMode) < 2;

    const initDataLeft = (initDataPromiseFn, initDataRightParameter) => {
        return new Promise(res => {
            setTableLoading(true);
            if (props.dataSelected && props.dataSelected.id > 0) {
                initDataPromiseFn().then(data => {
                    props.initData(structuredClone(data || []), initDataRightParameter);
                    setTableLoading(false);
                    res(data);
                });
            } else {
                props.initData([], []);
                setTableLoading(false);
                res([]);
            }
        });
    };

    const initDataPromise = () => fetchAdminTrainingPlanItemsByGroup(props.dataSelected.id);

    const handleCellClick = (cell, event) => {
        if (cell.column.id === 'isRowSelected' || !editModeLessThan2()) {
            if (editMode === '2') {
                if (props.hasUpdates) {
                    setShowCancelSelectModal(cell.row.original.id);
                } else {
                    props.toggleLeft(cell.row.original.id, true);
                }
            } else {
                props.toggleLeft(cell.row.original.id);
            }
        }
    };

    const handleCancelEdit = () => {
        if (props.hasUpdates) {
            setShowCancelEditModal(true);
        } else {
            resetToRO();
        }
    }

    const handleCellChanged = (cell, event) => {
        if (cell.column.id === 'hide') {
            props.updateBaseLeft(props.dataLeft.filter(x => x.id === cell.row.original.id).map(x => { return { ...x, hide: event.target.checked === true } }));
        } else if (cell.column.id === 'expiryDate') {
            props.updateBaseLeft(props.dataLeft.filter(x => x.id === cell.row.original.id).map(x => {
                return {
                    ...x,
                    expiryDate: toISODateTimeString(event.target.value),
                    expiryDateStr: toDDsMMsYYYYString(event.target.value)
                }
            }));
        }
    }

    const handleConfirmCancelEdit = () => {
        setShowCancelEditModal(false);
        resetToRO();
    }

    const handleConfirmMode = () => {
        if (showCancelModeModal > 0) {
            setEditMode(showCancelModeModal);
            setShowCancelModeModal(0);
        }
    }

    const handleConfirmModeCancel = () => {
        setShowCancelModeModal(0);
    }

    const handleConfirmSelect = () => {
        if (showCancelSelectModal > 0) {
            props.toggleLeft(showCancelSelectModal, true);
            setShowCancelSelectModal(0);
        }
    }

    const handleConfirmSelectCancel = () => {
        setShowCancelSelectModal(0);
    }

    const handleEdit = () => {
        setEdit(true);
    }

    const handleOnSave = () => {
        let items = props.dataLeft;
        items.forEach(item => {
            item.joinId = item.joinId || -1; // New entries must default to -1
            delete item.isRowSelected;
        });
        initDataLeft(() => postAdminTrainingPlanGroupInfo(useLmsStore, { groupId: props.dataSelected.id, learningItems: items }), true);
    }

    const handleEditModeChange = (event) => {
        if (props.hasUpdates) {
            setShowCancelModeModal(event.target.value);
        } else {
            setEditMode(event.target.value);
        }
    }

    const resetToRO = () => {
        initDataLeft(initDataPromise, []).then(() => {
            setEdit(false);
            setEditMode('1');
        });
    }

    const refreshGlobalInputCheckbox = () => {
        if (headerSquareRef.current && headerSquareCheckRef.current && headerSquareMinusRef.current) {
            headerSquareRef.current.style.display = editMode === '3' && props.hasSelectedLeft === 'none' ? 'inline-block' : 'none';
            headerSquareMinusRef.current.style.display = editMode === '3' && props.hasSelectedLeft === 'some' ? 'inline-block' : 'none';
            headerSquareCheckRef.current.style.display = editMode === '3' && props.hasSelectedLeft === 'all' ? 'inline-block' : 'none';
        }
    }

    const columnVisibility = () => {
        let editModeLT2 = editModeLessThan2();
        return {
            code: !edit,
            isRowSelected: editMode === '3',
            isRowSelectedSingle: editMode === '2',
            supplierName: editModeLT2,
            statusName: editModeLT2,
            isMandatory: editModeLT2,
            hide: editModeLT2,
            blankcolumn: editModeLT2
        }
    }

    const columns = useMemo(() => [
        columnIconLearningItem('Learner', 'xl'),
        {
            accessorFn: (row) => row.isRowSelected,
            id: 'isRowSelected',
            header: 'Select',
            Header: ({ column }) => (
                <>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquare} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareRef} onClick={() => handleHeaderClick('all')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Select All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareMinusRef} onClick={() => handleHeaderClick('all')} />
                    </OverlayTrigger>
                    <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={<Tooltip className='cls-theme-tooltip'>Deselect All</Tooltip>}>
                        <FontAwesomeIcon icon={faSquareCheck} style={{ cursor: 'pointer', display: 'none', fontSize: '1rem' }} ref={headerSquareCheckRef} onClick={() => handleHeaderClick('none')} />
                    </OverlayTrigger>
                </>
            ),
            muiTableBodyCellProps: {
                sx: {
                    cursor: 'pointer'
                }
            },
            size: 80,
            Cell: ({ cell, row }) => <FontAwesomeIcon icon={cell.getValue() === true ? faSquareCheck : faSquare} style={{ fontSize: '1rem' }} />
        },
        {
            accessorFn: (row) => row.isRowSelected,
            id: 'isRowSelectedSingle',
            header: '',
            muiTableBodyCellProps: {
                sx: {
                    cursor: 'pointer'
                }
            },
            size: 50,
            Cell: ({ cell, row }) => <FontAwesomeIcon icon={row.original.isRowSelected === true ? faCircleCheck : faCircle} style={{ fontSize: '1rem' }} />
        },
        {
            accessorFn: (row) => row.code,
            id: 'code2',
            enableEditing: false,
            header: 'Code',
            size: 100,
            muiTableBodyCellProps: ({ cell, table }) => {
                return {
                    sx: {
                        cursor: 'pointer',
                        fontWeight: cell.row.original.isRowSelected ? 'bold' : 'normal'
                    }
                }
            }
        },
        {
            accessorKey: 'title',
            enableEditing: false,
            header: 'Title',
            muiTableBodyCellProps: ({ cell, table }) => {
                let isRowSelectedIsVisible = table.getColumn('isRowSelected').getIsVisible();
                return {
                    classes: { root: 'fw-unset' },   // Flexible width
                    sx: {
                        cursor: isRowSelectedIsVisible ? 'pointer' : 'inherit',
                        fontWeight: cell.row.original.isRowSelected ? 'bold' : 'normal'
                    }
                }
            },
            muiTableHeadCellProps: { classes: { root: 'fw-unset' } } // Flexible width
        },
        columnIfWidth('md', {
            accessorKey: 'isMandatory',
            enableEditing: false,
            header: 'M/R',
            size: 80,
            Cell: ({ cell, row }) => (row.original.isMandatory ? 'M' : '') + (row.original.isRenewable ? (row.original.isMandatory ? '/R' : 'R') : '')
        }),
        {
            accessorFn: (row) => row.hide === true,
            id: 'hide',
            header: 'Hide',
            size: 80,
            muiTableBodyCellEditTextFieldProps: muiTableBodyCellEditFieldPropsCheckboxFn({ required: false }),
            Cell: ({ cell, row }) => <FontAwesomeIcon icon={cell.getValue() === true ? faSquareCheck : faSquare} style={{ fontSize: '1rem' }} />
        },
        columnBlankIfWidth('xl', 'blankcolumn')
    ], [],);

    return (<Row>
        <Col>
            <MaterialReactTable
                columns={columns}
                data={props.dataLeft}
                editingMode='table'
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableEditing={edit && editMode === '1'}
                enableFullScreenToggle={false}
                enableGrouping={false}
                enableHiding={false}
                enablePagination={false}
                enableRowVirtualization
                enableSorting={false}
                enableTopToolbar={true}
                muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                    onChange: (event) => {
                        handleCellChanged(cell, event);
                    },
                })}
                muiTableBodyCellProps={({ cell }) => ({
                    onClick: (event) => {
                        handleCellClick(cell, event);
                    },
                })}
                muiTableBodyRowProps={{ hover: false }}
                muiTableContainerProps={{ sx: { maxHeight: 'max(170px, calc(100vh - 477px))' } }}
                renderTopToolbarCustomActions={({ table }) => <Row>
                    {edit ? <>
                        <Col xs='auto' style={{ padding: '0.5rem 0 0 0.75rem' }}>
                            Edit Mode:
                        </Col>
                        <Col xs='auto' style={{ padding: '0 0.75rem 0 0.25rem' }}>
                            <Form.Select
                                disabled={empty(props.dataLeft)}
                                value={editMode}
                                onChange={handleEditModeChange}>
                                <option value="1">By Rows</option>
                                <option value="2">Dates</option>
                                <option value="3">Add / Remove</option>
                            </Form.Select>
                        </Col>
                        {editMode === '1' || editMode === '3' ?
                            <>
                                <Col xs='auto'>
                                    <Button variant="outline-secondary" onClick={handleCancelEdit}>
                                        Cancel
                                    </Button>
                                </Col>
                                <Col xs='auto'>
                                    <Button type="submit" variant="primary" onClick={handleOnSave} disabled={!props.hasUpdates}>
                                    {tableLoading?
                                        <>
                                        <span className="spinner-border spinner-border-sm mt-1" style={{ marginRight: '0.25rem' }} role="status" aria-hidden="true"></span>
                                        Please wait...
                                        </>
                                        :
                                        <>
                                        Save
                                        </>
                                    }
                                    </Button>
                                </Col>
                            </> :
                            null}
                    </> :
                        <Col xs='auto'>
                            <Button variant="outline-primary" onClick={handleEdit} disabled={!props.dataSelected}>
                                Edit
                            </Button>
                        </Col>
                    }
                </Row>}
                initialState={{
                    showGlobalFilter: true
                }}
                state={{
                    columnVisibility: columnVisibility(),
                    isLoading: tableLoading
                }}
            />
        </Col>
        {editMode === '2' ?
            <Col>
                <LearningPlanEditorGroupTableDate {...props} dateToApply={dateToApply} setDateToApply={setDateToApply} resetToRO={resetToRO} groupId={props.dataSelected && props.dataSelected.id} />
            </Col>
            : null}
        {editMode === '3' ?
            <>
                <Col sm='auto' style={{ marginTop: '8rem' }}>
                    <Stack gap={3}>
                        {/* The reason for the more complex if code here (rather than using the disabled value attribute) is because tooltips tend to stay
                             on when the button is disabled before the tooltip has had a chance to hide again (events do not fire on disabled elements) */}
                        {props.hasSelectedRight === 'none' ?
                            <Button variant="outline-secondary" onClick={props.moveSelectedLeft} disabled>
                                <FontAwesomeIcon icon={faChevronsLeft} />
                            </Button>
                            :
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 0 }} overlay={<Tooltip className='cls-theme-tooltip'>Move Left</Tooltip>}>
                                <Button variant="outline-secondary" onClick={props.moveSelectedLeft}>
                                    <FontAwesomeIcon icon={faChevronsLeft} />
                                </Button>
                            </OverlayTrigger>
                        }
                        {props.hasSelectedLeft === 'none' ?
                            <Button variant="outline-secondary" onClick={props.moveSelectedRight} disabled>
                                <FontAwesomeIcon icon={faChevronsRight} />
                            </Button>
                            :
                            <OverlayTrigger placement='top' delay={{ show: 250, hide: 0 }} overlay={<Tooltip className='cls-theme-tooltip'>Move Right</Tooltip>}>
                                <Button variant="outline-secondary" onClick={props.moveSelectedRight}>
                                    <FontAwesomeIcon icon={faChevronsRight} />
                                </Button>
                            </OverlayTrigger>
                        }
                    </Stack>
                </Col>
                <Col>
                    <LearningPlanEditorGroupTableSelect {...props} />
                </Col>
            </> : null}
        <ModalConfirmCancel
            showCancelEditModal={showCancelEditModal}
            abortFn={() => setShowCancelEditModal(false)}
            continueFn={handleConfirmCancelEdit}
        />
        <ModalConfirmCancel
            showCancelEditModal={showCancelModeModal > 0}
            abortFn={handleConfirmModeCancel}
            continueFn={handleConfirmMode}
        />
        <ModalConfirmCancel
            showCancelEditModal={showCancelSelectModal > 0}
            abortFn={handleConfirmSelectCancel}
            continueFn={handleConfirmSelect}
        />
    </Row>
    );
}
