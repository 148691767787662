import axios from "axios";
import {useMsal} from "@azure/msal-react";
import {pca} from "../../index";

export const REFRESH_AFTER = 600000; // 5 minutes

export const WDR_HUB_URL = '/wdrlmshub';

export const apiAxios = axios.create({
    withCredentials: true,
    headers: { 'authorization': `Bearer ${window.localStorage.getItem('token') === null ? "" : window.localStorage.getItem('token').replace(/"/g, '')}` }
});


function forceLogin() {
    // Clear token
    window.localStorage.setItem('token', null);

    if(pca.getActiveAccount()){
        pca.logoutRedirect()
    }
    window.location = `${window.location.protocol}//${window.location.host}/login`
};

apiAxios.interceptors.request.use((config) => {
    // Abort if no token present (user not logged in)
    config.headers = { 'authorization': `Bearer ${window.localStorage.getItem('token') === null ? "" : window.localStorage.getItem('token').replace(/"/g, '')}` }
    if (!window.localStorage.getItem('token')) {
        throw new Error('No Authorization Token');
    }
    return config;
}, (error) => {
    return Promise.reject(error); // Delegate error to calling side
});

apiAxios.interceptors.response.use((response) => {
    return response;
}, (error) => { // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    if ((!error.response || status === 401) && error?.message !== "Request cancelled on purpose") {
        forceLogin();
        // Clear token
    } else if (status > 499) {
        if (error.response && error.response.data && error.response.data.toLowerCase().includes('session expired')) {
            forceLogin();
        } else if (status === 504) {
            window.location = `${window.location.protocol}//${window.location.host}/lms/servererror?error=${status}`
        } else if (process.env.NODE_ENV !== 'development') {
            window.location = `${window.location.protocol}//${window.location.host}/lms/servererror?error=${status}`
        }
    } else {
        window.alert('6')
        return Promise.reject(error); // Delegate error to calling side
    }
});
