import React from "react";
import { Badge, Card, Col, Row } from 'react-bootstrap';

const EmployeeTraningDetails = (props) => { 
    const userInfo = props.userInfo;
    const accreditationGroupNameForUser =   props.accreditationGroupNameForUser;
    //console.log ('EmployeeTraningDetails : ' + JSON.stringify(userInfo ))
    console.log ('accreditationGroupNameForUser : ' + accreditationGroupNameForUser )

    //const fullName = userInfo.fullName;
    const firstName = userInfo?.firstName;    
    const lastName = userInfo?.lastName;    
    const jobTitle = userInfo?.jobTitle;    
    
    const email = userInfo?.email;    
    const department = userInfo?.department;    
        
    return (
        <>
            <Card >
                <Card.Body>
                    <Row>
                        <Col sm={10} md={9} lg={8} xl={7} xxl={6}>
                            <Row>                                        
                                <Col>                                           
                                    <Row>
                                        {/* <Col sm='auto'>
                                            <Badge bg='light' text='dark'>{'Employee Id:'} {empNNo}</Badge>
                                        </Col> */}
                                        <Col sm='auto'>
                                            <h5>{firstName} {lastName}</h5>
                                        </Col>
                                        <Col sm='auto'>
                                            <Badge bg='light' text='dark'>{jobTitle}</Badge>
                                        </Col>                                                
                                        <Col sm='auto'>
                                            <Badge bg='light' text='dark'>{'email:'} {email}</Badge>
                                        </Col>
                                            <Col sm='auto'>
                                            <Badge bg='light' text='dark'>{'department:'} {department}</Badge>
                                        </Col>                                      
                                        <Col sm='auto'>
                                        {accreditationGroupNameForUser ? (
                                            <Badge bg='light' text='dark'>Accreditation Group: {accreditationGroupNameForUser}</Badge>
                                        ) : (
                                            <Badge bg='light' text='dark'>Accreditation Group: N/A</Badge>
                                        )}
                                        </Col>
                                    </Row>                                            
                                </Col>
                            </Row>                        
                        </Col>
                    </Row>   
                </Card.Body>
            </Card>
        </>
    );
};

export default EmployeeTraningDetails;