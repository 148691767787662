import moment from "moment";

export const createUsersResponseApiToFront = (responseData) => {
    let convertedResponse = responseData.map(user => {
        const startDate = moment(user.startDateAtCompany.value, "YYYY-MM-DD")
        const leavingDate = moment(user.leavingDateFromCompany.value, "YYYY-MM-DD")

        user.startDateAtCompany = {
            ...user.startDateAtCompany,
            value: startDate.format("DD/MM/YYYY")
        }
        user.leavingDateFromCompany = {
            ...user.leavingDateFromCompany,
            value: leavingDate.format("DD/MM/YYYY")
        }

        return user
    })

    return convertedResponse
}
