import BaseContainer from "../components/BaseContainer";
import { BeginButton } from '../components/CustomButtons'
import { useEffect,  useState } from 'react';
import { Row } from 'react-bootstrap';
import useLmsStore, { hrefToAdminServer,hasPermission } from '../hooks/useLmsStore';
import shallow from 'zustand/shallow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildings, faClipboardList, faBooks,faListOl,faPeopleLine, faRoad,faNewspaper, faFileChartColumn, faPersonChalkboard,faUsers,faUser,faLaptopFile } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
function ExternalTitle(props) {
    return (<>{props.title} <FontAwesomeIcon size='xs' icon={faArrowUpRightFromSquare} className='text-mutted' /></>);
};
export default function MySuperAdministrationDashboard(props) {
   // const [hasPermission, setHasPermission] = useState(false);
    const { personBasic,   fetchPersonBasic } = useLmsStore(state =>
        ({
            personBasic: state.personBasic.data,
            fetchPersonBasic: state.fetchPersonBasic,         

        }), shallow)
    useEffect(() => {
        
    //    setHasPermission(handlePermission(useLmsStore, 'ac.view'));
    }, []);

    
    return (<BaseContainer title='Client Super User Dashboard'>
        <div style={{marginTop:'10px', maxHeight: '650px', overflow: 'auto', overflowX: 'hidden'}}>
        <Row className='gx-4 gy-4 mt-0'>
        <BeginButton title='Users' description='View, create & update user records.' icon={faUser} to='/lms/users' />
        <BeginButton title={<ExternalTitle title='Groups' />} description='View, create and update groups, assign users and learning pathways to the group.' icon={faUsers} href={hrefToAdminServer(personBasic, 'portal.admin.groups')} target='admin-tab' />
        <BeginButton title='Team Profiles' description='View all users learning plans, learning pathways and review their progress.' icon={faPeopleLine} to='/lms/clientteamprofiles' />
        <BeginButton title='News Items' description='View, create, update or delete news items.' icon={faNewspaper} to='/lms/newsfeeditems' />
        <BeginButton title='Reports' description='Run reports.' icon={faFileChartColumn} to='/lms/reports' />
        { hasPermission(useLmsStore, 'ea.view')===true? <>
        <BeginButton title={<ExternalTitle title='Event Attendance' />} description='View & update event attendance for instructor-led events and upload copies of registers to the event.' icon={faUsers} href={hrefToAdminServer(personBasic, 'portal.admin.eventAttendance')} target='admin-tab' />
        </>: <></>}
        <BeginButton title={<ExternalTitle title='Learning Items' />} description='View, create and update learning items.' icon={faLaptopFile} href={hrefToAdminServer(personBasic, 'portal.learningItems')} target='admin-tab' />
        <BeginButton title='Catalogues' description='View, create, update or mark catalogues as inactive and assign to users/groups.' icon={faBooks} to='/lms/catalogues' />
        <BeginButton title='Learning Plan Admin' description='View, create, update learning plans and assign to users/groups.' icon={faListOl} to='/lms/learningplan' />
        <BeginButton title={<ExternalTitle title='PathWays Admin' />} description='View, create, update and add learning items to learning pathways.' icon={faRoad} href={hrefToAdminServer(personBasic, 'portal.admin.trainingCards')} target='admin-tab' />
        <BeginButton title='Companies' description='View, create, update or delete companies.' icon={faBuildings} to='/lms/companies' />

          { hasPermission(useLmsStore, 'ac.view')===true? <>
        <BeginButton title={<ExternalTitle title='Access Card' />} description='View, print, activate, re-issue and deactivate access cards.' icon={faRoad} href={hrefToAdminServer(personBasic, 'portal.admin.accessCards')} target='admin-tab' />
            </>: <></>}
        </Row>
        </div>
    </BaseContainer>);
}