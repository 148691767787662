import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import BaseContainer from '../components/BaseContainer';
import EmployeeTraningDetails from "./EmployeeTraningDetails";
import CompetenciesAccordian from "./CompetenciesAccordian";

import TrainingPlanAccordian from "./TrainingPlanAccordian";
//import PendingEventsAccordian from "./PendingEventsAccordian";
//import TrainingHistoryAccordian from "./TrainingHistoryAccordian";
//import AuthorisedTrainingPlanAccordian from "./AuthorisedTrainingPlanAccordian";
//import UnathorisedTrainingPlanAccordian from "./UnathorisedTrainingPlanAccordian";

import useLmsStore, { responseData, getUserInfo } from "../hooks/useLmsStore";

import shallow from 'zustand/shallow';

export default function Qualifications() {

    const [userInfo, setUserInfo] = useState([]); 
    const [accreditationGroupNameForUser, setAccreditationGroupNameForUser] = useState([]); 
    
   // const [user, setUser] = useState(null);
    
    const { data,dataTP,personSummary, fetchAccreditationGroupNameForUser, fetchTrainingPlans, fetchCompetencies, fetchPersonSummary} = useLmsStore(state =>
    ({
        data: state.userCompetencies.data,
        dataTP: state.userTrainingPlans.data,
        accreditationGroupNameForUser: state.accreditationGroupNameForUser.data,
        fetchCompetencies: state.fetchCompetencies,
        fetchTrainingPlans: state.fetchTrainingPlans,
        fetchAccreditationGroupNameForUser:state.fetchAccreditationGroupNameForUser,
        employeeData : state.employeeData,        
        personSummary: state.personSummary.data,
        fetchPersonSummary: state.fetchPersonSummary
    }), shallow)
console.log ('dataTP : ' + JSON.stringify(dataTP ))
    //console.log ('personSummary : ' + JSON.stringify(personSummary ))

    useEffect(() => {

        fetchCompetencies();
        fetchPersonSummary();
        fetchTrainingPlans();
        fetchAccreditationGroupNameForUser();
    }, []);

    useEffect(() => {
        setUserInfo(personSummary);
        setAccreditationGroupNameForUser (accreditationGroupNameForUser )
    }, [personSummary]);

    return (
        <BaseContainer title='Employee - Training and Qualification record'>
            <Row>
                <Col>
                    <div>
                        <span><b>My Training</b></span>
                        <EmployeeTraningDetails accreditationGroupNameForUser = {accreditationGroupNameForUser}   userInfo={userInfo}></EmployeeTraningDetails>
                        <CompetenciesAccordian header='Competencies' data={data} />
                        <TrainingPlanAccordian header = 'Training Plan' data={dataTP} />




                        {/* <PendingEventsAccordian header = 'Pending Events' /> */}
                        {/* <TrainingHistoryAccordian header = 'Training History' />  */}

                        {/* <AuthorisedTrainingPlanAccordian header = 'Authorised Training Plan' />  */}
                        {/* <UnathorisedTrainingPlanAccordian header = 'Unauthorised Training Plan' />  */}
                    </div>
                </Col>
            </Row>
        </BaseContainer>
    );
}
