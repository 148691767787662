import { useState  } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap";
import useLmsStore, { postPersonfilestore } from "../hooks/useLmsStore";
   
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';


const ModalMyProfile = ({ closeFn = () => null, updateImageFn = (img) => null, open = false, personSummaryError, personSummaryLoading, personSummary}) => {
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [selectedFile, setSelectedFile] = useState(null);

    const handleChange = e => {
        
         if (e.target.files.length) {
            const selectedFile = e.target.files[0];
           // alert('handleChange  selectedFile: ' + selectedFile.size )
            const fileSize = selectedFile.size;
            setSelectedFile(fileSize);
            //console.log('fileSize : ' + fileSize)
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    const [fileSize, setFileSize] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    
    const handleUpload = async e => {
        console.log("in handle upload")
        e.preventDefault();
        const maxFileSize = process.env.REACT_APP_MAX_FILE_SIZE;

        if (!selectedFile) {
            setErrorMessage('Please select a file to upload.');
            return;
        }

        if (selectedFile) {            
            const formData = new FormData();
            formData.append("upload", image.raw);
            formData.append("person_id",personSummary.id);
            formData.append("part_id",666);

            const kiloByte = 1048576;
            const fileSizeInKB = selectedFile / kiloByte;

            console.log('kiloByte : ' + kiloByte);
            console.log('fileSize : ' + selectedFile );
            console.log('maxFileSize : ' + maxFileSize );
            console.log('fileSizeInKB : ' + fileSizeInKB );

            if (fileSizeInKB <= maxFileSize) {  
                setErrorMessage('');    
                postPersonfilestore(useLmsStore, formData).then(() => {
                    updateImageFn(image);
                 });
                } else {               
                    setErrorMessage('File size exceeds the limit. Limit to 2 MB.');
                  }
            } 
    };

    function ShowMyProfile(props) {   
        return (
            <Container fluid>
                <Row>
                    <Col>
                        {image.preview ? (
                            <Image fluid src={image.preview} />
                        ) : (
                            <>
                                <Image fluid src={props.data.photograph} />
                            </>
                        )}
                        
                    </Col>
                    <Col>
                        <h1>{props.data.fullName}</h1>
                        <h3>{props.data.jobTitle}</h3>
                        <Form>
                            <Form.Group className="mb-3 ml-2">
                                <Form.Label>Change Profile Picture:</Form.Label>
                                <Form.Control type="file" placeholder="Select Picture" id="upload-button" onChange={handleChange}/>
                            </Form.Group>

                            <Button type="button" className="btn btn-primary ml-2" onClick={handleUpload}>
                                Upload
                            </Button>

                            {errorMessage && <div className="text-danger">{errorMessage}</div>}
                        </Form>
                    </Col>
                </Row>
                <Row className="mt-2 w-100"><hr/></Row>
                <Row>
                    <h3>Personal Details</h3>
                    <Col>
                        <div className="fw-bold">First Name</div>
                        <div className="fst-italic"> {props.data.firstName? props.data.firstName : "None"}<br/></div>
                        <div className="fw-bold">Last Name</div>
                        <div className="fst-italic"> {props.data.lastName? props.data.lastName : "None"}<br/></div>
                        <div className="fw-bold">Job Title</div>
                        <div className="fst-italic"> {props.data.jobTitle? props.data.jobTitle : "None"}<br/></div>
                        <div className="fw-bold">Line Manager</div>
                        <div className="fst-italic"> {props.data.managerName? props.data.managerName: "None"}<br/></div>
                        <div className="fw-bold">Department</div>
                        <div className="fst-italic"> {props.data.department? props.data.department : "None"}<br/></div>
                    </Col>
                    <Col>
                        <div className="fw-bold">Email Address</div>
                        <div className="fst-italic"> {props.data.email? props.data.email : "None"}<br/></div>
                        <div className="fw-bold">Phone Number</div>
                        <div className="fst-italic"> {props.data.phoneNumber? props.data.phoneNumber : "None"}<br/></div>
                        <div className="fw-bold">Company</div>
                        <div className="fst-italic"> {props.data.company? props.data.company : "None"}<br/></div>
                        <div className="fw-bold">Division</div>
                        <div className="fst-italic"> {props.data.division? props.data.division : "None"}<br/></div>
                        <div className="fw-bold">Team</div>
                        <div className="fst-italic"> {props.data.team? props.data.team : "None"}<br/></div>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Modal fullscreen='md-down' show={open} onHide={closeFn} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{personSummaryError ? 'My Profile Error' : personSummaryLoading || !personSummary ? 'My Profile Loading...' : 'My Profile'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {personSummaryError ? <h6>Error!</h6> : personSummaryLoading || !personSummary ? <div className="loader-container"><div className="loader"></div></div> : <ShowMyProfile data={personSummary} />} 
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={closeFn}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalMyProfile;